/* eslint-disable */
/* global-module */
class OcrResultParser {
    __ocrTypeList = [
      'idcard', 'driver', 'passport', 'foreign-passport', 'alien', 'credit',
      'idcard-ssa', 'driver-ssa', 'passport-ssa', 'foreign-passport-ssa', 'alien-ssa', 'credit-ssa',
    ];
    __ocrResult = null;
    parseOcrResult(ocrType, ssaMode, ocrResult, ssaResult, ssaRetryCount, ssaResultList) {
        if (!this.__ocrTypeList.includes(ocrType)) throw new Error('ResultParser :: Unsupported OCR type');
        this.__ocrResult = ocrResult;
        let parseResult = {};

        switch (ocrType) {
            case 'idcard':
            case 'driver':
            case 'idcard-ssa':
            case 'driver-ssa':
                parseResult = this.__parseIdDriver();
                break;
            case 'passport':
            case 'passport-ssa':
            case 'foreign-passport':
            case 'foreign-passport-ssa':
                parseResult = this.__parsePassport();
                break;
            case 'alien':
            case 'alien-ssa':
                parseResult = this.__parseAlien();
                break;
            case 'credit':
            case 'credit-ssa':
                parseResult = this.__parseCredit();
                break;
            default:
                throw new Error('Unsupported OCR type');
        }

        if (ssaMode && !!ssaResult) {
            [parseResult.truth, parseResult.truthConfidence] = ssaResult.split('/');
            parseResult.truthRetryCount = ssaRetryCount;

            if (ssaRetryCount > 0) {
                let truthResultDetail = [];
                for (const item of ssaResultList) {
                    const [truth, truthConfidence] = item.split('/')
                    const truthResult = {truth: truth, truthConfidence: truthConfidence};
                    truthResultDetail.push(truthResult);
                }
                parseResult.truthResultDetail = truthResultDetail;
            }
        }
        return parseResult;
    }

    __parseIdDriver() {
        const target = {}
        const DlcLength = 14;
        const RrcLength = 12;

        // 아래 내용은 그대로 porting
        const resultSplit = this.__ocrResult.split('/');

        let resultIndex = 0;
        if (resultIndex < resultSplit.length)
            target.Completed = resultSplit[resultIndex], resultIndex++;
        if (resultIndex < resultSplit.length)
            target.type = resultSplit[resultIndex], resultIndex++;

        if (target.type === "1" && resultSplit.length === RrcLength) {
            if (resultIndex < resultSplit.length)
                target.name = resultSplit[resultIndex], resultIndex++;
            if (resultIndex < resultSplit.length)
                target.number = resultSplit[resultIndex], resultIndex++;
            if (resultIndex < resultSplit.length)
                target.Date = resultSplit[resultIndex], resultIndex++;
            if (resultIndex < resultSplit.length) {
                target.region = resultSplit[resultIndex], resultIndex++;
                target.region = target.region.trim();
            }
            if (target.number !== undefined && target.number.length >= 6)
                target.birth = target.number.slice(0, 6);

            if (target.number !== undefined && target.number.length === 13)
                target.number = target.number.slice(0, 6) + '-' + target.number.slice(6, 13);
            else
                target.number = "";

            if (resultIndex < resultSplit.length)
                target.overseasResident = resultSplit[resultIndex], resultIndex++;

        } else if (target.type === "2" && resultSplit.length === DlcLength) {
            if (resultIndex < resultSplit.length)
                target.name = resultSplit[resultIndex], resultIndex++;
            if (resultIndex < resultSplit.length)
                target.number = resultSplit[resultIndex], resultIndex++;
            if (resultIndex < resultSplit.length) {
                target.licenseNumber = resultSplit[resultIndex], resultIndex++;

                // 구형 면허증 포멧 판정 (ex: 제주 13-001234-12 -> true)
                const regex = /[가-힣]/g;
                target.isOldFormatLicenseNumber = !!target.licenseNumber.match(regex)
                if (target.isOldFormatLicenseNumber) {
                    // useb api 포멧에 맞게 변경 (to: 제주-13-001234-12)
                    target.licenseNumber = target.licenseNumber.replace(" ", "-");
                }
            }

            if (resultIndex < resultSplit.length)
                target.Date = resultSplit[resultIndex], resultIndex++;
            if (resultIndex < resultSplit.length) {
                target.region = resultSplit[resultIndex], resultIndex++;
                target.region = target.region.trim();
            }
            if (resultIndex < resultSplit.length)
                target.serial = resultSplit[resultIndex], resultIndex++;
            if (resultIndex < resultSplit.length)
                target.licenseType = resultSplit[resultIndex], resultIndex++;
            if (target.number !== undefined && target.number.length >= 6)
                target.birth = target.number.slice(0, 6);

            if (target.number !== undefined && target.number.length === 13)
                target.number = target.number.slice(0, 6) + '-' + target.number.slice(6, 13);
            else
                target.number = "";
        }

        if (resultIndex < resultSplit.length)
            target.color_point = resultSplit[resultIndex], resultIndex++;
        if (resultIndex < resultSplit.length)
            target.face_score = resultSplit[resultIndex], resultIndex++;
        if (resultIndex < resultSplit.length)
            target.specular = resultSplit[resultIndex], resultIndex++;
        if (resultIndex < resultSplit.length)
            target.start_t = resultSplit[resultIndex], resultIndex++;
        if (resultIndex < resultSplit.length)
            target.end_t = resultSplit[resultIndex], resultIndex++;

        target.id_type = target.type;

        return target
    }
    __parsePassport() {
        const target = {};

        // 아래 내용은 그대로 porting
        const resultSplit = this.__ocrResult.split('/');

        let resultIndex = 0;
        if (resultIndex < resultSplit.length)
            target.Completed = resultSplit[resultIndex], resultIndex++;

        if (resultIndex < resultSplit.length) {
            target.name = resultSplit[resultIndex], resultIndex++;
            target.name = target.name.trim();
        }
        if (resultIndex < resultSplit.length) {
            target.surName = resultSplit[resultIndex], resultIndex++;
            target.surName = target.surName.trim();
        }
        if (resultIndex < resultSplit.length) {
            target.givenName = resultSplit[resultIndex], resultIndex++;
            target.givenName = target.givenName.trim();
        }
        if (resultIndex < resultSplit.length)
            target.type = resultSplit[resultIndex], resultIndex++;
        if (resultIndex < resultSplit.length)
            target.issuing_country = resultSplit[resultIndex], resultIndex++;
        if (resultIndex < resultSplit.length)
            target.passport_no = resultSplit[resultIndex], resultIndex++;
        if (resultIndex < resultSplit.length)
            target.nationality = resultSplit[resultIndex], resultIndex++;
        if (resultIndex < resultSplit.length)
            target.date_of_issue = resultSplit[resultIndex], resultIndex++;
        if (resultIndex < resultSplit.length)
            target.sex = resultSplit[resultIndex], resultIndex++;
        if (resultIndex < resultSplit.length)
            target.date_of_expiry = resultSplit[resultIndex], resultIndex++;
        if (resultIndex < resultSplit.length)
            target.personal_no = resultSplit[resultIndex], resultIndex++;
        if (resultIndex < resultSplit.length)
            target.number = resultSplit[resultIndex], resultIndex++;
        if (resultIndex < resultSplit.length)
            target.date_of_birth = resultSplit[resultIndex], resultIndex++;
        if (resultIndex < resultSplit.length)
            target.name_kor = resultSplit[resultIndex], resultIndex++;
        if (resultIndex < resultSplit.length)
            target.color_point = resultSplit[resultIndex], resultIndex++;
        if (resultIndex < resultSplit.length)
            target.face_score = resultSplit[resultIndex], resultIndex++;
        if (resultIndex < resultSplit.length)
            target.specular = resultSplit[resultIndex], resultIndex++;
        if (resultIndex < resultSplit.length)
            target.mrz1 = resultSplit[resultIndex], resultIndex++;
        if (resultIndex < resultSplit.length)
            target.mrz2 = resultSplit[resultIndex], resultIndex++;
        if (resultIndex < resultSplit.length)
            target.id_type = resultSplit[resultIndex], resultIndex++;

        if (target.number !== undefined && target.number.length === 13)
            target.number = target.number.slice(0, 6) + '-' + target.number.slice(6, 13);
        else
            target.number = "";



        return target
    }
    __parseAlien() {
        const target = {};

        const resultSplit = this.__ocrResult.split('/');

        // 아래 내용은 그대로 porting
        var resultIndex = 0;
        if (resultIndex < resultSplit.length)
            target.Completed = resultSplit[resultIndex], resultIndex++;
        if (resultIndex < resultSplit.length)
            target.name = resultSplit[resultIndex], resultIndex++;
        if (resultIndex < resultSplit.length)
            target.number = resultSplit[resultIndex], resultIndex++;
        if (resultIndex < resultSplit.length)
            target.Date = resultSplit[resultIndex], resultIndex++;
        if (resultIndex < resultSplit.length)
            target.nationality = resultSplit[resultIndex], resultIndex++;
        if (resultIndex < resultSplit.length)
            target.visaType = resultSplit[resultIndex], resultIndex++;
        if (resultIndex < resultSplit.length)
            target.name_kor = resultSplit[resultIndex], resultIndex++;
        if (resultIndex < resultSplit.length)
            target.color_point = resultSplit[resultIndex], resultIndex++;
        if (resultIndex < resultSplit.length)
            target.face_score = resultSplit[resultIndex], resultIndex++;
        if (resultIndex < resultSplit.length)
            target.specular = resultSplit[resultIndex], resultIndex++;
        if (resultIndex < resultSplit.length)
            target.start_t = resultSplit[resultIndex], resultIndex++;
        if (resultIndex < resultSplit.length)
            target.end_t = resultSplit[resultIndex], resultIndex++;
        if (resultIndex < resultSplit.length)
            target.id_type = resultSplit[resultIndex], resultIndex++;

        if (target.number.length >= 6)
            target.birth = target.number.slice(0, 6);

        if (target.number.length === 13)
            target.number = target.number.slice(0, 6) + '-' + target.number.slice(6, 13);
        else
            target.number = "";
        return target
    }
    __parseCredit() {
        const target = {};

        const resultSplit = this.__ocrResult.split(',');

        let resultIndex = 0;
        if (resultIndex < resultSplit.length)
            target.Completed = resultSplit[resultIndex], resultIndex++;
        if (resultIndex < resultSplit.length)
            target.number = resultSplit[resultIndex], resultIndex++;
        if (resultIndex < resultSplit.length)
            target.exp_date = resultSplit[resultIndex], resultIndex++;

        return target
    }
}

export default new OcrResultParser;
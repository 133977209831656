<template>
  <div class="card-result-fail-root">
    <div class="card-result-fail-subject">
      {{ $t('온보딩.신분증 인증') }}
    </div>
    <div class="card-result-fail-info-container">
      <Icon_Card_FailVue />
      <div class="title">{{ $t('신분증.본인 인증 실패') }}</div>
      <div
        class="content"
        v-html="$t('신분증.신분증 진위 확인이 가능한 횟수를 초과하여')"
      ></div>
      <div class="count">{{ $t('신분증.시도 횟수') }} : 3/3</div>
    </div>
    <div
      @click="$emit('ok')"
      class="button-container"
      :id="'CardResultFail_button_ok'"
    >
      {{ $t('버튼.종료') }}
    </div>
  </div>
</template>

<script>
import Icon_Card_FailVue from './DynamicCt_imgs/Icon_Card_Fail.vue';

export default {
  components: { Icon_Card_FailVue },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.card-result-fail-root {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: center;
  background-color: white;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  padding-top: 32px;

  .card-result-fail-subject {
    margin-top: 32px;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    color: var(--surface-high);
  }
}

.card-result-fail-info-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 56px;
  flex-grow: 1;

  & img {
    width: 230px;
    margin-bottom: 51px;
  }

  .title {
    font-weight: bold;
    font-size: 28px;
    line-height: 42px;
    color: var(--surface-high);
    margin-bottom: 24px;
  }

  .content {
    font-weight: normal;
    text-align: center;
    font-size: 16px;
    line-height: 26px;
    color: var(--surface-high);
    margin-bottom: 8px;
  }

  .count {
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    color: var(--error-100);
  }
}

.button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 1rem;
  width: calc(100% - 60px);
  color: var(--font-color);
  background-color: var(--primary-100);
  border-radius: 8px;
  height: 60px;
  margin: 0 30px 48px;
  cursor: pointer;
}
</style>

import Vue from 'vue';
import Vuex from 'vuex';
import isMobile from 'ismobilejs';
Vue.use(Vuex);
const device = isMobile(window.navigator);

export default new Vuex.Store({
  state: {
    isMobile: device.phone || device.tablet,
    serverError: false,
    rootError: false,
    rootErrorCode: '',
    errorCode: '',
    companyPhoneNumber: '',
    companyName: '',
    loading: {
      message: null,
      flag: false,
      desc: null,
    },
    customUIDialog: false,
    customUIIcon: require('@/assets/warning.svg'),
    customUIMessageTitle: [],
    customUIMessage: [],
    customUIerrorCode: '',
    customUIButton: ['', '확인'],
    idCardsFilter: [],
  },
  mutations: {
    setCompanyPhoneNumber(state, payload) {
      state.companyPhoneNumber = payload;
    },
    setCompanyName(state, payload) {
      state.companyName = payload;
    },
    setIdCardsFilter(state, payload) {
      state.idCardsFilter = payload;
    },
    closeErrorDialog(state) {
      state.serverError = false;
      state.errorCode = '';
    },
    closeRootErrorDialog(state) {
      state.rootError = false;
      state.rootErrorCode = '';
    },
    closeCustomUIDialog(state) {
      state.customUIDialog = false;
      state.customUIerrorCode = '';
    },
    setLoading(state, payload) {
      var preventImmutable = {
        message: payload.message,
        flag: payload.flag,
        desc: payload.desc,
      };
      state.loading = preventImmutable;
    },
  },
  actions: {
    showLoadingScreen({ commit }, payload) {
      Vue.$log.debug('index#showLoading', payload);
      commit('setLoading', payload);
    },
  },
});

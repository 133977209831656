<template>
  <svg
    width="1.5em"
    height="1.5em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.87301 17.129C5.02801 15.819 3.56801 14.115 2.74301 13.039C2.51231 12.742 2.38708 12.3766 2.38708 12.0005C2.38708 11.6244 2.51231 11.259 2.74301 10.962C4.23601 9.013 7.81801 5 12 5C13.876 5 15.63 5.807 17.13 6.874"
      stroke="#868686"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14.13 9.88749C13.8523 9.60515 13.5214 9.3806 13.1565 9.22677C12.7916 9.07295 12.3998 8.9929 12.0038 8.99124C11.6078 8.98958 11.2153 9.06634 10.8491 9.21711C10.4829 9.36787 10.1502 9.58964 9.87014 9.86964C9.59008 10.1496 9.36822 10.4823 9.21738 10.8485C9.06653 11.2146 8.98967 11.6071 8.99123 12.0031C8.9928 12.3991 9.07276 12.7909 9.2265 13.1558C9.38024 13.5208 9.60472 13.8517 9.88698 14.1295"
      stroke="#868686"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M4 20L20 4"
      stroke="#868686"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10 18.704C10.6492 18.8972 11.3227 18.9969 12 19C16.182 19 19.764 14.987 21.257 13.038C21.4876 12.7407 21.6127 12.3751 21.6125 11.9988C21.6124 11.6226 21.4869 11.2571 21.256 10.96C20.7313 10.2755 20.1684 9.62112 19.57 9"
      stroke="#868686"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {};
</script>

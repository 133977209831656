export default Object.freeze({
  BANK_NAME: [
    {
      name: 'NH농협',
      fullName: 'NH농협은행',
      img: 'bank-01-NH.svg',
      bank_code: '011',
      bank_support: 'all',
    },
    {
      name: '지역농축협',
      fullName: '지역농축협_F',
      img: 'bank-01-NH.svg',
      bank_code: '012',
      bank_support: 'openbank',
    },
    {
      name: '우리',
      fullName: '우리은행',
      img: 'bank-02-woori.svg',
      bank_code: '020',
      bank_support: 'all',
    },
    {
      name: '신한',
      fullName: '신한은행',
      img: 'bank-03-shinhan.svg',
      bank_code: '088',
      bank_support: 'all',
    },
    {
      name: 'KB국민',
      fullName: 'KB국민은행',
      img: 'bank-04-kb.svg',
      bank_code: '004',
      bank_support: 'all',
    },
    {
      name: '하나',
      fullName: 'KEB하나은행',
      img: 'bank-05-hana.svg',
      bank_code: '081',
      bank_support: 'all',
    },
    {
      name: '씨티',
      fullName: '한국씨티은행',
      img: 'bank-06-citi.svg',
      bank_code: '027',
      bank_support: 'all',
    },
    {
      name: 'IBK기업',
      fullName: 'IBK기업은행',
      img: 'bank-07-ibk.svg',
      bank_code: '003',
      bank_support: 'all',
    },
    {
      name: '케이뱅크',
      fullName: '케이뱅크_F',
      img: 'bank-24-kbank.svg',
      bank_code: '089',
      bank_support: 'all',
    },
    {
      name: '카카오뱅크',
      fullName: '카카오뱅크_F',
      img: 'bank-09-kakao.svg',
      bank_code: '090',
      bank_support: 'all',
    },
    {
      name: '새마을',
      fullName: '새마을금고',
      img: 'bank-10-mg.svg',
      bank_code: '045',
      bank_support: 'all',
    },
    {
      name: '부산',
      fullName: '부산은행',
      img: 'bank-11-bs.svg',
      bank_code: '032',
      bank_support: 'all',
    },
    {
      name: '경남',
      fullName: '경남은행',
      img: 'bank-12-kn.svg',
      bank_code: '039',
      bank_support: 'all',
    },
    {
      name: '광주',
      fullName: '광주은행',
      img: 'bank-13-kj.svg',
      bank_code: '034',
      bank_support: 'all',
    },
    {
      name: '전북',
      fullName: '전북은행',
      img: 'bank-14-jb.svg',
      bank_code: '037',
      bank_support: 'all',
    },
    {
      name: '신협',
      fullName: '신용협동조합',
      img: 'bank-15-shinhyup.svg',
      bank_code: '048',
      bank_support: 'all',
    },
    {
      name: 'SC제일',
      fullName: 'SC제일은행',
      img: 'bank-15-sc.svg',
      bank_code: '023',
      bank_support: 'all',
    },
    {
      name: 'KDB산업',
      fullName: 'KDB산업은행',
      img: 'bank-16-kdb.svg',
      bank_code: '002',
      bank_support: 'all',
    },
    {
      name: '대구',
      fullName: '대구은행',
      img: 'bank-17-dgb.svg',
      bank_code: '031',
      bank_support: 'all',
    },
    {
      name: '제주',
      fullName: '제주은행',
      img: 'bank-03-shinhan.svg',
      bank_code: '035',
      bank_support: 'all',
    },
    {
      name: '우체국',
      fullName: '우체국_F',
      img: 'bank-19-post.svg',
      bank_code: '071',
      bank_support: 'all',
    },
    {
      name: '수협',
      fullName: 'Sh수협은행',
      img: 'bank-20-sh.svg',
      bank_code: '007',
      bank_support: 'all',
    },
    {
      name: '저축',
      fullName: '저축은행',
      img: 'bank-21-sb.svg',
      bank_code: '050',
      bank_support: 'all',
    },
    {
      name: '산림',
      fullName: '산림조합',
      img: 'bank-22-sanrim.svg',
      bank_code: '064',
      bank_support: 'all',
    },
    {
      name: '토스',
      fullName: '토스뱅크',
      img: 'sec-01-toss.svg',
      bank_code: '092',
      bank_support: 'all',
    },
    {
      name: '웰컴',
      fullName: '웰컴저축은행',
      img: 'bank-000-welcome.svg',
      bank_code: '000',
      bank_support: 'firmbank',
    },
    {
      name: 'HSBC',
      fullName: 'HSBC_F',
      img: 'bank-054-hsbc.svg',
      bank_code: '054',
      bank_support: 'firmbank',
    },
  ],
  BROKERAGE: [
    {
      name: '토스증권',
      fullName: '토스증권_F',
      img: 'sec-01-toss.svg',
      bank_code: '271',
      bank_support: 'all',
    },
    {
      name: '키움',
      fullName: '키움증권',
      img: 'sec-02-kiwoom.svg',
      bank_code: '264',
      bank_support: 'all',
    },

    {
      name: '미래에셋',
      fullName: '미래에셋_F',
      img: 'sec-03-mirae.svg',
      bank_code: '238',
      bank_support: 'all',
    },
    {
      name: '삼성',
      fullName: '삼성증권',
      img: 'sec-04-samsung.svg',
      bank_code: '240',
      bank_support: 'all',
    },
    {
      name: 'NH투자',
      fullName: 'NH투자증권',
      img: 'sec-05-nh.svg',
      bank_code: '247',
      bank_support: 'all',
    },
    {
      name: '대신',
      fullName: '대신증권',
      img: 'sec-06-daeshin.svg',
      bank_code: '267',
      bank_support: 'all',
    },
    {
      name: '신한투자',
      fullName: '신한투자증권',
      img: 'sec-07-shinhan.svg',
      bank_code: '278',
      bank_support: 'all',
    },
    {
      name: '메리츠',
      fullName: '메리츠증권',
      img: 'sec-08-meritz.svg',
      bank_code: '287',
      bank_support: 'all',
    },
    {
      name: '카카오페이',
      fullName: '카카오페이증권',
      img: 'sec-288-kakaopay.svg',
      bank_code: '288',
      bank_support: 'firmbank',
    },
    {
      name: '부국',
      fullName: '부국증권',
      img: 'sec-290-bukuk.svg',
      bank_code: '290',
      bank_support: 'firmbank',
    },
    {
      name: '케이프',
      fullName: '케이프투자증권',
      img: 'sec-292-kape.svg',
      bank_code: '292',
      bank_support: 'firmbank',
    },
    {
      name: '신영',
      fullName: '신영증권',
      img: 'sec-291-sinyoung.svg',
      bank_code: '291',
      bank_support: 'firmbank',
    },
    {
      name: '유진투자',
      fullName: '유진투자증권',
      img: 'sec-09-eugene.svg',
      bank_code: '280',
      bank_support: 'all',
    },
    {
      name: 'KB',
      fullName: 'KB증권',
      img: 'sec-10-kb.svg',
      bank_code: '218',
      bank_support: 'all',
    },
    {
      name: '한국투자',
      fullName: '한국투자증권',
      img: 'sec-11-hankook.svg',
      bank_code: '243',
      bank_support: 'all',
    },
    {
      name: '교보',
      fullName: '교보증권',
      img: 'sec-12-kyobo.svg',
      bank_code: '261',
      bank_support: 'all',
    },
    {
      name: '하이투자',
      fullName: '하이투자증권',
      img: 'sec-13-hi.svg',
      bank_code: '262',
      bank_support: 'all',
    },
    {
      name: '현대차',
      fullName: '현대차증권',
      img: 'sec-263-HMC.svg',
      bank_code: '263',
      bank_support: 'all',
    },
    {
      name: '이베스트',
      fullName: '이베스트증권',
      img: 'sec-15-ebest.svg',
      bank_code: '265',
      bank_support: 'all',
    },
    {
      name: 'SK',
      fullName: 'SK증권',
      img: 'sec-16-sk.svg',
      bank_code: '266',
      bank_support: 'all',
    },
    {
      name: '하나증권',
      fullName: '하나증권_F',
      img: 'bank-05-hana.svg',
      bank_code: '270',
      bank_support: 'all',
    },
    {
      name: '한화투자',
      fullName: '한화투자증권',
      img: 'sec-17-hanhwa.svg',
      bank_code: '269',
      bank_support: 'all',
    },
    {
      name: 'DB금융투자',
      fullName: 'DB금융투자_F',
      img: 'sec-18-db.svg',
      bank_code: '279',
      bank_support: 'all',
    },
    {
      name: '유안타',
      fullName: '유안타증권',
      img: 'sec-209-uanta.svg',
      bank_code: '209',
      bank_support: 'firmbank',
    },
    {
      name: '상상인',
      fullName: '상상인증권',
      img: 'sec-221-sangsangin.svg',
      bank_code: '221',
      bank_support: 'firmbank',
    },
    {
      name: '한양',
      fullName: '한양증권',
      img: 'sec-222-hanyang.svg',
      bank_code: '222',
      bank_support: 'firmbank',
    },
    {
      name: '리딩투자',
      fullName: '리딩투자증권',
      img: 'sec-223-leading.svg',
      bank_code: '223',
      bank_support: 'firmbank',
    },
    {
      name: 'BNK투자',
      fullName: 'BNK투자증권',
      img: 'sec-224-bnk.svg',
      bank_code: '224',
      bank_support: 'firmbank',
    },
    {
      name: 'IBK투자',
      fullName: 'IBK투자증권',
      img: 'sec-225-ibk.svg',
      bank_code: '225',
      bank_support: 'firmbank',
    },
    {
      name: '다올투자',
      fullName: '다올투자증권',
      img: 'sec-227-daol.svg',
      bank_code: '227',
      bank_support: 'all',
    },
  ],
});

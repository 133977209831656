<template>
  <v-dialog
    v-model="dialog"
    style="width: calc(100% - 96px)"
    width="calc(100% - 96px)"
    max-width="384px"
    @click:outside="() => {}"
    @keydown.esc="() => {}"
    persistent
  >
    <v-card>
      <v-card-text
        :id="id"
        :class="{ disabled: disabledProp }"
        style="
          display: flex;
          flex-direction: column;
          padding: 0px 30px;
          padding-top: 2rem;
          margin: 0;
          text-align: center;
        "
      >
        <img
          @click="stopScan = true"
          src="@/assets/warning.svg"
          class="icon-close"
          style="
            width: 60px;
            height: 60px;
            margin: 0px auto 18px;
            display: none;
          "
        />

        <div v-if="errorType === 'maintenanceError'" class="card-title">
          <div class="card-title">{{ $t('계좌.송금 실패') }}</div>
          <div
            class="card-body"
            v-html="
              $t(
                '계좌.은행 점검 시간으로 인해 <br />송금이 불가하여 <br />고객 신원 확인을 종료합니다. <br />서비스 가능 시간 확인 후 <br />재시도 해주세요.'
              )
            "
          />
          {{ errorCode ? `${$t('에러.에러코드')}: ${errorCode}` : '' }}
          <div class="button-container" style="margin-bottom: 32px">
            <div @click="cancel" class="button ok">{{ $t('버튼.확인') }}</div>
          </div>
        </div>
        <div v-else-if="errorType === 'verifyError'">
          <div class="card-title">{{ $t('계좌.계좌 검증 실패') }}</div>
          <div class="card-body" style="word-break: keep-all">
            {{ $t('계좌.입력하신 계좌가 해당 은행에 존재하지 않거나') }}
            {{ $t('계좌.예금주명이 일치하지 않습니다.') }}
            <div class="account-info" style="word-break: normal">
              {{
                $t(
                  '계좌.* 미니계좌, 가상계좌 등 본인명의 실계좌가 아닐경우 1원 인증이 어렵습니다.'
                )
              }}
            </div>
          </div>
          {{ errorCode ? `${$t('에러.에러코드')}: ${errorCode}` : '' }}
          <div class="button-container" style="margin-bottom: 32px">
            <div @click="retry" class="button ok">{{ $t('버튼.재시도') }}</div>
          </div>
        </div>
        <div v-else-if="errorType === 'accountNumberError'">
          <div class="card-title">{{ $t('계좌.계좌 검증 실패') }}</div>
          <div class="card-body">
            {{
              $t(
                '계좌.평생계좌번호일 경우 예금주 정보를 조회할 수 없습니다. 원계좌번호로 다시 시도해주세요.'
              )
            }}
            <div
              class="account-info"
              v-html="
                $t(
                  '계좌.* 평생계좌번호란? <br />휴대전화번호 등 고객이 임의 생성한 계좌번호'
                )
              "
            />
          </div>
          {{ errorCode ? `${$t('에러.에러코드')}: ${errorCode}` : '' }}
          <div class="button-container" style="margin-bottom: 32px">
            <div @click="retry" class="button ok">{{ $t('버튼.재시도') }}</div>
          </div>
        </div>
        <div v-else>
          <div class="card-title">{{ $t('계좌.시스템 오류') }}</div>
          <div
            class="card-body"
            v-html="
              $t(
                '계좌.시스템 오류로 1원 송금에 실패하였습니다.<br />잠시 후 재시도 해주세요.'
              )
            "
          />
          {{ errorCode ? `${$t('에러.에러코드')}: ${errorCode}` : '' }}
          <div class="button-container" style="margin-bottom: 32px">
            <div @click="cancel" class="button ok">{{ $t('버튼.확인') }}</div>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    errorCode: {
      type: String,
    },
    errorType: {
      type: String,
    },
    id: {
      type: String,
    },
    disabledProp: {
      type: Boolean,
    },
  },
  data() {
    return {
      dialog: this.value,
    };
  },
  watch: {
    value(val) {
      this.dialog = val;
    },
  },
  methods: {
    close() {
      this.dialog = false;
      this.$emit('input', false);
    },
    cancel() {
      this.close();
      this.$emit('cancel');
    },
    retry() {
      this.close();
      this.$emit('retry');
    },
  },
};
</script>

<style lang="scss" scoped>
.card-title {
  font-style: normal;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 2rem;
  color: var(--surface-high);
}

.card-body {
  /* KR / Body 3 - 16 */

  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 26px;

  color: var(--surface-high);
  margin: 8px 0px 32px;

  .account-info {
    border-radius: 8px;
    background-color: var(--gray-100);
    color: var(--surface-medium);
    font-size: 14px;
    padding: 10px 16px;
    margin: 16px 12px auto;
    text-align: left;
  }
}
.button-container {
  display: flex;
  margin-top: 32px;
  margin-bottom: 39px;

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    font-weight: 500;
    font-size: 1rem;
    height: 60px;
    cursor: pointer;
    user-select: none;

    &.cancel {
      flex-grow: 0.65;
      background: var(--gray-100);
      color: var(--surface-medium);
      margin-right: 10px;
    }

    &.ok {
      flex-grow: 1;
      color: var(--font-color);
      background: var(--primary-100);
    }
  }
}
</style>

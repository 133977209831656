<template>
  <div class="additional-input-ox-addr-container">
    <RadioField :field="field" @change="onUpdate">
      <div v-for="item in field.tree" :key="`${item.name}`">
        <div :value="item.name" :score="item.score">{{ item.name }}</div>
      </div>
    </RadioField>
    <p class="additional-input-label">거주지</p>
    <GooglePlaceWrapper>
      <GooglePlaceAutoComplete v-model="address" :nations="nations" />
    </GooglePlaceWrapper>
  </div>
</template>
<script>
import RadioField from './RadioField.vue';
import GooglePlaceWrapper from './googlePlace/GooglePlaceWrapper.vue';
import GooglePlaceAutoComplete from './googlePlace/GooglePlaceAutoComplete.vue';

export default {
  components: {
    RadioField,
    GooglePlaceWrapper,
    GooglePlaceAutoComplete,
  },
  props: {
    field: Object,
    nations: Array,
  },
  data: function () {
    return {
      radioSelected: { label: '', value: '', score: 0 },
      address: { nationality: '', address: '', subAddress: '' },
    };
  },
  watch: {
    address: function (newValue) {
      const { nationality, address, subAddress } = newValue;
      const nation_info = nationality && address ? [nationality, address, subAddress] : null; // prettier-ignore
      this.$log.debug('EddFieldAddr #watch address', { nationality, address, subAddress, nation_info }); // prettier-ignore
      this.$emit('change', {
        field: this.field,
        item: { ...this.radioSelected, nation_info },
      });
    },
  },
  mounted() {},
  updated() {
    this.$log.debug('EddFieldAddr #updated', { address: this.address });
  },
  methods: {
    onUpdate(arg) {
      this.$log.debug('EddFieldAddr #onUpdate', { arg, address: this.address });
      const { nationality, address, subAddress } = this.address;
      const nation_info =
        nationality && address ? [nationality, address, subAddress] : null;
      this.$set(this, 'radioSelected', { ...arg.item });
      this.$emit('change', {
        field: this.field,
        item: { ...arg.item, nation_info },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.additional-input-ox-addr-container {
  .additional-input-label {
    margin-top: 8px;
    font-size: 16px;
    color: var(--surface-medium);
    margin-bottom: 8px;
  }
}
</style>

<template>
  <v-dialog
    v-model="dialog"
    style="width: calc(100% - 96px)"
    width="calc(100% - 96px)"
    max-width="384px"
    @click:outside="() => {}"
    @keydown.esc="() => {}"
    persistent
  >
    <v-card>
      <v-card-text
        :id="id"
        :class="{ disabled: disabledProp }"
        style="
          display: flex;
          flex-direction: column;
          padding: 0px 30px;
          padding-top: 2rem;
          margin: 0;
          text-align: center;
        "
      >
        <img
          @click="stopScan = true"
          src="@/assets/warning.svg"
          class="icon-close"
          style="
            width: 60px;
            height: 60px;
            margin: 0px auto 18px;
            display: none;
          "
        />
        <div class="card-title">{{ $t('계좌.계좌 검증 실패') }}</div>
        <div
          class="card-body"
          v-html="
            $t('계좌.검증실패.예금주.valid', {
              userName: this.userName,
              accountHolder: this.accountHolder,
            })
          "
        />
        <div class="button-container" style="margin-bottom: 32px">
          <div @click="cancel" class="button cancel">
            {{ $t('버튼.아니오') }}
          </div>
          <div @click="ok" class="button ok">
            <span v-if="!loading">{{ $t('버튼.맞습니다') }}</span>
            <Loading v-else style="width: 88.5px" />
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import Loading from '../Loading.vue';

export default {
  components: {
    Loading,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    userName: {
      type: String,
      required: true,
    },
    accountHolder: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    id: {
      type: String,
    },
    disabledProp: {
      type: Boolean,
    },
  },
  data() {
    return {
      dialog: this.value,
    };
  },
  watch: {
    value(val) {
      this.dialog = val;
    },
  },
  methods: {
    close() {
      this.dialog = false;
      this.$emit('input', false);
    },
    cancel() {
      this.close();
      this.$emit('cancel');
    },
    ok() {
      this.$emit('ok');
    },
  },
};
</script>

<style lang="scss" scoped>
.card-title {
  font-style: normal;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 2rem;
  color: var(--surface-high);
}

.card-body {
  /* KR / Body 3 - 16 */

  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 26px;

  color: var(--surface-high);
  margin: 8px 0px 32px;
}
.button-container {
  display: flex;
  margin-bottom: 39px;

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    font-weight: 500;
    font-size: 1rem;
    height: 60px;
    cursor: pointer;
    user-select: none;

    &.cancel {
      flex-grow: 0.65;
      background: var(--gray-100);
      color: var(--surface-medium);
      margin-right: 10px;
    }

    &.ok {
      flex-grow: 1;
      color: var(--font-color);
      background: var(--primary-100);
    }
  }
}
</style>

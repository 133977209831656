<template>
  <svg
    width="220"
    height="160"
    viewBox="0 0 220 160"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="144" width="76" height="38" rx="8" fill="var(--primary-5)" />
    <rect y="105" width="38" height="47" rx="8" fill="var(--primary-5)" />
    <rect
      x="15"
      y="20.0645"
      width="190"
      height="118"
      rx="8"
      fill="var(--primary-10)"
    />
    <rect
      x="127.961"
      y="39.0254"
      width="56.8831"
      height="67.3117"
      fill="var(--primary-5)"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M161.948 75.266C165.344 73.2126 167.613 69.4852 167.613 65.2276C167.613 58.7533 162.365 53.5049 155.891 53.5049C149.416 53.5049 144.168 58.7533 144.168 65.2276C144.168 70.0013 147.021 74.1085 151.115 75.9368C151.391 78.3148 150.761 81.5769 150.761 81.5769H162.219C162.219 81.5769 161.316 77.7796 161.948 75.266Z"
      fill="var(--primary-10)"
    />
    <path
      d="M127.961 106.338H156.402H184.844C184.464 102.002 184.215 101.621 184.215 100.53C184.215 95.7197 180.276 90.4698 180.276 90.4698L178.865 89.691L167.089 86.0199L164.041 82.0645C163.722 81.6491 163.311 81.3133 162.84 81.0836C162.369 80.8538 161.851 80.7363 161.327 80.7403L151.699 80.8125C150.852 80.819 150.038 81.1414 149.416 81.7165L143.454 87.2422L134.711 91.4141L134.696 91.3993L134.604 91.4671L133.307 92.4008L132.472 93.0035C132.472 93.0035 130.282 95.6284 128.532 103.501C128.269 104.685 128.084 103.823 127.961 106.338Z"
      fill="var(--primary-20)"
    />
    <path
      d="M142.812 53.9501C142.901 52.6039 143.791 51.2263 146.62 50.5065C146.62 50.5065 151.474 44.2993 158.07 47.5544C158.07 47.5544 161.268 47.0043 163.639 50.3222C163.639 50.3222 165.99 48.9356 166.982 51.3933C166.982 51.3933 169.74 56.4064 168.724 61.5198C167.709 66.6332 167.19 67.2713 167.19 67.2713C167.19 67.2713 168.484 56.6944 161.031 57.4278C153.578 58.1612 147.578 55.5404 145.891 61.2786C144.489 66.0434 144.666 68.5181 144.666 68.5181C144.666 68.5181 139.979 63.7647 141.573 60.0219C142.388 58.1075 142.676 56.0262 142.812 53.9501Z"
      fill="var(--primary-20)"
    />
    <rect
      x="34.1035"
      y="51.3506"
      width="34.1299"
      height="3.79221"
      rx="1.8961"
      fill="var(--primary-20)"
    />
    <rect
      x="34.1035"
      y="73.1553"
      width="67.3117"
      height="3.79221"
      rx="1.8961"
      fill="var(--primary-20)"
    />
    <rect
      x="34.1035"
      y="84.5322"
      width="67.3117"
      height="3.79221"
      rx="1.8961"
      fill="var(--primary-20)"
    />
    <rect
      x="34.1035"
      y="94.9609"
      width="67.3117"
      height="3.79221"
      rx="1.8961"
      fill="var(--primary-20)"
    />
    <circle cx="109.809" cy="139" r="21" fill="#F53C3C" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M103.293 134.115C102.902 133.725 102.902 133.092 103.293 132.701C103.683 132.311 104.317 132.311 104.707 132.701L110 137.994L115.293 132.701C115.683 132.311 116.317 132.311 116.707 132.701C117.098 133.092 117.098 133.725 116.707 134.115L111.414 139.408L116.707 144.701C117.098 145.092 117.098 145.725 116.707 146.115C116.317 146.506 115.683 146.506 115.293 146.115L110 140.822L104.707 146.115C104.317 146.506 103.683 146.506 103.293 146.115C102.902 145.725 102.902 145.092 103.293 144.701L108.586 139.408L103.293 134.115Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {};
</script>

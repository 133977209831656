<template>
  <svg
    width="252"
    height="108"
    viewBox="0 0 252 108"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="179" cy="22" r="20" fill="var(--primary-10)" />
    <circle cx="73" cy="86" r="20" fill="var(--primary-10)" />
    <circle
      cx="126"
      cy="54"
      r="52"
      stroke="var(--primary-80)"
      stroke-width="3.5"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-dasharray="4 10"
    />
    <circle cx="33" cy="56" r="5" fill="var(--primary-40)" />
    <circle cx="209.5" cy="38.5" r="8.5" fill="var(--primary-20)" />
    <path
      d="M98 54H154"
      stroke="var(--primary-80)"
      stroke-width="4"
      stroke-linecap="round"
    />
    <path
      d="M126 82L126 26"
      stroke="var(--primary-80)"
      stroke-width="4"
      stroke-linecap="round"
    />
  </svg>
</template>

<script>
export default {};
</script>

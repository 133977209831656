<template>
  <ViewContainer id="accountHolder_container">
    <Step v-if="showStepper" :phase="phase" :items="phaseItems" />
    <div class="accountHolder-title">
      <h2 class="bank-title">{{ $t('온보딩.계좌 인증을 시작합니다.') }}</h2>
      <h2 class="bank-title">
        {{ $t('온보딩.본인 명의 계좌를 준비해 주세요.') }}
      </h2>
      <!-- <label class="bank-label">테스트를 위한 계좌 소유자 입력 창입니다.</label> -->
    </div>
    <div class="guide-account">
      <Guide_AccountVue />
    </div>
    <div class="guide-container">
      <h2 class="bank-title">{{ $t('온보딩.올바른 인증 TIP') }}</h2>
      <div class="guide-detail">
        <div>
          <img src="@/assets/check_24.svg" />
        </div>
        <p
          class="bank-label"
          v-html="$t(`온보딩.본인 명의의 계좌로만 인증이 가능합니다.`)"
        />
      </div>
      <div class="guide-detail">
        <div>
          <img src="@/assets/check_24.svg" />
        </div>
        <p
          class="bank-label"
          v-html="$t(`온보딩.계좌 인증 유효 시간은 최대 5분입니다.`)"
        />
      </div>
      <div class="guide-detail">
        <div>
          <img src="@/assets/check_24.svg" />
        </div>
        <p
          class="bank-label"
          v-html="$t(`온보딩.가상 계좌번호는 지정할 수 없습니다.`)"
        />
      </div>
    </div>
    <div class="button-container">
      <v-btn
        class="prev"
        color="var(--surface-medium)"
        @click="onClickBack"
        id="accountHolder_cancel_button"
        text
      >
        {{ $t('버튼.취소') }}
      </v-btn>
      <v-btn
        class="next"
        dark
        :loading="loading"
        color="var(--primary-100)"
        @click="onClickNext"
        id="accountHolder_submit_button"
      >
        {{ $t('버튼.계좌 입력') }}
      </v-btn>
    </div>
    <ExitDialog
      id="bankGuide_exitPopup"
      v-model="exitDialog"
      @ok="$emit('cancel')"
      :disabledProp="!exitDialog"
    />
    <ExitDisabledDialog
      v-model="exitDisabledDialog"
      @cancel="exitDisabledDialog = false"
      @ok="$emit('cancel')"
      :id="'additionalGuide_exitDisabledPopup'"
      :disabledProp="!exitDisabledDialog"
    />
  </ViewContainer>
</template>
<script>
import Step from '../auth/Step';
import ViewContainer from '../../../layout/auth/ViewContainer.vue';
import ExitDialog from './dialog/ExitDialog.vue';
import ExitDisabledDialog from '../dialog/ExitDisabledDialog.vue';
import Guide_AccountVue from '../auth/DynamicCt_imgs/Guide_Account.vue';

export default {
  components: {
    Step,
    ViewContainer,
    ExitDialog,
    ExitDisabledDialog,
    Guide_AccountVue,
  },
  props: {
    phase: Number,
    phaseItems: Array,
    appData: Object,
    showStepper: Boolean,
    isMultipleModule: Boolean,
  },
  mounted() {
    this.$log.debug('AccountHolder#mounted', { phaseItems: this.phaseItems });
  },
  data() {
    return {
      loading: false,
      exitDialog: false,
      exitDisabledDialog: false,
    };
  },
  methods: {
    onClickBack() {
      if (this.phaseItems[0] === this.$t('온보딩.계좌 인증')) {
        this.$emit('cancel');
      } else {
        if (this.isMultipleModule) {
          this.exitDisabledDialog = true;
        } else {
          this.exitDialog = true;
        }
      }
    },
    onClickNext() {
      this.$emit('next');
    },
  },
};
</script>
<style lang="scss" scoped>
@media (min-width: 800px) {
  .container {
    width: 800px !important;
    margin: auto !important;
  }
}

.accountHolder-title {
  text-align: center;
  margin-bottom: 40px;
}

.guide-account {
  width: 94px;
  height: 94px;
  border-radius: 50%;
  margin: 0px auto 50px;
}

.guide-container {
  .bank-title {
    font-style: normal;
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 2rem;

    color: var(--surface-high);
  }
  h2 {
    text-align: start;
    margin-bottom: 1.5rem;
  }
  div {
    display: flex;
    width: 100%;
  }
  .guide-detail {
    margin-bottom: 1rem;
    &:first-child {
      margin-top: 1.5rem;
    }
    &:last-child {
      margin-bottom: 0px;
    }
    div {
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      img {
        width: 13.33px;
        height: 10px;
      }
    }
    .bank-label {
      font-style: normal;
      font-weight: normal;
      font-size: 1rem;
      line-height: 1.625rem;
      color: var(--surface-high);
    }
  }
}

.bold {
  color: var(--primary-80);
}

.button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 40px;

  /* height: 116px; */
  cursor: pointer;
  background-color: white;
  /* border-top: 1px solid var(--gray-100); */
  button {
    height: 60px !important;
    border-radius: 8px;
    font-size: 1rem;
  }
  .prev {
    flex: 3.5;
    background-color: #f6f6f6;
    margin-right: 4px;
  }
  .next {
    flex: 6.5;
    color: var(--font-color);
    margin-left: 4px;
    box-shadow: none;
  }
}
</style>

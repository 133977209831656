<template>
  <v-dialog :value="show" max-width="384px" persistent>
    <v-card>
      <v-card-text :id="id" :class="{ disabled: disabledProp }">
        <div>
          <div class="title">{{ $t('신분증.정보 수정 안내') }}</div>
          <div class="message-top" v-html="message"></div>
          <div
            class="message-bottom"
            v-html="
              $t(
                '신분증.고객 센터를 통한 수정이 완료된 후 재시도해 주시기 바랍니다.'
              )
            "
          ></div>
          <div v-ripple class="button" @click="onClick">
            {{ $t('버튼.확인') }}
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'CustomerParameterContactInfoDialog',
  props: {
    show: {
      type: Boolean,
      required: true,
      default: false,
    },
    id: {
      type: String,
    },
    disabledProp: {
      type: Boolean,
    },
  },
  computed: {
    ...mapState(['companyPhoneNumber', 'companyName']),
    message() {
      if (this.companyPhoneNumber === '0000') {
        return this.$t('신분증.고객님의 정보가 올바르지 않다면_고객사', {
          companyName: this.companyName,
        });
      }
      return this.$t('신분증.고객님의 정보가 올바르지 않다면', {
        companyPhoneNumber: this.companyPhoneNumber,
      });
    },
  },
  methods: {
    onClick() {
      this.$emit('ok');
    },
  },
};
</script>

<style lang="scss" scoped>
.v-card__text {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;

  & > div {
    margin: 30px 32px;
    color: var(--surface-high);
  }

  .title {
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 2rem;
    text-align: center;
    margin-bottom: 16px;
  }
}

@media screen and (max-width: 768px) {
  .v-card__text {
    & > div {
      margin: 30px 0 15px;
    }
  }
}

.message-top {
  font-size: min(4vmin, 1rem);
  line-height: 1.5rem;
  text-align: center;
  padding: 16px 20px;
  border-radius: 8px;
  background-color: var(--gray-100);
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 16px;

  .phone-number-text {
    font-weight: 700;
    color: var(--primary-100);
  }
}

.message-bottom {
  font-size: 1rem;
  line-height: 1.75rem;
  text-align: center;
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.button {
  border-radius: 8px;
  background-color: var(--primary-100);
  color: var(--font-color);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  line-height: 36px;
  height: 60px;
  font-weight: 500;
}
</style>

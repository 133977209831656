<template>
  <svg
    width="98"
    height="98"
    viewBox="0 0 98 98"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M86.3256 49C86.3256 69.6143 69.6143 86.3256 49 86.3256C28.3857 86.3256 11.6744 69.6143 11.6744 49C11.6744 28.3857 28.3857 11.6744 49 11.6744C69.6143 11.6744 86.3256 28.3857 86.3256 49Z"
      stroke="var(--primary-80)"
      stroke-width="3.34884"
    />
    <path
      d="M32.0029 36.4425L40.0629 65.1003L48.1229 36.4425"
      stroke="var(--primary-80)"
      stroke-width="3.34884"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M48.1295 36.4425L56.1895 65.1003L64.2495 36.4425"
      stroke="var(--primary-80)"
      stroke-width="3.34884"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M30.2183 46.9923H48.1294H66.0405"
      stroke="var(--primary-80)"
      stroke-width="3.34884"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <circle
      cx="49"
      cy="49"
      r="47"
      stroke="var(--primary-80)"
      stroke-width="3.34884"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-dasharray="2.23 6.7"
    />
  </svg>
</template>

<script>
export default {};
</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; background: none; display: block; shape-rendering: auto;" width="40px" height="40px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
    <circle cx="84" cy="50" r="10" fill="#ffffff">
      <animate attributeName="r" repeatCount="indefinite" dur="0.6756756756756757s" calcMode="spline" keyTimes="0;1" values="8;0" keySplines="0 0.5 0.5 1" begin="0s"></animate>
      <animate attributeName="fill" repeatCount="indefinite" dur="2.7027027027027026s" calcMode="discrete" keyTimes="0;0.25;0.5;0.75;1" values="#ffffff;#ffffff;#ffffff;#ffffff;#ffffff" begin="0s"></animate>
    </circle>
    <circle cx="16" cy="50" r="10" fill="#ffffff">
      <animate attributeName="r" repeatCount="indefinite" dur="2.7027027027027026s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="0;0;8;8;8" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="0s"></animate>
      <animate attributeName="cx" repeatCount="indefinite" dur="2.7027027027027026s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="16;16;16;50;84" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="0s"></animate>
    </circle>
    <circle cx="50" cy="50" r="10" fill="#ffffff">
      <animate attributeName="r" repeatCount="indefinite" dur="2.7027027027027026s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="0;0;8;8;8" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.6756756756756757s"></animate>
      <animate attributeName="cx" repeatCount="indefinite" dur="2.7027027027027026s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="16;16;16;50;84" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.6756756756756757s"></animate>
    </circle>
    <circle cx="84" cy="50" r="10" fill="#ffffff">
      <animate attributeName="r" repeatCount="indefinite" dur="2.7027027027027026s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="0;0;8;8;8" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-1.3513513513513513s"></animate>
      <animate attributeName="cx" repeatCount="indefinite" dur="2.7027027027027026s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="16;16;16;50;84" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-1.3513513513513513s"></animate>
    </circle>
    <circle cx="16" cy="50" r="10" fill="#ffffff">
      <animate attributeName="r" repeatCount="indefinite" dur="2.7027027027027026s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="0;0;8;8;8" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-2.027027027027027s"></animate>
      <animate attributeName="cx" repeatCount="indefinite" dur="2.7027027027027026s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="16;16;16;50;84" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-2.027027027027027s"></animate>
    </circle>
  </svg>
</template>

<script>
export default {};
</script>

<template>
  <div class="radio__input__container">
    <div class="radio__input__option__wrapper">
      <div
        v-for="(option, index) in slotOptions"
        :key="index"
        :class="['radio__option', { selected: selectedValue === option.value }]"
      >
        <label>
          <input type="radio" :value="option.value" v-model="selectedValue" />
          {{ option.label }}
        </label>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: { field: Object, value: { type: String, defualt: null } },
  data: function () {
    return { selectedValue: null };
  },
  computed: {
    slotOptions() {
      return this.$slots.default.map((vnode) => {
        const { children } = vnode;
        const [item] = children;
        const node = item.data.attrs;

        return { ...node, label: node.value, value: node.value };
      });
    },
  },
  watch: {
    selectedValue(newValue) {
      const selectedSlots = this.slotOptions.find(
        (opt) => opt.value === newValue
      );
      this.$log.debug('RadioField #watch selectedValue', { selectedSlots });
      if (selectedSlots) {
        this.$emit('change', { item: selectedSlots, field: this.field });
      }
    },
  },
  mounted() {
    this.selectedValue = this.value ?? null;
  },
};
</script>
<style lang="scss">
.radio__input__container {
  .radio__input__option__wrapper {
    display: flex;
    align-items: initial;
    justify-content: initial;

    .radio__option {
      width: 100%;
      box-shadow: 0px 0px 0px 1px var(--gray-300) inset;
      -webkit-box-shadow: 0px 0px 0px 1px var(--gray-300) inset;
      -moz-box-shadow: 0px 0px 0px 1px var(--gray-300) inset;
      cursor: pointer;

      &:first-child {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }
      &:last-child {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }
      &.selected {
        box-shadow: 0px 0px 0px 2px var(--primary-80) inset;
        -webkit-box-shadow: 0px 0px 0px 2px var(--primary-80) inset;
        -moz-box-shadow: 0px 0px 0px 2px var(--primary-80) inset;

        label {
          color: var(--surface-high);
        }
      }

      label {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        padding: 16px;
        font-size: 20px;
        color: var(--surface-medium);
        cursor: pointer;
      }
      input {
        appearance: none;
      }
    }
  }
}
</style>

<template>
  <div class="card-result-fail-root">
    <div class="card-result-fail-subject">
      {{ $t('온보딩.얼굴 인증') }}
    </div>
    <div class="card-result-fail-info-container">
      <Face_ScanFailVue class="face-scan-fail" />
      <div class="fail-title">{{ $t('얼굴.얼굴 인증 실패') }}</div>
      <div class="fail-content">
        {{ $t('얼굴.얼굴 위변조 여부 판정 결과') }}<br />
        {{ $t('얼굴.실제 얼굴 여부 인증에 실패하였습니다.') }}
      </div>
      <div v-if="errorCode" class="fail-code">
        {{ $t('에러.에러코드') }} : {{ errorCode }}
      </div>
    </div>
    <div @click="$emit('ok')" class="button-container">
      {{ $t('버튼.종료') }}
    </div>
  </div>
</template>

<script>
import Face_ScanFailVue from './DynamicCt_imgs/Face_ScanFail.vue';

export default {
  components: { Face_ScanFailVue },
  props: {
    errorCode: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.card-result-fail-root {
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  width: 100%;
  height: 100%;
  padding-top: 32px;

  .card-result-fail-subject {
    margin-top: 32px;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    color: var(--surface-high);
  }
}

.card-result-fail-info-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-grow: 1;

  .face-scan-fail {
    width: 184px;
    margin-bottom: 57px;
    margin-right: 22px;
  }

  .fail-title {
    font-weight: bold;
    font-size: 28px;
    line-height: 42px;
    color: var(--surface-high);
    margin-bottom: 24px;
  }

  .fail-content {
    font-weight: normal;
    text-align: center;
    font-size: 16px;
    line-height: 26px;
    color: var(--surface-high);
    margin-bottom: 8px;
  }

  .fail-code {
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    color: var(--surface-medium);
  }
}

.button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 1rem;
  width: calc(100% - 60px);
  color: var(--font-color);
  background-color: var(--primary-100);
  border-radius: 8px;
  height: 60px;
  margin: 0 30px 48px;
}
</style>

<template>
  <div class="step-container">
    <div
      v-for="(item, index) in items"
      :key="index"
      class="step-container--item"
    >
      <div v-if="phase === index" class="step-container--current">
        {{ `${index + 1}. ${item}` }}
      </div>
      <div class="step-container--candidate" v-else>
        {{ index + 1 }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    phase: {
      type: Number,
      default: 0,
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
.step-container {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  // margin-top: 45px;
  margin-bottom: 48px;
  font-weight: 500;
  font-size: 14px;
  // 신규 추가
  .step-container--item {
    .step-container--current {
      padding: 3px 10px 5px;
      /* Primary/5% */

      border: 1px solid var(--primary-80);
      border-radius: 40px;

      font-size: 0.875rem;
      line-height: 1.5rem;
      color: var(--primary-80);
    }
    .step-container--candidate {
      width: 32px;
      height: 32px;

      /* On Surface/Disabled */

      border: 1px solid var(--surface-disabled);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 17px;

      font-size: 0.875rem;
      line-height: 1.5rem;

      color: var(--surface-disabled);
    }
  }
}
</style>

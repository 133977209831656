<template>
  <div class="card-result-fail-root" id="cardLocked_container">
    <div class="card-result-fail-subject">
      {{ $t('온보딩.신분증 인증') }}
    </div>
    <div class="card-result-fail-info-container">
      <Icon_Card_FailVue />
      <div class="title">{{ $t('신분증.진위 확인 실패') }}</div>
      <div
        class="content"
        v-html="$t('신분증.5회 이상 발급 일자 입력 오류로 인해')"
      ></div>
      <div class="unlock-guide">
        <div class="unlock-guide--title">
          <img src="@/assets/unlock_info.svg" alt="info-icon" />
          <span>{{ $t('신분증.주민등록증 잠김해제 방법') }}</span>
        </div>
        <div class="unlock-guide--body">
          <div>{{ $t('신분증.가까운 읍면동 센터 방문') }}</div>
          <div v-html="$t('신분증.정부24 접속')"></div>
        </div>
      </div>
    </div>
    <div
      @click="$emit('cancel')"
      id="cardLocked_submit_button"
      class="button-container"
    >
      {{ $t('버튼.확인') }}
    </div>
  </div>
</template>

<script>
import Icon_Card_FailVue from './DynamicCt_imgs/Icon_Card_Fail.vue';

export default {
  components: { Icon_Card_FailVue },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.card-result-fail-root {
  // position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  width: 100%;
  // height: 100%;

  .card-result-fail-subject {
    margin-top: 32px;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    color: var(--surface-high);
  }
}

.card-result-fail-info-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 56px auto;

  & > img {
    width: 230px;
    margin-bottom: 51px;
  }

  .title {
    font-weight: bold;
    font-size: 28px;
    line-height: 42px;
    color: var(--surface-high);
    margin-bottom: 24px;
  }

  .content {
    font-weight: normal;
    text-align: center;
    font-size: 16px;
    line-height: 26px;
    color: var(--surface-high);
    margin-bottom: 24px;
  }
}
.unlock-guide {
  padding: 16px 18px;
  background: #f7f7f7;
  border-radius: 8px;
  min-width: 260px;
  .unlock-guide--title {
    display: flex;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.5rem;
    color: var(--surface-high);
    img {
      margin-right: 6.5px;
    }
  }
  .unlock-guide--body {
    font-size: 0.875rem;
    line-height: 1.5rem;
    color: var(--surface-high);
    a {
      text-decoration: underline;
      color: var(--primary-100);
    }
    div::before {
      content: '';
      display: inline-block;
      margin: 0px 8px;
      width: 4px;
      height: 4px;
      background: var(--surface-high);
      border-radius: 50%;
      vertical-align: middle;
    }
  }
}

.button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 1rem;
  width: calc(100% - 60px);
  color: var(--font-color);
  background-color: var(--primary-100);
  border-radius: 8px;
  height: 60px;
  margin: 0 30px 48px;
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"value":_vm.show,"max-width":"384px","persistent":""}},[_c('v-card',[_c('v-card-text',{class:{ disabled: _vm.disabledProp },attrs:{"id":_vm.id}},[_c('div',{staticClass:"edit-info-container"},[_c('div',{staticClass:"title-text"},[_vm._v(_vm._s(_vm.title))]),(_vm.editFields.includes('name'))?_c('InputTextField',{ref:"userName",staticClass:"userinput-input",attrs:{"rules":[_vm.rules.required, _vm.errorRuleName, _vm.rules.koreanAndUpperEng],"transform":_vm.rules.transformOnlyUpperCase,"maxlength":45,"placeholder":_vm.$t('placeholder.이름을 입력하세요.'),"fontSize":"1rem","errorMessage":_vm.errorNameBlank
              ? _vm.$t('placeholder.이름을 입력하세요.')
              : _vm.errorDefaultMessageName},on:{"onupdate":_vm.validateForm,"blur":_vm.onBlur},model:{value:(_vm.userinfo.userName),callback:function ($$v) {_vm.$set(_vm.userinfo, "userName", $$v)},expression:"userinfo.userName"}}):_vm._e(),(_vm.editFields.includes('birthdate'))?_c('InputTextField',{ref:"birthDate",staticClass:"userinput-input",attrs:{"placeholder":_vm.$t('placeholder.생년월일 8자리를 입력하세요.'),"fontSize":"1rem","error":_vm.errorInvalidBirthDate,"errorMessage":_vm.errorInvalidBirthDate
              ? _vm.errorInvalidMessage
              : _vm.userinfo.birthDate
              ? _vm.$t('개인정보입력.입력 형식이 올바르지 않습니다.')
              : _vm.errorDefaultMessageBirthDate,"rules":[
            _vm.rules.required,
            _vm.errorRuleBirthDate,
            _vm.rules.date,
            _vm.rules.isPastThanFuture,
          ],"transform":_vm.rules.transformDateFormat,"onblurTransform":_vm.rules.onblurTransformDateFormat,"pattern":"\\d*"},on:{"onupdate":_vm.validateForm},model:{value:(_vm.userinfo.birthDate),callback:function ($$v) {_vm.$set(_vm.userinfo, "birthDate", $$v)},expression:"userinfo.birthDate"}}):_vm._e()],1),_c('div',{staticClass:"button-container"},[_c('div',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"button cancel",on:{"click":function($event){return _vm.cancel()}}},[_vm._v(" "+_vm._s(_vm.$t('버튼.취소'))+" ")]),_c('div',{directives:[{name:"ripple",rawName:"v-ripple"}],class:{ button: true, next: true, disabled: _vm.disabled },on:{"click":function($event){return _vm.ok()}}},[_vm._v(" "+_vm._s(_vm.$t('버튼.확인'))+" ")])])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12" cy="12" r="10" fill="var(--primary-100)" />
    <path
      d="M7.31409 12.0004L10.6612 15.3475L16.2396 8.65332"
      stroke="white"
      stroke-width="1.33883"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {};
</script>

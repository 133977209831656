<template>
  <div class="root-container-auth" ref="rootAuth">
    <WasmPreloading
      v-if="useWasmPreloading"
      :isWasmOCRMode="isWasmOCRMode"
      :isWasmFaceMode="isWasmFaceMode"
      @onInitialized="onInitialized"
      @onPreloaded="onPreloaded"
    >
    </WasmPreloading>
    <InitLoading
      v-if="phase === PHASE_INIT_LOADING"
      :appData="appData"
      :hasLogo="appData.hasLogo"
    ></InitLoading>
    <Logo
      :appData="appData"
      :hasLogo="appData.hasLogo"
      v-if="phase > PHASE_EMPTY_PAGE && appData.hasLogo"
    ></Logo>
    <OnBoardingScreen
      v-if="showOnBoardingScreen"
      :appData="appData"
      @cancel="onCancel"
      @next="showOnBoardingScreen = false"
    />
    <ReuseScreen
      v-if="showReuseScreen"
      :appData="appData"
      :phase="currentPhase"
      @cancel="onCancel"
      @next="showReuseScreen = false"
    />
    <PcFileUploadDisabledScreen
      v-if="showPcFileUploadDisabledScreen"
      @start="onCancel"
    ></PcFileUploadDisabledScreen>
    <UserInfoInput
      v-if="
        appData.identification_config_enabled &&
        !showPcFileUploadDisabledScreen &&
        phase === PHASE_USER_INFO_INPUT
      "
      :appData="appData"
      @cancel="onCancel"
      @next="onNext"
    ></UserInfoInput>
    <CardSelect
      v-if="phase === PHASE_CARD_SELECT"
      :appData="appData"
      :phase="currentPhase"
      :phaseItems="phaseItems"
      :isMultipleModule="isMultipleModule"
      :showStepper="isMultipleModule"
      @cancel="onCancel"
      @next="onNext"
    ></CardSelect>
    <CardGuide
      v-if="phase === PHASE_CARD_GUIDE"
      :phase="currentPhase"
      :phaseItems="phaseItems"
      :showStepper="isMultipleModule"
      @cancel="onCancel"
      @next="onNext"
    ></CardGuide>
    <CardUpload
      v-if="
        phase === PHASE_CARD_SCAN &&
        (!isMobile || uploadProcess) &&
        !isWasmOCRMode
      "
      :phase="currentPhase"
      :phaseItems="phaseItems"
      :showStepper="isMultipleModule"
      :appData="appData"
      :wasmOCR="wasmOCR"
      :isIDCardFaceDetectMode="isIDCardFaceDetectMode"
      @cancel="onCancel"
      @next="onNext"
      @uploadtype="uploadType = $event"
    ></CardUpload>
    <CardScan
        v-if="phase === PHASE_CARD_SCAN && isMobile && !uploadProcess && !isWasmOCRMode"
        :appData="appData"
        :platform="platform"
        :isIDCardFaceDetectMode="isIDCardFaceDetectMode"
        @cancel="onCancel"
        @next="onNext"
        @manual="onManual"
        @uploadtype="uploadType = $event"
    ></CardScan>
    <CardScanWasm
      v-if="phase === PHASE_CARD_SCAN && isWasmOCRMode"
      :appData="appData"
      :wasmOCR="wasmOCR"
      :platform="platform"
      :isWasmSSAMode="isWasmSSAMode"
      :isSupportWasmToServer="isSupportWasmToServer"
      :isIDCardFaceDetectMode="isIDCardFaceDetectMode"
      @cancel="onCancel"
      @changeToServerOCRMode="changeToServerOCRMode"
      @next="onNext"
      @manual="onManual"
      @uploadtype="uploadType = $event"
    ></CardScanWasm>
    <CardNotMatched
      v-if="phase === PHASE_CARD_NOT_MATCHED"
      :appData="appData"
      @cancel="onCancel"
      @next="onNext"
    ></CardNotMatched>
    <CardLocked v-if="phase === PHASE_CARD_LOCKED" @cancel="onCancel" />
    <CardResult
      v-if="phase === PHASE_CARD_RESULT"
      :appData="appData"
      :manualInput="manualInput"
      :uploadType="uploadType"
      :industry="industry"
      :isIDCardFaceDetectMode="isIDCardFaceDetectMode"
      @init="initializeAppData"
      @cancel="onCancel"
      @next="onNext"
      @retry="onRetry"
    ></CardResult>
    <FaceGuide
      :isMultipleModule="isMultipleModule"
      v-if="phase === PHASE_FACE_GUIDE"
      :phase="currentPhase"
      :phaseItems="phaseItems"
      :showStepper="isMultipleModule"
      @cancel="onCancel"
      @next="onNext"
    ></FaceGuide>
    <FaceScan
      v-if="phase === PHASE_FACE_SCAN"
      :appData="appData"
      @cancel="onCancel"
      @next="onNext"
    ></FaceScan>
    <AccountHolder
      :isMultipleModule="isMultipleModule"
      v-if="phase === PHASE_BANK_HOLDER"
      :phase="currentPhase"
      :phaseItems="phaseItems"
      :showStepper="isMultipleModule"
      @next="onNext"
      @cancel="onCancel"
    >
    </AccountHolder>
    <BankSelect
      v-if="phase === PHASE_BANK_SEND"
      :phase="currentPhase"
      :phaseItems="phaseItems"
      :showStepper="isMultipleModule"
      :appData="appData"
      :selectBank="selectBank"
      :industry="industry"
      @next="onNext"
      @cancel="onCancel"
    ></BankSelect>
    <BankVerify
      v-if="phase === PHASE_BANK_VERIFY"
      :appData="appData"
      :industry="industry"
      @next="onNext"
      @cancel="onCancel"
      @select="onSelect"
    ></BankVerify>
    <AdditionalGuide
      v-if="phase === PHASE_ADDITIONAL_GUIDE"
      :phase="currentPhase"
      :phaseItems="phaseItems"
      @cancel="onCancel"
      @next="onNext"
    />
    <AdditionalInput
      v-if="phase === PHASE_ADDITIONAL_INPUT"
      :phase="currentPhase"
      :phaseItems="phaseItems"
      :appData="appData"
      @cancel="onCancel"
      @next="onNext"
    />
    <VerifyTryOut
      :appData="appData"
      v-if="phase === PHASE_VERIFY_FAIL"
      @cancel="onCancel"
    ></VerifyTryOut>
    <ResultSuccess
      v-if="phase === PHASE_RESULT_SUCCESS"
      :resultConfig="appData.config"
      :resultType="appData.resultType"
      :moduleName="appData.moduleName"
      @next="onComplete"
    ></ResultSuccess>
    <AccessDenied
      v-if="phase === PHASE_ACCESS_DENIED"
      :errorCode="onBoardingErrorCode"
      @ok="onCancel"
    ></AccessDenied>
    <SystemErrorDialog
      id="authRoot_systemErrorPopup"
      v-model="invalidServerResponseErrorFound"
      :errorCode="systemErrorCode"
      @ok="appDestroy"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Constants from '@/constants';
import WasmPreloading from '../components/mobile/auth/WasmPreloading';
import InitLoading from '../components/mobile/auth/InitLoading';
import Logo from '../components/mobile/auth/Logo';
import PcFileUploadDisabledScreen from '../components/mobile/auth/PcFileUploadDisabledScreen';
import OnBoardingScreen from '../components/mobile/auth/OnBoardingScreen';
import UserInfoInput from '../components/mobile/auth/UserInfoInput';
import CardSelect from '../components/mobile/auth/CardSelect';
import CardGuide from '../components/mobile/auth/CardGuide';
import CardScan from '../components/mobile/auth/CardScan';
import CardScanWasm from '../components/mobile/auth/CardScanWasm';
import CardUpload from '../components/mobile/auth/CardUpload';
import CardNotMatched from '../components/mobile/auth/CardNotMatched';
import CardResult from '../components/mobile/auth/CardResult';
import CardLocked from '../components/mobile/auth/CardLocked.vue';
import FaceGuide from '../components/mobile/auth/FaceGuide';
import FaceScan from '../components/mobile/auth/FaceScan';
import AccountHolder from '../components/mobile/openbank/AccountHolder';
import BankSelect from '../components/mobile/openbank/BankSelect';
import BankVerify from '../components/mobile/openbank/BankVerify';
import VerifyTryOut from '../components/mobile/openbank/VerifyTryOut';
import ResultSuccess from '../components/mobile/auth/ResultSuccess';
import AccessDenied from '../components/mobile/auth/AccessDenied';
import SystemErrorDialog from '@/components/mobile/dialog/SystemErrorDialog';
import * as faceApi from '../components/mobile/auth/face-api-impl';
import server from '../api/server';
import util from '@/util';
import rules from '../components/mobile/rules';
import { setToken } from '../api/auth';
import AdditionalGuide from '../components/mobile/auth/AdditionalGuide.vue';
import AdditionalInput from '../components/mobile/auth/AdditionalInput.vue';
import ReuseScreen from '../components/mobile/auth/ReuseScreen.vue';
import { tint } from 'tint-shade-color';
import { getFontColorByPrimaryColor } from '../components/submodules/getFontColorByPrimaryColor';
import i18next from 'i18next';

export default {
  components: {
    WasmPreloading,
    InitLoading,
    Logo,
    PcFileUploadDisabledScreen,
    OnBoardingScreen,
    UserInfoInput,
    CardSelect,
    CardGuide,
    CardScan,
    CardScanWasm,
    CardUpload,
    CardNotMatched,
    CardResult,
    CardLocked,
    FaceGuide,
    FaceScan,
    AccountHolder,
    BankSelect,
    BankVerify,
    VerifyTryOut,
    ResultSuccess,
    AccessDenied,
    SystemErrorDialog,
    AdditionalGuide,
    AdditionalInput,
    ReuseScreen,
  },
  data() {
    const self = this;
    return {
      invalidServerResponseErrorFound: false,
      showOnBoardingScreen: false,
      showReuseScreen: false,
      showPcFileUploadDisabledScreen: false,

      PHASES: Constants.PHASES,

      PHASE_INIT_LOADING: Constants.PHASES.PHASE_INIT_LOADING, // -1
      PHASE_EMPTY_PAGE: Constants.PHASES.PHASE_EMPTY_PAGE, // 0
      PHASE_USER_INFO_INPUT: Constants.PHASES.PHASE_USER_INFO_INPUT, // 1
      PHASE_CARD_SELECT: Constants.PHASES.PHASE_CARD_SELECT, // 2
      PHASE_CARD_GUIDE: Constants.PHASES.PHASE_CARD_GUIDE, // 3
      PHASE_CARD_SCAN: Constants.PHASES.PHASE_CARD_SCAN, // 4
      PHASE_CARD_NOT_MATCHED: Constants.PHASES.PHASE_CARD_NOT_MATCHED, // 5
      PHASE_CARD_RESULT: Constants.PHASES.PHASE_CARD_RESULT, // 6
      PHASE_FACE_GUIDE: Constants.PHASES.PHASE_FACE_GUIDE, // 7
      PHASE_FACE_SCAN: Constants.PHASES.PHASE_FACE_SCAN, // 8
      PHASE_BANK_HOLDER: Constants.PHASES.PHASE_BANK_HOLDER, // 9
      PHASE_BANK_SEND: Constants.PHASES.PHASE_BANK_SEND, // 10
      PHASE_BANK_VERIFY: Constants.PHASES.PHASE_BANK_VERIFY, // 11
      PHASE_ADDITIONAL_GUIDE: Constants.PHASES.PHASE_ADDITIONAL_GUIDE, // 12
      PHASE_ADDITIONAL_INPUT: Constants.PHASES.PHASE_ADDITIONAL_INPUT, //13
      PHASE_VERIFY_FAIL: Constants.PHASES.PHASE_VERIFY_FAIL, // 14
      PHASE_RESULT_SUCCESS: Constants.PHASES.PHASE_RESULT_SUCCESS, // 15
      PHASE_ACCESS_DENIED: Constants.PHASES.PHASE_ACCESS_DENIED, // 16
      PHASE_CARD_LOCKED: Constants.PHASES.PHASE_CARD_LOCKED, // 17

      MODULE: Constants.MODULE,

      phase: 0,
      appStarted: false,
      moduleName: '', // full name is "ocr+status+face+liveness+account"
      phaseModule: {
        // set entry point of application
        // check appData.progress
        first: () => {
          const progress = self.appData.progress;

          if (progress) {
            const ID_CARD_CHECKED = progress.is_id_card_checked;
            const FACE_CHECKED = progress.is_face_checked;
            const ACCOUNT_CHECKED = progress.is_account_checked;
            const CUSTOM_CHECKED =
              progress.is_edd_field_checked && progress.is_custom_field_checked;

            const MODULE_FACE_ENABLED = self.moduleName.includes(
              Constants.MODULE.FACE
            );
            const MODULE_ACCOUNT_ENABLED = self.moduleName.includes(
              Constants.MODULE.ACCOUNT
            );
            const MODULE_CUSTOM_ENABLED = self.moduleName.includes(
              Constants.MODULE.CUSTOM_FIELD
            );

            if (ID_CARD_CHECKED) {
              if (MODULE_FACE_ENABLED && !FACE_CHECKED) {
                return self.PHASE_FACE_GUIDE;
              } else if (MODULE_ACCOUNT_ENABLED && !ACCOUNT_CHECKED) {
                return self.PHASE_BANK_HOLDER;
              } else if (MODULE_CUSTOM_ENABLED && !CUSTOM_CHECKED) {
                return self.PHASE_ADDITIONAL_GUIDE;
              }
            } else if (FACE_CHECKED) {
              if (MODULE_ACCOUNT_ENABLED && !ACCOUNT_CHECKED) {
                return self.PHASE_BANK_HOLDER;
              } else if (MODULE_CUSTOM_ENABLED && !CUSTOM_CHECKED) {
                return self.PHASE_ADDITIONAL_GUIDE;
              }
            } else if (ACCOUNT_CHECKED) {
              if (MODULE_CUSTOM_ENABLED && !CUSTOM_CHECKED) {
                return self.PHASE_ADDITIONAL_GUIDE;
              }
              // nothing. finished.
            }
          }
          // pre-set user data option selected
          // if (self.appData.userInputPass) {
          //   return self.phaseModule.next(self.PHASE_USER_INFO_INPUT);
          // }
          return self.phaseModule.next(self.PHASE_USER_INFO_INPUT);
        },
        prev(phase) {
          switch (phase) {
            case self.PHASE_CARD_SCAN:
              return self.PHASE_CARD_SELECT;
            case self.PHASE_BANK_VERIFY:
              return self.PHASE_BANK_SEND;
            default:
              return phase - 1;
          }
        },
        next(phase) {
          const MODULE_OCR_ENABLED = self.moduleName.includes(
            Constants.MODULE.OCR
          );
          const MODULE_FACE_ENABLED = self.moduleName.includes(
            Constants.MODULE.FACE
          );
          const MODULE_ACCOUNT_ENABLED = self.moduleName.includes(
            Constants.MODULE.ACCOUNT
          );
          const MODULE_CUSTOM_ENABLED = self.moduleName.includes(
            Constants.MODULE.CUSTOM_FIELD
          );

          switch (phase) {
            case self.PHASE_USER_INFO_INPUT:
              if (MODULE_OCR_ENABLED) {
                return self.PHASE_CARD_SELECT;
              } else if (MODULE_FACE_ENABLED) {
                return self.PHASE_FACE_GUIDE;
              } else if (MODULE_ACCOUNT_ENABLED) {
                return self.PHASE_BANK_HOLDER;
              } else {
                if (this.invalidServerResponseErrorFound) {
                  return;
                }
                return self.PHASE_RESULT_SUCCESS;
              }
            case self.PHASE_CARD_SELECT:
              if (!self.isMobile || self.uploadProcess) {
                return self.PHASE_CARD_SCAN;
              } else {
                return self.PHASE_CARD_GUIDE;
              }
            case self.PHASE_CARD_GUIDE:
              return self.PHASE_CARD_SCAN;
            case self.PHASE_CARD_SCAN:
              return self.PHASE_CARD_RESULT;
            case self.PHASE_CARD_NOT_MATCHED:
              return self.PHASE_CARD_RESULT;
            case self.PHASE_CARD_RESULT:
              if (MODULE_FACE_ENABLED) {
                return self.PHASE_FACE_GUIDE;
              } else if (MODULE_ACCOUNT_ENABLED) {
                return self.PHASE_BANK_HOLDER;
              } else if (MODULE_CUSTOM_ENABLED) {
                return self.PHASE_ADDITIONAL_GUIDE;
              } else {
                if (this.invalidServerResponseErrorFound) {
                  return;
                }
                return self.PHASE_RESULT_SUCCESS;
              }
            case self.PHASE_FACE_GUIDE:
              return self.PHASE_FACE_SCAN;
            case self.PHASE_FACE_SCAN:
              if (MODULE_ACCOUNT_ENABLED) {
                return self.PHASE_BANK_HOLDER;
              } else if (MODULE_CUSTOM_ENABLED) {
                return self.PHASE_ADDITIONAL_GUIDE;
              } else {
                if (this.invalidServerResponseErrorFound) {
                  return;
                }
                return self.PHASE_RESULT_SUCCESS;
              }
            case self.PHASE_BANK_HOLDER:
              return self.PHASE_BANK_SEND;

            case self.PHASE_BANK_SEND:
              return self.PHASE_BANK_VERIFY;
            case self.PHASE_BANK_VERIFY:
              if (MODULE_CUSTOM_ENABLED) {
                return self.PHASE_ADDITIONAL_GUIDE;
              } else if (this.invalidServerResponseErrorFound) {
                return;
              }
              return self.PHASE_RESULT_SUCCESS;
            case self.PHASE_ADDITIONAL_GUIDE:
              return self.PHASE_ADDITIONAL_INPUT;
            case self.PHASE_ADDITIONAL_INPUT:
              if (this.invalidServerResponseErrorFound) {
                return;
              }
              return self.PHASE_RESULT_SUCCESS;
            default:
              return -1;
          }
        },
        phase(phase) {
          const MODULE_FACE_ENABLED = self.moduleName.includes(
            Constants.MODULE.FACE
          );
          const MODULE_OCR_ENABLED = self.moduleName.includes(
            Constants.MODULE.OCR
          );

          const MODULE_ACCOUNT_ENABLED = self.moduleName.includes(
            Constants.MODULE.ACCOUNT
          );

          switch (phase) {
            case self.PHASE_USER_INFO_INPUT:
              return 0;
            case self.PHASE_CARD_SELECT:
            case self.PHASE_CARD_GUIDE:
            case self.PHASE_CARD_SCAN:
              return 0;
            case self.PHASE_FACE_GUIDE:
              return 1;
            case self.PHASE_BANK_HOLDER:
            case self.PHASE_BANK_SEND:
              if (!MODULE_FACE_ENABLED) {
                if (!MODULE_OCR_ENABLED) {
                  return 0;
                }
                return 1;
              }
              return 2;
            case self.PHASE_ADDITIONAL_GUIDE:
            case self.PHASE_ADDITIONAL_INPUT:
              if (!MODULE_FACE_ENABLED) {
                if (!MODULE_OCR_ENABLED) {
                  if (!MODULE_ACCOUNT_ENABLED) {
                    return 0;
                  } else {
                    return 1;
                  }
                } else {
                  if (!MODULE_ACCOUNT_ENABLED) {
                    return 1;
                  } else {
                    return 2;
                  }
                }
              }
              return 3;
            default:
              return -1;
          }
        },
      },
      phaseItems: [],
      appData: {
        cardScanRetryCount: 0,
        customField: [],
        changeBankCount: 0,
        hasLogo: false,
        fromCardNotMatched: false,
        fromCardNotMatchedOriginOcr: null,
      },
      platform: {},
      wasmOCR: null,
      wasmFace: null,
      selectBank: false,
      industry: 0,
      isWasmOCRMode: false,
      isWasmSSAMode: false,
      isIDCardFaceDetectMode: false,
      isSupportWasmToServer: true,
      isWasmFaceMode: false,
      useWasmPreloading: false,
      uploadProcess: false,
      inReuseProgress: false,
      manualInput: false,
      uploadType: 'camera',
      exclude_finance_code_list: [],
      priority_finance_code_list: [],
      onBoardingErrorCode: '',
      color: '',
      socket: {},
      webSocketServerURL: '',
      systemErrorCode: 'CE001',
      closeWindow: false,
    };
  },
  computed: {
    ...mapState(['isMobile']),
    currentPhase() {
      return this.phaseModule.phase(this.phase);
    },
    currentPhaseName() {
      return Object.keys(this.PHASES).find(
        (phaseName) => this.PHASES[phaseName] === this.phase
      );
    },
    isMultipleModule() {
      this.$log.debug('isMultipleModule', this.moduleName);
      if (
        !this.moduleName ||
        this.moduleName === this.MODULE.OCR ||
        this.moduleName === this.MODULE.IDCARD ||
        this.moduleName === this.MODULE.ACCOUNT
      ) {
        return false;
      }
      return true;
    },
  },
  async created() {
    this.$log.debug('Auth#created to', this.appData);
    try {
      this.phase = this.PHASE_INIT_LOADING;
      this.webSocketServerURL =
        process.env.VUE_APP_WS_URL || 'wss://kyb-ws-dev.useb.co.kr/ws';
      await this.appStart();
    } catch (error) {
      this.$log.error(error);
    }
  },
  beforeDestroy() {
    faceApi.destoryFaceApi();
  },
  methods: {
    async appStart() {
      this.supportOldBrowsers();
      const nativeHandler = async (e) => {
        try {
          const response = e.data ? e.data : e;

          if (!response) {
            this.$log.info(
              '[INFO] Auth#~message is skipped, cause : response is undefined'
            );
            return;
          }

          if (response.type === 'webpackOk') {
            this.$log.info(
              '[INFO] Auth#~message is skipped, cause : webpackOk type'
            );
            return;
          }

          this.$log.debug('Auth#~message', response);

          let data;

          if (typeof response === 'string' && response !== 'undefined') {
            try {
              data = JSON.parse(decodeURIComponent(atob(response)));
            } catch (err) {
              this.$log.debug('[WARNING] token parse error');
              this.onBoardingErrorCode =
                Constants.CLIENT.ERROR_CODE.ONBOARDING_EVT_MSG_FORMAT_INVALID;
              throw new Error('parameter format is invalid');
            }

            if (data.language) i18next.changeLanguage(data.language);
            if (data.font) this.setFonts(data.font);
            if (data.closeWindow) this.closeWindow = true;
            if (data.isWasmOCRMode) {
              this.isWasmOCRMode = data.isWasmOCRMode === 'true';
              if (data.isWasmSSAMode) {
                this.isWasmSSAMode = data.isWasmSSAMode === 'true';
              }
            }
            if (data.isWasmFaceMode) {
              this.isWasmFaceMode = data.isWasmFaceMode === 'true';
            }
            if (data.isIDCardFaceDetectMode) {
              this.isIDCardFaceDetectMode = data.isIDCardFaceDetectMode === 'true';
            }

            if (
              (data && data.customer_id && data.id && data.key) ||
              (data && data.access_token)
            ) {
              if (this.origin !== e.origin) {
                this.$log.info(
                  "[WARNING] origin is replaced : '" +
                    this.origin +
                    "' -> '" +
                    e.origin +
                    "'"
                );
                this.origin = e.origin;
              }
              this.$log.debug('Auth#~message origin', this.origin);

              if (this.platform.isWebBrowser) {
                // set preventGoBack (default : true)
                if (data && data.prevent_go_back === undefined) {
                  data.prevent_go_back = true;
                }
                if (data && data.prevent_go_back) {
                  history.pushState(
                    'ekyc-history-state',
                    document.title,
                    location.href
                  );
                  window.addEventListener('popstate', function () {
                    history.pushState(
                      'ekyc-history-state',
                      document.title,
                      location.href
                    );
                  });
                  if (this.platform.iOS) {
                    const skipTouchActionforZoom = (ev) => {
                      if (ev.touches.length > 1) {
                        ev.preventDefault();
                        ev.stopImmediatePropagation();
                      }
                    };

                    window.addEventListener(
                      'touchstart',
                      skipTouchActionforZoom,
                      { passive: false }
                    );
                    window.addEventListener(
                      'touchmove',
                      skipTouchActionforZoom,
                      { passive: false }
                    );
                    window.addEventListener(
                      'touchend',
                      skipTouchActionforZoom,
                      { passive: false }
                    );
                  }
                }
              }

              // set exclude finance code list
              this.appData.exclude_finance_code_list =
                data.exclude_finance_code_list
                  ? (Array.isArray(data.exclude_finance_code_list) ? data.exclude_finance_code_list : [])
                  : [];

              // set priority finance code list
              this.appData.priority_finance_code_list =
                data.priority_finance_code_list
                  ? (Array.isArray(data.priority_finance_code_list) ? data.priority_finance_code_list : [])
                  : [];

              // get company industry to change openbank scenario(openbank or firmbank)

              if (data.access_token) {
                this.$log.debug(data);
                try {
                  const base64 = data.access_token.split('.')[1];
                  const response = Buffer.from(base64, 'base64');
                  const result = JSON.parse(response.toString());
                  const expireTime = new Date(result.exp * 1000);

                  setToken({
                    token: data.access_token,
                    expire: expireTime.toISOString(),
                    company: {
                      name: result.payload.customer_name,
                      phone_number: result.payload.customer_phone_number,
                    },
                  });

                  this.industry = result.payload.industry;
                } catch (error) {
                  this.onBoardingErrorCode =
                    Constants.CLIENT.ERROR_CODE.ONBOARDING_ACCESS_TOKEN_INVALID;
                  throw new Error('access token is invalid');
                }
              } else {
                try {
                  const {
                    company: { industry },
                  } = await server.signin({
                    customer_id: parseInt(data.customer_id),
                    username: data.id,
                    password: data.key,
                  });
                  this.industry = industry;
                } catch (error) {
                  this.onBoardingErrorCode =
                    Constants.CLIENT.ERROR_CODE.ONBOARDING_SIGIN_IN_FAILED;
                  throw new Error('sign-in failed');
                }
              }

              this.$store.commit(
                'setCompanyPhoneNumber',
                server.getCompanyPhoneNumber()
              );
              this.$store.commit('setCompanyName', server.getCompanyName());

              // config 불러와서 세팅
              await this.setConfig();
              this.setColors();
              this.setLogo();

              const userModule = await server.getUserModule();
              await this.setModule(userModule);

              if (
                !this.appData.module.id_card_ocr &&
                !this.appData.module.face_authentication &&
                !this.appData.module.account_verification
              ) {
                this.$log.debug('모듈 모두 꺼짐');
                this.onBoardingErrorCode =
                  Constants.CLIENT.ERROR_CODE.ONBOARDING_ALL_MODULES_DISABLED;
                throw new Error('the modules are all turned off.');
              }

              if (
                this.appData.module.custom_field &&
                this.appData.config.use_custom_field &&
                !this.appData.customField?.filter((item) => item.active).length
              ) {
                this.onBoardingErrorCode =
                  Constants.CLIENT.ERROR_CODE.ONBOARDING_CUSTOM_FIELD_SETTING_ERROR;
                throw new Error('custom field setting error');
                // this.phase = this.PHASE_ACCESS_DENIED;
                // this.$log.error('custom field setting error');
                // return;
              }

              this.$log.debug('moduleName#Auth', this.moduleName);

              // useFaceDetectionFromIDCard 여부 판단
              // for DB저축은행 // 임시코드
              // use config 가 true 이거나, params의 isIDCardFaceDetectMode true
              if (this.appData.config.use_idcard_face_detect || this.isIDCardFaceDetectMode) {
                this.$log.debug('isIDCardFaceDetectMode TRUE')
                this.isIDCardFaceDetectMode = true;
              }

              // wasm ocr 여부 판단
              // allow config 가 true
              if (this.appData.config.allow_wasm_ocr) {
                // use config 가 true 이거나, params의 isWasmOCRMode true
                if (this.appData.config.use_wasm_ocr || this.isWasmOCRMode) {
                  this.$log.debug('isWasmOCRMode TRUE')
                  this.isWasmOCRMode = true;
                  this.useWasmPreloading = true;

                  // wasm error 혹은 fail 시 server mode로 전환 off 여부 (default : true)
                  if (data.isSupportWasmToServer === false) {
                    this.isSupportWasmToServer = false;
                  }

                  // wasm ssa 여부 판단
                  // allow config 가 true
                  if (this.appData.config.allow_wasm_ssa) {
                    // use config 가 true 이거나, params의 isWasmSSAMode true
                    if (
                      this.appData.config.use_wasm_ssa ||
                      data.isWasmSSAMode
                    ) {
                      this.$log.debug('isWasmSSAMode TRUE');
                      this.isWasmSSAMode = true;
                    }
                  }
                }
              } else {
                this.isWasmOCRMode = false;
              }

              // wasm face 여부 판단
              // allow config 가 true
              if (this.appData.config.allow_wasm_face) {
                // use config 가 true 이거나, params의 isWasmFaceMode true
                if (this.appData.config.use_wasm_face || this.isWasmFaceMode) {
                  this.isWasmFaceMode = true;
                  this.useWasmPreloading = true;
                }
              } else {
                this.isWasmFaceMode = false;
              }

              // 안면인증이 활성화 되어 있을 경우 face api 불러오기
              if (this.moduleName.includes(Constants.MODULE.FACE) || this.isIDCardFaceDetectMode) {
                this.$log.debug('안면인증 모듈 불러오기');
                await faceApi.initFaceApi();
              }

              // OCR이 활성화 되어 있을 경우 아이디 카드 불러오기
              if (this.moduleName.includes(Constants.MODULE.OCR)) {
                this.$log.debug('IDcard 불러옴');
                // check which ID card options to show
                await server.getIdCards().then((list) => {
                  this.$store.commit('setIdCardsFilter', list);
                });
              } else {
                this.$log.debug(this.moduleName);
                this.$log.debug('IDcard 안 불러옴');
              }

              // OCR 파일 업로드 가능에 따라 페이지 보여주기
              if (this.moduleName.includes(Constants.MODULE.OCR)) {
                this.showPcFileUploadDisabledScreen =
                  !this.appData.manual_upload_id_pc &&
                  !this.isMobile &&
                  !this.isWasmOCRMode;
              }

              // identification 값 기준으로 show input UI 보여준다.
              if (this.appData.identification_config_enabled) {
                this.appStarted = true;
                this.phase = this.PHASE_USER_INFO_INPUT;
              } else {
                if (
                  !data.birthday ||
                  !data.email ||
                  !data.name ||
                  !data.phone_number
                ) {
                  this.$log.debug('필수정보가 제대로 입력되지 않음');
                  this.onBoardingErrorCode =
                    Constants.CLIENT.ERROR_CODE.ONBOARDING_ENDUSER_INFO_SOME_EMPTY;
                  throw new Error('end-user information is some empty');
                  // this.phase = this.PHASE_ACCESS_DENIED;
                  // return;
                }
                // set parameter data
                await this.setApplication({ ...data });
                this.$log.debug('필수정보 입력되어 온보딩 보여주기');

                // 계좌인증 활성화 되어 있을 경우 변경 이력 조회
                // transaction_id 필요
                if (this.moduleName.includes(Constants.MODULE.ACCOUNT)) {
                  const total_count = await server.getChangeCountHistoryAccount(
                    this.appData.transaction_id
                  );
                  this.appData.changeBankCount = total_count;
                }

                // 이어하기일 경우 이어하기 화면 보여주기
                this.checkReuseProgress();

                if (this.isMultipleModule) {
                  if (this.inReuseProgress) {
                    this.showReuseScreen = true;
                  } else {
                    this.showOnBoardingScreen = true;
                  }
                }

                // 필수정보 입력됬으니 온보딩 화면 보여줌
                // this.showOnBoardingScreen = this.isMultipleModule;

                // 안면인증 모듈 불러온 것 확인
                if (
                  !this.moduleName.includes(Constants.MODULE.FACE) ||
                  faceApi.isFaceDetectionModelLoadedSync()
                ) {
                  this.phase = this.phaseModule.first();
                }
                this.appStarted = true;
              }
            } else if (!this.appStarted) {
              this.$log.debug('[WARNING] params is not exist.');
            }
          }
          this.handleInvalidBrowsers();
          this.getPlatformInfomation();
          if (data && data.isWebSocket) {
            this.platform.isWebSocket = true;
            const { token } = server.getToken();
            this.socketData = { action: 'join-room', target: data.room_key };

            this.$log.debug('[DEBUG] KYB user KYC, WebSocket connecting', {
              ...this.platform,
              token,
            });
            this.connectWebSocket(token);
          }
        } catch (e) {
          if (
            !(e instanceof SyntaxError && e.message.includes('JSON')) &&
            !this.appStarted
          ) {
            this.$log.error('alcherakyc error', e);
            const clientErrorCode = Object.values(Constants.CLIENT.ERROR_CODE);
            if (
              this.onBoardingErrorCode === '' &&
              !clientErrorCode.includes(e.errorCode)
            ) {
              this.onBoardingErrorCode =
                Constants.CLIENT.ERROR_CODE.UNKNOWN_ERROR + '<br/>' + e.message;
            }
            this.phase = this.PHASE_ACCESS_DENIED;
          }
        }
      };
      //ios
      window.addEventListener('message', nativeHandler);
      //android
      document.addEventListener('message', nativeHandler);
      window.alcherakycreceive = nativeHandler;

      if (this.$route.query && this.$route.query.token) {
        nativeHandler(this.$route.query.token);
      } else if (this.$route.query && this.$route.query.params) {
        nativeHandler(this.$route.query.params);
      } else {
        this.$log.debug('TOKEN이 없음');
        this.$log.warn('[WARNING] token is not exist.');
        // this.phase = this.PHASE_ACCESS_DENIED;
      }
    },
    // returns true if config is enabled
    // @param { Array, Object } data
    // @param { String } key
    // @return { Boolean }
    isEnabledConfig(data, key) {
      return (
        data[key] ||
        data?.list?.some((item) => {
          return item.key === key && item.value;
        })
      );
    },
    async setConfig() {
      const config = await server.getConfigs();
      const defaultConfig = {
        /* 기타 */
        sms_notification: false,
        email_notification: true,
        reuse_limit_minute: 1440,
        use_custom_end_message: false,
        custom_end_message: '',
        allow_reuse: false,
        /* 신분증 인증 */
        identification: false,
        save_original_id_image: false,
        manual_upload_id_mobile: false,
        manual_input_mobile: false,
        manual_upload_id_pc: false,
        use_ocr_driver_date: true,
        trust_driver_date: true,
        use_ocr_driver_expired_date: true,
        trust_driver_expired_date: true,
        /* 계좌 인증 */
        allow_account_subcode: false,
        use_account_subcode: false,
        account_subcode: '',
        account_subcode_position: 'left',
        use_name_verification: false,
        allow_change_account: true,
        max_change_account: 1,
        /* 테마 설정 */
        allow_custom_theme: false,
        use_custom_theme: false,
        theme_color: 'default',
        use_custom_logo: false,
        custom_logo: '',
        allow_wasm_ocr: false,
        allow_wasm_ssa: false,
        allow_wasm_face: false,
        use_wasm_ocr: false,
        use_wasm_ssa: false,
        use_wasm_face: false,
        use_idcard_face_detect: false,
        /* EDD, 추가 설문 */
        allow_ra: false,
        use_ra: false,
        use_edd: false,
        use_custom_field: false,
        edd_countries_mode: 1,
      };
      // 서버 configs값 일괄 설정
      if (!this.appData.config) {
        this.appData.config = config.list.reduce(
          (prev, cur) => ({ ...prev, [cur.key]: cur.value }),
          { ...defaultConfig }
        );
      }

      // CONFIG: handle when "identification" config is set to true/false
      this.appData.identification_config_enabled = this.isEnabledConfig(
        config,
        'identification'
      );

      // CONFIG: set flag for saving original id image
      this.appData.save_original_id_image = this.isEnabledConfig(
        config,
        'save_original_id_image'
      );
      this.$log.debug(
        'Auth#save_original_id_image',
        this.appData.save_original_id_image
      );
      // CONFIG: check if allow upload id by PC
      this.appData.manual_upload_id_pc = this.isEnabledConfig(
        config,
        'manual_upload_id_pc'
      );

      // CONFIG: check if allow upload id by mobile 사진 첨부 옵션
      this.appData.manual_upload_id_mobile = this.isEnabledConfig(
        config,
        'manual_upload_id_mobile'
      );

      // 신분증 직접 입력
      this.appData.manual_input_mobile = this.isEnabledConfig(
        config,
        'manual_input_mobile'
      );

      // 계좌 실명 인증 사용 여부
      this.appData.use_name_verification = this.isEnabledConfig(
        config,
        'use_name_verification'
      );

      // 1원 인증 코드 문구
      if (this.isEnabledConfig(config, 'use_account_subcode')) {
        this.appData.account_subcode = config.list.find(
          (item) => item.key === 'account_subcode'
        ).value;
      }
    },
    setFonts(font) {
      document.documentElement.style.setProperty('--font-family',`"${font}", "Noto Sans KR", sans-serif`); // prettier-ignore
    },
    setColors() {
      const { allow_custom_theme, use_custom_theme } = this.appData.config;
      if (!allow_custom_theme || !use_custom_theme) {
        this.appData.colorPalette = {
          tint_100: '#373eff',
          tint_80: '#5f65ff',
          tint_60: '#878bff',
          tint_40: '#afb2ff',
          tint_20: '#d7d8ff',
          tint_10: '#ebecff',
          tint_5: '#f9f9ff',
          fontColor: '#ffffff',
        };
        return;
      }
      if (this.appData.config.theme_color === 'default') {
        this.appData.config.theme_color = '#373EFF';
      }
      function hexToRgb(hex) {
        // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
        const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
        hex = hex.replace(shorthandRegex, function (m, r, g, b) {
          return r + r + g + g + b + b;
        });

        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result
          ? `${parseInt(result[1], 16)},
          ${parseInt(result[2], 16)},
          ${parseInt(result[3], 16)}
        `
          : null;
      }

      const [tint_100, font] = this.appData.config.theme_color.split(',');
      const tint_80 = tint(`${tint_100}`, 0.2);
      const tint_60 = tint(`${tint_100}`, 0.4);
      const tint_40 = tint(`${tint_100}`, 0.6);
      const tint_20 = tint(`${tint_100}`, 0.8);
      const tint_10 = tint(`${tint_100}`, 0.9);
      const tint_5 = tint(`${tint_100}`, 0.95);
      const tint_rgb = hexToRgb(tint_100);

      const fontColor = font || getFontColorByPrimaryColor(tint_100);
      this.$log.debug({ tint_100, font, fontColor });
      this.appData.colorPalette = {
        tint_100,
        tint_80,
        tint_60,
        tint_40,
        tint_20,
        tint_10,
        tint_5,
        fontColor,
      };

      document.documentElement.style.setProperty('--primary-100', tint_100);
      document.documentElement.style.setProperty('--primary-80', tint_80);
      document.documentElement.style.setProperty('--primary-60', tint_60);
      document.documentElement.style.setProperty('--primary-40', tint_40);
      document.documentElement.style.setProperty('--primary-20', tint_20);
      document.documentElement.style.setProperty('--primary-10', tint_10);
      document.documentElement.style.setProperty('--primary-5', tint_5);
      document.documentElement.style.setProperty('--primary-100-rgb', tint_rgb);
      document.documentElement.style.setProperty('--font-color', fontColor);
    },
    setLogo() {
      const {
        allow_custom_theme,
        use_custom_theme,
        use_custom_logo,
        custom_logo,
      } = this.appData.config;
      this.appData.hasLogo = true;
      if (
        allow_custom_theme &&
        use_custom_theme &&
        use_custom_logo &&
        custom_logo === ''
      ) {
        this.appData.hasLogo = false;
      }
    },

    async setModule(module) {
      this.$log.debug('모듈 불러오기 시작', module);
      const { use_edd, use_ra, use_custom_field } = this.appData.config;
      const moduleName = [];
      const phaseItems = [];
      // phaseItems.push('정보입력');
      if (module.id_card_ocr) {
        phaseItems.push(this.$t('온보딩.신분증 인증'));
        moduleName.push(Constants.MODULE.OCR);
      }
      if (module.id_card_verification) {
        moduleName.push(Constants.MODULE.STATUS);
      }
      if (module.face_authentication) {
        phaseItems.push(this.$t('온보딩.얼굴 인증'));
        moduleName.push(Constants.MODULE.FACE);
      }
      if (module.liveness) {
        moduleName.push(Constants.MODULE.LIVENESS);
      }
      if (module.account_verification) {
        phaseItems.push(this.$t('온보딩.계좌 인증'));
        moduleName.push(Constants.MODULE.ACCOUNT);
      }
      if (module.edd_field && use_edd) {
        const [eddCategory, eddCountries] = await Promise.all([
          server.getEddCategory(),
          server.getEddCountry(),
        ]);

        this.appData.eddCategory = eddCategory;
        this.appData.eddCountries = eddCountries;

        if (use_ra) {
          const raThreshold = await server.getRaThreshold();
          this.appData.raThreshold = raThreshold;
        }

        const eddFields = await server.getEddFields();
        this.appData.eddField = eddFields;
        this.$log.debug('getCategory and getFields', {  eddCategory, eddFields }); // prettier-ignore
        phaseItems.push('추가 인증');
        moduleName.push(Constants.MODULE.CUSTOM_FIELD);
      }

      if (module.custom_field && use_custom_field) {
        const custom_field_list = await server.getCustomFields();

        this.appData.customField = custom_field_list;
        this.$log.debug('custom_field_list', { custom_field_list });
        phaseItems.push('추가 인증');
        moduleName.push(Constants.MODULE.CUSTOM_FIELD);
      }
      if (
        (module.edd_field && use_edd) ||
        (module.custom_field && use_custom_field)
      ) {
        const countries = await server.getCountry();
        this.appData.countries = countries;
      }

      this.moduleName = [...new Set(moduleName)].join('+');
      this.phaseItems = [...new Set(phaseItems)];
      this.appData.module = module;

      this.appData.moduleName = this.moduleName;
      this.$log.debug('Auth#setModule', this.moduleName);
    },
    async setApplication(data) {
      const phoneNumberTest = /^\d+$/;
      const birthdayTest = /^\d{4}-\d{2}-\d{2}$/;
      data.name && (data.name = data.name.trim());
      data.phone_number && (data.phone_number = data.phone_number.trim());
      data.birthday && (data.birthday = data.birthday.trim());
      data.email && (data.email = data.email.trim());
      this.$log.debug('Auth#setApplication#data', data);
      if (!data.name || !data.phone_number || !data.birthday || !data.email) {
        // set "userInputPass" flag to false
        this.appData = {
          ...this.appData,
          userInputPass: false,
        };
      } else if (
        data.name &&
        phoneNumberTest.test(data.phone_number) &&
        birthdayTest.test(data.birthday) &&
        rules.email(data.email)
      ) {
        await this.setTransactionId(data);
      } else {
        this.onBoardingErrorCode =
          Constants.CLIENT.ERROR_CODE.ONBOARDING_ENDUSER_INFO_FORMAT_INCORRECT;
        throw new Error('end-user information format is incorrect');
      }
    },
    async setTransactionId(data) {
      let formData = new FormData();
      formData.append('name', data.name);
      formData.append('phone_number', data.phone_number);
      formData.append('birthday', data.birthday);
      formData.append('email', data.email);
      this.$log.debug('Auth#setTransactionId#formData', formData);
      const result = await server.startApplication(formData);
      this.appData = {
        ...this.appData,
        userInputPass: true,
        userName: data.name,
        phoneNumber: data.phone_number,
        birthDate: data.birthday,
        email: data.email,
        transaction_id: result.form.transaction_id,
        progress: result.form.progress,
        module: result.form.module,
      };
    },
    async setHistoryAccount() {
      const {
        transaction_id,
        finance_code,
        account_number,
        account_holder,
        mod_account_holder,
      } = this.appData;
      await server.postHistoryAccount({
        transaction_id,
        finance_code,
        account_number,
        account_holder: mod_account_holder || account_holder,
      });
    },
    async setChangeBankCount() {
      this.appData.changeBankCount = await server.getChangeCountHistoryAccount(
        this.appData.transaction_id
      );
    },
    checkReuseProgress() {
      if (this.isMultipleModule) {
        let isReuse = false;
        for (let key in this.appData.progress) {
          isReuse = isReuse || this.appData.progress[key];
        }
        this.inReuseProgress = isReuse;
      }
    },

    appDestroy() {
      // release mode에서 타이밍 이슈 발생. sendResult 요청보다 먼저 실행되는 경우가 발생되는 현상
      setTimeout(() => {
        try {
          if (this.closeWindow) {
            window.opener = window;
            const win = window.open('', '_self');
            win.close();
            top.close();
          }
          location.replace('about:blank');
        } catch (e) {
          console.error('error on appDestory() : ' + e);
        }
      });
    },
    handleInvalidBrowsers() {
      // Add blacklist of fixed number of known browsers where
      // there is an issue with camera permissions on Android.
      const USERAGENT_BLACKLIST = ['nate'];
      const AGENT = navigator.userAgent.toLowerCase();
      const isAndroid = navigator.userAgent.match(/Android/i);

      this.$log.debug(`useragent: ${AGENT}`);
      if (isAndroid && USERAGENT_BLACKLIST.some((ua) => AGENT.includes(ua))) {
        this.openAppInNewBrowser();
      }
    },
    openAppInNewBrowser() {
      alert(
        '지원되지 않는 브라우저입니다. 삼성인터넷, 크롬, 사파리 브라우저에서 시도해주세요.'
      );
      this.sendResult('{"result": "close", "cause": "unsupported_browser"}');
      this.appDestroy();

      // launch new browser
      // location.href =
      //   "intent://kyc-demo-dev.useb.co.kr#Intent;scheme=http;package=com.android.chrome;end";
    },
    supportOldBrowsers() {
      this.$log.debug('Auth#supportOldBrowsers');
      // Older browsers might not implement mediaDevices at all, so we set an empty object first
      if (navigator.mediaDevices === undefined) {
        navigator.mediaDevices = {};
      }
      // Some browsers partially implement mediaDevices. We can't just assign an object
      // with getUserMedia as it would overwrite existing properties.
      // Here, we will just add the getUserMedia property if it's missing.
      if (navigator.mediaDevices.getUserMedia === undefined) {
        navigator.mediaDevices.getUserMedia = function (constraints) {
          // First get ahold of the legacy getUserMedia, if present
          let getUserMedia =
            navigator.getUserMedia ||
            navigator.webkitGetUserMedia ||
            navigator.mozGetUserMedia ||
            navigator.msGetUserMedia;
          // Some browsers just don't implement it - return a rejected promise with an error
          // to keep a consistent interface
          if (!getUserMedia) {
            return Promise.reject(
              new Error('getUserMedia is not implemented in this browser')
            );
          }
          // Otherwise, wrap the call to the old navigator.getUserMedia with a Promise
          return new Promise(function (resolve, reject) {
            getUserMedia.call(navigator, constraints, resolve, reject);
          });
        };
      }
    },
    getPlatformInfomation() {
      this.platform.isWebViewIOSReactNative = false;
      this.platform.isWebViewAndroidReactNative = false;
      this.platform.isWebviewIOS = false;
      this.platform.isWebviewAndroid = false;
      this.platform.isWebBrowser = false;
      // this.platform.isWebSocket = false;

      const agentInfo = window.navigator.userAgent.toLowerCase();
      this.platform.iOS = /iphone|ipod|ipad/.test(agentInfo);
      this.platform.android = /android/i.test(agentInfo);

      this.platform.chromeVer = 0;
      try {
        const chromeVer = parseInt(agentInfo.match(/chrome\/([0-9]*)./)[1]);
        if (!isNaN(chromeVer)) {
          this.platform.chromeVer = chromeVer;
        }
      } catch (e) {
        this.$log.error('getPlatformInfomation() in error : ', e);
      }

      if (window.ReactNativeWebView) {
        // android + react-native cli + webview
        // android + react-native expo + webview
        // iOS + react-native cli + webview
        // iOS + react-native expo + webview
        if (this.platform.iOS) {
          this.platform.isWebViewIOSReactNative = true;
          this.platform.isWebViewAndroidReactNative = false;
        } else {
          this.platform.isWebViewIOSReactNative = false;
          this.platform.isWebViewAndroidReactNative = true;
        }
      } else if (
        window.webkit &&
        window.webkit.messageHandlers &&
        window.webkit.messageHandlers.alcherakyc
      ) {
        // iOS + swift + WebView
        this.platform.isWebviewIOS = true;
      } else if (window['alcherakyc']) {
        // android + webview
        this.platform.isWebviewAndroid = true;
      } else if (window.parent) {
        // web browser + iframe
        this.platform.isWebBrowser = true;
      }
    },
    sendResult(result) {
      this.$log.debug('Auth#sendResult', result);
      const returnMessage = btoa(encodeURIComponent(result));
      this.$log.debug('[DEBUG] platform', { ...this.platform });
      if (this.platform.isWebSocket) {
        try {
          // websocket send (kyb user kyc). no parent frame
          this.$log.debug('[DEBUG] KYB user KYC, WebSocket send', {
            socket: this.socket,
            socketData: { ...this.socketData },
          });
          const resultObj = JSON.parse(result);
          if (
            resultObj.result === 'success' &&
            this.socket &&
            this.socket.send
          ) {
            const { result: res, review_result } = resultObj;
            const { id, result_type, id_card } = review_result;
            const { id_card_origin, id_card_image } = id_card;
            const message = JSON.stringify({
              result: res,
              id,
              result_type,
              id_card_origin,
              id_card_image,
            });
            const data = {
              target: this.socketData.target,
              action: 'send-message',
              message,
            };
            this.socketData = { ...data };
          }
        } catch (error) {
          this.$log.error('WebSocket Error: ' + error);
        }
      } else if (
        this.platform.isWebViewIOSReactNative ||
        this.platform.isWebViewAndroidReactNative
      ) {
        // android + react-native cli + webview
        // android + react-native expo + webview
        // iOS + react-native cli + webview
        // iOS + react-native expo + webview
        window.ReactNativeWebView.postMessage(returnMessage);
      } else if (this.platform.isWebviewIOS) {
        // iOS + swift + WebView
        window.webkit.messageHandlers.alcherakyc &&
          window.webkit.messageHandlers.alcherakyc.postMessage(returnMessage);
      } else if (this.platform.isWebviewAndroid) {
        // android + webview
        window['alcherakyc'] &&
          window['alcherakyc']['receive'] &&
          window['alcherakyc']['receive'](returnMessage);
      } else if (this.platform.isWebBrowser) {
        // web browser + iframe
        window.parent.postMessage(returnMessage, this.origin);
      }
    },
    changeToServerOCRMode() {
      // WASM 오류시 방어코드 서버 OCR 모드로 전환
      this.$set(this, 'isWasmOCRMode', false);
      this.$set(this, 'isWasmSSAMode', false);
      this.$set(this, 'useWasmPreloading', false);
      // this.onCancel({prev: true});
    },
    onCancel(data) {
      this.$log.debug('onCancel 이벤트 발생', data);

      if (data && data.prev) {
        this.$log.debug('뒤로가기 발생, 현재 단계 : ', this.phase);
        this.$log.debug(
          '페이지 전환 타겟 : ',
          this.phaseModule.prev(this.phase)
        );
        if (this.showOnBoardingScreen === true) {
          this.showOnBoardingScreen = false;
          this.phase = this.PHASE_USER_INFO_INPUT;
          return;
        }
        switch (this.phase) {
          case this.PHASE_CARD_SCAN:
            this.$refs.rootAuth.style.overflow = 'auto';
            break;
          default:
            break;
        }
        if (this.phase === this.PHASE_CARD_SELECT && this.isMultipleModule) {
          if (this.inReuseProgress) {
            this.showReuseScreen = true;
          } else {
            this.showOnBoardingScreen = true;
          }
          this.showOnBoardingScreen = true;
        } else {
          this.phase = this.phaseModule.prev(this.phase);
        }
      } else if (
        this.showOnBoardingScreen === true ||
        this.showReuseScreen === true
      ) {
        // 온보딩에서 x 버튼 누른 경우
        this.sendResult('{"result": "close"}');
        this.appDestroy();
      } else if (this.phase === this.PHASE_CARD_SELECT) {
        if (this.isMultipleModule) {
          // 모듈이 여러개라 온보딩 보여주기
          if (this.inReuseProgress) {
            this.showReuseScreen = true;
          } else {
            this.showOnBoardingScreen = true;
          }
          // this.showOnBoardingScreen = true;
          this.$log.debug('모듈이 여러개라 온보딩 스크린이 보여져야 함');
        } else {
          //모듈이 한개라 그냥 꺼져야 함
          this.sendResult('{"result": "close"}');
          this.appDestroy();
        }
      } else if (this.phase === this.PHASE_CARD_SCAN && data?.upload) {
        this.manualInput = false;
        this.uploadProcess = true;
      } else if (this.phase === this.PHASE_BANK_HOLDER) {
        if (
          this.appData.identification_config_enabled &&
          this.appData.moduleName === 'account'
        ) {
          this.phase = this.PHASE_USER_INFO_INPUT;
        } else {
          this.sendResult('{"result": "close"}');
          this.appDestroy();
        }
      } else if (data && data.selectBank) {
        this.setHistoryAccount();
        this.setChangeBankCount();
        this.phase = this.phaseModule.prev(this.phase);
        this.selectBank = data.selectBank;
      } else if (data && data.fail) {
        this.appData = { ...this.appData, ...data };
        this.onResult(true, data.response);
        this.appDestroy();
      } else {
        this.sendResult('{"result": "close"}');
        this.appDestroy();
      }
    },
    initializeAppData() {
      this.$log.debug('UserInfoInput initialize');
      // this.$set(this.appData, 'cardScanRetryCount', 0);
      // this.$delete(this.appData, 'ocr');
      const identification = this.appData.identification_config_enabled;

      this.appData = {
        cardScanRetryCount: identification
          ? 0
          : this.appData.cardScanRetryCount,
        identification_config_enabled:
          this.appData.identification_config_enabled,
        manual_input_mobile: this.appData.manual_input_mobile,
        manual_upload_id_mobile: this.appData.manual_upload_id_mobile,
        manual_upload_id_pc: this.appData.manual_upload_id_pc,
        module: this.appData.module,
        progress: this.appData.progress,
        moduleName: this.appData.moduleName,
        save_original_id_image: this.appData.save_original_id_image,
        userName: this.appData.userName,
        birthDate: this.appData.birthDate,
        email: this.appData.email,
        phoneNumber: this.appData.phoneNumber,
        transaction_id: identification ? null : this.appData.transaction_id,
        exclude_finance_code_list: this.appData.exclude_finance_code_list,
        priority_finance_code_list: this.appData.priority_finance_code_list,
        customField: this.appData.customField,
        config: this.appData.config,
      };
    },
    onManual(data) {
      this.$log.debug('onManual', data);
      this.appData = { ...this.appData, ...data };
      this.$refs.rootAuth.style.overflow = 'auto';
      this.manualInput = true;
      this.phase = this.PHASE_CARD_RESULT;
    },

    onInitialized(data) {
      this.$set(this, 'wasmOCR', data.ocr);
      this.$set(this, 'wasmFace', data.face);
      this.$log.debug('Auth.onInitialized() - wasmOCR : ', this.wasmOCR);
    },

    onPreloaded(data) {
      this.$set(this, 'wasmOCR', data.ocr);
      this.$set(this, 'wasmFace', data.face);
      this.$log.debug(
        'Auth.onPreloaded() - wasmOCR.OCREngine - ',
        this.wasmOCR.getOCREngine()
      );
    },

    async onNext(data) {
      // check for server error response
      // TODO call checkServerResponse() only before final confirmation screen
      this.checkServerResponse(data);

      this.$log.debug(
        `Auth.vue#onNext()#${this.currentPhaseName}`,
        data,
        this.appData
      );

      // set app data
      if (
        ![
          Constants.PHASES.PHASE_CARD_NOT_MATCHED,
          Constants.PHASES.PHASE_BANK_HOLDER,
          Constants.PHASES.PHASE_BANK_VERIFY,
        ].includes(this.phase)
      ) {
        this.appData = {
          ...this.appData,
          ...data,
        };
      }
      switch (this.phase) {
        case this.PHASE_USER_INFO_INPUT:
          if (this.appData.module) {
            // 안면인증 모듈 불러오기
            if (
              (!this.moduleName.includes(Constants.MODULE.FACE) ||
                faceApi.isFaceDetectionModelLoadedSync()) &&
              this.isMultipleModule
            ) {
              this.$log.debug('onNext 온보딩 화면 나타나기');
              this.checkReuseProgress();

              if (this.inReuseProgress) {
                this.showReuseScreen = true;
              } else {
                this.showOnBoardingScreen = true;
              }
            }
            this.phase = this.phaseModule.first();
            return;
          }
          break;
        case this.PHASE_CARD_SELECT:
          // data: {
          //   cardIndex: 0,
          // }
          break;
        case this.PHASE_CARD_GUIDE:
          this.$refs.rootAuth.style.overflow = 'hidden';
          break;
        case this.PHASE_CARD_SCAN: {
          this.$refs.rootAuth.style.overflow = 'auto';
          this.appData = {
            ...this.appData,
            cardIndex: this.appData.cardIndex,
          };
          const birthday = util.getBirthdayFromOcr(
            this.appData.cardIndex,
            this.appData.ocr
          );
          // check name & birthday
          if (
            this.appData.userName !== this.appData.ocr.userName ||
            this.appData.birthDate !== birthday
          ) {
            this.$log.debug(
              '입력된 이름 ',
              this.appData.userName,
              ' 스캔된 이름 ',
              this.appData.ocr.userName
            );
            this.$log.debug(
              '입력된 생년월일 ',
              this.appData.birthDate,
              ' 스캔된 생년월일 ',
              birthday
            );

            this.appData.scannedFormattedDate = birthday;

            this.phase = this.PHASE_CARD_NOT_MATCHED;
            return;
          }
          break;
        }
        case this.PHASE_CARD_NOT_MATCHED:
          this.appData.fromCardNotMatchedOriginOcr = { ...this.appData.ocr };
          this.appData.fromCardNotMatched = true;
          if (data && data.userinfoChanged) {
            // OCR 결과를 변경하는 경우
            // backup original ocr
            this.$log.debug(
              '스캔된 ocr 정보',
              this.appData.fromCardNotMatchedOriginOcr
            );

            const newUserInfo = {
              name: this.appData.userName,
              birthday: this.appData.birthDate,
              phone_number: this.appData.phoneNumber,
              email: this.appData.email,
            };

            // change user info
            if (data.updatedEditType === 'user-info') {
              if (data.updatedFields.includes('name')) {
                this.appData.userName = data.updatedName;
                newUserInfo.name = data.updatedName;
              }
              if (data.updatedFields.includes('birthdate')) {
                this.appData.birthDate = data.updatedBirthDate;
                newUserInfo.birthday = data.updatedBirthDate;
              }
            }

            if (data.updatedFields.includes('name')) {
              this.appData.ocr.userName = data.updatedName;
              newUserInfo.name = data.updatedName;
            }

            if (data.updatedFields.includes('birthdate')) {
              // update wrong birthdate
              this.appData.scannedFormattedDate = data.updatedBirthDate;
              newUserInfo.birthday = data.updatedBirthDate;

              // when uploaded document is not foreign passport
              if (
                this.appData.cardIndex !==
                Constants.APP_CARD_INDEX.PASSPORT_ALIEN
              ) {
                const newJuminNo = data.updatedBirthDate
                  .slice()
                  .replace(/[_-]/g, '')
                  .slice(2);

                if (this.appData.ocr?.juminNo1) {
                  this.$log.debug('주민번호 수정');
                  this.appData.ocr.juminNo1 = newJuminNo;
                }
                if (this.appData.ocr?.issueNo1) {
                  this.$log.debug('발급번호 수정');
                  this.appData.ocr.issueNo1 = newJuminNo;
                }
                // update juminNo2
              }
              if (
                this.appData.cardIndex ===
                Constants.APP_CARD_INDEX.PASSPORT_ALIEN
              ) {
                this.$log.debug('임의로 변경함 + 외국인 여권');
                this.appData.ocr.userName = newUserInfo.name;
                this.appData.ocr.birthDate = newUserInfo.birthday;
              }
            }

            await this.setTransactionId(newUserInfo);

            // this.phase = this.phaseModule.first();
            // return;
          } else {
            // ocr 된 결과가 아닌 사용자 임의 입력값이 같다는 것을 의미하므로 바꿔줘야함
            this.$log.debug('ocr ', this.appData.ocr);
            this.$log.debug('appData', this.appData);
            this.appData.ocr.userName = this.appData.userName;
            const newJuminNo = this.appData.birthDate
              .slice()
              .replace(/[_-]/g, '')
              .slice(2);
            if (this.appData.ocr?.juminNo1) {
              this.$log.debug('주민번호 수정');
              this.appData.ocr.juminNo1 = newJuminNo;
            }
            if (this.appData.ocr?.issueNo1) {
              this.$log.debug('발급번호 수정');
              this.appData.ocr.issueNo1 = newJuminNo;
            }

            this.appData.ocr.birthDate = this.appData.birthDate;
          }
          break;
        case this.PHASE_CARD_RESULT:
          if (data && data.fail) {
            if (data.errorCode === 'A005') {
              this.phase = this.PHASE_CARD_LOCKED;
            } else {
              this.onResult(true, data.response);
            }
            return;
          } else if (
            !this.moduleName.includes(Constants.MODULE.FACE) &&
            !this.moduleName.includes(Constants.MODULE.ACCOUNT) &&
            !this.moduleName.includes(Constants.MODULE.CUSTOM_FIELD)
          ) {
            this.onResult(false, data.response);
          }
          break;
        case this.PHASE_FACE_SCAN:
          if (data && data.fail) {
            this.onResult(true, data.response);
            return;
          } else if (
            !this.moduleName.includes(Constants.MODULE.ACCOUNT) &&
            !this.moduleName.includes(Constants.MODULE.CUSTOM_FIELD)
          ) {
            this.onResult(false, data.response);
          }
          break;
        case this.PHASE_BANK_HOLDER:
          this.appData = {
            ...this.appData,
            // account_holder_name: data.account_holder_name,
          };
          break;
        case this.PHASE_BANK_SEND:
          break;
        case this.PHASE_BANK_VERIFY:
          if (data && data.fail) {
            if (data.errorType === 'tryout') {
              this.appData = {
                ...this.appData,
                errorType: 'tryout',
              };
            } else {
              this.appData = {
                ...this.appData,
                errorType: 'timeout',
              };
            }
            this.phase = this.PHASE_VERIFY_FAIL;
            this.onResult(true, data.response);
            return;
          } else {
            if (!this.moduleName.includes(Constants.MODULE.CUSTOM_FIELD)) {
              this.onResult(false, data.response);
            }
          }
          break;
        case this.PHASE_ADDITIONAL_INPUT:
          this.onResult(false, data.response);

          break;
        default:
          break;
      }
      this.$log.debug('phaseModuel.next', this.phase);
      this.phase = this.phaseModule.next(this.phase);
    },
    onRetry(event) {
      if (event && event.phase == 'cardSelect') {
        this.phase = this.PHASE_CARD_SELECT;
        return;
      }
      if (this.appData.identification_config_enabled) {
        this.phase = this.PHASE_USER_INFO_INPUT;
      } else {
        this.phase = this.PHASE_CARD_SELECT;
      }
      this.manualInput = false;
      this.appData.fromCardNotMatched = false;
      this.appData.fromCardNotMatchedOriginOcr = null;
    },
    onSelect() {
      this.phase = this.PHASE_BANK_SEND;
    },
    onResult(error, response) {
      response.result = error ? 'failed' : 'success';
      if (
        response?.review_result?.face_check &&
        'is_masked' in response.review_result.face_check
      ) {
        // remove is_masked
        delete response.review_result.face_check.is_masked;
      }

      if (error === false) {
        this.appData.resultType = response.review_result.result_type;
      }

      this.sendResult(JSON.stringify(response));
    },
    onComplete() {
      this.sendResult('{"result": "complete"}');

      if (this.platform.isWebSocket) {
        this.$log.debug('[WS] send-message', JSON.stringify(this.socketData));
        this.socket.send(JSON.stringify(this.socketData));
      }

      this.appDestroy();
    },
    checkServerResponse(res) {
      // TODO expand out this function to handle different server response types
      if (res && res.result === 'success' && !res.review_result) {
        this.systemErrorCode = Constants.CLIENT.ERROR_CODE.REVIEW_RESULT_EMPTY;
        this.invalidServerResponseErrorFound = true;
      } else {
        this.invalidServerResponseErrorFound = false;
      }
    },
    connectWebSocket(token) {
      this.socket = new WebSocket(`${this.webSocketServerURL}?token=${token}`);
      this.socket.onopen = () => {
        this.socket.send(JSON.stringify(this.socketData));
      };
      this.socket.onerror = () => {
        this.systemErrorCode =
          Constants.CLIENT.ERROR_CODE.WEBSOCKET_CONNECTION_FAILED;
        this.invalidServerResponseErrorFound = true;
      };
      this.socket.onmessage = (res) => {
        try {
          const data = JSON.parse(res.data);
          if (data.action === 'leave-room') {
            this.appDestroy();
          }
        } catch (e) {
          this.$log.error('error socket message', e);
        }
      };
      this.socket.onclose = () => {
        this.$log.debug('onClose');
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.root-container-auth {
  position: fixed;
  overflow: auto;
  width: 100%;
  height: 100%;
  color: var(--surface-high);
}
</style>

<style>
video::-webkit-media-controls-panel {
  display: none;
  -webkit-appearance: none;
}
video::-webkit-media-controls-play-button {
  display: none;
  -webkit-appearance: none;
}
video::-webkit-media-controls-start-playback-button {
  display: none;
  -webkit-appearance: none;
}

video::-webkit-media-controls {
  display: none;
  -webkit-appearance: none;
}
</style>

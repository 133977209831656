<template>
  <header>
    <img v-if="logoSrc !== ''" :src="logoSrc" alt="logo" />
    <button v-if="isDev" type="button" @click="changeLocale">
      {{ $i18next.language }}
    </button>
  </header>
</template>

<script>
import { changeLanguage, i18n } from '../../../i18n';
export default {
  props: {
    appData: { type: Object },
    hasLogo: { type: Boolean },
  },
  created() {
    this.$log.debug('Logo #created', {
      appData: this.appData,
      hasLogo: this.hasLogo,
    });
  },
  methods: {
    changeLocale() {
      const current = i18n.language;
      changeLanguage(current === 'ko' ? 'en' : 'ko');
    },
  },
  computed: {
    logoSrc() {
      if (!this.hasLogo) return '';
      const {
        allow_custom_theme,
        use_custom_theme,
        use_custom_logo,
        custom_logo,
      } = this.appData.config;
      if (
        !allow_custom_theme ||
        !use_custom_theme ||
        (use_custom_theme && !use_custom_logo)
      )
        return require('@/assets/logo-useb.svg');
      if (custom_logo.indexOf('data:image') > -1) return custom_logo;
      return '';
    },
    isDev() {
      return process.env.NODE_ENV !== 'production';
    },
  },
};
</script>

<style lang="scss" scoped>
header {
  position: fixed;
  width: 100%;
  z-index: 3;
  padding: 16px 32px 4px;
  background-color: var(--surface-100);
  // margin-bottom: -32px;
  display: flex;
  justify-content: space-between;

  img {
    max-width: 100px;
    max-height: 20px;
  }
}
</style>

<template>
  <button
    @click="dim ? () => {} : $emit('click')"
    class="add-button"
    type="submit"
    :style="dim ? additionalDimButtonStyle : additionalButtonStyle"
  >
    <div style="display: flex; justify-content: center">
      <img height="24px;" v-if="img != ''" :src="img" alt="" />
      <div v-if="buttonLoading">
        <img
          v-if="bgColor == 'var(--primary-100)'"
          :src="require('@/assets/Loading_40px.svg')"
          alt="loading"
        />
        <img
          v-if="bgColor == '#F6F6F6'"
          :src="require('@/assets/Loading_32px.svg')"
          alt="loading"
        />
      </div>
      <div v-else>{{ buttonString }}</div>
    </div>
  </button>
</template>

<script>
export default {
  name: 'SubmitButton',
  props: {
    dim: {
      type: Boolean,
      default: false,
    },
    img: {
      type: String,
      default: '',
    },
    buttonLoading: {
      type: Boolean,
      default: false,
    },
    buttonString: {
      type: String,
      default: '+등록',
    },
    lineColor: {
      type: String,
      default: 'none',
    },
    bgColor: {
      type: String,
      default: 'var(--surface-100)',
    },
    bgDimColor: {
      type: String,
      default: 'var(--primary-20)',
    },
    textColor: {
      type: String,
      default: 'var(--surface-100)',
    },
    textDimColor: {
      type: String,
      default: 'var(--surface-100)',
    },
    height: {
      type: String,
      default: '32px',
    },
    width: {
      type: String,
      default: '62px',
    },
    fontSize: {
      type: String,
      default: '14px',
    },
  },
  components: {},
  computed: {
    additionalButtonStyle() {
      return {
        'background-color': this.bgColor,
        width: this.width,
        height: this.height,
        border: `1px solid ${this.lineColor}`,
        color: this.textColor,
        fontSize: `${this.fontSize}`,
      };
    },
    additionalDimButtonStyle() {
      return {
        cursor: 'unset',
        'background-color': this.bgDimColor,
        width: this.width,
        height: this.height,
        border: `1px solid ${this.lineColor}`,
        color: this.textDimColor,
        fontSize: `${this.fontSize}`,
      };
    },
  },
  methods: {},
};
</script>

<style scoped>
.add-button,
.add-button:focus {
  color: white;
  border-radius: 8px;
  font-weight: normal;
  font-style: normal;
  align-self: end;
  outline: none;
}
</style>

<template>
  <ViewContainer
    id="cardResult_container"
    :paddingTop="appData.hasLogo ? 48 : 32"
    ref="root"
  >
    <img
      @click="stopScan = true"
      :style="appData.hasLogo ? 'top:48px' : 'top:32px'"
      src="@/assets/icon-close-32.svg"
      class="icon-close"
    />
    <div class="card-result-title">{{ $t('온보딩.신분증 인증') }}</div>
    <div class="card-result-info">
      {{ $t('신분증.신분증 정보를 확인해 주세요.') }}
    </div>
    <div
      v-if="appData.cardIndex === 0 && this.appData.retry > 0"
      class="card-result-warning-text"
    >
      <div class="card-result-warning-text-top">
        <img src="@/assets/info_20_red.svg" />
        <div>
          {{ $t('신분증.발급 일자 입력 오류 5회 발생 시 주민등록증 잠김') }}
        </div>
      </div>
      <div class="card-result-warning-text-bottom">
        {{ $t('신분증.(진위 확인 불가)') }}
      </div>
    </div>
    <div class="card-result-label">{{ $t('개인정보입력.이름') }}</div>
    <InputTextField
      ref="name"
      :readonly="true"
      v-model="ocr.userName"
      :error="!ocr.userName"
      :rules="rulesName"
      :errorMessage="errorMessageName"
      @onupdate="validateForm"
      class="card-result-input"
      :id="'cardResult_input_name'"
    />
    <div
      v-if="
        [
          Constants.APP_CARD_INDEX.PASSPORT_KOREAN,
          Constants.APP_CARD_INDEX.PASSPORT_ALIEN,
        ].includes(appData.cardIndex)
      "
      class="card-result-label"
    >
      {{ $t('신분증.여권번호') }}
    </div>
    <InputTextField
      v-if="
        [
          Constants.APP_CARD_INDEX.PASSPORT_KOREAN,
          Constants.APP_CARD_INDEX.PASSPORT_ALIEN,
        ].includes(appData.cardIndex)
      "
      v-model="ocr.passportNo"
      :placeholder="manualInput ? 'A00000000' : originOcr.passportNo"
      :maxlength="9"
      :error="errorPassport"
      :errorMessage="
        ocr.passportNo
          ? defaultErrorMessage
          : $t('신분증.여권번호를 입력해 주세요.')
      "
      @onupdate="validateForm"
      :id="'cardResult_input_passportNumber'"
    />
    <div v-if="appData.cardIndex === Constants.APP_CARD_INDEX.DRIVER">
      <div
        v-if="
          manualInput && appData.cardIndex === Constants.APP_CARD_INDEX.DRIVER
        "
        class="card-result-label"
      >
        {{ $t('신분증.운전면허번호 형식') }}
      </div>
      <div
        v-if="
          manualInput && appData.cardIndex === Constants.APP_CARD_INDEX.DRIVER
        "
        class="flex-row-layout dl-format-option-container"
      >
        <div class="dl-format-option" @click="dlFormatOptionValue = 1">
          <img
            :src="dlFormatOptionValue === 1 ? onRadioIcon : offRadioIcon"
            class="radio-icon"
            :id="'cardResult_radio_driverNumberFormat_new'"
          />
          <span>{{ $t('신분증.신형 (숫자)') }}</span>
        </div>
        <div class="dl-format-option" @click="dlFormatOptionValue = 2">
          <img
            :src="dlFormatOptionValue === 2 ? onRadioIcon : offRadioIcon"
            class="radio-icon"
            :id="'cardResult_radio_driverNumberFormat_old'"
          />
          <span>{{ $t('신분증.구형 (한글 + 숫자)') }}</span>
        </div>
      </div>
      <div class="card-result-label">
        {{ $t('신분증.운전면허번호') }}
      </div>
      <div class="flex-row-layout">
        <InputTextField
          v-if="
            isDriverLicenseOld || (manualInput && dlFormatOptionValue === 2)
          "
          :key="`input-key-driverNo1`"
          v-model="ocr.driverNo1"
          :placeholder="manualInput ? '서울' : originOcr.driverNo1"
          :maxlength="2"
          :error="validRequireDriver"
          :errorMessage="
            ocr.driverNo1
              ? defaultErrorMessage
              : $t('신분증.운전면허번호를 입력해 주세요.')
          "
          @onupdate="validateForm"
          style="min-width: 85px; width: 85px"
          :id="'cardResult_input_driverNo1'"
        ></InputTextField>
        <div
          v-if="
            isDriverLicenseOld || (manualInput && dlFormatOptionValue === 2)
          "
          style="width: 15px"
        />
        <InputTextField
          :key="`input-key-driverNo2`"
          v-model="ocr.driverNo2"
          :placeholder="
            manualInput
              ? dlFormatOptionValue === 2
                ? '00-000000-00'
                : '00-00-000000-00'
              : originOcr.driverNo2
          "
          :transform="transformDriverLicense"
          :error="validRequireDriver"
          :errorMessage="
            isDriverLicenseOld || (manualInput && dlFormatOptionValue === 2)
              ? ''
              : ocr.driverNo2
              ? defaultErrorMessage
              : $t('신분증.운전면허번호를 입력해 주세요.')
          "
          pattern="\d*"
          @onupdate="validateForm"
          :style="
            isDriverLicenseOld || (manualInput && dlFormatOptionValue === 2)
              ? 'flex-grow:1;'
              : 'flex-grow:1;'
          "
          :id="'cardResult_input_driverNo2'"
        />
      </div>
    </div>
    <div
      v-if="appData.cardIndex === Constants.APP_CARD_INDEX.PASSPORT_ALIEN"
      class="card-result-label"
    >
      {{ $t('신분증.국적 코드') }}
    </div>
    <InputTextField
      v-if="appData.cardIndex === Constants.APP_CARD_INDEX.PASSPORT_ALIEN"
      v-model="ocr.nationality"
      :key="'input-key-nationality'"
      :placeholder="manualInput ? 'ABC' : originOcr.nationality"
      :transform="rules.transformOnlyUpperCase"
      :rules="[
        rules.UpperEng,
        (val) => {
          return val.length > 2;
        },
      ]"
      :errorMessage="
        ocr.nationality
          ? defaultErrorMessage
          : $t('신분증.국적코드를 입력해 주세요.')
      "
      @onupdate="validateForm"
      class="card-result-input"
      :id="'cardResult_input_nationality'"
    />
    <div
      v-if="
        appData.cardIndex === Constants.APP_CARD_INDEX.PASSPORT_KOREAN ||
        appData.cardIndex === Constants.APP_CARD_INDEX.PASSPORT_ALIEN
      "
      class="card-result-label"
    >
      {{ $t('개인정보입력.생년월일') }}
    </div>
    <InputTextField
      ref="birthday"
      :readonly="true"
      v-if="
        appData.cardIndex === Constants.APP_CARD_INDEX.PASSPORT_KOREAN ||
        appData.cardIndex === Constants.APP_CARD_INDEX.PASSPORT_ALIEN
      "
      v-model="ocr.birthDate"
      :key="'input-key-birthDate'"
      :placeholder="manualInput ? 'YYYY-MM-DD' : originOcr.birthDate"
      :rules="rulesBirthday"
      :errorMessage="errorMessageBirthday"
      @onupdate="validateForm"
      :transform="rules.transformDateFormat"
      :onblurTransform="rules.onblurTransformDateFormat"
      class="card-result-input"
      :id="'cardResult_input_bod'"
    />
    <div v-if="juminTitle" class="card-result-label">
      {{ juminTitle }}
    </div>
    <div
      v-if="
        appData.cardIndex !== Constants.APP_CARD_INDEX.PASSPORT_KOREAN &&
        appData.cardIndex !== Constants.APP_CARD_INDEX.PASSPORT_ALIEN
      "
      class="flex-row-layout not-passport-alien"
    >
      <InputTextField
        :key="`input-key-juminNo1`"
        v-model="ocr.juminNo1"
        :readonly="true"
        type="number"
        pattern="\d*"
        :placeholder="originOcr.juminNo1"
        :maxlength="6"
        :transform="rules.transformOnlyNumber"
        :error="errorJuminFirst"
        @onupdate="validateForm"
        :class="{ correctJumin: !errorJuminFirst }"
        style="width: calc(50% - 14px)"
        :id="'cardResult_input_juminNo1'"
      />
      <!-- :showErrorMessage="errorJumin"
        :errorMessage="errorJuminMessage" -->
      <div class="input-between-dash-style" />
      <InputTextField
        v-if="!showJumin2"
        :key="`input-key-_juminNo2`"
        v-model="ocr._juminNo2"
        type="number"
        pattern="\d*"
        :placeholder="manualInput ? '0' : originOcr._juminNo2"
        :maxlength="1"
        :transform="rules.transformOnlyNumber"
        :error="errorJumin_Second"
        @onupdate="validateForm"
        style="margin-right: 8px; width: 60px"
        :id="'cardResult_input__juminNo2'"
      />

      <InputTextField
        v-else
        :key="`input-key-juminNo2`"
        v-model="ocr.juminNo2"
        type="password"
        pattern="\d*"
        placeholder="0000000"
        :maxlength="7"
        :transform="rules.transformOnlyNumber"
        :error="errorJuminSecond"
        @onupdate="validateForm"
        style="width: calc(50% - 14px)"
        :id="'cardResult_input_juminNo2'"
        :color="appData.colorPalette?.tint_80"
      />
      <div class="hide-jumin" v-if="!showJumin2">******</div>
    </div>
    <!-- v-if="showErrorMessage || (!validFlag && errorMessage)" -->
    <div
      v-if="
        appData.cardIndex !== Constants.APP_CARD_INDEX.PASSPORT_KOREAN &&
        appData.cardIndex !== Constants.APP_CARD_INDEX.PASSPORT_ALIEN &&
        errorJumin
      "
      class="text-error"
    >
      {{ errorJuminMessage }}
    </div>
    <div v-if="showIssueDate" class="card-result-label">
      {{ $t('신분증.발급 일자') }}
    </div>
    <InputTextField
      v-if="showIssueDate"
      v-model="ocr.issueDate"
      :key="'input-key-issueDate'"
      :placeholder="manualInput ? 'YYYY-MM-DD' : originOcr.issueDate"
      :rules="[rules.required, rules.date, rules.isPastThanFuture]"
      :transform="rules.transformDateFormat"
      :onblurTransform="rules.onblurTransformDateFormat"
      :error="!validRequireIssueDate"
      :errorMessage="
        ocr.issueDate
          ? defaultErrorMessage
          : $t('신분증.발급일자를 입력해 주세요.')
      "
      @keyup="validateForm"
      class="card-result-input"
      :id="'cardResult_input_issueDate'"
      pattern="\d*"
    />
    <div
      v-if="appData.cardIndex === Constants.APP_CARD_INDEX.PASSPORT_KOREAN"
      class="card-result-label"
    >
      {{ $t('신분증.만료 일자') }}
    </div>
    <InputTextField
      v-if="appData.cardIndex === Constants.APP_CARD_INDEX.PASSPORT_KOREAN"
      v-model="ocr.expiryDate"
      :key="'input-key-expiryDate'"
      :placeholder="manualInput ? 'YYYY-MM-DD' : originOcr.expiryDate"
      appendWidth="24px"
      :rules="[rules.required, rules.date, rules.expiryDate]"
      :transform="rules.transformDateFormat"
      :onblurTransform="rules.onblurTransformDateFormat"
      :error="errorPassportExpiryDate"
      :errorMessage="
        validRequireExpiryDate && expiryError
          ? $t('신분증.만료된 유효기간입니다. 확인 후 수정해 주세요.')
          : ocr.expiryDate
          ? defaultErrorMessage
          : $t('신분증.만료일자를 입력해 주세요.')
      "
      @onupdate="validateForm"
      class="card-result-input"
      :id="'cardResult_input_expiryDate'"
      pattern="\d*"
    />
    <div data-description="fixed-button-area" />
    <div class="button-container">
      <div
        :id="'cardResult_button_retry'"
        v-ripple
        @click="onClickRetry"
        class="button retry"
      >
        {{ $t('버튼.재시도') }}
      </div>
      <div
        v-ripple
        @click="nextHandler"
        :class="{ button: true, next: true, disabled }"
        :id="'cardResult_button_next'"
      >
        {{ $t('버튼.다음') }}
      </div>
    </div>
    <Loading
      v-if="
        loading && appData.retry !== MAX_RETRY_COUNT && !appData.isIdcardLock
      "
      :success="loadingSuccess"
      :messages="loadingMessages"
    />
    <ExitDialog
      v-model="stopScan"
      @cancel="cancelExit"
      @ok="$emit('cancel')"
      :id="'cardResult_exitPopup'"
      :disabledProp="!stopScan"
    />
    <ServerErrorDialog
      v-model="serverError"
      :icon="errorIcon"
      :title="errorMessageTitle"
      :customTitleColor="customErrorTitleColor"
      :message="errorMessage"
      :customMessageHtmlFormat="errorHtmlMessage"
      :errorCode="errorCode"
      :button="errorButton"
      @minorBtn="onClickBack(true)"
      @majorBtn="onClickCloseServerError"
      :id="'cardResult_serverErrorPopup'"
      :disabledProp="!serverError"
    />
    <CardResultFail
      v-if="appData.retry === MAX_RETRY_COUNT"
      @ok="$emit('cancel')"
      :id="'cardResult_failPopup'"
    />

    <CardResultFailFromIdcardLock
      v-if="appData.isIdcardLock"
      @ok="$emit('cancel')"
      :id="'cardResult_failPopup_lock'"
    />
    <EditIssueDateDialog
      v-if="issueDateUserInputDialog"
      :ocr="ocr"
      :id="'editIssueDateDialog'"
      @ok="onClickEditIssueDate"
      @cancel="onClickBack(true)"
    />
    <EditExpiryDateDialog
      v-if="driverExpiryDateUserInputDialog"
      :ocr="ocr"
      :id="'editExpiryDateDialog'"
      @ok="onClickEditExpiryDate"
      @cancel="driverExpiryDateUserInputDialog = false"
    />
  </ViewContainer>
</template>

<script>
import Constants from '@/constants';
import util from '@/util';
import useb from '@/api/useb';
import InputTextField from './InputTextField';
import CardResultFail from './CardResultFail';
import CardResultFailFromIdcardLock from './CardResultFailFromIdcardLock';
import Loading from './Loading';
import ExitDialog from '../dialog/ExitDialog';
import ServerErrorDialog from '../dialog/ServerErrorDialog';
import server from '@/api/server';
import rules from '../rules';
import { mapState } from 'vuex';
import * as netfunnel from './netfunnel.js';
import ViewContainer from '../../../layout/auth/ViewContainer.vue';
import EditIssueDateDialog from '../dialog/EditIssueDateDialog.vue';
import EditExpiryDateDialog from '../dialog/EditExpiryDateDialog.vue';
import ErrorVue from './DynamicCt_imgs/Error.vue';
import Icon_CautionVue from './DynamicCt_imgs/Icon_Caution.vue';
//import * as netfunnelSkin from "./netfunnel-skin.js";
//console.log(netfunnelSkin);   // prevent build error for unused import file

export default {
  components: {
    InputTextField,
    CardResultFail,
    CardResultFailFromIdcardLock,
    ExitDialog,
    Loading,
    ServerErrorDialog,
    ViewContainer,
    EditIssueDateDialog,
    EditExpiryDateDialog,
  },

  props: {
    appData: Object,
    manualInput: {
      type: Boolean,
      required: true,
      default: false,
    },
    uploadType: {
      type: String,
      default: '',
    },
    industry: {
      type: Number,
    },
    isIDCardFaceDetectMode: {
      type: Boolean,
      required: true,
      default: false,
    }
    /**
     * emit events
     * cancel
     * next
     * retry
     */
  },
  data: function () {
    const self = this;
    return {
      Constants,
      rules,
      loading: false,
      loadingSuccess: false,
      loadingMessages: [],
      loadingMessageStart: [
        this.$t('신분증.정부기관의 신분증 정보와'),
        this.$t('신분증.진위 확인 중입니다.'),
      ],
      loadingMessageSuccess: [this.$t('신분증.진위 확인이 완료되었습니다.')],
      systemError: false,
      serverError: false,
      inErrorProgress: false,
      errorIcon: {},
      errorMessageTitle: [],
      customErrorTitleColor: '',
      errorMessage: [],
      // defaultErrorMessage: '입력 형식이 올바르지 않습니다.',
      errorHtmlMessage: '',
      errorCode: '',
      errorButton: [],
      errorMessageName: '',
      errorMessageBirthday: '',
      disabled: false,
      MAX_RETRY_COUNT: 3,
      stopScan: false,
      isDriverLicenseOld: false,
      dlFormatOptionValue: 1,
      alienId: 0,
      originOcr: {},
      ocr: {},
      issueDateUserInputDialog: false,
      driverExpiryDateUserInputDialog: false,
      iconChecked: require('@/assets/icon-check-green.svg'),
      iconCaution: Icon_CautionVue,
      iconError: ErrorVue,
      onRadioIcon: require('@/assets/icon-radio-on.svg'),
      offRadioIcon: require('@/assets/icon-radio-off.svg'),
      rulesName: [
        (val) => !!val || (self.errorMessageName = '신분증을 재시도해주세요.'),
        (val) =>
          val === self.appData.userName ||
          (self.errorMessageName =
            '정보가 일치하지 않습니다. 확인 후 수정해 주세요.'),
      ],
      rulesBirthday: [
        (val) =>
          (!!val && rules.date(val)) ||
          (self.errorMessageBirthday = '생년월일을 입력해주세요.'),
        (val) =>
          val === self.appData.birthDate ||
          (self.errorMessageBirthday =
            '정보가 일치하지 않습니다. 확인 후 수정해 주세요.'),
      ],
      transformDriverLicense: (text, isBackspace) => {
        let first, start, middle, end;
        const lastText = text.substring(text.length - 1);
        if (
          this.isDriverLicenseOld ||
          (this.manualInput && this.dlFormatOptionValue === 2)
        ) {
          first = '';
          start = text.substring(0, 2);
          middle = text.substring(3, 9);
          end = text.substring(10, 12);
          start = self.rules.transformOnlyNumber(start);
          middle = self.rules.transformOnlyNumber(middle);
          end = self.rules.transformOnlyNumber(end);
          if (text.length === 3 && lastText !== '-') {
            middle = lastText;
          }
          if (text.length === 10 && lastText !== '-') {
            end = lastText;
          }
          if (start.length === 2 && (middle || !isBackspace)) {
            start += '-';
          }
          if (middle.length === 6 && (end || !isBackspace)) {
            middle += '-';
          }
        } else {
          first = text.substring(0, 2);
          start = text.substring(3, 5);
          middle = text.substring(6, 12);
          end = text.substring(13, 15);
          first = self.rules.transformOnlyNumber(first);
          start = self.rules.transformOnlyNumber(start);
          middle = self.rules.transformOnlyNumber(middle);
          end = self.rules.transformOnlyNumber(end);
          if (text.length === 3 && lastText !== '-') {
            start = lastText;
          }
          if (text.length === 6 && lastText !== '-') {
            middle = lastText;
          }
          if (text.length === 13 && lastText !== '-') {
            end = lastText;
          }
          if (first.length === 2 && (start || !isBackspace)) {
            first += '-';
          }
          if (start.length === 2 && (middle || !isBackspace)) {
            start += '-';
          }
          if (middle.length === 6 && (end || !isBackspace)) {
            middle += '-';
          }
        }
        return first + start + middle + end;
      },
    };
  },
  watch: {},
  computed: {
    ...mapState(['companyPhoneNumber']),
    defaultErrorMessage() {
      return this.$t('개인정보입력.입력 형식이 올바르지 않습니다.');
    },
    juminTitle() {
      if (
        this.appData.cardIndex === Constants.APP_CARD_INDEX.ALIEN &&
        this.alienId == Constants.APP_CARD_INDEX.ALIEN_RESI
      ) {
        return this.$t('신분증.거소신고번호');
      } else if (
        this.appData.cardIndex === Constants.APP_CARD_INDEX.ALIEN &&
        (this.alienId == Constants.APP_CARD_INDEX.ALIEN_REGI ||
          this.alienId == Constants.APP_CARD_INDEX.ALIEN_PERM)
      ) {
        return this.$t('신분증.외국인 등록번호');
      } else if (this.appData.cardIndex === Constants.APP_CARD_INDEX.ALIEN) {
        return `${this.$t('신분증.외국인 등록번호')} /
        ${this.$t('신분증.거소신고번호')}`;
      } else if (
        this.appData.cardIndex !== Constants.APP_CARD_INDEX.PASSPORT_KOREAN &&
        this.appData.cardIndex !== Constants.APP_CARD_INDEX.PASSPORT_ALIEN
      ) {
        return this.$t('신분증.주민등록번호');
      } else {
        return '';
      }
    },
    showJumin2() {
      return [
        Constants.APP_CARD_INDEX.JUMIN,
        // Constants.APP_CARD_INDEX.PASSPORT_KOREAN,
        Constants.APP_CARD_INDEX.ALIEN,
      ].includes(this.appData.cardIndex);
    },
    userInputIssueDate() {
      this.$log.debug('userInputIssueDate', {
        cardIndex: this.appData.cardIndex,
        const_driver: Constants.APP_CARD_INDEX.DRIVER,
        config: this.appData.config.use_ocr_driver_date,
      });

      if (
        this.appData.cardIndex === Constants.APP_CARD_INDEX.DRIVER &&
        !this.appData.config.use_ocr_driver_date
      ) {
        return true;
      }
      return false;
    },
    showIssueDate() {
      if (
        this.appData.cardIndex === Constants.APP_CARD_INDEX.PASSPORT_ALIEN ||
        this.appData.cardIndex === Constants.APP_CARD_INDEX.DRIVER
      )
        return false;
      return true;
    },
    userInputExpiryDate() {
      const { cardIndex ,config: { use_ocr_driver_expired_date } } = this.appData; // prettier-ignore
      this.$log.debug('userInputExpiryDate', { use_ocr_driver_expired_date });
      // prettier-ignore
      if (cardIndex === Constants.APP_CARD_INDEX.DRIVER && !use_ocr_driver_expired_date) {  
        return true;
      }
      return false;
    },
    errorJuminFirst() {
      let birthday = '';
      if (!this.manualInput) {
        birthday = util.getBirthdayFromOcr(
          this.appData.cardIndex,
          this.restoreOcrFormat(this.ocr)
        );
      }
      return (
        !this.ocr.juminNo1 ||
        this.ocr.juminNo1.length != 6 ||
        (!this.manualInput &&
          this.appData.scannedFormattedDate &&
          this.appData.birthDate !== birthday)
      );
    },
    errorJuminSecond() {
      let birthday = '';
      if (!this.manualInput) {
        birthday = util.getBirthdayFromOcr(
          this.appData.cardIndex,
          this.restoreOcrFormat(this.ocr)
        );
      }
      return (
        (this.appData.cardIndex !== Constants.APP_CARD_INDEX.DRIVER &&
          !this.ocr.juminNo2) ||
        (this.appData.cardIndex !== Constants.APP_CARD_INDEX.DRIVER &&
          this.ocr.juminNo2.length != 7) ||
        // !this.ocr._juminNo2 ||
        (!this.manualInput &&
          this.appData.scannedFormattedDate &&
          this.appData.birthDate !== birthday)
      );
    },
    errorJumin_Second() {
      return (
        (this.appData.cardIndex === Constants.APP_CARD_INDEX.DRIVER ||
          this.appData.cardIndex ===
            Constants.APP_CARD_INDEX.PASSPORT_KOREAN) &&
        !this.ocr._juminNo2
      );
    },
    errorJumin() {
      let birthday = '';
      if (!this.manualInput) {
        birthday = util.getBirthdayFromOcr(
          this.appData.cardIndex,
          this.restoreOcrFormat(this.ocr)
        );
      }
      return (
        !this.ocr.juminNo1 ||
        this.ocr.juminNo1.length != 6 ||
        (this.appData.cardIndex !== Constants.APP_CARD_INDEX.DRIVER &&
          this.appData.cardIndex !== Constants.APP_CARD_INDEX.PASSPORT_KOREAN &&
          !this.ocr.juminNo2) ||
        (this.appData.cardIndex !== Constants.APP_CARD_INDEX.DRIVER &&
          this.appData.cardIndex !== Constants.APP_CARD_INDEX.PASSPORT_KOREAN &&
          this.ocr.juminNo2.length != 7) ||
        ((this.appData.cardIndex === Constants.APP_CARD_INDEX.DRIVER ||
          this.appData.cardIndex ===
            Constants.APP_CARD_INDEX.PASSPORT_KOREAN) &&
          !this.ocr._juminNo2) ||
        //!this.ocr._juminNo2 ||
        (!this.manualInput &&
          this.appData.scannedFormattedDate &&
          this.appData.birthDate !== birthday)
      );
    },

    errorJuminMessage() {
      // const showJuminMessage = this.errorJumin || this.errorJuminFirst;
      // this.$log.debug(
      //   'errorJuminMessage',
      //   showJuminMessage,
      //   this.appData.cardIndex
      // );
      // let errorText = '정보가 일치하지 않습니다. 확인 후 수정해 주세요.';
      let errorText = this.defaultErrorMessage;
      if (
        (!this.showJumin2 && this.ocr._juminNo2 === '') ||
        (this.showJumin2 && this.ocr.juminNo2 === '')
      ) {
        if (
          this.appData.cardIndex === Constants.APP_CARD_INDEX.ALIEN &&
          this.alienId == Constants.APP_CARD_INDEX.ALIEN_RESI
        ) {
          errorText = this.$t('신분증.거소신고번호 뒷자리를 입력해 주세요.');
        } else if (
          this.appData.cardIndex === Constants.APP_CARD_INDEX.ALIEN &&
          (this.alienId == Constants.APP_CARD_INDEX.ALIEN_REGI ||
            this.alienId == Constants.APP_CARD_INDEX.ALIEN_PERM)
        ) {
          errorText = this.$t('신분증.외국인 등록번호 뒷자리를 입력해 주세요.');
        } else if (this.appData.cardIndex === Constants.APP_CARD_INDEX.ALIEN) {
          errorText = this.$t(
            '신분증.외국인등록번호 또는 거소신고번호 뒷자리를 입력해 주세요.'
          );
        } else {
          errorText = this.$t('신분증.주민등록번호 뒷자리를 입력해 주세요.');
        }
      }
      return errorText;
    },
    errorPassport() {
      // const passportRegex = /([a-zA-Z]{1}|[a-zA-Z]{2})\d{8}/;
      // const passportRegex = /[a-zA-Z]{1}[0-9a-zA-Z]{1}[0-9]{7}/;
      // console.log(!passportRegex.test(this.ocr.passportNo));
      // return !passportRegex.test(this.ocr.passportNo);
      return !(this.ocr.passportNo.length >= 9);
      // return true;
    },

    errorPassportExpiryDate() {
      if (this.validRequireExpiryDate) {
        const now = new Date();

        const nowYear = now.getFullYear();
        const nowMonth = now.getMonth();
        const nowDate = now.getDate();

        const nowExpirePeriod = new Date(nowYear, nowMonth - 1, nowDate); // 정각 구하려고 표현
        const inputExpiryDate = new Date(this.ocr.expiryDate);

        return nowExpirePeriod.getTime() > inputExpiryDate.getTime();
      } else {
        return true;
      }
    },
    errorDriverExpiryDate() {
      if (this.validRequireDriverExpiryDate) {
        const now = new Date();

        const nowYear = now.getFullYear();
        const nowMonth = now.getMonth();
        const nowDate = now.getDate();

        const nowExpirePeriod = new Date(nowYear, nowMonth, nowDate); // 정각 구하려고 표현
        const inputExpiryDate = new Date(this.ocr.expiryDate);

        return nowExpirePeriod.getTime() > inputExpiryDate.getTime();
      } else {
        return true;
      }
    },
    errorNationalityCode() {
      return this.ocr.nationality.length < 3;
    },
    expiryDate() {
      if (this.ocr.expiryDate && this.ocr.expiryDate.length === 10) {
        const now = Date.now();
        const expireDate = this.ocr.expiryDate
          .split('-')
          .map((v) => parseInt(v));
        const expireTime = new Date(
          expireDate[0],
          expireDate[1] - 1,
          expireDate[2] + 1
        ).getTime();

        return expireTime < now ? '' : this.iconChecked;
      }
      return '';
    },
    expiryError() {
      if (this.validRequireExpiryDate) {
        return !this.expiryDate;
      }
      return false;
    },
    expiryDriverDate() {
      if (this.ocr.expiryDate2 && this.ocr.expiryDate2.length === 10) {
        const now = Date.now();
        const expireDate = this.ocr.expiryDate2
          .split('-')
          .map((v) => parseInt(v));
        const expireTime = new Date(
          expireDate[0],
          expireDate[1] - 1,
          expireDate[2] + 1
        ).getTime();

        return expireTime < now ? '' : this.iconChecked;
      }
      return '';
    },
    expiryDriverError() {
      if (this.validRequireDriverExpiryDate) {
        return !this.expiryDriverDate;
      }
      return false;
    },
    validRequireExpiryDate() {
      return this.ocr.expiryDate && this.ocr.expiryDate.length === 10;
    },
    validRequireDriverExpiryDate() {
      return this.ocr.expiryDate2 && this.ocr.expiryDate2.length === 10;
    },
    validRequireIssueDate() {
      return this.ocr.issueDate && this.ocr.issueDate.length === 10;
    },
    validRequireDriver() {
      if (
        this.isDriverLicenseOld ||
        (this.manualInput && this.dlFormatOptionValue === 2)
      ) {
        return (
          !this.ocr.driverNo1 ||
          this.ocr.driverNo1.length !== 2 ||
          !this.ocr.driverNo2 ||
          this.ocr.driverNo2.length !== 12
        );
      } else {
        return !this.ocr.driverNo2 || this.ocr.driverNo2.length !== 15;
      }
    },
    nextHandler() {
      const { cardIndex, config } = this.appData;
      const { use_ocr_driver_date, use_ocr_driver_expired_date } = config;
      if (cardIndex === Constants.APP_CARD_INDEX.DRIVER) {
        if (!use_ocr_driver_date) {
          return this.onClickNextIssueDate;
        }
        if (!use_ocr_driver_expired_date) {
          return this.onClickNextExpiryDate;
        }
      }
      return this.onClickNext;
    },
  },
  created() {
    this.initialize();
  },
  mounted() {
    this.$refs.name.valid();
    this.$refs.birthday && this.$refs.birthday.valid();
    this.initialize();
    this.validateForm();
  },
  methods: {
    initialize() {
      this.appData.isIdcardLock = false;
      this.appData.retry = 0;
      this.systemError = false;
      this.serverError = false;
      this.appData.base64Card;
      this.appData.base64Face;
      this.loading = false;
      this.loadingSuccess = false;
      this.loadingMessages = [];
      this.originOcr = this.appData.fromCardNotMatched
        ? this.translateOcrFormat({
            ...this.appData.fromCardNotMatchedOriginOcr,
          })
        : this.translateOcrFormat({ ...this.appData.ocr });
      /***** make test data */
      // this.appData.cardIndex = Constants.APP_CARD_INDEX.PASSPORT_KOREAN;
      // this.originOcr = util.makeTestOcrData("3");
      /********** */
      // this.originOcr = this.translateOcrFormat(this.originOcr);
      this.ocr = this.appData.fromCardNotMatched
        ? this.translateOcrFormat({ ...this.appData.ocr })
        : { ...this.originOcr };
      //this.translateOcrFormat(this.ocr);
    },
    translateOcrFormat(target) {
      if (target.birthDate) {
        target.birthDate = target.birthDate.replace(
          /(\d{4})(\d{2})(\d{2})/g,
          '$1-$2-$3'
        );
      }
      if (target.issueDate) {
        target.issueDate = target.issueDate.replace(
          /(\d{4})(\d{2})(\d{2})/g,
          '$1-$2-$3'
        );
      }
      if (target.expiryDate) {
        target.expiryDate = target.expiryDate.replace(
          /(\d{4})(\d{2})(\d{2})/g,
          '$1-$2-$3'
        );
      }
      if (target.expiryDate2) {
        // 운전면허 마감일자
        target.expiryDate2 = target.expiryDate2.replace(
          /(\d{4})(\d{2})(\d{2})/g,
          '$1-$2-$3'
        );
      }
      if (target._issueNo2) {
        target._juminNo2 = target._issueNo2;
      }
      if (target._juminNo2) {
        target._juminNo2 = target._juminNo2.substring(0, 1);
      }
      if (this.appData.cardIndex === Constants.APP_CARD_INDEX.DRIVER) {
        // check old driver license
        if (target.driverNo) {
          target.driverNo = !this.manualInput
            ? target.driverNo.split('-')
            : ['', ''];
        }
        if (
          target.driverNo &&
          this.rules.testIncludesNotNumber.test(target.driverNo[0])
        ) {
          this.isDriverLicenseOld = true;
          target.driverNo1 = target.driverNo[0];
          target.driverNo2 = target.driverNo.slice(1).join('-');
        } else {
          this.isDriverLicenseOld = false;
          target.driverNo1 = '';
          target.driverNo2 = target.driverNo.length
            ? target.driverNo.join('-')
            : '';
        }
      }
      // check issues number
      if (
        !this.manualInput &&
        this.appData.cardIndex === Constants.APP_CARD_INDEX.ALIEN
      ) {
        this.alienId = target.idType.split('-')[1];
      }
      if (target.issueNo1) {
        target.juminNo1 = target.issueNo1;
        target.juminNo2 = target.issueNo2;
      }
      return target;
    },
    restoreOcrFormat(ocr) {
      let originOcr = { ...ocr };
      if (originOcr.birthDate) {
        originOcr.birthDate = originOcr.birthDate.split('-').join('');
      }
      if (originOcr.issueDate) {
        originOcr.issueDate = originOcr.issueDate.split('-').join('');
      }
      if (originOcr.expiryDate) {
        originOcr.expiryDate = originOcr.expiryDate.split('-').join('');
      }
      if (originOcr.expiryDate2) {
        originOcr.expiryDate2 = originOcr.expiryDate2.split('-').join('');
      }
      if (this.appData.cardIndex === Constants.APP_CARD_INDEX.DRIVER) {
        if (
          this.isDriverLicenseOld ||
          (this.manualInput && this.dlFormatOptionValue === 2)
        ) {
          originOcr.driverNo = originOcr.driverNo1 + '-' + originOcr.driverNo2;
        } else {
          originOcr.driverNo = originOcr.driverNo2;
        }
      }
      delete originOcr.driverNo1;
      delete originOcr.driverNo2;
      if (!this.manualInput && originOcr._juminNo2) {
        if (this.showJumin2) {
          originOcr._juminNo2 = originOcr.juminNo2.slice(0, 1) + '******';
        } else {
          originOcr.juminNo2 = originOcr.juminNo2.replace(
            /\d/,
            originOcr._juminNo2
          );
          originOcr._juminNo2 = originOcr._juminNo2 + '******';
        }
        if (this.originOcr._issueNo2) {
          delete originOcr._juminNo2;
        }
      }
      if (originOcr.issueNo1) {
        originOcr.issueNo1 = originOcr.juminNo1;
        originOcr.issueNo2 = originOcr.juminNo2;
        delete originOcr.juminNo1;
        delete originOcr.juminNo2;
        if (originOcr.issueNo) {
          originOcr.issueNo = originOcr.issueNo1 + '-' + originOcr.issueNo2;
        }
      }
      return originOcr;
    },
    async onClickCloseServerError() {
      // handle when selected ID is Korean citizen and
      // user clicks "수정"
      if (this.errorHtmlMessage !== '') {
        this.serverError = false;
        this.loading = false;
      } else if (this.systemError) {
        this.serverError = false;
        this.systemError = false;
        this.loading = false;
        this.onClickBack(false);
      } else if (this.errorCode.includes('F611')) {
        this.serverError = false;
        this.loading = false;
        this.onClickRetry();
      } else if (this.errorCode.includes('진위확인/잠김해제')) {
        this.appData.isIdcardLock = true;
        await this.success(true);
        this.$forceUpdate();
      } else if (this.errorMessageTitle[0].includes('점검')) {
        this.serverError = false;
        this.loading = false;
        this.$emit('retry', { phase: 'cardSelect' });
      } else {
        this.serverError = false;
        this.loading = false;
      }
    },
    onClickBack(isExitMode = false) {
      this.serverError = false;
      this.systemError = false;
      this.loading = false;
      if (isExitMode) {
        this.stopScan = true;
      }
    },
    cancelExit() {
      this.stopScan = false;

      if (this.inErrorProgress) {
        this.loading = false;
      }
    },
    onClickRetry() {
      this.$emit('retry');
      if (this.appData.identification_config_enabled) {
        this.$emit('init');
      }
    },
    onClickEditIssueDate(param) {
      this.$log.debug('onClickEditIssueDate', { param });
      this.issueDateUserInputDialog = false;
      if (!param) {
        this.$log.debug('param is null');
      }
      if (param) {
        this.ocr = {
          ...this.ocr,
          issueDate: param.issueDate,
        };
        this.$log.debug('param is not null', {
          ocr: this.ocr,
          appDataOcr: this.appData.ocr,
        });
        if (this.userInputExpiryDate) {
          this.driverExpiryDateUserInputDialog = true;
        } else {
          this.onClickNext();
        }
      }
    },
    onClickEditExpiryDate() {
      this.driverExpiryDateUserInputDialog = false;
      this.validateForm();
      this.onClickNext();
    },
    validateForm() {
      this.$nextTick(() => {
        let result = this.$refs.root.$children
          .map((comp) => (comp.valid ? comp.validFlag : true))
          .every((val) => val);

        // if no error conditions shown but not all required fields completed
        if (result && this.manualInput) {
          switch (this.appData.cardIndex) {
            case Constants.APP_CARD_INDEX.JUMIN:
              if (this.ocr.issueDate === '') {
                result = false;
              }
              break;
            case Constants.APP_CARD_INDEX.DRIVER:
              if (this.ocr.expiryDate2 === '') {
                result = false;
              }
              break;
            case Constants.APP_CARD_INDEX.PASSPORT_KOREAN:
              if (
                this.errorPassport ||
                this.ocr.passportNo === '' ||
                this.ocr.issueDate === '' ||
                this.errorPassportExpiryDate
              ) {
                result = false;
              }
              break;
            case Constants.APP_CARD_INDEX.PASSPORT_ALIEN:
              break;
            case Constants.APP_CARD_INDEX.ALIEN:
              break;
            default:
              break;
          }
        }
        this.disabled = !result;
      });
    },
    async onClickNextIssueDate() {
      if (this.disabled || this.loading) return;
      this.issueDateUserInputDialog = true;
    },
    async onClickNextExpiryDate() {
      if (this.disabled || this.loading) return;
      this.driverExpiryDateUserInputDialog = true;
    },
    async onClickNext() {
      if (this.disabled || this.loading) return;

      // netfunnel for waiting queue
      const _this = this;

      const __impl = async () => {
        try {
          // eslint-disable-next-line no-unreachable
          this.loading = true;
          this.loadingSuccess = false;
          this.$log.debug('CardResult#onClickNext', this.appData.moduleName);
          if (this.appData.moduleName.includes(Constants.MODULE.STATUS)) {
            this.loadingMessages = this.loadingMessageStart;
            const cardIndex = this.appData.cardIndex;

            let juminNo2;
            if (this.manualInput) {
              juminNo2 = this.ocr.juminNo2;
            } else {
              // juminNo2 = this.ocr.juminNo2
              //          ? this.ocr.juminNo2.replace(/\d/, this.ocr._juminNo2)
              //          : "";
              juminNo2 = this.ocr.juminNo2 ? this.ocr.juminNo2 : '';
            }

            if (cardIndex === Constants.APP_CARD_INDEX.JUMIN) {
              // 주민등록증
              await useb.getStatusIdcard({
                identity: this.ocr.juminNo1 + juminNo2,
                issueDate: this.ocr.issueDate.split('-').join(''),
                userName: this.ocr.userName,
              });
              await this.success();
            } else if (cardIndex === Constants.APP_CARD_INDEX.DRIVER) {
              // 운전면허증
              let driverNo;
              if (
                this.isDriverLicenseOld ||
                (this.manualInput && this.dlFormatOptionValue === 2)
              ) {
                driverNo = this.ocr.driverNo1 + '-' + this.ocr.driverNo2;
              } else {
                driverNo = this.ocr.driverNo2;
              }
              let params = {
                userName: this.ocr.userName,
                birthDate: util.getBirthday(this.ocr.juminNo1, juminNo2),
                licenseNo: driverNo,
              };
              if (!this.manualInput) {
                params.juminNo = this.ocr.juminNo1 + juminNo2;
              }
              await useb.getStatusDriver(params);
              await this.success();
            } else if (cardIndex === Constants.APP_CARD_INDEX.PASSPORT_KOREAN) {
              // 한국 여권
              let birthDate;
              // 신여권의 경우 주민번호 뒷자리가 없기 때문에 MRZ(Machine Readable Zone)에서 찾는다.
              if (juminNo2) {
                birthDate = util.getBirthday(this.ocr.juminNo1, juminNo2);
              } else {
                //let juminNo2 = this.ocr.mrz2.substring(28, 35);
                birthDate = util.getBirthdayFromOcr(
                  Constants.APP_CARD_INDEX.PASSPORT_KOREAN,
                  this.restoreOcrFormat(this.ocr)
                );

                birthDate = birthDate.replaceAll('-', '');
              }
              if (this.manualInput) {
                birthDate = this.appData.birthDate.replaceAll('-', '');
              }
              await useb.getStatusPassport({
                userName: this.ocr.userName,
                passportNo: this.ocr.passportNo,
                issueDate: this.ocr.issueDate.split('-').join(''),
                expirationDate: this.ocr.expiryDate.split('-').join(''),
                birthDate,
              });

              await this.success();
            } else if (cardIndex === Constants.APP_CARD_INDEX.PASSPORT_ALIEN) {
              // 외국 여권
              await useb.getStatusPassportOverseas({
                passportNo: this.ocr.passportNo,
                nationality: this.ocr.nationality,
                birthDate: this.ocr.birthDate.split('-').join(''),
              });
              await this.success();
            } else if (cardIndex === Constants.APP_CARD_INDEX.ALIEN) {
              // 외국인등록증
              await useb.getStatusAlien({
                issueNo: this.ocr.juminNo1 + '-' + this.ocr.juminNo2,
                issueDate: this.ocr.issueDate.split('-').join(''),
              });
              await this.success();
            }
          } else {
            // for dev test
            await this.success();
          }
        } catch (e) {
          await errorPopupProcess.call(this, e);
          return 'error: ' + e;
        }
      };

      const errorPopupProcess = async (e) => {
        if (!e.errorCode || typeof e.errorCode !== 'string') {
          this.showSystemErrorPopup(e);
          return;
        }

        if (['A005'].includes(e.errorCode)) {
          this.$emit('next', { fail: true, errorCode: 'A005' });
          const title = this.$t('신분증.진위 확인 실패');
          const message = [
            '발급 일자 입력오류가 5회 발생하여 ' +
              '타인의 무단사용 방지를 위해 더 이상 확인할 수 없습니다. ' +
              '가까운 읍면동 센터를 방문하시거나, ' +
              "'정부24'에 접속하여 잠김을 해제하세요.",
          ];
          const buttons = ['', this.$t('버튼.확인')];
          const code =
            '※ 정부24 > 서비스 > 사실/진위확인 > 주민등록증 진위확인/잠김해제';
          this.showServerErrorPopup({ title, message, code, buttons });
        }

        if (['C061'].includes(e.errorCode)) {
          const title = this.$t('신분증.진위 확인 서비스 점검 중');
          const message = [
            this.$t('신분증.해당 정부 기관이 점검 중입니다.'),
            this.$t('신분증.다른 신분증을 이용해주세요.'),
          ];
          const buttons = ['', this.$t('버튼.확인')];
          const code = '';

          this.showServerErrorPopup({ title, message, code, buttons });
        }
        if (e.errorCode.startsWith('A')) {
          this.appData.retry += 1;
          if (this.appData.retry !== this.MAX_RETRY_COUNT) {
            if (!['A006', 'A016', 'A044', 'A045'].includes(e.errorCode)) {
              const title = this.$t('신분증.진위 확인 실패');
              const message = [
                this.$t('신분증.촬영된 신분증 정보와 정부기관의'),
                this.$t('신분증.진위 확인 대조 결과 일치하지 않습니다.'),
                this.$t('신분증.신분증 정보를 다시 확인해 주세요.'),
              ];
              const buttons = [this.$t('버튼.종료'), this.$t('버튼.수정')];
              const code = `${this.$t('신분증.시도 횟수')} : ${
                this.appData.retry
              }/${this.MAX_RETRY_COUNT}`;

              this.customErrorTitleColor = 'var(--surface-high)';
              // if selected Korean Citizen ID, alter shown error message content
              if (this.appData.cardIndex === 0) {
                this.errorHtmlMessage = this.$t(
                  '신분증.정부기관 진위 확인에 실패하였습니다. 주민등록번호 또는 발급일자를 확인 후 수정해주세요.'
                );
              }

              this.showServerErrorPopup({ title, message, code, buttons });
            }

            if (['A006'].includes(e.errorCode)) {
              const title = this.$t('신분증.진위 확인 실패');
              const message = [
                this.$t('신분증.분실 신고된 주민등록증 입니다.'),
                this.$t('신분증.다른 신분증으로 진행 부탁드립니다.'),
              ];
              const buttons = [this.$t('버튼.종료'), this.$t('버튼.재시도')];
              const code = `${this.$t('신분증.시도 횟수')} : ${
                this.appData.retry
              }/${this.MAX_RETRY_COUNT}`;
              this.showServerErrorPopup({ title, message, code, buttons });
            }

            if (['A016'].includes(e.errorCode)) {
              const title = this.$t('신분증.진위 확인 실패');
              const message = [
                this.$t('신분증.유효하지 않은 운전면허번호입니다.'),
                this.$t('신분증.다른 신분증으로 진행 부탁드립니다.'),
              ];
              const buttons = [this.$t('버튼.종료'), this.$t('버튼.재시도')];
              const code = `${this.$t('신분증.시도 횟수')} : ${
                this.appData.retry
              }/${this.MAX_RETRY_COUNT}`;
              this.showServerErrorPopup({ title, message, code, buttons });
            }

            if (['A044'].includes(e.errorCode)) {
              const title = this.$t('신분증.진위 확인 실패');
              const message = [
                this.$t('신분증.한국 체류기간이 만료된 상태입니다.'),
                this.$t('신분증.다른 신분증으로 진행 부탁드립니다.'),
              ];
              const buttons = [this.$t('버튼.종료'), this.$t('버튼.재시도')];
              const code = `${this.$t('신분증.시도 횟수')} : ${
                this.appData.retry
              }/${this.MAX_RETRY_COUNT}`;
              this.showServerErrorPopup({ title, message, code, buttons });
            }

            if (['A045'].includes(e.errorCode)) {
              const title = this.$t('신분증.진위 확인 실패');
              const message = [
                this.$t('신분증.분실 혹은 발급 취소된 신분증입니다.'),
                this.$t('신분증.다른 신분증으로 진행 부탁드립니다.'),
              ];
              const buttons = [this.$t('버튼.종료'), this.$t('버튼.재시도')];
              const code = `${this.$t('신분증.시도 횟수')} : ${
                this.appData.retry
              }/${this.MAX_RETRY_COUNT}`;
              this.showServerErrorPopup({ title, message, code, buttons });
            }
          } else {
            await this.success(true);
            this.$forceUpdate();
          }
        }
      };

      if (netfunnel.isUsed) {
        netfunnel.NetFunnel_Action(
          { action_id: 'idcard_verify' },
          {
            success: function () {
              //대기가 없거나, 대기 종료 후에 넷퍼넬 자원 할당을 받을 때 호출
              __impl().then((ret) => {
                _this.$log.fatal('NetFunnel_Action Result : ' + ret);
                netfunnel.NetFunnel_Complete();
              });
            },
            continued: function () {
              //대기 단계에서 호출
              // nothing to do
            },
            stop: function () {
              //대기창의 중지 버튼 클릭 시 호출
              netfunnel.NetFunnel_Complete();
              _this.serverError = false;
              _this.loading = false;
            },
            error: function (ev, ret) {
              //넷퍼넬 서버에서 error응답을 받았을 때
              //(default: error callbcak생략시, error도 success로 동작)
              netfunnel.NetFunnel_Complete();
              try {
                if (ret?.code == '513') {
                  _this.$log.error(
                    'NetFunnel_Action Skip (cause : ' + ret?.data?.msg
                  );
                  __impl();
                  return;
                }

                let error_code = 'NFN_ERR';
                if (ret?.code) {
                  error_code += '_' + ret?.code;
                }
                if (ret?.data?.msg) {
                  error_code += '_' + ret?.data?.msg;
                }

                throw new useb.UsebError(
                  error_code,
                  'netFunnel Error\n - ' + JSON.stringify(ret)
                );
              } catch (e) {
                errorPopupProcess.call(_this, e);
              }
            },
            /*
          bypass: function(ev, ret){
            //넷퍼넬 관리자페이지에서 해당 actionID를 bypass(우회) 설정 시 호출
          },
          block: function(ev, ret){
            //넷퍼넬 관리자페이지에서 해당 actionID를 block(차단) 설정 시 호출
          },
          ipblock: function(ev, ret){
            //엑세스 제어기능을 통해, 제어룰에 해당되어 차단되는 경우에 호출
          },
          expressnumber: function(ev, ret){
            //VIP 설정(ip, id)에 등록된 사용자의 요청이 있을 경우에 호출 bypass
          }
          */
          }
        );
      } else {
        __impl();
      }
    },

    async success(failed_verified_card) {
      this.loading = true;
      if (this.appData.moduleName.includes(Constants.MODULE.STATUS)) {
        this.loadingSuccess = true;
        this.loadingMessages = this.loadingMessageSuccess;
      }

      const cardFile = util.dataURItoBlob(this.appData.base64Card);
      let ocrModified = this.restoreOcrFormat(this.ocr);
      const ocrOrigin = this.appData.fromCardNotMatched
        ? this.appData.fromCardNotMatchedOriginOcr
        : this.appData.ocr;
      if (this.userInputIssueDate && this.appData.config.trust_driver_date) {
        ocrOrigin.issueDate = ocrModified.issueDate;
      }
      if (
        this.userInputExpiryDate &&
        this.appData.config.trust_driver_expired_date
      ) {
        ocrOrigin.expiryDate2 = ocrModified.expiryDate2;
      }
      let ocr_modified = 0;
      for (let key in ocrOrigin) {
        if (ocrOrigin[key] !== ocrModified[key]) {
          ocr_modified = 1;
          break;
        }
      }

      if (this.manualInput) {
        switch (this.appData.cardIndex) {
          case Constants.APP_CARD_INDEX.JUMIN:
            ocrModified.idType = '1';
            break;
          case Constants.APP_CARD_INDEX.DRIVER:
            ocrModified.idType = '2';
            break;
          case Constants.APP_CARD_INDEX.PASSPORT_KOREAN:
            ocrModified.idType = '3';
            break;
          case Constants.APP_CARD_INDEX.PASSPORT_ALIEN:
            ocrModified.idType = '4';
            break;
          case Constants.APP_CARD_INDEX.ALIEN:
            ocrModified.idType = '5';
            break;
          default:
            this.$log.warn(
              'wrong appData.cardIndex : ',
              this.appData.cardIndex
            );
            break;
        }
      }

      let verified_id_card = 0;
      if (this.appData.moduleName.includes(Constants.MODULE.STATUS)) {
        verified_id_card = 1;
      }

      let id_crop_image = null;
      if (this.appData.moduleName.includes(Constants.MODULE.FACE)
        ||
        this.isIDCardFaceDetectMode
      ) {
        id_crop_image = util.dataURItoBlob(this.appData.base64Face);
      }

      const ori_ocr_data = this.manualInput
        ? JSON.stringify({})
        : JSON.stringify(ocrOrigin);
      const mod_ocr_data = ocr_modified ? JSON.stringify(ocrModified) : '';

      let formData = new FormData();
      formData.append('transaction_id', this.appData.transaction_id);
      formData.append('id_card_image', cardFile);
      if (this.appData.save_original_id_image) {
        let id_card_origin = util.dataURItoBlob(
          'data:image/jpeg;base64,' + this.appData.base64CardOrigin
        );
        formData.append('id_card_origin', id_card_origin);
      }
      formData.append('id_crop_image', id_crop_image);
      formData.append('ocr_modified', ocr_modified);
      if (this.appData.moduleName.includes(Constants.MODULE.STATUS)) {
        formData.append(
          'verified_id_card',
          failed_verified_card ? 0 : verified_id_card
        );
      }
      formData.append('ori_ocr_data', ori_ocr_data);
      formData.append('mod_ocr_data', mod_ocr_data);
      formData.append(
        'is_uploaded',
        this.uploadType === 'camera' ? 'false' : 'true'
      );
      formData.append('is_manual_input', this.manualInput ? 1 : 0);
      formData.append('uploaded_type', this.uploadType);
      try {
        const response = await server.startApplicationIDCard(formData);
        this.$emit('next', { fail: failed_verified_card, response });
      } catch (e) {
        this.customErrorTitleColor = '';
        if (
          e.errorCode &&
          typeof e.errorCode === 'string' &&
          e.errorCode.includes('F611')
        ) {
          this.serverError = true;
          this.errorIcon = this.iconError;
          this.errorButton = [this.$t('버튼.종료'), this.$t('버튼.재시도')];
          this.errorMessageTitle = [this.$t('얼굴.얼굴 감지 실패')];
          this.errorMessage = [
            this.$t('신분증.얼굴을 감지하지 못하였습니다.'),
            this.$t('신분증.재시도 하시겠습니까?'),
          ];
        } else {
          this.systemError = true;
          this.serverError = true;
          this.errorIcon = {};
          this.errorMessageTitle = [
            this.$t('에러.시스템 에러가 발생하였습니다.'),
            this.$t('에러.잠시 후 다시 이용해 주세요.'),
          ];
          this.errorMessage = [
            this.$t('에러.계속해서 문제가 발생한다면'),
            this.$t('에러.고객센터로 문의해주세요.', {
              companyPhoneNumber: this.companyPhoneNumber,
            }),
          ];
          this.errorButton = ['', this.$t('버튼.확인')];
        }
        this.errorCode = e.errorCode
          ? `${this.$t('에러.에러코드')} : ${e.errorCode}`
          : e;
      }
    },
    showServerErrorPopup({ title, message, code, buttons }) {
      this.serverError = true;
      this.errorIcon = this.iconCaution;
      this.errorMessageTitle = [title];
      this.errorMessage = message;
      this.errorButton = buttons;
      this.errorCode = code;
      // '※ 정부24 > 서비스 > 사실/진위확인 > 주민등록증 진위확인/잠김해제';
    },
    showSystemErrorPopup(e) {
      this.$log.debug(e);
      this.systemError = true;
      this.serverError = true;
      this.errorIcon = {};
      this.errorMessageTitle = [
        this.$t('에러.시스템 에러가 발생하였습니다.'),
        this.$t('에러.잠시 후 다시 이용해 주세요.'),
      ];
      this.errorMessage = [
        this.$t('에러.계속해서 문제가 발생한다면'),
        this.$t('에러.고객센터로 문의해주세요.', {
          companyPhoneNumber: this.companyPhoneNumber,
        }),
      ];
      this.errorButton = ['', this.$t('버튼.확인')];
      this.errorCode = e.errorCode
        ? `${this.$t('에러.에러코드')} : ${e.errorCode}`
        : '';
    },
    // updateTimeStats(timeInMs) {
    //   this.forwardTimes = [timeInMs].concat(this.forwardTimes).slice(0, 30)
    //   const avgTimeInMs = this.forwardTimes.reduce((total, t) => total + t) / this.forwardTimes.length
    //   this.status = `${Math.round(avgTimeInMs)} ms ${(1000 / avgTimeInMs).toFixed(0)} fps`
    // },
  },
};
</script>

<style lang="scss" scoped>
.root-container {
  position: relative;
  display: flex;
  flex-direction: column;
  color: var(--surface-high);
}

.icon-close {
  position: absolute;
  width: 32px;
  right: 32px;
  top: 32px;
  cursor: pointer;
}

.card-result-title {
  font-size: 20px;
  line-height: 32px;
  font-weight: 500;
  margin-top: 0px;
  text-align: center;
  margin-bottom: 48px;
}

.card-result-info {
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  margin-bottom: 8px;
  text-align: center;
}

.card-result-warning-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--error-100);
}

.card-result-warning-text-top {
  display: flex;

  & > img {
    width: 20px;
    margin-right: 6px;
  }
}

.hide-jumin {
  display: flex;
  align-items: center;
}

// .card-result-warning-text-bottom {
// }

// .card-result-info2 {
//   font-size: 14px;
//   line-height: 24px;
//   text-align: center;
//   color: var(--surface-medium);
// }

.card-result-label {
  font-size: 16px;
  color: var(--surface-medium);
  margin-bottom: 8px;
  margin-top: 24px;
}

.correctJumin .input-textfield-container.error {
  border-color: var(--gray-300);
}
#app
  > div.root-container-auth
  > div.root-container
  > div.flex-row-layout
  > div.input-textfield-root.correctJumin
  > div.input-textfield-container.error {
  border-color: var(--gray-300);
}

.text-error {
  font-size: 0.75rem;
  line-height: 0.75rem;
  margin-top: 8px;
  color: #f53c3c;
}

.flex-row-layout {
  display: flex;
  flex-wrap: nowrap;
  // align-items: baseline;
  width: 100%;

  &.dl-format-option-container {
    padding: 0 30px;
  }

  .dl-format-option {
    display: flex;
    align-items: center;
    margin-left: 20px;

    .radio-icon {
      margin-right: 6px;
    }
  }

  &.not-passport-alien {
    align-items: none;
  }
}

@media screen and (max-width: 768px) {
  .flex-row-layout {
    &.dl-format-option-container {
      padding: 0;
    }
  }
}

.input-between-dash-style {
  min-width: 6px;
  height: 1px;
  background-color: var(--surface-medium);
  margin: 32px 11px 0;
  align-self: flex-start;
}

.card-result-image {
  margin: 55px auto 30px;
  width: 80%;
  max-width: 600px;
  box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

div[data-description='fixed-button-area'] {
  min-height: 56px;
}

.button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  cursor: pointer;
  background-color: white;

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    font-weight: 500;
    font-size: 1rem;
    height: 60px;
  }

  .retry {
    flex-grow: 3.5;

    margin-right: 10px;
    background-color: var(--gray-100);
    color: var(--surface-medium);
  }

  .next {
    flex-grow: 6.5;

    background-color: var(--primary-100);
    color: var(--font-color);
  }

  .next-lock {
    flex-grow: 1;
    background-color: var(--primary-100);
    color: var(--font-color);
  }

  .disabled {
    background-color: var(--primary-20);
  }
}
</style>

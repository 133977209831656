<template>
  <div>
    <!-- <img src="@/assets/root-bg.svg" alt="">
    <img src="@/assets/admin-bg.svg" alt="">
    <img src="@/assets/reviewer-bg.svg" alt=""> -->
    <Loading
      v-if="loadingFlag"
      :messages="loadingMessage"
      :descriptions="loadingDescription"
    />
    <div id="app" data-app>
      <router-view />
      <div id="hide-border" />

      <SystemErrorDialog
        v-if="$store.state.errorCode != 401"
        v-model="$store.state.serverError"
        :errorCode="$store.state.errorCode"
        @ok="$store.commit('closeErrorDialog')"
      ></SystemErrorDialog>
      <CustomUIDialog
        v-if="$store.state.customUIDialog === true"
        v-model="$store.state.customUIDialog"
        :icon="$store.state.customUIIcon"
        :title="$store.state.customUIMessageTitle"
        :message="$store.state.customUIMessage"
        :errorCode="$store.state.customUIerrorCode"
        :button="$store.state.customUIButton"
        @ok="$store.commit('closeCustomUIDialog')"
      ></CustomUIDialog>
      <RootErrorDialog
        v-if="$store.state.rootError === true"
        v-model="$store.state.rootError"
        :errorCode="$store.state.rootErrorCode"
        @ok="$store.commit('closeRootErrorDialog')"
      />
    </div>
  </div>
</template>

<script>
import SystemErrorDialog from '@/components/dialog/SystemErrorDialog.vue';
import CustomUIDialog from '@/components/dialog/CustomUIDialog.vue';
import Loading from '@/components/Loading.vue';
import { mapState } from 'vuex';

export default {
  name: 'App',
  components: {
    SystemErrorDialog,
    CustomUIDialog,
    Loading,
  },

  computed: {
    ...mapState({
      loadingFlag: (state) => state.loading.flag,
      loadingMessage: (state) => state.loading.message,
      loadingDescription: (state) => state.loading.desc,
    }),
  },
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Nanum+Gothic:wght@400;700;800&family=Noto+Sans+KR:wght@100;300;' + '400;500;700;900&family=Noto+Serif+KR:wght@200;300;400;500;600;700;900&display=swap');

@font-face {
  font-family: '에셋플러스 Medium';
  src: url('/fonts/assetPlus/ASSETPLUS-Medium.woff') format('woff');
}

* {
  font-family: var(--font-family);
}
.font-500 {
  font-weight: 500;
}
:root {
  --font-family: 'Noto Sans KR', sans-serif;
  --gray-100: #f7f7f7;
  --gray-200: #dddfdf;
  --gray-300: #ced1d2;
  --gray-500: #abacad;

  --primary-100: #373eff;
  --primary-80: #5f65ff;
  --primary-60: #878bff;
  --primary-40: #afb2ff;
  --primary-20: #d7d8ff;
  --primary-10: #ebecff;
  --primary-5: #f9f9ff;

  --secondary-100: #353648;

  --error-100-rgb: 245, 60, 60;
  --error-100: #f53c3c;
  --error-80: #f76363;
  --error-60: #f98a8a;
  --error-30: #fcc5c5;
  --error-10: #feecec;

  --success-100: #12b78f;
  --success-80: #41c5a5;
  --success-60: #71d4bc;
  --success-30: #b8e9dd;
  --success-10: #e7f8f4;

  --background-100: #f3f5f7;

  --surface-100: #ffffff;
  --surface-disabled: #cccccc;
  --surface-high: #363636;
  --surface-medium: #868686;

  --font-color: #ffffff;
}
html,
body,
body > div,
#app {
  //height: 100%;
  width: 100%;
}
#app {
  //background: transparent !important;

  background: var(--primary-5);
  color: #363636;
}
input::placeholder,
textarea::placeholder {
  color: var(--surface-disabled);
}
.v-data-table__wrapper {
  overflow-x: inherit !important;
  overflow-y: inherit !important;
}
.v-data-table__wrapper > table > thead > tr > th {
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: var(--surface-100);
  border-bottom: 1px solid var(--primary-80) !important;
  color: var(--surface-medium) !important;
  font-weight: 400;
  font-size: 14px !important;
  height: 48px;
}
.v-data-table__wrapper > table > tbody > tr > td {
  font-size: 16px !important;
  height: 72px !important;
  border-bottom: 4px solid var(--primary-10) !important;
  color: var(--surface-high);
}
.v-data-table__wrapper
  > table
  > tbody
  > tr.v-data-table__expanded.v-data-table__expanded__row
  > td {
  border-bottom: 1px solid var(--surface-disabled) !important;
}
.v-data-table
  > .v-data-table__wrapper
  tbody
  tr.v-data-table__expanded__content {
  box-shadow: none !important;
}
.v-data-table
  > .v-data-table__wrapper
  tbody
  tr.v-data-table__expanded__content
  td {
  padding: 0;
}
#app {
  .v-dialog {
    margin: 18px;
    border-radius: 8px;
  }
}
.v-expansion-panel-content {
  border-bottom: none !important;
}
.nav-tabs .v-tab--active {
  color: var(--primary-100) !important;
}
.nav-tabs .v-tabs-slider-wrapper {
  height: 4px !important;
}
.nav-tabs .v-tab {
  font-size: 16px;
  font-weight: 500;
}
.full-size {
  height: 100%;
  width: 100%;
}

.view-container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--primary-5);
}
.content-container {
  min-height: 100vh;
  padding: 130px 0 60px 0;
  height: 100%;
  max-width: 1080px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.text-center {
  text-align: center;
}
.text-truncate {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

::-webkit-scrollbar {
  width: 8px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background-color: var(--gray-500);
  background-clip: padding-box;
  border: 2px solid transparent;
  // border-right-width: 0px;
  border-radius: 12px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
  /*   box-shadow: inset 0px 0px 5px white; */
}

#hide-border {
  height: 0px;
}
</style>

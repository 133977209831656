<template>
  <div class="root-container" id="cardScanWasm_container">
    <!-- phase1: card capture -->
    <div v-if="phase === PHASE_SCAN" class="container-phase-scan">
      <div v-if="showWasmLoadingUI" class="container-phase-loading">
        <img src="@/assets/Loading_40px.svg" class="loading small"/>
        <div class="message">{{ $t('로딩.잠시만 기다려 주세요.') }}</div>
        <div class="message small">
          {{ $t('신분증.신분증 촬영을 위해 카메라를 불러오는 중 입니다.') }}
        </div>
      </div>

      <div data-useb-ocr='ocr' style='background-color: #333333; display: none;'>
        <div data-useb-ocr='topUI'>
          <span id='top-ui-text-msg' class='text-info'></span>
        </div>

        <div data-useb-ocr='middleUI'>
          <div id='middle-ui-bg' style="width: 100%; background-color: #00000044">
            <span id='middle-ui-loading'></span>
            <span id='middle-ui-text-msg' class='text-info'></span>
          </div>
        </div>

        <div data-useb-ocr='bottomUI'>
          <span id='bottom-ui-text-msg' class='text-info'></span>
        </div>
      </div>



      <div style="position: absolute; left: 0; top: 0; color: white">
        {{ status }}
      </div>
      <img
          src="@/assets/back_32_white.svg"
          alt="back"
          class="prev"
          width="30"
          @click="stopOCR; $emit('cancel', { prev: true })"
      />
      <div class="scan-subject">{{ $t('온보딩.신분증 인증') }}</div>
      <img
          @click="stopScan = true"
          id="cardScanWasm_submit_button"
          src="@/assets/icon-close.svg"
          width="48px"
          class="close"
      />
    </div>
    <!-- phase2: loading -->
    <div v-if="phase === PHASE_LOADING" class="container-phase-loading">
      <LoadingImgVue class="loading"/>
      <img src="@/assets/Loading_40px.svg" class="loading small"/>
      <div class="message">{{ $t('로딩.잠시만 기다려 주세요.') }}</div>
      <div class="message small">
        {{ $t('로딩.신분증 정보를 확인 중입니다.') }}
      </div>
    </div>
    <!-- modal -->
    <ServerErrorDialog
        v-model="serverError"
        :icon="errorIcon"
        :title="errorMessageTitle"
        :message="errorMessage"
        :errorCode="errorCode"
        :detail="errorDetail"
        :button="errorButton"
        @minorBtn="onClickBack"
        @majorBtn="onClickRetry"
        id="cardScanWasm_serverErrorPopup"
    />
    <ExitDialog v-model="stopScan" @ok="$emit('cancel')" @cancel="cancelExit"/>
    <CardScanWASMPreviewDialog
        v-model="previewDialog"
        :image="base64MaskCardImageNotSplited"
        :wasmScanDone='wasmScanDone'
        @retry="retry"
        @ok="onConfirmOk"
        id="cardScanWasm_previewPopup"
    />
    <canvas id="canvas_crop" ref="canvas_crop"
            style="display: none; width: 25%; position: absolute; right: 100px; top: 100px; border: blue 2px solid;">
    </canvas>
  </div>
</template>
<script>
import Constants from '@/constants';
import useb from '@/api/useb';
import ServerErrorDialog from '../dialog/ServerErrorDialog';
import ExitDialog from '../dialog/ExitDialog';
import CardScanWASMPreviewDialog from '../dialog/CardScanWASMPreviewDialog';
import * as faceApi from './face-api-impl.js';
import {mapState} from 'vuex';
import Icon_Card_GuideVue from './DynamicCt_imgs/Icon_Card_Guide.vue';
import LoadingImgVue from './DynamicCt_imgs/Loading.vue';
// import UseBOCR from "./ocr.js";

export default {
  components: {
    CardScanWASMPreviewDialog,
    ServerErrorDialog,
    ExitDialog,
    LoadingImgVue,
  },
  props: {
    appData: Object,
    wasmOCR: {
      type: Object,
    },
    platform: Object,
    isWasmSSAMode: {
      type: Boolean,
      required: true,
      default: false,
    },
    isSupportWasmToServer: {
      type: Boolean,
      required: true,
      default: true,
    },
    isIDCardFaceDetectMode: {
      type: Boolean,
      required: true,
      default: false,
    }
    /**
     * emit events
     * cancel
     * next
     */
  },
  data: function () {
    return {
      origin: '',
      PHASE_SCAN: 1,
      PHASE_LOADING: 2,
      phase: 1,
      showWasmLoadingUI: true,
      previewDialog: false,
      wasmScanDone: false,
      tmpAppData: {},
      stopScan: false,
      serverError: false,
      errorIcon: {},
      errorMessageTitle: [],
      errorMessage: [],
      errorCode: '',
      errorCodeOrigin: '',
      errorDetail: '',
      errorButton: [],
      errorCodeControlList: [
        Constants.WASM.ERROR_CODE.CARD_SCAN_FACE_RECOGNITION_FAILED,
        Constants.WASM.ERROR_CODE.CARD_SCAN_IDCARD_TYPE_NOT_MATCH,
        'O003',
        'O005',
        'O010',
        'M003',
        'O021',
        'O022',
        Constants.WASM.ERROR_CODE.CARD_SCAN_IDCARD_DETECTION_ERROR,
        Constants.WASM.ERROR_CODE.CARD_SCAN_IDCARD_RECOGNITION_ERROR,
      ],
      cardGuideIcon: Icon_Card_GuideVue,
      streaming: false,
      facingMode: 'environment' /** front: user, back: environment */,
      stream: null,
      card: {ltX: 0, ltY: 0, rtX: 0, rtY: 0, lbX: 0, lbY: 0, rb: 0, rbY: 0},
      // styles
      rectStrokeWidth: 4,
      stroke: 'white',
      maskingColor: '#202023B2',
      rectX: 2,
      rectY: 2,
      rectWidth: 0,
      rectHeight: 0,
      rectGuideWidth: 30,
      // face status
      status: '',
      permissionTimeout: null,
      callbackWindowResize: null,
      captureProcess: false,
      lastValidBase64Card: null,
      uploadFile: null,
      showFileUploadErrorDialog: false,
      fileUploadErrorType: '',
      uploadType: 'camera',
      inErrorProgress: false,
      // userOcrInfo: '',
      // cardOcrType: '',
      cardIsDetecting: false,
      base64MaskCardImage: '',
      base64MaskCardImageNotSplited: '',
      base64OriginalCardImage: '',
      cropResolution_w: 0,
      cropResolution_h: 0,
      cropRect: null,
      UIResolutionMode: null,
      VideoResolutionMode: null,
    };
  },
  computed: {
    ...mapState(['companyPhoneNumber', 'isMobile']),
  },

  async created() {
    this.$log.debug('CardScanWasm#created()');
  },
  async mounted() {
    this.$log.debug('CardScanWasm#mounted appData', this.appData);
    this.callbackWindowResize = () => {
      this.initialize();
    }
    window.addEventListener('resize', this.callbackWindowResize);
    this.initialize();
    await this.startOCR();
  },
  beforeDestroy() {
    // this.closeCamera();
    this.stopOCR()
    window.removeEventListener('resize', this.callbackWindowResize);
    this.lastValidBase64Card = null;
  },
  methods: {
    cardIndexFormatter(cardIndex) {
      switch (cardIndex) {
        case 0:
          return 'idcard';
        case 1:
          return 'driver';
        case 2:
          return 'passport';
        case 3:
          return 'foreign-passport';
        case 4:
        case '4-1':
        case '4-2':
        case '4-3':
          return 'alien';
      }
    },
    cardIndexFormatterForI18N(cardIndex) {
      switch (cardIndex) {
        case 0:
          return this.$t('신분증.주민등록증');
        case 1:
          return this.$t('신분증.운전면허증');
        case 2:
          return this.$t('신분증.한국여권');
        case 3:
          return this.$t('신분증.외국여권');
        case 4:
        case '4-1':
        case '4-2':
        case '4-3':
          return this.$t('신분증.외국인 등록증');
      }
    },

    async startOCR() {
      if (this.wasmOCR === null) {
        throw new useb.UsebError(Constants.WASM.ERROR_CODE.UNKNOWN_ERROR, "wasmOCR is null");
      }

      let cardStringType = this.cardIndexFormatter(this.appData.cardIndex);
      if (this.isWasmSSAMode) {
        cardStringType += "-ssa";
      }

      const callback = async (data) => {
        this.$log.debug("END startOCR()");
        this.$log.debug(data);
        if (data.result === "success") {
          await this.onSuccessCardScan(cardStringType, data.review_result);
        } else {
          this.onFailureCardScan(data);
        }
      };

      const onInProgressChange = (ocrType, inProgress, customUI, uiPosition,
                                  useTextMsg, usePreviewUI, recognizedImage) => {
        this.$log.debug('onInProgressChange - ' + inProgress);

        if (customUI && useTextMsg) {
          let textMsg = '';
          switch (inProgress) {
            case this.wasmOCR.IN_PROGRESS.NOT_READY:
              this.showWasmLoadingUI = true;
              break;
            case this.wasmOCR.IN_PROGRESS.READY:
              textMsg = this.$t('신분증.영역 안에 신분증이 꽉 차도록 위치시키면 자동 촬영됩니다.');
              this.showWasmLoadingUI = false;
              break;
            case this.wasmOCR.IN_PROGRESS.CARD_DETECT_SUCCESS:
              textMsg = this.$t('신분증.신분증이 감지되었습니다. 신분증 정보를 자동으로 인식(OCR) 중 입니다.');
              break;
            case this.wasmOCR.IN_PROGRESS.CARD_DETECT_FAILED:
              textMsg = this.$t('신분증.신분증이 감지되지 않습니다. 신분증 영역 안에 신분증을 위치시켜 주세요.');
              break;
            case this.wasmOCR.IN_PROGRESS.OCR_RECOGNIZED:
              this.base64MaskCardImageNotSplited = recognizedImage;
              textMsg = this.$t('신분증.신분증 정보가 자동으로 인식(OCR) 되었습니다.');
              this.previewDialog = true;
              break;
            case this.wasmOCR.IN_PROGRESS.OCR_RECOGNIZED_WITH_SSA:
              this.base64MaskCardImageNotSplited = recognizedImage;
              this.previewDialog = true;
              break;
            case this.wasmOCR.IN_PROGRESS.OCR_SUCCESS:
              textMsg = this.$t('신분증.신분증 인식이 완료 되었습니다.');
              break;
            case this.wasmOCR.IN_PROGRESS.OCR_SUCCESS_WITH_SSA:
              textMsg = this.$t('신분증.신분증 인식 및 사본(도용) 여부 판별이 완료되었습니다.');
              break;
            case this.wasmOCR.IN_PROGRESS.OCR_FAILED:
              textMsg = this.$t('신분증.신분증 인식에 실패하였습니다. 다시 시도해주세요.');
              break;
          }

          const textMsgUI = document.getElementById(`${uiPosition}-ui-text-msg`);
          if (textMsgUI) {
            textMsgUI.innerHTML = textMsg;
          }
        }
      };

      await this.wasmOCR.startOCR(cardStringType, callback, callback, onInProgressChange);
    },

    stopOCR() {
      if (this.wasmOCR !== null) {
        try {
          this.wasmOCR.stopOCR();
        } catch (e) {
          this.$log.debug("[WARN] this.wasmOCR is not null. but, this.wasmOCR.stopOCR is undefined : " + e);
        }
      }
    },

    async restartOCR() {
      // this.stopOCR();
      await this.startOCR();
    },

    cancelExit() {
      if (this.inErrorProgress) {
        this.stopScan = false;
        this.serverError = true;
        this.inErrorProgress = false;
      } else {
        this.stopScan = false;
      }
    },

    initialize() {
      this.serverError = false;
      this.wasmScanDone = false;
      this.previewDialog =false
      if (this.wasmOCR === null) {
        throw new useb.UsebError(Constants.WASM.ERROR_CODE.UNKNOWN_ERROR, "wasmOCR is null");
      }
    },

    async cropFaceImage(image) {
      let cardImage = new Image();
      cardImage.src = image;
      const result = await faceApi.detectSingleFaceForCard(cardImage);
      if (!result) {
        throw new useb.UsebError(
            Constants.WASM.ERROR_CODE.CARD_SCAN_FACE_RECOGNITION_FAILED,
            this.$t('얼굴.얼굴 감지 실패')
        );
      }
      const box = result.detection.box;
      const face = {x: box.x, y: box.y, w: box.width, h: box.height};
      const padding = 50;
      const canvas_crop = this.$refs.canvas_crop;
      const ctx = canvas_crop.getContext('2d');
      canvas_crop.width = face.w + padding;
      canvas_crop.height = face.h + padding;
      const cropSizeX = face.x - padding / 2;
      const cropSizeY = face.y - padding / 2;
      ctx.drawImage(
          cardImage,
          cropSizeX < 0 ? 0 : cropSizeX,
          cropSizeY < 0 ? 0 : cropSizeY,
          face.w + padding,
          face.h + padding,
          0,
          0,
          face.w + padding,
          face.h + padding
      );
      return canvas_crop.toDataURL('image/jpeg');
    },
    onClickBack() {
      this.$log.debug('onClickBack 이벤트 발생');
      // appData.base64Face
      if (
          this.phase === this.PHASE_SCAN ||
          this.phase === this.PHASE_LOADING
      ) {
        this.$log.debug('uploadFile 초기화');
        this.uploadFile = null;
        this.inErrorProgress = true;
        this.stopScan = true;
      } else {
        this.uploadFile = null;
      }
    },

    async retry() {
      this.initialize();
      await this.restartOCR();
      this.phase = this.PHASE_SCAN;
    },

    async onClickRetry() {
      this.$log.debug('uploadFile 현재 상태', this.uploadFile);
      this.$log.debug(this.errorCodeOrigin);
      this.$log.debug(this.errorCodeControlList);
      if (this.errorCodeOrigin) {
        // 정보감지 실패(O003 등) : 재시도
        if (this.errorCodeControlList.includes(this.errorCodeOrigin)) {
          if (
              [
                Constants.WASM.ERROR_CODE.CARD_SCAN_IDCARD_DETECTION_ERROR,
                Constants.WASM.ERROR_CODE.CARD_SCAN_IDCARD_RECOGNITION_ERROR
              ].includes(this.errorCodeOrigin)
          ) {
            this.$log.debug('WASM 에러 후 재시도 / 수동촬영 전환');
            if (this.isSupportWasmToServer) {
              this.$emit('changeToServerOCRMode');
            } else {
              this.$emit('cancel', {prev: true});
            }
          } else {
            this.$log.debug('정보감지 실패(O003 등) : 재시도');
            this.$emit('cancel', {prev: true});          // 신분증 종류 선택 화면으로
          }
        } else if (this.errorCodeOrigin === Constants.WASM.ERROR_CODE.CARD_SCAN_FACE_RECOGNITION_FAILED) {
          // 안면인증을 사용하는 경우 얼굴 인식에 실패하였을 때
          this.$log.debug('안면인증을 사용하는 경우 얼굴 인식에 실패하였을 때 재시도');
          await this.retry();
        } else {
          // 시스템에러팝업 : 확인
          this.$emit('cancel', {prev: true});
        }
      }
    },

    onConfirmFileUploadError() {
      this.uploadFile = null;
      this.showFileUploadErrorDialog = false;
      this.serverError = true;
    },

    errorPopupProcess(e) {
      this.$log.debug('error Popup Process : ', e.errorCode, e.message);
      if (
          e.errorCode &&
          typeof e.errorCode === 'string' &&
          this.errorCodeControlList.includes(e.errorCode)
      ) {
        this.serverError = true;
        this.errorIcon = this.cardGuideIcon;
        this.errorButton = [this.$t('버튼.종료'), this.$t('버튼.재시도')];
        this.errorMessageTitle = [this.$t('신분증.정보 감지 실패')];

        switch (e.errorCode) {
          case Constants.WASM.ERROR_CODE.CARD_SCAN_IDCARD_TYPE_NOT_MATCH:
            this.errorMessage = [
              this.$t('신분증.선택하신 신분증 종류와', {
                idcardType: `${this.cardIndexFormatterForI18N(this.appData.cardIndex)}`
              }),
              this.$t('신분증.제출된 신분증이 일치하지 않습니다.'),
              this.$t('신분증.재시도 하시겠습니까?'),
            ];
            break;
          case Constants.WASM.ERROR_CODE.CARD_SCAN_FACE_RECOGNITION_FAILED:
            this.errorMessage = [
              this.$t('신분증.신분증 사진에서'),
              this.$t('신분증.얼굴을 감지하지 못하였습니다.'),
              this.$t('신분증.재시도 하시겠습니까?'),
            ];
            this.errorButton = [this.$t('버튼.종료'), this.$t('버튼.재시도')];
            break;
          case Constants.WASM.ERROR_CODE.CARD_SCAN_IDCARD_DETECTION_ERROR:
          case Constants.WASM.ERROR_CODE.CARD_SCAN_IDCARD_RECOGNITION_ERROR:
            this.errorMessage = [
              this.$t('신분증.신분증 사진에서'),
              this.$t('신분증.신분증을 인식하지 못하였습니다.'),
              this.$t('신분증.재시도 하시겠습니까?'),
            ];
            this.errorButton = [this.$t('버튼.종료'), this.$t('버튼.재시도')];
            break;
          default:
            this.errorMessage = [
              this.$t('신분증.신분증 사진에서'),
              this.$t('신분증.신분증을 인식하지 못하였습니다.'),
              this.$t('신분증.재시도 하시겠습니까?'),
            ];
            break;
        }
      } else {
        this.serverError = true;
        this.errorIcon = {};
        this.errorMessageTitle = [
          this.$t('에러.시스템 에러가 발생하였습니다.'),
          this.$t('에러.잠시 후 다시 이용해 주세요.'),
        ];
        this.errorMessage = this.companyPhoneNumber
          ? [
            this.$t('에러.계속해서 문제가 발생한다면'),
            this.$t('에러.고객센터로 문의해주세요.', {
              companyPhoneNumber: this.companyPhoneNumber,
            }),
          ]
          : [];
        this.errorButton = ['', this.$t('버튼.확인')];

        // control 하지 않는 에러코드인 경우 errorDetail 표시
        if (e.errorDetail && e.errorDetail.length > 0) {
          this.errorDetail = e.errorDetail;
        } else if (e.message) {
          this.errorDetail = e.message;
        }
      }

      this.errorCodeOrigin = e.errorCode;
      this.errorCode = e.errorCode
        ? `${this.$t('에러.에러코드')} : ${e.errorCode}`
        : '';
    },

    onFailureCardScan(result) {
      this.captureProcess = false;

      const errorCode = result.api_response.result_code;
      const errorDetail = result.api_response.result_message;
      this.errorPopupProcess.call(this, new useb.UsebError(errorCode, errorDetail));
    },

    async onSuccessCardScan(cardStringType, result) {
      this.$log.debug('onSuccessCardScan');
      // initialize error code
      this.errorCode = '';
      this.errorCodeOrigin = '';
      try {
        // let testabc = null;
        // if (testabc === null) {   // 시스템 에러팝업 테스트를 위한 강제 에러
        //   throw new useb.UsebError("E123", "test error occurred!");
        // }

        if (this.captureProcess) return;
        this.captureProcess = true;

        const masked_base64 = result.ocr_masking_image;
        const base64Card = result.ocr_origin_image;

        let base64Face;
        // 마스킹 이미지 설정
        this.base64MaskCardImage = masked_base64.split(',')[1];
        this.base64MaskCardImageNotSplited = masked_base64;           // dialog 팝업 용
        // 이미지 설정
        this.base64OriginalCardImage = base64Card.split(',')[1];
        // 안면인증 켜져 있을 경우 crop 진행
        if (
            base64Card &&
            this.appData.moduleName.includes(Constants.MODULE.FACE)
            ||
            this.isIDCardFaceDetectMode
        ) {
          base64Face = await this.cropFaceImage(base64Card);
        }

        const ocrEnumType = result.ocr_data.type;
        const ocrType = this.wasmOCR.getOcrType(ocrEnumType);
        if (ocrType !== undefined) {
          // 주민증 / 운전면허증의 경우 scanner 가 동일하여, 주민증 선택후 면허증으로 시도했을때 에러 처리 필요
          if (cardStringType.indexOf(ocrType) === -1) {
            throw new useb.UsebError(Constants.WASM.ERROR_CODE.CARD_SCAN_IDCARD_TYPE_NOT_MATCH,
                "ocrResultType is not matched to cardStringType : (detected type : "
                + ocrType + " / " + "selected type : " + cardStringType);
          }
        }

        let ocr = {};
        const target = result.ocr_data;

        switch (cardStringType) {
          case 'idcard':
          case 'idcard-ssa':
          {
            const overseasResident = target.overseasResident;
            const region = target.region

            ocr = {
              idType: target.id_type,
              userName: target.name,
              juminNo1: target.number.substr(0, 6),
              juminNo2: target.number.substr(7, 14),
              _juminNo2: target.number[7] + '******',
              issueDate: target.Date.replaceAll('.', ''),
              overseasResident,
              region,
            };
          }
            break;
          case 'driver':
          case 'driver-ssa':
          {
            const licenseType = target.licenseType;  // 1종보통
            const serial = target.serial // "0TD18P"
            const region = target.region // 서울지방경찰청장

            ocr = {
              idType: target.id_type,
              userName: target.name,
              driverNo: target.licenseNumber,
              juminNo1: target.number.substr(0, 6),
              juminNo2: target.number.substr(7, 14),
              _juminNo2: target.number[7] + '******',
              issueDate: target.Date.replaceAll('.', ''),
              licenseType,
              serial,
              region,
            };
          }
            break;
          case 'passport':
          case 'passport-ssa':
          {
            const idType = target.id_type;
            const userName = target.name_kor;
            const userNameEng = target.name;
            const nationality = target.issuing_country;
            const passportNo = target.passport_no;
            const gender = target.sex;
            const number = target.number;
            const issueDate = target.date_of_issue;
            const expiryDate = target.date_of_expiry;
            // 큐램 OCR에서는 YYYYMMDD 가 아닌 YYMMDD로 주기때문에 birthDate 대신 juminNo1, juminNo2를 넘겨서 자동 계산되게 한다
            // 참고 : util.js의 getBirthdayFromOcr()
            // const birthDate = target.date_of_birth;
            const mrz1 = target.mrz1;
            const mrz2 = target.mrz2;

            ocr = {
              idType,
              userName,
              userNameEng,
              nationality,
              passportNo,
              gender,
              // birthDate,
              juminNo1: number.substr(0, 6),
              juminNo2: number.substr(7, 14),
              _juminNo2: number[7] + '******',
              issueDate: issueDate.replaceAll('.', ''),
              expiryDate: expiryDate.replaceAll('.', ''),
              mrz1,
              mrz2,
            };
          }
            break;
          case 'foreign-passport':
          case 'foreign-passport-ssa':
          {
            const idType = target.id_type;
            const userName = target.name;
            const nationality = target.nationality;
            const passportNo = target.passport_no;
            const gender = target.sex;
            // 큐램 OCR에서는 YYYYMMDD 가 아닌 YYMMDD로 주기때문에 birthDate대신 juminNo1로 넘겨서 자동 계산처리되게 한다
            // 참고 : util.js의 getBirthdayFromOcr()
            const birthDate = target.date_of_birth;
            const issueDate = target.date_of_issue;
            const expiryDate = target.date_of_expiry;
            const mrz1 = target.mrz1;
            const mrz2 = target.mrz2;

            ocr = {
              idType,
              userName,
              passportNo,
              nationality,
              gender,
              juminNo1: birthDate,
              issueDate: issueDate.replaceAll('.', ''),
              expiryDate: expiryDate.replaceAll('.', ''),
              mrz1,
              mrz2,
            };
          }
            break;
          case 'alien':
          case 'alien-ssa':
          {
            ocr = {
              idType: target.id_type,
              country: target.nationality,
              countryCode: target.nationality,
              visa: target.visaType,
              userName: target.name,
              issueNo: target.number,
              issueNo1: target.number.substr(0, 6),
              issueNo2: target.number.substr(7, 14),
              _juminNo2: target.number[7] + '******',
              issueDate: target.Date.replaceAll('.', ''),
            };
          }
            break;
          default:
            throw new useb.UsebError(Constants.WASM.ERROR_CODE.UNKNOWN_ERROR, "invalid cardStringType : " + cardStringType);
        }

        if (cardStringType.indexOf("-ssa")) {
          ocr.truth = target.truth;
          ocr.truthConfidence = target.truthConfidence;
          ocr.truthRetryCount = target.truthRetryCount;
          ocr.truthResultDetail = target.truthResultDetail;
        }

        this.tmpAppData = {ocr, masked_base64, base64Face};

        this.wasmScanDone = true;
        this.captureProcess = false;
      } catch (e) {
        this.$log.debug('error in onSuccessCardScan()  ', e.errorCode, e.message);
        this.captureProcess = false;
        this.errorPopupProcess.call(this, e);
      }
    },

    async onConfirmOk() {
      const {ocr, masked_base64, base64Face} = this.tmpAppData;
      this.success(ocr, masked_base64, base64Face);
    },

    success(ocr, base64Card, base64Face) {
      this.$log.debug('CardScan#success', ocr);
      //delete ocr.image_base64_mask;
      this.$emit('next', {
        ocr,
        base64Card,
        base64Face,
        base64CardOrigin: this.base64OriginalCardImage,
      });
      this.$emit('uploadtype', this.uploadType);
    },
  },
};
</script>

<style lang="scss" scoped>
.root-container {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  color: var(--surface-high);
  z-index: 3;
}

.step-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 45px;
  font-size: 14px;

  .dot {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background: var(--primary-100);
  }

  .line {
    width: 112px;
    border-top: 1px solid var(--primary-100);
  }

  .dash {
    width: 112px;
    border-top: 1px dashed var(--surface-high);
  }

  .current {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    border: 2px solid var(--primary-100);
  }

  .text-info {
    position: absolute;
    width: 100px;
    top: 22px;
    left: -45px;
    text-align: center;
  }
}

.container-phase-guide {
  display: flex;
  flex-direction: column;
  height: 100%;

  .text-title-tip {
    text-align: left;
    margin-left: 30px;
    margin-bottom: 24px;
    font-weight: 500;
    font-size: 20px;
  }

  .tip-container {
    display: flex;
    align-items: baseline;
    text-align: left;
    margin-left: 36px;
    margin-right: 36px;
    margin-bottom: 16px;
    font-size: 16px;
  }
}

.spacer {
  flex-grow: 1;
}

.button-container {
  display: flex;
  margin-bottom: 39px;
  padding: 0 30px;

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    font-weight: 500;
    font-size: 1rem;
    height: 60px;
    cursor: pointer;
    user-select: none;

    &.cancel {
      flex-grow: 0.65;
      background: var(--gray-100);
      color: var(--surface-medium);
      margin-right: 10px;
    }

    &.ok {
      flex-grow: 1;
      color: var(-font-color);
      background: var(--primary-100);
    }
  }
}

.container-phase-scan {
  height: 100%;

  .scan-subject {
    position: absolute;
    top: 32px;
    width: 100%;
    text-align: center;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    color: var(--surface-100);
  }

  .prev {
    position: absolute;
    top: 32px;
    left: 32px;
    cursor: pointer;
    z-index: 10;
  }

  .close {
    position: absolute;
    top: 24px;
    right: 24px;
    cursor: pointer;
  }

  .text-info {
    display: block;
    width: 100%;
    text-align: center;
    font-size: 1.0em;
    line-height: 1.8em;
    color: white;
  }

  .text-color-black {
    color: black;
    font-weight: bold;
  }

  .text-weight-bold {
    font-weight: bold;
  }

  .preview-img {
    border-radius: 3vmin;
  }

  .preview-ui {
    display: flex;
    border-radius: 3vmin;
    background-color: #FFF;
    justify-content: center;
    align-items: center;
    border: 6vmin solid #FFF;
  }
}

.container-phase-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: var(--secondary-100);
  color: white;

  .loading {
    width: 172px;
    margin-bottom: 50px;

    &.small {
      width: 40px;
      margin-bottom: 16px;
    }
  }

  .message {
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 8px;

    &.small {
      font-size: 16px;
      color: var(--surface-medium);
    }
  }
}

.container-phase-complete {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}
</style>

<template>
  <svg
    width="62"
    height="62"
    viewBox="0 0 62 62"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M61 31C61 47.5685 47.5685 61 31 61C14.4315 61 1 47.5685 1 31C1 14.4315 14.4315 1 31 1C47.5685 1 61 14.4315 61 31Z"
      stroke="var(--primary-80)"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-dasharray="2 5"
    />
    <path
      d="M52.2574 31.0001C52.2574 42.4734 42.9564 51.7743 31.4832 51.7743C20.0099 51.7743 10.709 42.4734 10.709 31.0001C10.709 19.5269 20.0099 10.226 31.4832 10.226C42.9564 10.226 52.2574 19.5269 52.2574 31.0001Z"
      stroke="var(--primary-80)"
      stroke-width="2"
    />
    <line
      x1="24.6626"
      y1="23.818"
      x2="24.6626"
      y2="26.3634"
      stroke="var(--primary-80)"
      stroke-width="2"
      stroke-linecap="round"
    />
    <line
      x1="37.1304"
      y1="23.818"
      x2="37.1304"
      y2="26.3634"
      stroke="var(--primary-80)"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M31.5031 28.5991L29.896 30.2062L31.5031 31.8132"
      stroke="var(--primary-80)"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M24.0513 39.3733C24.772 38.3751 27.2355 35.7369 31.324 35.7369C35.4124 35.7369 37.876 38.3751 38.5967 39.3733"
      stroke="var(--primary-80)"
      stroke-width="2"
      stroke-linecap="round"
    />
  </svg>
</template>

<script>
export default {};
</script>

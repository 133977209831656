import axios from 'axios';
import server from './server';

// const USEB_API_URL = "https://api3.useb.co.kr/";
const USEB_API_URL = 'https://lb1.useb.co.kr/';
// const USEB_API4_URL = "https://api4.useb.co.kr/";
const USEB_OPENBANK_URL = 'https://openapi.useb.co.kr/';
const USEB_FIRMBANK_URL = 'https://openapi.useb.co.kr/firmbank-custom/';

let TOKEN = null;

async function _getBearerToken() {
  if (!TOKEN) {
    TOKEN = await server.getTokenUseb();
  }
  return `Bearer ${TOKEN}`;
}

class UsebError extends Error {
  constructor(errorCode, message) {
    super(message);
    this.errorCode = errorCode;
  }
}

function _makeError(e) {
  if (e.errorCode) {
    return e;
  }
  if (e.response) {
    if (e.response.data) {
      return new UsebError(
        e.response.data.error_code,
        `${e.response.data.message} (${e.response.data.error_code})`
      );
    }
    return new UsebError(
      e.response.status,
      `${e.response.statusText} (${e.response.status})`
    );
  }
  return e;
}

async function _baseRequest(url, params) {
  try {
    const authorization = await _getBearerToken();
    const { data } = await axios.post(url, params, {
      headers: {
        Authorization: authorization,
      },
    });
    if (data.success) {
      if (data.data) {
        data.data.transaction_id = data.transaction_id;
        return data.data;
      } else {
        return data;
      }
    } else {
      throw _makeError({ response: { data } });
    }
  } catch (e) {
    throw _makeError(e);
  }
}

/**
 * 계좌실명조회(오픈뱅킹)
 * @see https://doc.useb.co.kr/#api-1%EC%9B%90%EA%B3%84%EC%A2%8C%EC%9D%B8%EC%A6%9D-%EC%98%A4%ED%94%88%EB%B1%85%ED%82%B9-%EA%B3%84%EC%A2%8C%EC%8B%A4%EB%AA%85%EC%A1%B0%ED%9A%8C
 * @param {bank_code, account_num, account_holder_info} sendData
 */
async function checkAccount(checkData, isFirmbank) {
  if (isFirmbank) {
    return await _baseRequest(`${USEB_FIRMBANK_URL}realname`, checkData);
  }
  return await _baseRequest(`${USEB_OPENBANK_URL}realname`, checkData);
}

/**
 * 1원입금이체(오픈뱅킹)
 * @see https://doc.useb.co.kr/#api-1%EC%9B%90_%EA%B3%84%EC%A2%8C%EC%9D%B8%EC%A6%9D-1%EC%9B%90%EC%9E%85%EA%B8%88%EC%9D%B4%EC%B2%B4
 */
async function sendBank(sendData, isFirmbank) {
  if (isFirmbank) {
    return await _baseRequest(`${USEB_FIRMBANK_URL}send`, sendData);
  }
  return await _baseRequest(`${USEB_OPENBANK_URL}send`, sendData);
}

/**
 * 인증코드검증
 * @see https://doc.useb.co.kr/#api-1%EC%9B%90_%EA%B3%84%EC%A2%8C%EC%9D%B8%EC%A6%9D-%EC%9D%B8%EC%A6%9D%EC%BD%94%EB%93%9C%EA%B2%80%EC%A6%9D
 */
async function verifyBank(verifyData, isFirmbank) {
  if (isFirmbank) {
    return await _baseRequest(`${USEB_FIRMBANK_URL}verify`, verifyData);
  }
  return await _baseRequest(`${USEB_OPENBANK_URL}verify`, verifyData);
}

/**
 * OCR(주민등록증)
 * @see https://doc.useb.co.kr/#api-OCR-OCR-%EC%A3%BC%EB%AF%BC%EB%93%B1%EB%A1%9D%EC%A6%9D
 */
async function getOcrIdcard(image_base64) {
  return await _baseRequest(`${USEB_API_URL}ocr/idcard-driver`, {
    image_base64,
    mask_mode: true,
  });
}
async function getOcrIdcardFile(formData) {
  return await _baseRequest(`${USEB_API_URL}ocr/idcard-driver`, formData);
}

/**
 * OCR(운전면허증)
 * @see https://doc.useb.co.kr/#api-OCR-OCR-%EC%9A%B4%EC%A0%84%EB%A9%B4%ED%97%88%EC%A6%9D
 */
async function getOcrDriver(image_base64) {
  return await getOcrIdcard(image_base64);
}
async function getOcrDriverFile(formData) {
  return await getOcrIdcardFile(formData);
}

/**
 * OCR(한국여권)
 * @see https://doc.useb.co.kr/#api-OCR-OCR-%EC%97%AC%EA%B6%8C
 */
async function getOcrPassport(image_base64) {
  return await _baseRequest(`${USEB_API_URL}ocr/passport`, {
    image_base64,
    mask_mode: true,
  });
}
async function getOcrPassportFile(formData) {
  return await _baseRequest(`${USEB_API_URL}ocr/passport`, formData);
}

/**
 * OCR(외국인여권)
 * @see https://doc.useb.co.kr/#api-OCR-OCR-%EC%99%B8%EA%B5%AD%EC%9D%B8%EC%97%AC%EA%B6%8C
 */
async function getOcrPassportOverseas(image_base64) {
  return await _baseRequest(`${USEB_API_URL}ocr/passport-overseas`, {
    image_base64,
    mask_mode: true,
  });
}
async function getOcrPassportOverseasFile(formData) {
  return await _baseRequest(`${USEB_API_URL}ocr/passport-overseas`, formData);
}

/**
 * OCR(외국인등록증)
 * @see https://doc.useb.co.kr/#api-OCR-OCR-%EC%99%B8%EA%B5%AD%EC%9D%B8%EB%93%B1%EB%A1%9D%EC%A6%9D
 */
async function getOcrAlien(image_base64) {
  return await _baseRequest(`${USEB_API_URL}ocr/alien`, {
    image_base64,
    mask_mode: true,
  });
}
async function getOcrAlienFile(formData) {
  return await _baseRequest(`${USEB_API_URL}ocr/alien`, formData);
}

/**
 * 진위확인(주민등록증)
 * @see https://doc.useb.co.kr/#api-%EC%A7%84%EC%9C%84%ED%99%95%EC%9D%B8-%EC%A3%BC%EB%AF%BC%EB%93%B1%EB%A1%9D%EC%A6%9D%EC%A7%84%EC%9C%84%ED%99%95%EC%9D%B8
 */
async function getStatusIdcard({ identity, issueDate, userName }) {
  return await _baseRequest(`${USEB_API_URL}status/idcard`, {
    identity,
    issueDate,
    userName,
  });
}

/**
 * 진위확인(운전면허증)
 * @see https://doc.useb.co.kr/#api-%EC%A7%84%EC%9C%84%ED%99%95%EC%9D%B8-%EC%A3%BC%EB%AF%BC%EB%93%B1%EB%A1%9D%EC%A6%9D%EC%A7%84%EC%9C%84%ED%99%95%EC%9D%B8
 */
async function getStatusDriver({ juminNo, userName, birthDate, licenseNo }) {
  return await _baseRequest(`${USEB_API_URL}status/driver`, {
    juminNo,
    userName,
    birthDate,
    licenseNo,
  });
}

/**
 * 진위확인(한국여권)
 * @see https://doc.useb.co.kr/#api-%EC%A7%84%EC%9C%84%ED%99%95%EC%9D%B8-%ED%95%9C%EA%B5%AD%EC%97%AC%EA%B6%8C%EC%A7%84%EC%9C%84%ED%99%95%EC%9D%B8
 */
async function getStatusPassport({
  userName,
  passportNo,
  issueDate,
  expirationDate,
  birthDate,
}) {
  return await _baseRequest(`${USEB_API_URL}status/passport`, {
    userName,
    passportNo,
    issueDate,
    expirationDate,
    birthDate,
  });
}

/**
 * 진위확인(외국인여권)
 * @see https://doc.useb.co.kr/#api-%EC%A7%84%EC%9C%84%ED%99%95%EC%9D%B8-%EC%99%B8%EA%B5%AD%EC%9D%B8%EC%97%AC%EA%B6%8C%EC%A7%84%EC%9C%84%ED%99%95%EC%9D%B8
 */
async function getStatusPassportOverseas({
  passportNo,
  nationality,
  birthDate,
}) {
  return await _baseRequest(`${USEB_API_URL}status/passport-overseas`, {
    passportNo,
    nationality,
    birthDate,
  });
}

/**
 * 진위확인(외국인등록증)
 * @see https://doc.useb.co.kr/#api-%EC%A7%84%EC%9C%84%ED%99%95%EC%9D%B8-%EC%99%B8%EA%B5%AD%EC%9D%B8%EB%93%B1%EB%A1%9D%EC%A6%9D%EC%A7%84%EC%9C%84%ED%99%95%EC%9D%B8
 */
async function getStatusAlien({ issueNo, issueDate }) {
  return await _baseRequest(`${USEB_API_URL}status/alien`, {
    issueNo,
    issueDate,
  });
}

export default {
  UsebError,
  sendBank,
  checkAccount,
  verifyBank,
  getOcrIdcard,
  getOcrIdcardFile,
  getOcrDriver,
  getOcrDriverFile,
  getOcrPassport,
  getOcrPassportFile,
  getOcrPassportOverseas,
  getOcrPassportOverseasFile,
  getOcrAlien,
  getOcrAlienFile,
  getStatusIdcard,
  getStatusDriver,
  getStatusPassport,
  getStatusPassportOverseas,
  getStatusAlien,
};

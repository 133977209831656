import 로딩 from './로딩/translation.json';
import 에러 from './에러/translation.json';
import 온보딩 from './온보딩/translation.json';
import 버튼 from './버튼/translation.json';
import 신분증 from './신분증/translation.json';
import 계좌 from './계좌/translation.json';
import 얼굴 from './얼굴/translation.json';
import placeholder from './placeholder/translation.json';
import 개인정보입력 from './개인정보입력/translation.json';
import 인증완료 from './인증완료/translation.json';
import banklist from './banklist/translation.json';

export default {
  로딩,
  에러,
  온보딩,
  버튼,
  신분증,
  계좌,
  얼굴,
  placeholder,
  개인정보입력,
  인증완료,
  banklist,
};

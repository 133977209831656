var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ViewContainer',{attrs:{"id":"cardSelect_container","paddingHorizontal":30}},[_c('div',{staticClass:"container-phase-guide"},[(_vm.showStepper)?_c('Step',{attrs:{"phase":_vm.phase,"items":_vm.phaseItems}}):_vm._e(),_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('신분증.신분증을 선택해 주세요.')))]),_c('div',{staticClass:"message",domProps:{"innerHTML":_vm._s(_vm.$t('신분증.사진이 포함된 신분증으로 본인 확인을 진행합니다.'))}}),_c('div',{staticClass:"cardSelect_wrapper"},_vm._l((_vm.filteredIdCardsList),function(card,index){return _c('div',{key:index,class:{ 'select-card': true, selected: _vm.selectIndex === card.id },attrs:{"id":'cardSelect_button_' + card.name},on:{"click":function($event){_vm.selectIndex = card.id}}},[_c('div',{staticClass:"select-image-area"},[_c('img',{staticClass:"select-image",attrs:{"src":card.image}})]),_c('div',{staticClass:"select-name"},[_vm._v(" "+_vm._s(card.name)+" "),_c('span',{staticStyle:{"font-size":"14px","line-height":"20px"}},[_c('br'),_vm._v(_vm._s(card.sub_name))])]),_c('div',{staticStyle:{"flex-grow":"1"}}),(_vm.selectIndex === card.id)?_c('Complete_24Vue',{staticStyle:{"margin-right":"24px"}}):_vm._e()],1)}),0),_c('div',{staticStyle:{"min-height":"56px"}}),_c('div',{staticClass:"button-container"},[_c('div',{class:{
          button: true,
          cancel: true,
          disabled: _vm.selectIndex === -1,
        },attrs:{"id":"cardSelect_button_cancel"},on:{"click":_vm.onCancel}},[_vm._v(" "+_vm._s(_vm.$t('버튼.이전'))+" ")]),_c('div',{class:{
          button: true,
          ok: true,
          disabled: _vm.selectIndex === undefined,
        },attrs:{"id":"cardSelect_button_submit"},on:{"click":function($event){_vm.selectIndex !== undefined &&
            _vm.$emit('next', { cardIndex: _vm.selectIndex })}}},[_vm._v(" "+_vm._s(_vm.$t('버튼.선택 완료'))+" ")])])],1),_c('ExitDialog',{attrs:{"id":'cardSelect_exitPopup',"disabledProp":!_vm.exitDialog},on:{"ok":function($event){return _vm.$emit('cancel')},"cancel":function($event){_vm.exitDialog = false}},model:{value:(_vm.exitDialog),callback:function ($$v) {_vm.exitDialog=$$v},expression:"exitDialog"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
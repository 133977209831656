<template>
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M27.25 14C27.25 21.3178 21.3178 27.25 14 27.25C6.68223 27.25 0.75 21.3178 0.75 14C0.75 6.68223 6.68223 0.75 14 0.75C21.3178 0.75 27.25 6.68223 27.25 14Z"
      stroke="var(--primary-80)"
      stroke-width="1.5"
    />
    <line
      x1="9.72363"
      y1="9.48828"
      x2="9.72363"
      y2="10.9108"
      stroke="var(--primary-80)"
      stroke-width="1.5"
      stroke-linecap="round"
    />
    <line
      x1="17.7383"
      y1="9.48828"
      x2="17.7383"
      y2="10.9108"
      stroke="var(--primary-80)"
      stroke-width="1.5"
      stroke-linecap="round"
    />
    <path
      d="M14.0137 12.455L12.9805 13.4883L14.0137 14.5216"
      stroke="var(--primary-80)"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M18.5742 17.0449C18.1108 17.6867 16.5268 19.383 13.8981 19.383C11.2694 19.383 9.68543 17.6867 9.22203 17.0449"
      stroke="var(--primary-80)"
      stroke-width="1.5"
      stroke-linecap="round"
    />
  </svg>
</template>

<script>
export default {};
</script>

<template>
  <v-dialog
    v-model="dialog"
    style="width: calc(100% - 96px); max-width: 384px"
    width="calc(100% - 96px)"
    max-width="384px"
    persistent
  >
    <v-card>
      <v-card-text
        :id="id"
        :class="{ disabled: disabledProp }"
        style="display: flex; flex-direction: column; padding: 0; margin: 0"
      >
        <div
          style="
            margin: 32px 30px 40px;
            font-size: 20px;
            line-height: 32px;
            color: var(--surface-high);
          "
        >
          <h2 class="account-holder-modal--title" style="margin-bottom: 32px">
            {{ $t('계좌.예금주명') }}
          </h2>
          <InputTextField
            :maxlength="45"
            v-model="account_holder_name"
            :placeholder="$t('placeholder.예금주명을 입력하세요.')"
            class="userinput-input blue-border-constant"
            @keyup="enterSend"
            fontSize="1rem"
          ></InputTextField>
          <div class="userinput-label">
            {{
              $t(
                '계좌.외국인 또는 개인사업자의 경우, 고객님의 이름과 예금주명이 다를 수 있으므로 확인 후 입력해 주세요.'
              )
            }}
          </div>
        </div>
        <div class="button-container" style="margin-bottom: 32px">
          <div @click="cancel" class="button cancel">{{ $t('버튼.취소') }}</div>
          <div @click="ok" :class="{ disabled }" class="button ok">
            <span v-if="!loading">{{ $t('계좌.1원 송금') }}</span>
            <Loading v-else />
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import InputTextField from '../../auth/InputTextField.vue';
import rules from '../../rules';
import Loading from '../Loading.vue';

export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
    },
    disabledProp: {
      type: Boolean,
    },
  },
  components: { InputTextField, Loading },
  data() {
    return {
      rules,
      dialog: this.value,
      account_holder_name: '',
      disabled: true,
    };
  },
  watch: {
    value(val) {
      this.dialog = val;
    },
    account_holder_name: {
      handler: function (newValue) {
        if (newValue.length > 1) {
          this.disabled = false;
        }
      },
    },
  },

  methods: {
    enterSend(e) {
      if (e.key == 'Enter') {
        this.ok();
        this.disabled = true;
      }
    },
    close() {
      this.dialog = false;
      this.$emit('input', false);
    },
    cancel() {
      this.close();
      this.$emit('cancel');
    },
    ok() {
      if (this.disabled) {
        return;
      }
      this.$emit('ok', this.account_holder_name);
    },
  },
};
</script>

<style lang="scss" scoped>
.account-holder-modal--title {
  font-style: normal;
  font-weight: bold;
  color: var(--surface-high);

  font-weight: 500;
  font-size: 1.25rem;
  line-height: 2rem;
  text-align: center;
}
.userinput-label {
  font-size: 0.8125rem;
  line-height: 1.25rem;
  color: var(--surface-medium);
  display: flex;
  align-items: flex-start;
  margin-top: 8px;

  &::before {
    content: '';
    display: block;
    background-image: url('../../../../assets/Tooltip_16.svg');
    min-width: 16px;
    height: 16px;
    margin-top: 1px;
    margin-right: 2px;
  }
}
.button-container {
  display: flex;
  margin-bottom: 39px;
  padding: 0 30px;

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    font-weight: 500;
    font-size: 1rem;
    height: 60px;
    cursor: pointer;
    user-select: none;
    &.disabled {
      // background: var(--primary-20) !important;
    }

    &.cancel {
      flex-grow: 0.65;
      background: var(--gray-100);
      color: var(--surface-medium);
      margin-right: 10px;
    }

    &.ok {
      flex-grow: 1;
      color: var(--font-color);
      background: var(--primary-100);
    }
  }
}
.input-textfield-root .input-textfield-container input {
  font-size: 1rem;
}
</style>

<template>
  <div class="root-container" id="cardScan_container">
    <!-- phase1: card capture -->
    <div v-if="phase === PHASE_SCAN" class="container-phase-scan">
      <video
          playsinline
          ref="video"
          autoplay
          controls
          style="position: absolute; left: 0; top: 0; z-index: -1; display: none"
      >
        Video stream not available.
      </video>
      <canvas
          ref="canvas"
          style="position: absolute; left: 0; top: 0; display: none"
      ></canvas>
      <div style="height: 100%">
        <svg
            class="scanner-svg-container"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
          <mask id="mask-rect">
            <rect x="0" y="0" width="100%" height="100%" fill="white"></rect>
            <rect
                :x="rectX"
                :y="rectY"
                :width="rectWidth"
                :height="rectHeight"
                rx="10"
                ry="10"
                fill="black"
                stroke="black"
            />
          </mask>
          <rect
              x="0"
              y="0"
              :width="maskRectWidth"
              height="100%"
              fill="#202023FF"
              mask="url(#mask-rect)"
          ></rect>
          <svg
              :x="rectX - 4"
              :y="rectY"
              :width="rectGuideWidth"
              :height="rectHeight"
              viewBox="0 0 30 264"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
          >
            <path
                d="M28 262H10C5.58172 262 2 258.418 2 254V10C2 5.58172 5.58172 2 10 2H28"
                stroke="white"
                stroke-width="4"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
          </svg>
          <svg
              :x="rectX + rectWidth - rectGuideWidth"
              :y="rectY"
              :width="rectGuideWidth"
              :height="rectHeight"
              viewBox="0 0 30 264"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
          >
            <path
                d="M2 262H20C24.4183 262 28 258.418 28 254V10C28 5.58172 24.4183 2 20 2H2"
                stroke="white"
                stroke-width="4"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
          </svg>
        </svg>
      </div>
      <div ref="captureContainer" class="capture-container">
        <div
            class="text-info"
            v-html="
            $t(
              '신분증.영역 안에 신분증이 꽉 차도록 배치 후 하단 버튼을 누르면 촬영됩니다.'
            )
          "
        ></div>
        <div class="button-capture">
          <!-- 카메라 버튼 -->
          <img
              ref="capture"
              @click="capture"
              width="80px"
              src="@/assets/Camera.svg"
          />
        </div>
      </div>
      <div style="position: absolute; left: 0; top: 0; color: white">
        {{ status }}
      </div>
      <div style="position: absolute; bottom: 0; right: 0;">
        <img width='1' height='1'
          v-if="platform.isWebviewAndroid"
          :src="require('@/assets/Loading_32px.svg')"
          alt="prevent to freeze video stream on android WebView"
        />
      </div>
      <img
          src="@/assets/back_32_white.svg"
          alt="back"
          class="prev"
          width="30"
          @click="$emit('cancel', { prev: true })"
      />
      <div class="scan-subject">{{ $t('온보딩.신분증 인증') }}</div>
      <img
          @click="stopScan = true"
          id="cardScan_submit_button"
          src="@/assets/icon-close.svg"
          width="48px"
          class="close"
      />
    </div>
    <!-- phase2: loading -->
    <div v-if="phase === PHASE_LOADING" class="container-phase-loading">
      <LoadingImgVue class="loading" />
      <img src="@/assets/Loading_40px.svg" class="loading small" />
      <div class="message">{{ $t('로딩.잠시만 기다려 주세요.') }}</div>
      <div class="message small">
        {{ $t('로딩.신분증 정보를 확인 중입니다.') }}
      </div>
    </div>
    <!-- modal -->
    <ServerErrorDialog
        v-model="serverError"
        :icon="errorIcon"
        :title="errorMessageTitle"
        :message="errorMessage"
        :errorCode="errorCode"
        :button="errorButton"
        @minorBtn="onClickBack"
        @majorBtn="onClickRetry"
        id="cardScan_serverErrorPopup"
    />
    <ExitDialog v-model="stopScan" @ok="$emit('cancel')" @cancel="cancelExit" />
    <CardScanConfirmDialog
        v-model="confirmDialog"
        :image="base64MaskCardImage"
        @retry="retry"
        @ok="onConfirmOk"
        id="cardScan_scanConfirmPopup"
    />
    <v-file-input
        id="cardScanFileInput"
        accept=".jpg,.jpeg,.png,.pdf"
        v-model="uploadFile"
        @change="onChangeFile"
    />
    <FileUploadErrorDialog
        :show="showFileUploadErrorDialog"
        :error-type="fileUploadErrorType"
        @ok="onConfirmFileUploadError"
        id="cardScan_fileUploadErrorPopup"
    />
    <canvas
        id="canvas_crop"
        ref="canvas_crop"
        style="
        display: none;
        width: 25%;
        position: absolute;
        left: 10px;
        top: 0px;
        border: red 2px solid;
      "
    >
    </canvas>
  </div>
</template>
<script>
import Constants from '@/constants';
import util from '@/util';
import useb from '@/api/useb';
import ServerErrorDialog from '../dialog/ServerErrorDialog';
import ExitDialog from '../dialog/ExitDialog';
import CardScanConfirmDialog from '../dialog/CardScanConfirmDialog';
import FileUploadErrorDialog from '../dialog/FileUploadErrorDialog';
import * as faceApi from './face-api-impl.js';
import * as netfunnel from './netfunnel.js';
import * as pdfjs from 'pdfjs-dist/legacy/build/pdf';
//import * as netfunnelSkin from "./netfunnel-skin.js";
//console.log(netfunnelSkin);   // prevent build error for unused import file
import { mapState } from 'vuex';
import Icon_Card_GuideVue from './DynamicCt_imgs/Icon_Card_Guide.vue';
import LoadingImgVue from './DynamicCt_imgs/Loading.vue';

export default {
  components: {
    ServerErrorDialog,
    CardScanConfirmDialog,
    FileUploadErrorDialog,
    ExitDialog,
    LoadingImgVue,
  },
  props: {
    appData: Object,
    platform: Object,
    isIDCardFaceDetectMode: {
      type: Boolean,
      required: true,
      default: false,
    }
    /**
     * emit events
     * cancel
     * next
     */
  },
  data: function () {
    return {
      origin: '',
      PHASE_SCAN: 1,
      PHASE_LOADING: 2,
      phase: 1,
      confirmDialog: false,
      base64MaskCardImage: '',
      tmpAppData: {},
      stopScan: false,
      serverError: false,
      errorIcon: {},
      errorMessageTitle: [],
      errorMessage: [],
      errorCode: '',
      errorCodeOrigin: '',
      errorButton: [],
      errorCodeControlList: [
        Constants.CLIENT.ERROR_CODE.CARD_SCAN_IDCARD_TYPE_NOT_MATCH,
        Constants.SERVER.ERROR_CODE.CARD_SCAN_IDCARD_NO_MASKING_IMAGE,
        'O003',
        'O005',
        'O010',
        'M003',
        'O021',
        'O022',
      ],
      cardGuideIcon: Icon_Card_GuideVue,
      streaming: false,
      facingMode: 'environment' /** front: user, back: environment */,
      stream: null,
      card: { ltX: 0, ltY: 0, rtX: 0, rtY: 0, lbX: 0, lbY: 0, rb: 0, rbY: 0 },
      // styles
      rectStrokeWidth: 4,
      rectX: 2,
      rectY: 2,
      maskRectWidth: '0%',
      rectWidth: 0,
      rectHeight: 0,
      rectGuideWidth: 30,
      // face status
      status: '',
      permissionTimeout: null,
      callbackWindowResize: null,
      captureProcess: false,
      MAX_CARD_SCAN_RETRY_COUNT:
        parseInt(process.env.VUE_APP_MAX_CARD_SCAN_RETRY_COUNT) || 5,
      lastValidBase64Card: null,
      uploadFile: null,
      showFileUploadErrorDialog: false,
      fileUploadErrorType: '',
      uploadType: 'camera',
      inErrorProgress: false,
    };
  },
  computed: {
    ...mapState(['companyPhoneNumber', 'isMobile']),
  },
  async created() {
    this.stream = await navigator.mediaDevices.getUserMedia({
      video: true,
      audio: false,
    });
    this.stopStream();
    this.initialize();
    pdfjs.GlobalWorkerOptions.workerSrc = 'pdf/pdf.worker-3.3.122.js';
  },
  mounted() {
    this.$log.debug('CardScan#mounted appData', this.appData);
    this.callbackWindowResize = () => this.adjustStyles();
    window.addEventListener('resize', this.callbackWindowResize);
  },
  beforeDestroy() {
    this.closeCamera();
    window.removeEventListener('resize', this.callbackWindowResize);
    this.lastValidBase64Card = null;
  },
  methods: {
    cardIndexFormatterForI18N(cardIndex) {
      switch (cardIndex) {
        case 0:
          return this.$t('신분증.주민등록증');
        case 1:
          return this.$t('신분증.운전면허증');
        case 2:
          return this.$t('신분증.한국여권');
        case 3:
          return this.$t('신분증.외국여권');
        case 4:
        case '4-1':
        case '4-2':
        case '4-3':
          return this.$t('신분증.외국인 등록증');
      }
    },
    cancelExit() {
      if (this.inErrorProgress) {
        this.stopScan = false;
        this.serverError = true;
        this.inErrorProgress = false;
      } else {
        this.stopScan = false;
      }
    },
    proceedCameraPermission() {
      navigator.mediaDevices.enumerateDevices().then((devices) => {
        let camera = [];
        for (const device of devices) {
          if (device.kind === 'videoinput') {
            if (device.getCapabilities) {
              const cap = device.getCapabilities();
              if (cap?.facingMode?.includes(this.facingMode)) {
                camera.push(cap.deviceId);
              }
            }
          }
        }

        let cameraWidth = { ideal: 1280 };
        let cameraHeight = { ideal: 720 };

        navigator.mediaDevices
            .getUserMedia({
              video: {
                deviceId: camera.length
                    ? {
                      ideal:
                          this.platform.isWebViewAndroidReactNative ||
                          this.platform.isWebviewAndroid
                              ? camera[camera.length - 1]
                              : camera[camera.length - 1],
                    }
                    : null,
                width: cameraWidth,
                height: cameraHeight,
                facingMode: { ideal: this.facingMode },
                focusMode: { ideal: 'continuous' },
                whiteBalanceMode: { ideal: 'continuous' },
                zoom: { ideal: 1 },
              },
              audio: false,
            })
            .then((stream) => {
              const video = this.$refs.video;
              const canvas = this.$refs.canvas;
              this.stream = stream;
              if (video) {
                const [track] = stream.getVideoTracks();
                const capability = track.getCapabilities();
                this.$log.debug('CardScan#initialize capability', capability);
                if ('srcObject' in video) {
                  video.srcObject = stream;
                } else {
                  // Avoid using this in new browsers, as it is going away.
                  video.src = window.URL.createObjectURL(stream);
                }
                video.addEventListener('loadedmetadata', () => {
                  this.$log.debug('CardScan#initialize~onloadedmetadata');
                  video.play();
                });
                video.addEventListener('canplay', () => {
                  this.$log.debug('CardScan#initialize~canplay', this.streaming);
                  if (!this.streaming) {
                    canvas.setAttribute('width', video.videoWidth);
                    canvas.setAttribute('height', video.videoHeight);
                    this.render();
                    this.$log.debug(
                        'CardScan#initialize~canplay',
                        video.videoWidth,
                        video.videoHeight,
                        video.clientWidth,
                        video.clientHeight,
                        this.rectWidth
                    );
                    this.streaming = true;
                    // this.render(); // auto detection start
                  }
                });
                video.webkitExitFullscreen();
              } else {
                this.closeCamera();
              }
            })
            .catch((e) => {
              this.$log.error('alcherakyc error', e.name, e);
              if (e.name === 'NotAllowedError') {
                this.checkCameraPermission();
              } else if (e.name === 'NotReadableError') {
                this.stopStream();
                this.checkCameraPermission();
              }
            });
      });
    },
    initialize() {
      this.streaming = false;
      this.serverError = false;
      const supports = navigator.mediaDevices.getSupportedConstraints();
      this.$log.debug('CardScan#initialize supports', supports);
      this.proceedCameraPermission();
    },
    checkCameraPermission() {
      clearTimeout(this.permissionTimeout);
      this.permissionTimeout = setTimeout(() => {
        this.proceedCameraPermission();
      }, 1000);
    },
    checkCameraPlay() {
      this.$log.debug('CardScan#checkCameraPlay');
      if (this.phase === this.PHASE_SCAN) {
        this.stopStream();
        this.proceedCameraPermission();
      }
    },
    render() {
      let videoEl = this.$refs.video;
      let loop = async () => {
        if (videoEl.paused || videoEl.ended || this.stopScan) {
          // nothing
        } else {
          this.adjustStyles(false);
        }
        cancelAnimationFrame(this.requestAnimationFrameId);
      };
      cancelAnimationFrame(this.requestAnimationFrameId);
      this.requestAnimationFrameId = requestAnimationFrame(loop);
    },
    adjustStyles(forceRerender = true) {
      this.maskRectWidth = '100%';
      const video = this.$refs.video;
      const canvas = this.$refs.canvas;
      const parentElement = canvas.parentElement;
      const minWidth = 400;
      const cardRatio = 260 / minWidth;
      const rectGuideRatio = 30 / 400;
      let captureContainer = this.$refs.captureContainer;

      let cssText = '';
      const ratio = video.clientWidth / video.clientHeight;
      const current = parentElement.clientWidth / parentElement.clientHeight;
      this.$log.debug('CardScan#adjustStyles', ratio, current);
      if (forceRerender) {
        this.$emit('forceRerender');
        video.webkitExitFullscreen();
      }

      if (current < 1) {
        // portrait mode
        video.style.cssText = '';
        canvas.style.cssText = '';
        cssText = `width: 90%;position: absolute;top: 50%;transform: translate(-50%, -50%);left: 50%;`;
        video.style.cssText += cssText;
        canvas.style.cssText += cssText;

        this.rectWidth = parentElement.clientWidth * 0.9;
        const tmpRectHeight = this.rectWidth * cardRatio;
        this.rectHeight =
            tmpRectHeight > video.clientHeight
                ? video.clientHeight
                : tmpRectHeight;

        // 촬영 버튼, 힌트 조정
        if (captureContainer) {
          const captureText =
              captureContainer.getElementsByClassName('text-info')[0];
          const captureButton =
              captureContainer.getElementsByClassName('button-capture')[0];
          captureText.className = 'text-info text-info-portrait';
          captureButton.className = 'button-capture button-capture-portrait';
        }
      } else {
        // landscape mode
        video.style.cssText = '';
        canvas.style.cssText = '';
        cssText = `height: 80%;position: absolute;top: 50%;transform: translate(-50%, -50%);left: 50%;`;
        video.style.cssText += cssText;
        canvas.style.cssText += cssText;

        const tmpRectHeight = parentElement.clientHeight * 0.6;
        this.rectHeight =
            tmpRectHeight > video.clientHeight
                ? video.clientHeight
                : tmpRectHeight;
        this.rectWidth = (this.rectHeight * minWidth) / 260;

        // 촬영 버튼, 힌트 조정
        if (captureContainer) {
          const captureText =
              captureContainer.getElementsByClassName('text-info')[0];
          const captureButton =
              captureContainer.getElementsByClassName('button-capture')[0];
          captureText.className = 'text-info text-info-landscape';
          captureButton.className = 'button-capture button-capture-landscape';
        }
      }
      this.rectGuideWidth = this.rectWidth * rectGuideRatio;
      this.rectX =
          parentElement.clientWidth / 2 -
          this.rectWidth / 2 +
          this.rectStrokeWidth / 2;
      this.rectY =
          parentElement.clientHeight / 2 -
          this.rectHeight / 2 +
          this.rectStrokeWidth / 2; // center

      captureContainer && (captureContainer.style.display = 'inline-block');
    },
    cropImage() {
      this.uploadType = 'camera';
      this.$emit('');
      let video = this.$refs.video;
      let canvas = this.$refs.canvas;
      let canvas_crop = this.$refs.canvas_crop;

      const width = video.videoWidth;
      const height = video.videoHeight;
      const videoSizeRatio = video.videoWidth / video.clientWidth;
      let rect = {
        x: (video.clientWidth / 2 - this.rectWidth / 2) * videoSizeRatio,
        y: (video.clientHeight / 2 - this.rectHeight / 2) * videoSizeRatio,
        w: this.rectWidth * videoSizeRatio,
        h: this.rectHeight * videoSizeRatio,
      };
      this.$log.debug('CardScan#cropImage', rect);
      let ctx = canvas.getContext('2d');
      if (width === 0) {
        return [null, null];
      }
      ctx.drawImage(video, 0, 0, width, height);
      ctx = canvas_crop.getContext('2d');
      canvas_crop.width = rect.w;
      canvas_crop.height = rect.h;

      ctx.drawImage(
          canvas,
          rect.x,
          rect.y,
          rect.w,
          rect.h,
          0,
          0,
          rect.w,
          rect.h
      );

      return canvas_crop.toDataURL('image/jpeg');
    },
    async cropFaceImage(image) {
      let cardImage = new Image();
      cardImage.src = image;
      const result = await faceApi.detectSingleFaceForCard(cardImage);
      if (!result) {
        throw new useb.UsebError(
            Constants.CLIENT.ERROR_CODE.CARD_SCAN_FACE_RECOGNITION_FAILED,
            this.$t('얼굴.얼굴 감지 실패')
        );
      }
      const box = result.detection.box;
      const face = { x: box.x, y: box.y, w: box.width, h: box.height };
      const padding = 50;
      const canvas_crop = this.$refs.canvas_crop;
      const ctx = canvas_crop.getContext('2d');
      canvas_crop.width = face.w + padding;
      canvas_crop.height = face.h + padding;
      const cropSizeX = face.x - padding / 2;
      const cropSizeY = face.y - padding / 2;
      ctx.drawImage(
          cardImage,
          cropSizeX < 0 ? 0 : cropSizeX,
          cropSizeY < 0 ? 0 : cropSizeY,
          face.w + padding,
          face.h + padding,
          0,
          0,
          face.w + padding,
          face.h + padding
      );
      return canvas_crop.toDataURL('image/jpeg');
    },
    stopStream() {
      cancelAnimationFrame(this.requestAnimationFrameId);
      if (this.stream) {
        this.stream.stop && this.stream.stop();
        let tracks = this.stream.getTracks && this.stream.getTracks();
        this.$log.debug('CardScan#stopStream', tracks);
        if (tracks && tracks.length) {
          tracks.forEach((track) => track.stop());
        }
        this.stream = null;
      }
    },
    closeCamera() {
      clearTimeout(this.permissionTimeout);
      this.stopStream();
    },
    startManualInput() {
      let ocr = {
        userName: this.appData.userName,
        birthDate: this.appData.birthDate,
      };
      const juminNo1 = this.appData.birthDate
          .replace('-', '')
          .replace('-', '')
          .slice(2);
      switch (this.appData.cardIndex) {
          // 0
        case Constants.APP_CARD_INDEX.JUMIN:
          ocr = {
            ...ocr,
            juminNo1,
            juminNo2: '',
            _juminNo2: '0******',
            issueDate: '',
          };
          break;
          // 1
        case Constants.APP_CARD_INDEX.DRIVER:
          ocr = {
            ...ocr,
            driverNo: '',
            juminNo1,
            juminNo2: '',
            _juminNo2: '',
            issueDate: '',
          };
          break;
          // 2
        case Constants.APP_CARD_INDEX.PASSPORT_KOREAN:
          ocr = {
            ...ocr,
            passportNo: '',
            issueNo1: '',
            issueNo2: '',
            juminNo1,
            juminNo2: '',
            _juminNo2: '',
            issueDate: '',
            expiryDate: '',
          };
          break;
          // 3
        case Constants.APP_CARD_INDEX.PASSPORT_ALIEN:
          ocr = {
            ...ocr,
            passportNo: '',
            nationality: '',
          };
          break;
          // 4
        case Constants.APP_CARD_INDEX.ALIEN:
          ocr = {
            ...ocr,
            juminNo1,
            juminNo2: '',
            _juminNo2: '0******',
            issueDate: '',
          };
          break;
        default:
          break;
      }
      let tempAppDataOnManual = {
        base64Card: this.lastValidBase64Card,
        base64CardOrigin: this.tmpAppData.base64CardImage,
        ocr,
      };
      if (
          this.appData.base64Card &&
          this.appData.moduleName.includes(Constants.MODULE.FACE)
          ||
          this.isIDCardFaceDetectMode
      ) {
        tempAppDataOnManual.base64Face = this.appData.base64Face;
      }
      this.$emit('manual', tempAppDataOnManual);
    },
    onClickBack() {
      this.$log.debug('onClickBack 이벤트 발생');
      // appData.base64Face
      if (this.appData.cardScanRetryCount >= this.MAX_CARD_SCAN_RETRY_COUNT) {
        // N(5회)이상 실패 시 직접입력
        // depending on the type of document, create mvp ocr object
        this.startManualInput();
      } else if (
          this.phase === this.PHASE_SCAN ||
          this.phase === this.PHASE_LOADING
      ) {
        this.$log.debug('uploadFile 초기화');
        this.uploadFile = null;
        this.inErrorProgress = true;
        this.stopScan = true;
        // this.$emit('cancel');
      } else {
        this.uploadFile = null;
      }
    },

    retry() {
      this.initialize();
      this.phase = this.PHASE_SCAN;
    },

    onClickRetry() {
      if (
        // 정보감지 실패(O003 등) : 재시도
        this.errorCodeOrigin &&
        this.errorCodeControlList.includes(this.errorCodeOrigin)
      ) {
        this.$emit('cancel', { prev: true });   // 신분증 종류 선택 화면으로
      } else if (
          this.errorCodeOrigin &&
          this.errorCodeOrigin ===
          Constants.CLIENT.ERROR_CODE.CARD_SCAN_FACE_RECOGNITION_FAILED // 안면인증을 사용하는 경우 얼굴 인식에 실패하였을 때
      ) {
        this.retry();
      } else {
        if (
            this.appData.cardScanRetryCount < this.MAX_CARD_SCAN_RETRY_COUNT
        ) {
          // 사진확인 : 재시도, // 시도회수초과 : 첨부파일   // 시스템 에러팝업 : 확인
          this.retry();
        } else {
          this.uploadType = 'mobile';

          this.$emit('uploadtype', this.uploadType);
          // upload image file123
          let fileInput = document.getElementById('cardScanFileInput');
          if (fileInput) {
            // prevent error for 'File chooser dialog can only be shown with a user activation.'
            fileInput.style.position = 'fixed';
            fileInput.style.top = '-100em';
            fileInput.value = null;
            fileInput.click();
          }
          // prevent focus-out problem(cannot move any step anymore)
          // when cancel to attach file
          this.retry();
        }
      }
    },
    async onChangeFile(file) {
      this.$log.debug('CardScan#onChangeFile', file);
      // restrict file format
      const ACCEPTED_FILE_SIZE = 1024 * 1024 * 5; // 5MB
      const ACCEPTED_FILE_FORMATS =
          /(.*?)\.(jpg|jpeg|png|pdf|JPG|JPEG|PNG|PDF)$/;
      if (file) {
        const isValidFileSize = file.size <= ACCEPTED_FILE_SIZE;
        const isValidFileFormat = file.name.match(ACCEPTED_FILE_FORMATS);

        this.$log.debug(
            'CardScan#onChangeFile#file',
            isValidFileSize,
            isValidFileFormat
        );

        if (!isValidFileSize || !isValidFileFormat) {
          this.fileUploadErrorType = !isValidFileSize ? 'size' : 'format';
          this.showFileUploadErrorDialog = true;
        } else {
          this.uploadFile = file;
          if (/\.(pdf|PDF)$/.test(this.uploadFile.name)) {
            const base64Card = await this.readFileAsPdf(this.uploadFile);
            await this.capture(base64Card);
            this.uploadType = 'mobile';
          } else {
            const base64Card = await this.readFileAsImage(this.uploadFile);
            await this.capture(base64Card);
            this.uploadType = 'mobile';
          }
        }
      }
    },
    async readFileAsPdf(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        try {
          reader.onload = async () => {
            const typedArray = new Uint8Array(reader.result);
            try {
              const loadingTask = pdfjs.getDocument(typedArray);
              const pdf = await loadingTask.promise;

              // Fetch the first page
              const pageNumber = 1;
              const page = await pdf.getPage(pageNumber);

              const scale = 1;
              const viewport = page.getViewport({ scale: scale });

              // Prepare canvas using PDF page dimensions
              const canvas = document.createElement('canvas');
              const context = canvas.getContext('2d');
              canvas.height = viewport.height;
              canvas.width = viewport.width;

              // Render PDF page into canvas context
              const renderContext = { canvasContext: context, viewport };
              const renderTask = page.render(renderContext);
              await renderTask.promise;

              // Get base64 string from pdf canvas
              const base64Card = canvas.toDataURL('image/jpeg');
              this.$log.debug('readFileAsPdf', { base64Card });
              resolve(base64Card);
            } catch (error) {
              this.$log.error('CardScan#attachPdf#imageError: ', error);
              this.fileUploadErrorType = 'invalid-image';
              this.showFileUploadErrorDialog = true;
              reject(error);
            }
          };
          reader.readAsArrayBuffer(file);
        } catch (e) {
          this.$log.error('CardScan#attachPdf#readerError: ', e);
          reject(e);
        }
      });
    },
    async readFileAsImage(file) {
      // 이미지를 캔버스에 대입해서 이미지가 유효한지 검증
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          const image = new Image();
          image.src = reader.result;
          image.onload = () => {
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');
            canvas.width = image.width;
            canvas.height = image.height;
            context.drawImage(image, 0, 0, image.width, image.height);
            const base64Card = canvas.toDataURL('image/jpeg');
            resolve(base64Card);
          };
          image.onerror = (error) => {
            this.$log.error('CardScan#attachImage#imageError: ', error);
            this.fileUploadErrorType = 'invalid-image';
            this.showFileUploadErrorDialog = true;
            reject(error);
          };
        };
        reader.onerror = (error) => {
          this.$log.error('CardScan#attachImage#readerError: ', error);
          this.fileUploadErrorType = 'invalid-image';
          this.showFileUploadErrorDialog = true;
          reject(error);
        };
      });
    },
    onConfirmFileUploadError() {
      this.uploadFile = null;
      this.showFileUploadErrorDialog = false;
      this.serverError = true;
    },
    async capture(uploadedFile) {
      this.$log.debug('사진 캡쳐 시작');
      // initialize error code
      this.errorCode = '';
      this.errorCodeOrigin = '';
      try {
        if (this.captureProcess) return;
        this.captureProcess = true;

        let base64Card;
        if (uploadedFile.split) {
          this.uploadType = 'mobile';

          base64Card = uploadedFile;
        } else {
          this.uploadType = 'camera';

          base64Card = this.cropImage(); // 에러발생
        }
        this.$emit('uploadtype', this.uploadType);

        this.lastValidBase64Card = base64Card;
        this.phase = this.PHASE_LOADING;
        this.closeCamera();
        let base64CardImage, base64Face, mime;
        if (base64Card) {
          const splitedImageData = base64Card.split(',');
          mime = splitedImageData[0];
          base64CardImage = splitedImageData[1]; // remove mime
        }

        if (
            base64Card &&
            this.appData.moduleName.includes(Constants.MODULE.FACE)
            ||
            this.isIDCardFaceDetectMode
        ) {
          base64Face = await this.cropFaceImage(base64Card);
        }

        this.tmpAppData = { base64Card, base64Face, mime, base64CardImage };
        this.base64MaskCardImage = base64Card;
        this.confirmDialog = true;
        this.captureProcess = false;
      } catch (e) {
        this.$log.debug('CardScan#capture error', e.errorCode, e.message);
        this.captureProcess = false;
        if (
            e.errorCode &&
            typeof e.errorCode === 'string' &&
            e.errorCode.startsWith(
                Constants.CLIENT.ERROR_CODE.CARD_SCAN_FACE_RECOGNITION_FAILED
            )
        ) {
          this.serverError = true;
          this.errorIcon = this.cardGuideIcon;
          this.errorMessageTitle = [e.message];
          this.errorMessage = [
            this.$t('신분증.신분증 사진에서'),
            this.$t('신분증.얼굴을 감지하지 못하였습니다.'),
            this.$t('신분증.재시도 하시겠습니까?'),
          ];
          this.errorButton = [this.$t('버튼.종료'), this.$t('버튼.재시도')];
        }
        this.errorCodeOrigin = e.errorCode;
        this.errorCode = e.errorCode
            ? `${this.$t('에러.에러코드')} : ${e.errorCode}`
            : '';
      }
    },
    async onConfirmOk() {
      // netfunnel for waiting queue
      const _this = this;

      const __impl = async () => {
        try {
          // let testabc = null;
          // if (testabc === null) {   // 세스템 에러팝업 테스트를 위한 강제 에러
          //   throw new useb.UsebError("E123", "test error occurred!");
          // }

          // eslint-disable-next-line no-unreachable
          const { base64Card, base64Face, mime } = this.tmpAppData;
          const cardIndex = this.appData.cardIndex;
          const pdfToImage = util.dataURItoBlob(base64Card);
          const formData = new FormData();
          formData.append('image', pdfToImage);
          formData.append('mask_mode', true);

          if (cardIndex === Constants.APP_CARD_INDEX.JUMIN) {
            // 주민등록증
            // const ocr = await useb.getOcrIdcard(base64CardImage);
            const ocr = await useb.getOcrIdcardFile(formData);
            if (!ocr.image_base64_mask) {
              throw new useb.UsebError(Constants.SERVER.ERROR_CODE.CARD_SCAN_IDCARD_NO_MASKING_IMAGE);
            }
            if (!['1', '2', '3', '4'].includes(ocr.juminNo2[0])) {
              throw new useb.UsebError('O003');
            }
            if (ocr.idType === '1') {
              this.success(ocr, `${mime},${ocr.image_base64_mask}`, base64Face);
            } else {
              throw new useb.UsebError(Constants.CLIENT.ERROR_CODE.CARD_SCAN_IDCARD_TYPE_NOT_MATCH);
            }
          } else if (cardIndex === Constants.APP_CARD_INDEX.DRIVER) {
            // 운전면허증
            const ocr = await useb.getOcrDriverFile(formData);
            if (!ocr.image_base64_mask) {
              throw new useb.UsebError(Constants.SERVER.ERROR_CODE.CARD_SCAN_IDCARD_NO_MASKING_IMAGE);
            }
            if (ocr.idType === '2') {
              this.success(ocr, `${mime},${ocr.image_base64_mask}`, base64Face);
            } else {
              throw new useb.UsebError(Constants.CLIENT.ERROR_CODE.CARD_SCAN_IDCARD_TYPE_NOT_MATCH);
            }
          } else if (cardIndex === Constants.APP_CARD_INDEX.PASSPORT_KOREAN) {
            // 한국 여권
            this.$log.debug('한국 여권 진입점');
            const ocr = await useb.getOcrPassportFile(formData);
            if (!ocr.image_base64_mask) {
              throw new useb.UsebError(Constants.SERVER.ERROR_CODE.CARD_SCAN_IDCARD_NO_MASKING_IMAGE);
            }
            if (!ocr.userName) {
              throw new useb.UsebError('O003');
            }
            if (ocr._juminNo2) {
              this.success(ocr, `${mime},${ocr.image_base64_mask}`, base64Face);
            } else {
              this.success(ocr, base64Card, base64Face);
            }
          } else if (cardIndex === Constants.APP_CARD_INDEX.PASSPORT_ALIEN) {
            // 외국 여권
            this.$log.debug('외국인 여권 진입점');
            const ocr = await useb.getOcrPassportOverseasFile(formData);
            this.$log.debug(ocr);
            if (/KOR|K0R/.test(ocr.nationality)) {
              throw new useb.UsebError(Constants.CLIENT.ERROR_CODE.CARD_SCAN_IDCARD_TYPE_NOT_MATCH);
            }
            this.success(ocr, base64Card, base64Face);
          } else if (cardIndex === Constants.APP_CARD_INDEX.ALIEN) {
            // 외국인등록증
            const ocr = await useb.getOcrAlienFile(formData);
            if (!ocr.image_base64_mask) {
              throw new useb.UsebError(Constants.SERVER.ERROR_CODE.CARD_SCAN_IDCARD_NO_MASKING_IMAGE);
            }
            this.success(ocr, `${mime},${ocr.image_base64_mask}`, base64Face);
          }
        } catch (e) {
          errorPopupProcess.call(this, e);
          return 'error: ' + e;
        }
        // eslint-disable-next-line no-unreachable
        return 'success';
      };

      const errorPopupProcess = (e) => {
        this.$log.debug('CardScan#onConfirmOk error', e.errorCode, e.message);
        this.appData.cardScanRetryCount++;
        this.$log.debug(
            'cardScanRetryCount : ' + this.appData.cardScanRetryCount
        );
        if (
            this.appData.cardScanRetryCount >= this.MAX_CARD_SCAN_RETRY_COUNT &&
            this.appData.manual_upload_id_mobile
        ) {
          this.$log.debug(`uploadType is ${this.uploadType}`);
          this.serverError = true;
          this.errorIcon = this.cardGuideIcon;
          this.errorMessageTitle = [this.$t('신분증.정보 감지 실패')];

          if (this.appData.manual_input_mobile) {
            if (
                this.tmpAppData.base64Card &&
                this.appData.moduleName.includes(Constants.MODULE.FACE)
                ||
                this.isIDCardFaceDetectMode
            ) {
              this.appData.base64Face = this.tmpAppData.base64Face;
            }
            this.errorButton = [
              this.$t('버튼.직접 입력'),
              this.$t('버튼.사진 첨부'),
            ];
          } else {
            this.errorButton = ['', this.$t('버튼.사진 첨부')];
          }
          // this.errorButton = ['직접 입력', '사진 첨부'];
          this.errorMessage = [
            this.$t('신분증.신분증 인식이 잘되지 않았습니다.'),
            this.$t('신분증.이미 잘 촬영된 신분증을 보유하고 있다면'),
            this.$t('신분증.파일 첨부가 가능합니다.'),
          ];
        } else if (
            this.appData.cardScanRetryCount > this.MAX_CARD_SCAN_RETRY_COUNT &&
            this.appData.manual_upload_id_mobile
        ) {
          this.onClickBack();
        } else {
          if (
              e.errorCode &&
              typeof e.errorCode === 'string' &&
              this.errorCodeControlList.includes(e.errorCode)
          ) {
            this.serverError = true;
            this.errorIcon = this.cardGuideIcon;
            this.errorButton = [this.$t('버튼.종료'), this.$t('버튼.재시도')];
            this.errorMessageTitle = [this.$t('신분증.정보 감지 실패')];

            switch (e.errorCode) {
              case Constants.CLIENT.ERROR_CODE.CARD_SCAN_IDCARD_TYPE_NOT_MATCH:
                this.errorMessage = [
                  this.$t('신분증.선택하신 신분증 종류와', {
                    idcardType: `${this.cardIndexFormatterForI18N(this.appData.cardIndex)}`
                  }),
                  this.$t('신분증.제출된 신분증이 일치하지 않습니다.'),
                  this.$t('신분증.재시도 하시겠습니까?'),
                ];
                break;
              case Constants.CLIENT.ERROR_CODE.CARD_SCAN_FACE_RECOGNITION_FAILED:
                this.errorMessage = [
                  this.$t('신분증.신분증 사진에서'),
                  this.$t('신분증.얼굴을 감지하지 못하였습니다.'),
                  this.$t('신분증.재시도 하시겠습니까?'),
                ];
                this.errorButton = [this.$t('버튼.종료'), this.$t('버튼.재시도')];
                break;
              default:
              this.errorMessage = [
                this.$t('신분증.신분증 사진에서'),
                this.$t('신분증.신분증을 인식하지 못하였습니다.'),
                this.$t('신분증.재시도 하시겠습니까?'),
              ];
                break;
            }
          } else {
            this.$log.debug(e);
            this.serverError = true;
            this.errorIcon = {};
            this.errorMessageTitle = [
              this.$t('에러.시스템 에러가 발생하였습니다.'),
              this.$t('에러.잠시 후 다시 이용해 주세요.'),
            ];
            this.errorMessage = this.companyPhoneNumber
              ? [
                this.$t('에러.계속해서 문제가 발생한다면'),
                this.$t('에러.고객센터로 문의해주세요.', {
                  companyPhoneNumber: this.companyPhoneNumber,
                }),
              ]
              : [];
            this.errorButton = ['', this.$t('버튼.확인')];
          }
          this.errorCodeOrigin = e.errorCode;
          this.errorCode = e.errorCode
              ? `${this.$t('에러.에러코드')} : ${e.errorCode}`
              : '';
        }
      };

      if (netfunnel.isUsed) {
        netfunnel.NetFunnel_Action(
            { action_id: 'idcard_ocr' },
            {
              success: function () {
                //대기가 없거나, 대기 종료 후에 넷퍼넬 자원 할당을 받을 때 호출
                __impl().then((ret) => {
                  _this.$log.fatal('NetFunnel_Action Result : ' + ret);
                  netfunnel.NetFunnel_Complete();
                });
              },
              continued: function () {
                //대기 단계에서 호출
                // nothing to do
              },
              stop: function () {
                //대기창의 중지 버튼 클릭 시 호출
                netfunnel.NetFunnel_Complete();
                _this.initialize();
                _this.phase = _this.PHASE_SCAN;
              },
              error: function (ev, ret) {
                //넷퍼넬 서버에서 error응답을 받았을 때
                //(default: error callbcak생략시, error도 success로 동작)
                netfunnel.NetFunnel_Complete();
                try {
                  if (ret?.code == '513') {
                    _this.$log.error(
                        'NetFunnel_Action Skip (cause : ' + ret?.data?.msg
                    );
                    __impl();
                    return;
                  }

                  let error_code = 'NFN_ERR';
                  if (ret?.code) {
                    error_code += '_' + ret?.code;
                  }
                  if (ret?.data?.msg) {
                    error_code += '_' + ret?.data?.msg;
                  }

                  throw new useb.UsebError(
                      error_code,
                      'netFunnel Error\n - ' + JSON.stringify(ret)
                  );
                } catch (e) {
                  errorPopupProcess.call(_this, e);
                }
              },
              /*
            bypass: function(ev, ret){
              //넷퍼넬 관리자페이지에서 해당 actionID를 bypass(우회) 설정 시 호출
            },
            block: function(ev, ret){
              //넷퍼넬 관리자페이지에서 해당 actionID를 block(차단) 설정 시 호출
            },
            ipblock: function(ev, ret){
              //엑세스 제어기능을 통해, 제어룰에 해당되어 차단되는 경우에 호출
            },
            expressnumber: function(ev, ret){
              //VIP 설정(ip, id)에 등록된 사용자의 요청이 있을 경우에 호출 bypass
            }
            */
            }
        );
      } else {
        await __impl();
      }
    },

    success(ocr, base64Card, base64Face) {
      this.$log.debug('CardScan#success', ocr);
      delete ocr.image_base64_mask;
      this.$emit('next', {
        ocr,
        base64Card,
        base64Face,
        base64CardOrigin: this.tmpAppData.base64CardImage,
      });
      this.$emit('uploadtype', this.uploadType);
    },
  },
};
</script>

<style lang="scss" scoped>
.root-container {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  color: var(--surface-high);
  z-index: 3;
}

.step-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 45px;
  font-size: 14px;

  .dot {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background: var(--primary-100);
  }

  .line {
    width: 112px;
    border-top: 1px solid var(--primary-100);
  }

  .dash {
    width: 112px;
    border-top: 1px dashed var(--surface-high);
  }

  .current {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    border: 2px solid var(--primary-100);
  }

  .text-info {
    position: absolute;
    width: 100px;
    top: 22px;
    left: -45px;
    text-align: center;
  }
}

.container-phase-guide {
  display: flex;
  flex-direction: column;
  height: 100%;

  .text-title-tip {
    text-align: left;
    margin-left: 30px;
    margin-bottom: 24px;
    font-weight: 500;
    font-size: 20px;
  }

  .tip-container {
    display: flex;
    align-items: baseline;
    text-align: left;
    margin-left: 36px;
    margin-right: 36px;
    margin-bottom: 16px;
    font-size: 16px;
  }
}

.spacer {
  flex-grow: 1;
}

.button-container {
  display: flex;
  margin-bottom: 39px;
  padding: 0 30px;

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    font-weight: 500;
    font-size: 1rem;
    height: 60px;
    cursor: pointer;
    user-select: none;

    &.cancel {
      flex-grow: 0.65;
      background: var(--gray-100);
      color: var(--surface-medium);
      margin-right: 10px;
    }

    &.ok {
      flex-grow: 1;
      color: var(-font-color);
      background: var(--primary-100);
    }
  }
}

.container-phase-scan {
  height: 100%;

  .scanner-svg-container {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
  }

  .scan-subject {
    position: absolute;
    top: 32px;
    width: 100%;
    text-align: center;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    color: var(--surface-100);
  }

  .prev {
    position: absolute;
    top: 32px;
    left: 32px;
    cursor: pointer;
    z-index: 10;
  }

  .close {
    position: absolute;
    top: 24px;
    right: 24px;
    cursor: pointer;
  }

  .capture-container {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: none;
  }

  .text-info {
    position: absolute;
    width: 100%;
    text-align: center;
    font-size: 20px;
    line-height: 32px;
    color: white;
  }

  .text-info-portrait {
    bottom: 20px;
  }

  .text-info-landscape {
    bottom: 10px;
  }

  .button-capture {
    position: absolute;
    width: 100%;
    text-align: center;
    cursor: pointer;
  }

  .button-capture-portrait {
    bottom: 100px;
  }

  .button-capture-landscape {
    text-align: right;
    bottom: 50px;
    right: 50px;
  }
}

.container-phase-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: var(--secondary-100);
  color: white;

  .loading {
    width: 172px;
    margin-bottom: 50px;
    &.small {
      width: 40px;
      margin-bottom: 16px;
    }
  }

  .message {
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 8px;
    &.small {
      font-size: 16px;
      color: var(--surface-medium);
    }
  }
}

.container-phase-complete {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}
</style>

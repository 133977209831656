<template>
  <v-dialog :value="true" max-width="384px" persistent>
    <v-card>
      <v-card-text :id="id" :class="{ disabled: disabledProp }">
        <div class="edit-issue-date-container">
          <div class="title-text">
            {{ $t('신분증.운전면허증 발급일자 확인') }}
          </div>
          <div class="title-content">
            <p>
              {{ $t('신분증.운전면허증 발급일자가 정확한지 확인해주세요.') }}
            </p>
            <p>{{ $t('신분증.정확하지 않다면 날짜를 수정해주세요.') }}</p>
          </div>
          <div class="card-result-label">
            {{ $t('신분증.발급 일자') }}
          </div>
          <InputTextField
            ref="issueDate"
            v-model="ocr.issueDate"
            :rules="[rules.required, rules.date, rules.isPastThanFuture]"
            @onupdate="validateForm"
            @blur="onBlur"
            :transform="rules.transformDateFormat"
            :onblurTransform="rules.onblurTransformDateFormat"
            :placeholder="$t('placeholder.발급일자 8자리를 입력하세요.')"
            fontSize="1rem"
            :errorMessage="errorMessageName"
            class="issue-date-input"
          />
          <InputCheckboxField
            class="issue-date-check"
            :label="$t('신분증.발급일자를 확인하였습니다.')"
            v-model="checked"
            @change="onCheckboxChange"
          />
        </div>
        <div class="button-container">
          <div v-ripple @click="cancel()" class="button cancel">
            {{ $t('버튼.취소') }}
          </div>
          <div
            v-ripple
            @click="ok()"
            :class="{ button: true, next: true, disabled }"
          >
            {{ $t('버튼.확인 완료') }}
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import InputTextField from '../auth/InputTextField';
import InputCheckboxField from '../../common/InputCheckboxField.vue';
import rules from '../rules';

export default {
  name: 'EditIssueDateDialog',
  components: {
    InputTextField,
    InputCheckboxField,
  },
  props: {
    show: {
      type: Boolean,
      // required: true,
      default: false,
    },
    ocr: {
      type: Object,
    },
    id: {
      type: String,
    },
    disabledProp: {
      type: Boolean,
    },
  },
  data() {
    return {
      rules,
      disabled: true,
      checked: false,
    };
  },
  created() {},
  mounted() {
    this.$log.debug('EditIssueDateDialog.vue #mounted', {
      ocr: this.ocr,
    });
  },
  computed: {
    errorMessageName() {
      if (!rules.isPastThanFuture(this.ocr.issueDate)) {
        return this.$t(
          '신분증.유효하지 않은 발급일자입니다. 확인 후 수정해주세요.'
        );
      }
      return this.$t('신분증.발급일자를 입력해 주세요.');
    },
  },
  methods: {
    onCheckboxChange() {
      this.validateForm();
    },
    validateForm() {
      this.$nextTick(() => {
        if (this.ocr.issueDate) {
          const result = this.$refs.issueDate.valid
            ? this.$refs.issueDate.validFlag
            : true;
          this.disabled = !result || !this.checked;
        } else {
          this.disabled = true;
        }
      });
    },
    cancel() {
      this.$emit('ok', null);
    },
    ok() {
      if (!this.disabled) {
        this.$emit('ok', {
          ...this.ocr,
        });
      }
    },
    onBlur() {},
  },
};
</script>

<style lang="scss" scoped>
#editIssueDateDialog.v-card__text {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;

  .edit-issue-date-container {
    padding: 32px 24px;
    color: var(--surface-high);
  }

  .title-text {
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 2rem;
    text-align: center;
    color: var(--surface-high);
    margin-bottom: 32px;
  }

  .title-content {
    text-align: center;
    font-size: 16px;
    margin-bottom: 32px;
  }

  .card-result-label {
    font-size: 16px;
    color: var(--surface-medium);
  }

  .issue-date-input {
    margin-top: 8px;
  }

  .issue-date-check {
    margin-top: 16px;
  }
}
.button-container {
  display: flex;
  margin-bottom: 30px;
  padding: 0 30px;

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    font-weight: 500;
    font-size: 1rem;
    height: 60px;
    cursor: pointer;
    user-select: none;

    &.cancel {
      flex-grow: 3.5;
      background: var(--gray-100);
      color: var(--surface-medium);
      margin-right: 10px;
    }
  }

  .next {
    flex-grow: 6.5;
    background-color: var(--primary-100);
    color: var(--font-color);
  }

  .disabled {
    background-color: var(--primary-20);
  }
}

@media screen and (max-width: 480px) {
  #editIssueDateDialog.v-card__text {
    .title-text {
      font-size: 18px;
    }
    .title-content {
      font-size: 14px;
    }
    .card-result-label {
      font-size: 14px;
    }
  }
}
</style>

<template>
  <div class="card-result-fail-root" id="accessDenied_container">
    <div class="card-result-fail-info-container">
      <Face_FailVue style="margin-bottom: 40px; margin-left: 10px" />
      <div class="card-result-fail-title">
        {{ $t('에러.일시적 오류 발생') }}
      </div>
      <div
        class="card-result-fail-content"
        v-html="$t('에러.잘못된 경로 또는 비정상적 접근이 감지되어')"
      />
      <div
        class="card-result-fail-contact"
        v-html="$t('에러.동일한 문제가 지속적으로 발생한다면')"
      />
      <div class="card-result-fail-error">
        {{ $t('에러.에러코드') }} : {{ errorCodeMessage[0] }}
      </div>
      <div
        v-if="errorCodeMessage.length > 1"
        class="card-result-fail-error small"
      >
        [Detail] {{ errorCodeMessage[1] }}
      </div>
    </div>
    <div
      id="accessDenied_submit_button"
      @click="$emit('ok')"
      class="button-container"
    >
      {{ $t('버튼.확인') }}
    </div>
  </div>
</template>

<script>
import Face_FailVue from './DynamicCt_imgs/Face_Fail.vue';

export default {
  components: { Face_FailVue },
  props: {
    errorCode: {
      type: String,
      default: '',
    },
  },
  computed: {
    errorCodeMessage() {
      return this.errorCode.split('<br/>');
    },
  },
};
</script>

<style lang="scss" scoped>
.card-result-fail-root {
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  width: 100%;
  height: 100%;
}

.card-result-fail-info-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-grow: 1;

  .card-result-fail-title {
    font-weight: bold;
    font-size: 28px;
    line-height: 36px;
    margin-bottom: 24px;
  }

  .card-result-fail-content {
    font-weight: normal;
    text-align: center;
    font-size: 16px;
    line-height: 26px;
    color: var(--surface-high);
    margin-bottom: 16px;
  }

  .card-result-fail-contact {
    font-weight: normal;
    text-align: center;
    font-size: 14px;
    line-height: 21px;
    color: var(--surface-medium);
    margin-bottom: 16px;
    a {
      color: var(--surface-medium);
    }
  }

  .card-result-fail-error {
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    color: var(--surface-medium);
    max-width: 300px;
    &.small {
      margin-top: 8px;
      font-size: 10px;
      border-radius: 8px;
      padding: 6px 8px;
      background-color: var(--background-100);
    }
  }
}

.button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 1rem;
  width: calc(100% - 60px);
  color: var(--font-color);
  background-color: var(--primary-100);
  border-radius: 8px;
  height: 60px;
  margin: 0 30px 48px;
}
</style>

<template>
  <svg
    width="179"
    height="118"
    viewBox="0 0 179 118"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="1"
      y="1"
      width="177"
      height="116"
      rx="8"
      stroke="var(--primary-80)"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-dasharray="4 8"
    />
    <rect
      x="10"
      y="10"
      width="159"
      height="98"
      rx="7"
      stroke="var(--primary-80)"
      stroke-width="2"
    />
    <rect
      x="107.69"
      y="23.2437"
      width="45.7232"
      height="54.271"
      stroke="var(--primary-40)"
      stroke-width="0.901408"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M135.097 52.4978C137.88 50.8147 139.74 47.7595 139.74 44.2698C139.74 38.9631 135.439 34.6611 130.132 34.6611C124.825 34.6611 120.523 38.9631 120.523 44.2698C120.523 48.1825 122.862 51.549 126.218 53.0476C126.444 54.9967 125.927 57.6705 125.927 57.6705H135.319C135.319 57.6705 134.579 54.5581 135.097 52.4978Z"
      fill="var(--primary-20)"
    />
    <path
      d="M107.239 77.9654H130.552H153.864C153.552 74.412 153.349 74.0994 153.349 73.2054C153.349 69.2624 150.12 64.9593 150.12 64.9593L148.964 64.3209L139.311 61.3119L136.813 58.0698C136.551 57.7293 136.214 57.4541 135.828 57.2658C135.442 57.0775 135.017 56.9812 134.588 56.9845L126.697 57.0436C126.002 57.0489 125.335 57.3132 124.825 57.7846L119.939 62.3138L112.772 65.7333L112.76 65.7211L112.685 65.7768L111.621 66.5421L110.937 67.036C110.937 67.036 109.142 69.1876 107.708 75.6405C107.492 76.611 107.34 75.9043 107.239 77.9654Z"
      fill="var(--primary-80)"
    />
    <path
      d="M119.413 35.0258C119.485 33.9224 120.215 32.7933 122.533 32.2033C122.533 32.2033 126.512 27.1155 131.919 29.7836C131.919 29.7836 134.54 29.3326 136.484 32.0522C136.484 32.0522 138.411 30.9157 139.224 32.9301C139.224 32.9301 141.484 37.0391 140.652 41.2304C139.82 45.4216 139.394 45.9446 139.394 45.9446C139.394 45.9446 140.454 37.2752 134.346 37.8763C128.237 38.4775 123.319 36.3293 121.936 41.0327C120.787 44.9382 120.932 46.9666 120.932 46.9666C120.932 46.9666 117.09 43.0705 118.396 40.0026C119.064 38.4335 119.301 36.7275 119.413 35.0258Z"
      fill="var(--primary-80)"
    />
    <rect
      x="25.3793"
      y="33.1377"
      width="31.0345"
      height="3.44828"
      rx="1.72414"
      fill="var(--primary-80)"
    />
    <rect
      x="25.3793"
      y="52.9658"
      width="61.2069"
      height="3.44828"
      rx="1.72414"
      fill="var(--primary-80)"
    />
    <rect
      x="25.3793"
      y="63.3105"
      width="61.2069"
      height="3.44828"
      rx="1.72414"
      fill="var(--primary-80)"
    />
    <rect
      x="25.3793"
      y="72.793"
      width="61.2069"
      height="3.44828"
      rx="1.72414"
      fill="var(--primary-80)"
    />
    <rect
      x="63.3103"
      y="91.7588"
      width="52.5862"
      height="6.03448"
      rx="2"
      fill="var(--primary-20)"
    />
  </svg>
</template>

<script>
export default {};
</script>

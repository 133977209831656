var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ViewContainer',{ref:"root",attrs:{"paddingTop":_vm.appData.hasLogo ? 48 : 32,"paddingHorizontal":30,"id":"user-info-input--container"}},[_c('div',{staticClass:"card-title-area"},[_c('div',{staticClass:"empty-div"}),_c('div',{staticClass:"card-title-text"},[_vm._v(_vm._s(_vm.$t('개인정보입력.개인정보 입력')))]),_c('img',{staticClass:"close-icon",style:(_vm.appData.hasLogo ? 'top:48px' : 'top:32px'),attrs:{"width":"30","src":require("@/assets/icon-close-32.svg")},on:{"click":function($event){_vm.exitDialog = true}}})]),_c('div',{staticClass:"userinput-label"},[_vm._v(_vm._s(_vm.$t('개인정보입력.이름')))]),_c('InputTextField',{staticClass:"userinput-input",attrs:{"rules":[_vm.rules.required, _vm.errorRuleName, _vm.rules.koreanAndUpperEng],"transform":_vm.rules.transformOnlyUpperCase,"append":_vm.clearIconUserName,"maxlength":45,"placeholder":_vm.$t('placeholder.이름을 입력하세요.'),"error":_vm.errorInvalidName,"errorMessage":!_vm.userinfo.userName
        ? _vm.$t('placeholder.이름을 입력하세요.')
        : _vm.errorInvalidName
        ? _vm.errorDefaultMessageBirthDate
        : _vm.errorDefaultMessageName,"id":'userInfoInput_name'},on:{"append":function($event){_vm.userinfo.userName = ''},"onupdate":_vm.validateForm,"focus":function($event){_vm.showUsernameClearIcon = true},"blur":function($event){_vm.showUsernameClearIcon = false}},model:{value:(_vm.userinfo.userName),callback:function ($$v) {_vm.$set(_vm.userinfo, "userName", $$v)},expression:"userinfo.userName"}}),_c('span',{staticClass:"userinput-label userinput-label--detail"},[_vm._v(" "+_vm._s(_vm.$t( '개인정보입력.*외국인의 경우 Lastname Firstname Middlename 순으로 입력하세요.' ))+" ")]),_c('div',{staticClass:"userinput-label"},[_vm._v(_vm._s(_vm.$t('개인정보입력.휴대전화 번호')))]),_c('InputTextField',{staticClass:"userinput-input",attrs:{"rules":[_vm.rules.required, _vm.rules.phoneNumber],"transform":_vm.rules.transformOnlyNumber,"type":"tel","pattern":"\\d*","append":_vm.clearIconPhoneNumber,"maxlength":45,"placeholder":_vm.$t('placeholder.\'-\'를 제외하고 입력하세요.'),"errorMessage":!_vm.userinfo.phoneNumber
        ? _vm.$t('placeholder.휴대전화 번호를 입력하세요.')
        : _vm.$t('개인정보입력.입력 형식이 올바르지 않습니다.'),"id":'userInfoInput_phone'},on:{"onupdate":_vm.validateForm,"append":function($event){_vm.userinfo.phoneNumber = ''},"focus":function($event){_vm.showPhoneNumberClearIcon = true},"blur":function($event){_vm.showPhoneNumberClearIcon = false}},model:{value:(_vm.userinfo.phoneNumber),callback:function ($$v) {_vm.$set(_vm.userinfo, "phoneNumber", $$v)},expression:"userinfo.phoneNumber"}}),_c('div',{staticClass:"userinput-label"},[_vm._v(_vm._s(_vm.$t('개인정보입력.생년월일')))]),_c('InputTextField',{staticClass:"userinput-input",attrs:{"placeholder":_vm.$t('placeholder.YYYY-MM-DD'),"error":_vm.errorInvalidBirthDate,"errorMessage":!_vm.userinfo.birthDate ? _vm.errorDefaultMessageBirthDate : _vm.errorInvalidInput,"rules":[
      _vm.rules.required,
      _vm.errorRuleBirthDate,
      _vm.rules.date,
      _vm.rules.isPastThanFuture,
    ],"transform":_vm.rules.transformDateFormat,"onblurTransform":_vm.rules.onblurTransformDateFormat,"id":'userInfoInput_bod',"type":"text","pattern":"\\d*"},on:{"keyup":_vm.bithDayInputKeyUp,"onupdate":_vm.validateForm},model:{value:(_vm.userinfo.birthDate),callback:function ($$v) {_vm.$set(_vm.userinfo, "birthDate", $$v)},expression:"userinfo.birthDate"}}),_c('div',{staticClass:"userinput-label"},[_vm._v(_vm._s(_vm.$t('개인정보입력.이메일')))]),_c('InputTextField',{staticClass:"userinput-input",attrs:{"rules":[_vm.rules.required, _vm.rules.email],"maxlength":45,"placeholder":_vm.$t('placeholder.example@email.com'),"errorMessage":!_vm.userinfo.email
        ? _vm.$t('placeholder.이메일을 입력하세요.')
        : _vm.$t('개인정보입력.입력 형식이 올바르지 않습니다.'),"id":'userInfoInput_email'},on:{"onupdate":_vm.validateForm},model:{value:(_vm.userinfo.email),callback:function ($$v) {_vm.$set(_vm.userinfo, "email", $$v)},expression:"userinfo.email"}}),_c('div',{staticStyle:{"min-height":"56px"},attrs:{"data-description":"fixed-button-area"}}),_c('div',{staticClass:"button-container"},[_c('div',{directives:[{name:"ripple",rawName:"v-ripple"}],class:{ button: true, next: true, disabled: _vm.disabled },attrs:{"id":'userInfoInput_button_next'},on:{"click":_vm.onClickNext}},[_vm._v(" "+_vm._s(_vm.$t('버튼.다음'))+" ")])]),_c('ServerErrorDialog',{attrs:{"id":"userInfoInput_server_error_dialog","disabledProp":!_vm.serverError,"icon":_vm.errorIcon,"title":_vm.errorMessageTitle,"message":_vm.errorMessage,"errorCode":_vm.errorCode,"button":_vm.errorButton},on:{"minorBtn":_vm.onClickBack,"majorBtn":_vm.onClickCloseServerError},model:{value:(_vm.serverError),callback:function ($$v) {_vm.serverError=$$v},expression:"serverError"}}),_c('ExitDialog',{attrs:{"disabledProp":!_vm.exitDialog,"id":"userInfoInput_exit_dialog"},on:{"ok":function($event){return _vm.$emit('cancel')},"cancel":function($event){_vm.exitDialog = false}},model:{value:(_vm.exitDialog),callback:function ($$v) {_vm.exitDialog=$$v},expression:"exitDialog"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
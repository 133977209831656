<template>
  <v-dialog v-model="dialog" max-width="384px" persistent>
    <v-card>
      <v-card-text
        id="exit_dialog"
        :class="['exit_dialog', { disabled: disabledProp }]"
      >
        <div
          class="message"
          v-html="
            $t('신분증.본인 인증이 완료되지 않았습니다. 바로 종료하시겠습니까?')
          "
        />
        <div class="button-container">
          <div @click="ok" class="button cancel">{{ $t('버튼.네') }}</div>
          <div @click="cancel" class="button ok">{{ $t('버튼.아니오') }}</div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    image: {
      type: String,
    },
    id: {
      type: String,
    },
    disabledProp: {
      type: Boolean,
    },
  },
  data() {
    return {
      dialog: this.value,
    };
  },
  watch: {
    value(val) {
      this.dialog = val;
    },
  },
  methods: {
    close() {
      this.dialog = false;
      this.$emit('input', false);
    },
    cancel() {
      this.close();
      this.$emit('cancel');
    },
    ok() {
      this.close();
      this.$emit('ok');
    },
  },
};
</script>

<style lang="scss" scoped>
#exit_dialog.exit_dialog,
.exit_dialog {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 32px;
}
.message {
  font-size: 20px;
  line-height: 32px;
  color: var(--surface-high);
  text-align: center;
}

.button-container {
  display: flex;

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    font-weight: 500;
    font-size: 1rem;
    height: 60px;
    cursor: pointer;
    user-select: none;

    &.cancel {
      flex-grow: 0.65;
      background: var(--gray-100);
      color: var(--surface-medium);
      margin-right: 10px;
    }

    &.ok {
      flex-grow: 1;
      color: var(--font-color);
      background: var(--primary-100);
    }
  }
}

@media screen and (max-width: 480px) {
  .message {
    font-size: 18px;
  }
}
</style>

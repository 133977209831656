<template>
  <div class="input-textfield-root">
    <div
      :class="{
        'input-textfield-container': true,
        readonly,
        focus,
        error: !validFlag,
        password: isPassword,
      }"
    >
      <input
        ref="input"
        :max="c_type == 'date' ? maxDate : null"
        :value="value"
        :placeholder="placeholder"
        @keydown="onKeydown"
        @input="onInput"
        @focus="onFocus"
        @blur="onBlur"
        @keyup="onKeyUp"
        :pattern="pattern"
        :type="c_type"
        :readonly="readonly"
        :maxlength="maxlength"
        :id="id"
        :style="{ fontSize }"
      />
      <img
        v-if="append"
        @mousedown="onClickIcon"
        :src="append"
        class="icon-append"
        :width="appendWidth"
      />
      <div v-if="isPassword" @mousedown="onClickPwdIcon" class="icon-password">
        <component :is="passwordIcon" :color="color" />
      </div>
    </div>
    <div v-if="!validFlag && errorMessage" class="text-error">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
import Icon_Visible from './DynamicCt_imgs/Icon_Visible.vue';
import Icon_Invisible from './DynamicCt_imgs/Icon_Invisible.vue';

export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    pattern: {
      type: String,
    },
    placeholder: {
      type: String,
      default: '',
    },
    transform: {
      type: Function,
      default: (v) => v,
    },
    onblurTransform: {
      type: Function,
      default: (v) => v,
    },
    maxlength: {
      type: Number,
      default: null,
    },
    append: {
      type: String,
      default: '',
    },
    appendWidth: {
      type: String,
      default: '',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: '',
    },
    rules: {
      type: Array,
      default: () => [],
    },
    maxDate: {
      type: String,
      default: '',
    },
    id: {
      type: String,
    },
    fontSize: {
      type: String,
    },
    color: {
      type: String,
    },
  },
  data() {
    return {
      text: this.value,
      focus: false,
      isBackspace: false,
      validFlag: true,
      c_type: this.type,
      showPassword: false,
    };
  },
  watch: {
    value(val) {
      this.text = val;
    },
    error(val) {
      this.validFlag = !val;
    },
  },
  computed: {
    isPassword() {
      return this.type === 'password';
    },
    passwordIcon() {
      return this.showPassword ? Icon_Visible : Icon_Invisible;
    },
  },
  mounted() {
    if (this.value) {
      this.text = this.value;
      this.valid();
    }
    if (this.error) {
      this.validFlag = !this.error;
    }
  },
  methods: {
    onKeydown(e) {
      if (e.code === 'Backspace' || e.key === 'Backspace') {
        this.isBackspace = true;
      }
      this.$emit('keydown', e);
    },
    onInput(e, isBlur) {
      let text = e.target.value;
      if (
        this.type === 'number' &&
        this.maxlength !== null &&
        !!text &&
        text.length > this.maxlength
      ) {
        text = text.substring(0, text.length - 1);
      }
      const position = this.$refs.input.selectionStart;
      text = this.transform(text, this.isBackspace, isBlur, position);
      this.isBackspace = false;
      e.target.value = text;
      this.text = text;
      this.valid();
      this.$emit('input', text);
      this.$emit('onupdate');
    },
    valid() {
      if (this.rules.length) {
        this.validFlag = true;
        for (const rule of this.rules) {
          const result = rule(this.text);
          if (result === false || typeof result === 'string') {
            this.validFlag = false;
            break;
          }
        }
        return this.validFlag;
      }
      return true;
    },
    onKeyUp(e) {
      this.valid();
      this.$emit('keyup', e);
    },
    onFocus() {
      this.focus = true;
      this.$emit('focus');
    },
    onBlur() {
      this.focus = false;
      this.onInput({ target: { value: this.text } }, true);
      this.text = this.onblurTransform(this.text);
      this.valid();
      this.$emit('input', this.text);
      this.$emit('onupdate');
      this.$emit('blur');
    },
    onClickIcon() {
      // this.$refs.input.click();

      this.$emit('append');
    },
    onClickPwdIcon() {
      const { showPassword } = this;
      this.c_type = !showPassword ? 'text' : 'password';
      this.showPassword = !showPassword;
    },
  },
};
</script>

<style lang="scss" scoped>
.input-textfield-root {
  .input-textfield-container {
    position: relative;
    display: flex;
    align-items: center;
    color: var(--surface-high);
    // font-size: 20px;
    border-radius: 8px;
    border: 1px solid var(--gray-300);
    min-height: 64px;
    overflow: hidden;
    input {
      font-size: 1.25rem;
      padding: 0 24px;
      width: 100%;
      height: 62px;
      outline: none;
      color: var(--surface-high);
      caret-color: var(--primary-80);
      -webkit-appearance: none; // for hide default icon

      &::-webkit-date-and-time-value {
        text-align: left;
      }

      // for hide default icon
      &::-webkit-inner-spin-button,
      &::-webkit-calendar-picker-indicator {
        display: none;
        -webkit-appearance: none;
      }
    }
    &.focus {
      border-color: var(--primary-80);
      box-shadow: 0px 2px 8px 4px rgba(var(--primary-100-rgb), 0.05);
      filter: drop-shadow(0px 0px 8px rgba(var(--primary-100-rgb), 0.1));
      // box-shadow: 0px 2px 8px 4px var(--primary-5);
      // filter: drop-shadow(0px 0px 8px var(--primary-10));
      border-radius: 8px;

      &.error {
        box-shadow: 0px 2px 8px 4px rgba(var(--error-100-rgb), 0.05);
        filter: drop-shadow(0px 0px 8px rgba(var(--error-100-rgb), 0.1));
      }
    }

    &.readonly {
      background-color: var(--gray-100);
      border: 1px solid var(--surface-disabled);

      input {
        color: var(--surface-medium);
      }
    }

    &.error {
      border-color: var(--error-100);
    }

    &.password {
      input {
        padding: 0 32px 0 24px;
      }
    }

    .icon-append {
      margin-right: 29px;
      cursor: pointer;
    }
    .icon-password {
      position: absolute;
      right: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    input[type='password'] {
      font-family: -apple-system, var(--font-family);
    }
  }

  .text-error {
    margin-top: 8px;
    font-size: 12px;
    line-height: 12px;
    color: var(--error-100);
    inline-size: max-content;
  }
}
</style>

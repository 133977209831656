<template>
  <section id="google_place__autocomplete__wrapper">
    <!-- <component :is="gpRender" /> -->
    <!-- {{ gpRender }} -->
    <template v-if="gpStatus === 'SUCCESS'">
      <slot>ADDRESS SUCCESS</slot>
    </template>
    <template v-else-if="gpStatus === 'FAIL'">
      <slot>ADDRESS FAIL</slot>
    </template>
    <template v-else-if="gpStatus === 'LOADING'">
      <img src="@/assets/Loading_32px.svg" width="32px" />
    </template>
  </section>
</template>
<script>
import { initGooglePlace } from './googlePlacesAutoComplete';

export default {
  data() {
    return { gpStatus: 'LOADING' };
  },
  created() {
    this.$log.debug('GooglePlaceWrapper #created');
    initGooglePlace(this.googlePlaceSuccess, this.googlePlaceFail);
  },
  methods: {
    googlePlaceSuccess() {
      this.$log.debug('googlePlaceSuccess');
      this.$set(this, 'gpStatus', 'SUCCESS');
    },
    googlePlaceFail() {
      this.$log.debug('googlePlaceFail');
      this.$set(this, 'gpStatus', 'FAIL');
    },
  },
};
</script>
<style lang="scss" scoped></style>

<template>
  <div class="linkedlist__container">
    <v-bottom-sheet scrollable inset v-model="show">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :class="[
            'linkedlist__field__button',
            { selected: selectedItem.name !== '선택' },
          ]"
          v-bind="attrs"
          v-on="on"
          :ripple="false"
          @click="onClickField"
          >{{ selectedItem.name }}</v-btn
        >
      </template>
      <div class="linkedlist__field__list__wrapper">
        <div />
        <div style="overflow: auto; height: 100%">
          <v-list class="linkedlist__field__list__container">
            <v-list-item
              v-if="displayTree.name !== 'root'"
              :ripple="false"
              class="linkedlist__field__list__item prev"
              @click="onClickPrevItem"
            >
              <img src="@/assets/back_32.svg" alt="back" class="icon-prev" />
              {{ displayTree.name }}
            </v-list-item>
            <v-list-item
              v-for="item in displayTree.tree"
              :key="item.name"
              :ripple="false"
              class="linkedlist__field__list__item"
              @click="onClickItem(item)"
            >
              {{ item.name }}
              <CheckIcon
                v-if="selectedItem.name === item.name"
                :color="primaryColor"
                :st="{ width: '30px', height: '30px' }"
              />
            </v-list-item>
          </v-list>
        </div>
      </div>
    </v-bottom-sheet>
  </div>
</template>
<script>
import CheckIcon from './DynamicCt_imgs/CheckIcon.vue';
export default {
  components: {
    CheckIcon,
  },
  props: {
    field: Object,
    value: { type: String, default: '' },
    primaryColor: {
      type: String,
      default: '#373EFF',
    },
  },
  data: function () {
    return {
      show: false,
      selectedItem: { name: '선택', score: 0 },
      parentItem: null,
      root: { name: '', score: 0, tree: [] },
      displayTree: { name: '', score: 0, tree: [] },
      names: [],
    };
  },
  mounted() {
    const values = JSON.parse(this.value ? this.value : '["선택"]');
    const root = { name: 'root', score: 0, tree: [...this.field.tree] };
    this.$set(this, 'root', root);
    this.$set(this, 'displayTree', root);
    this.$set(this, 'selectedItem', {
      name: this.value.length > 0 ? values[values.length - 1] : '선택',
      score: 0,
    });
  },
  methods: {
    onClickField() {
      this.$set(this, 'displayTree', this.root);
      this.$set(this, 'names', []);
    },
    onClickPrevItem() {
      this.$log.debug('onClickPrevItem', {
        displayTree: { ...this.displayTree },
        parentItem: { ...this.parentItem },
      });
      const displayTree = this.parentItem
        ? { ...this.parentItem }
        : { ...this.root };

      this.$set(this, 'names', this.names.slice(0, this.names.length - 1));
      this.$set(this, 'displayTree', displayTree);
      this.$set(this, 'parentItem', null);
    },
    onClickItem(item) {
      this.$log.debug('onClickItem', { item });

      this.$set(this, 'names', [...this.names, item.name]);

      if (item.tree.length) {
        this.$set(this, 'parentItem', { ...this.displayTree });
        this.$set(this, 'displayTree', item);
      } else {
        this.$set(this, 'show', false);
        this.$set(this, 'selectedItem', { ...item });
        this.$emit('selected', { item, field: this.field, names: this.names });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.linkedlist__container {
  .v-btn.linkedlist__field__button {
    width: 100%;
    background-color: var(--surface-100);
    box-shadow: none;
    border: 1px solid var(--gray-300);
    border-radius: 8px;
    padding: 16px 24px;
    height: auto;
    justify-content: start;
    color: var(--surface-medium);
    font-size: 20px;
    font-weight: 400;
    &.selected {
      color: var(--surface-high);
    }
    &:hover:before,
    &:focus:before {
      opacity: 0;
    }
  }
}

.linkedlist__field__list__wrapper {
  background-color: var(--surface-100);
  border-radius: 8px 8px 0 0;
  position: relative;

  & > div:first-child {
    padding: 16px;
    &::after {
      content: '';
      display: block;
      height: 4px;
      width: 60px;
      border-radius: 2px;
      background: #c4c4c4;
      margin: 0 auto;
    }
  }

  .linkedlist__field__list__container {
    overflow: auto;
    height: calc(100% - 36px);
    position: relative;
    padding: 0 0 8px 0;
    // min-height: 626px;
    // .v-list-item.v-list-item--link {
    .v-list-item.linkedlist__field__list__item {
      font-size: 20px;
      font-weight: 400;
      padding: 16px 24px;
      justify-content: space-between;
      color: var(--surface-high);

      &.prev {
        align-items: center;
        justify-content: initial;
        gap: 8px;
        position: sticky;
        top: 0;
        background-color: var(--surface-100);
        z-index: 1;
      }
      &:after {
        content: unset;
      }

      img {
        height: 28px;
        width: 28px;
        color: var(--primary-100);
      }
    }
  }
}
</style>

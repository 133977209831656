<template>
  <v-dialog :value="show" max-width="384px" persistent>
    <v-card>
      <v-card-text :id="id" :class="{ disabled: disabledProp }">
        <div class="content">
          <div class="title-text">{{ title }}</div>
          <p class="message-text" v-html="message" />
        </div>
        <div class="button-container">
          <div class="button ok" @click="$emit('ok')">
            {{ $t('버튼.확인') }}
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'FileUploadErrorDialog',
  props: {
    show: {
      type: Boolean,
      required: true,
      default: false,
    },
    errorType: {
      type: String,
      required: true,
      default: '',
    },
    id: {
      type: String,
    },
    disabledProp: {
      type: Boolean,
    },
    ACCEPTED_FILE_SIZE: { type: Number },
  },
  computed: {
    title() {
      switch (this.errorType) {
        case 'size':
          return this.$t('에러.용량 초과');
        case 'format':
          return this.$t('에러.파일 형식 오류');
        case 'invalid-image':
          return this.$t('에러.파일 형식 오류');
        case 'invalid-ocr':
          return this.$t('에러.OCR 실패');
        default:
          return '';
      }
    },
    message() {
      switch (this.errorType) {
        case 'size':
          return this.$t('에러.첨부 가능한 파일 용량을 초과하였습니다.', {
            maxSize: `${this.ACCEPTED_FILE_SIZE}MB`,
          });
        case 'format':
          return this.$t('에러.지원하지 않는 형식의 파일입니다.');
        case 'invalid-image':
          return this.$t('에러.파일의 확장자와 형식이 일치하지 않습니다.');
        case 'invalid-ocr':
          return this.$t('에러.신분증 정보를 읽을 수 없습니다.<br />올바른 파일을 첨부해 주세요.');
        default:
          return '';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-card__text {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;

  .content {
    margin: 36px 0 28px;
    text-align: center;
  }
  .title-text {
    font-size: 20px;
    line-height: 32px;
    font-weight: 500;
    color: var(--surface-high);
    margin-bottom: 4px;
  }

  .message-text {
    font-size: 16px;
    line-height: 26px;
    color: var(--surface-high);
  }
}
.button-container {
  display: flex;
  margin: 0px 30px 32px 30px;

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    font-weight: 500;
    font-size: 1rem;
    height: 60px;
    cursor: pointer;
    user-select: none;

    &.ok {
      flex-grow: 1;
      color: var(--font-color);
      background: var(--primary-100);
    }
  }
}
</style>

import { Loader } from '@googlemaps/js-api-loader';

const API_KEY = process.env.VUE_APP_GOOGLE_PLACE_PRIVATE_KEY || '';

export function initGooglePlace(successCallback, failureCallback) {
  const loader = new Loader({ apiKey: API_KEY, libraries: ['places'] });
  loader.load().then(successCallback, failureCallback);
}

export function initGoogleAutoComplete(inputRef, countryCode = 'KR', fn) {
  const instance = new window.google.maps.places.Autocomplete(inputRef, {
    fields: ['formatted_address', 'name', 'address_components', 'vicinity'],
    componentRestrictions: { country: [countryCode] },
  });

  instance.addListener('place_changed', () => {
    const place = instance.getPlace();
    fn(place);
  });
  return instance;
}

export function initGoogleAutoCompleteService(onQuerySuccess) {
  const service = new window.google.maps.places.AutocompleteService();

  const onSuccess = (predictions, status) => {
    if (
      status === window.google.maps.places.PlacesServiceStatus.OK &&
      predictions
    ) {
      onQuerySuccess(predictions.map((p) => p.description));
    }
  };
  const getQuery = (keyword, countryCode = 'KR') => {
    service.getPlacePredictions(
      { input: keyword, componentRestrictions: { country: [countryCode] } },
      onSuccess
    );
  };

  return getQuery;
}

<template>
  <div
    style="
      display: flex;
      width: 58px;
      justify-content: center;
      align-items: center;
    "
  >
    <img src="@/assets/Loading_40px.svg" width="35px" />
    <!-- <div class="stage">
      <div class="dot-stretching" />
    </div> -->
  </div>
</template>
<script>
export default {
  name: 'loading-3dot',
  props: {
    loading: Boolean,
    // default: false,
  },
};
</script>
<style lang="scss" scoped>
.dot-stretching {
  position: relative;
  width: 8px;
  height: 8px;
  border-radius: 5px;
  background-color: #fff;
  /* border: 1px solid black; */
  color: #fff;
  transform: scale(1.25, 1.25);
  animation: dotStretching 2s infinite ease-in;
}

.dot-stretching::before,
.dot-stretching::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0px);
}

.dot-stretching::before {
  width: 8px;
  height: 8px;
  border-radius: 5px;
  background-color: #fff;
  color: #fff;
  animation: dotStretchingBefore 2s infinite ease-in;
  left: -4px;
  transform: translateX(-4px);
}

.dot-stretching::after {
  width: 8px;
  height: 8px;
  border-radius: 5px;
  background-color: #fff;
  color: #fff;
  right: 4px;
  animation: dotStretchingAfter 2s infinite ease-in;

  transform: translateX(-4px);
}

@keyframes dotStretching {
  0% {
    transform: scale(1.25, 1.25);
  }
  50%,
  60% {
    transform: scale(0.8, 0.8);
  }
  100% {
    transform: scale(1.25, 1.25);
  }
}

@keyframes dotStretchingBefore {
  0% {
    transform: translate(0) scale(0.7, 0.7);
  }
  50%,
  60% {
    transform: translate(-16px) scale(1, 1);
  }
  100% {
    transform: translate(0) scale(0.7, 0.7);
  }
}

@keyframes dotStretchingAfter {
  0% {
    transform: translate(0) scale(0.7, 0.7);
  }
  50%,
  60% {
    transform: translate(16px) scale(1, 1);
  }
  100% {
    transform: translate(0) scale(0.7, 0.7);
  }
}
</style>

<template>
  <div class="card-result-fail-root" id="pcFileUploadDisabled_container">
    <div class="card-result-fail-info-container">
      <Face_FailVue
        width="103px"
        style="margin-bottom: 40px; margin-left: 10px"
      />
      <div class="title">{{ $t('온보딩.지원하지 않는 모드') }}</div>
      <div
        class="message"
        v-html="
          $t(
            '온보딩.PC 모드를 지원하지 않습니다.<br />모바일에서 다시 시도해주세요.'
          )
        "
      />
    </div>
    <div
      id="pcFileUploadDisabled_submit_button"
      class="button-container"
      @click="$emit('start')"
    >
      {{ $t('버튼.확인') }}
    </div>
  </div>
</template>

<script>
import Face_FailVue from './DynamicCt_imgs/Face_Fail.vue';

export default {
  components: { Face_FailVue },
  name: 'PcFileUploadDisabledScreen',
};
</script>

<style lang="scss" scoped>
.card-result-fail-root {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.card-result-fail-info-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-grow: 1;

  .title {
    font-weight: bold;
    font-size: 28px;
    line-height: 36px;
    margin-bottom: 24px;
  }

  .message {
    font-weight: normal;
    text-align: center;
    font-size: 16px;
    line-height: 26px;
    color: var(--surface-high);
    margin-bottom: 16px;
  }
}

.button-container {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 1rem;
  width: calc(100% - 60px);
  color: var(--font-color);
  background-color: var(--primary-100);
  border-radius: 8px;
  height: 60px;
  margin: 0 30px 48px;
}
</style>

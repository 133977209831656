<template>
  <div class="input-checkbox-field-root">
    <div class="input-checkbox-field-container">
      <label>
        <template v-if="position === 'left'">{{ label }}</template>
        <input
          ref="checkbox"
          type="checkbox"
          :value="value"
          @input="changeHandler"
        />
        <Icon_Check24_Square :checked="value" />
        <template v-if="position === 'right'">{{ label }}</template>
      </label>
    </div>
  </div>
</template>
<script>
import Icon_Check24_Square from '../mobile/auth/DynamicCt_imgs/Icon_Check24_Square.vue';
export default {
  components: {
    Icon_Check24_Square,
  },
  props: {
    value: { type: Boolean, default: false },
    label: { type: String, default: '체크박스' },
    position: { type: String, default: 'right' },
  },
  methods: {
    changeHandler(event) {
      const { checked } = event.target;
      this.$emit('change', checked);
      this.$emit('input', checked);
    },
  },
};
</script>
<style lang="scss">
.input-checkbox-field-root {
  .input-checkbox-field-container {
    display: inline-block;

    label {
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 4px;
      input {
        appearance: none;
        display: none;
      }
    }
  }
}
</style>

<template>
  <v-dialog :value="true" max-width="384px" persistent>
    <v-card>
      <v-card-text :id="id" :class="{ disabled: disabledProp }">
        <div class="edit-expiry-date-container">
          <div class="title-text">
            {{ $t('신분증.적성검사(면허증 갱신) 기간 확인') }}
          </div>
          <div class="title-description">
            <div>{{ $t('신분증.운전면허증의 적성 검사(면허증 갱신)') }}</div>
            <div>{{ $t('신분증.기간이 정확한지 확인해주세요.') }}</div>
            <div>{{ $t('신분증.정확하지 않다면 날짜를 수정해주세요.') }}</div>
          </div>
          <div class="guide-image">
            <img src="@/assets/guide-driver-expiry.svg" />
          </div>
          <div class="card-result-label">
            {{ $t('신분증.면허증 갱신 (적성일자) 마감일자') }}
          </div>
          <InputTextField
            ref="expiryDate2"
            v-model="ocr.expiryDate2"
            :rules="[rules.required, rules.date, rules.expiryDate]"
            @onupdate="validateForm"
            @blur="onBlur"
            :transform="rules.transformDateFormat"
            :onblurTransform="rules.onblurTransformDateFormat"
            :placeholder="$t('placeholder.마감일자 8자리를 입력하세요.')"
            fontSize="1rem"
            :errorMessage="errorMessageName"
            class="expiry-date-input"
          />
          <InputCheckboxField
            class="expiry-date-check"
            :label="$t('신분증.마감일자를 확인하였습니다.')"
            v-model="checked"
            @change="onCheckboxChange"
          />
        </div>
        <div class="button-container">
          <div v-ripple @click="cancel" class="button cancel">
            {{ $t('버튼.취소') }}
          </div>
          <div
            v-ripple
            @click="ok"
            :class="{ button: true, next: true, disabled }"
          >
            {{ $t('버튼.확인 완료') }}
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import InputTextField from '../auth/InputTextField';
import InputCheckboxField from '../../common/InputCheckboxField.vue';
import rules from '../rules';

export default {
  name: 'EditExpiryDateDialog',
  components: {
    InputTextField,
    InputCheckboxField,
  },
  props: {
    show: {
      type: Boolean,
      // required: true,
      default: false,
    },
    ocr: {
      type: Object,
    },
    id: {
      type: String,
    },
    disabledProp: {
      type: Boolean,
    },
  },
  data() {
    return {
      rules,
      disabled: true,
      checked: false,
    };
  },
  mounted() {
    this.$log.debug('EditExpiryDateDialog.vue #mounted', {
      ocr: this.ocr,
      value: this.value,
    });
  },
  computed: {
    errorMessageName() {
      if (this.errorDriverExpiryDate) {
        return this.$t('신분증.만료된 마감일자입니다. 확인 후 수정해주세요.');
      }
      return this.$t('신분증.마감일자를 입력해주세요.');
    },
    errorDriverExpiryDate() {
      const now = new Date();

      const nowYear = now.getFullYear();
      const nowMonth = now.getMonth();
      const nowDate = now.getDate();

      const nowExpirePeriod = new Date(nowYear, nowMonth, nowDate); // 정각 구하려고 표현
      const inputExpiryDate = new Date(this.ocr.expiryDate2);

      return nowExpirePeriod.getTime() > inputExpiryDate.getTime();
    },
  },
  methods: {
    onCheckboxChange() {
      this.validateForm();
    },
    validateForm() {
      this.$nextTick(() => {
        if (this.ocr.expiryDate2) {
          const result = this.$refs.expiryDate2.valid
            ? this.$refs.expiryDate2.validFlag
            : true;
          this.disabled = !result || !this.checked;
        } else {
          this.disabled = true;
        }
      });
    },
    cancel() {
      this.$emit('cancel');
    },
    ok() {
      if (!this.disabled) {
        this.$emit('ok');
      }
    },
    onBlur() {},
  },
};
</script>

<style lang="scss" scoped>
#editExpiryDateDialog.v-card__text {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  width: 100%;

  .edit-expiry-date-container {
    padding: 32px 30px 40px;
    color: var(--surface-high);
  }

  .title-text {
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 2rem;
    text-align: center;
    color: var(--surface-high);
    margin-bottom: 32px;
  }

  .title-description {
    font-size: 16px;
    margin-bottom: 32px;
    & > div {
      text-align: center;
    }
  }

  .guide-image {
    display: flex;
    padding: 8px;
    // background-color: #e5e5e5;
    background-color: var(--gray-200);
    margin-bottom: 32px;
    img {
      width: 100%;
      height: 100%;
      min-height: 100px;
    }
  }

  .card-result-label {
    font-size: 16px;
    color: var(--surface-medium);
  }

  .expiry-date-input {
    margin-top: 8px;
  }

  .expiry-date-check {
    margin-top: 16px;
  }
}
.button-container {
  display: flex;
  margin-bottom: 30px;
  padding: 0 30px;

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    font-weight: 500;
    font-size: 1rem;
    height: 60px;
    cursor: pointer;
    user-select: none;

    &.cancel {
      flex-grow: 3.5;
      background: var(--gray-100);
      color: var(--surface-medium);
      margin-right: 10px;
    }
  }

  .next {
    flex-grow: 6.5;
    background-color: var(--primary-100);
    color: var(--font-color);
  }

  .disabled {
    background-color: var(--primary-20);
  }
}

@media screen and (max-width: 480px) {
  #editExpiryDateDialog.v-card__text {
    .title-text {
      font-size: 18px;
    }
    .title-description {
      font-size: 14px;
    }
    .card-result-label {
      font-size: 14px;
    }
  }
}
</style>

<template>
  <div class="additional-input-fields-container">
    <article v-for="field in eddField" :key="`${field.id}_${field.name}`">
      <p class="additional-input-label">
        {{ getDisplayName(field) }}
      </p>
      <p class="additional-input-label description" v-if="field.description">
        {{ getDescription(field) }}
      </p>
      <template v-if="field.type === 'tree'">
        <LinkedList
          :field="field"
          :primaryColor="appData.colorPalette.tint_100"
          @selected="onTreeSelected"
        />
      </template>
      <template v-else-if="field.type === 'ox_addr'">
        <EddFieldAddr
          :field="field"
          @change="onOxAddrChange"
          :nations="appData.countries"
        />
      </template>
      <template v-else-if="field.type === 'ox'">
        <RadioField :field="field" @change="onOxSelected">
          <div v-for="item in field.tree" :key="`${item.name}`">
            <div :value="item.name" :score="item.score">{{ item.name }}</div>
          </div>
        </RadioField>
      </template>
      <template v-else-if="field.type === 'country'">
        <CountryField
          :field="field"
          :nations="appData.countries"
          @change="onCountryChange"
        />
      </template>
    </article>
  </div>
</template>
<script>
import LinkedList from './LinkedList.vue';
import RadioField from './RadioField.vue';
import CountryField from './CountryField.vue';
import EddFieldAddr from './EddFieldAddr.vue';

export default {
  components: {
    LinkedList,
    RadioField,
    CountryField,
    EddFieldAddr,
  },
  props: { eddField: { type: Array, default: () => [] }, appData: Object },
  data: function () {
    return {
      selectedEddFieldList: [],
    };
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      const { eddField } = this;
      const selectedEddFieldList = eddField.map((field) => {
        const { category, name, id } = field;
        return { category, name, id, result: null };
      });
      this.$set(this, 'selectedEddFieldList', selectedEddFieldList);
    },
    getDisplayName(field) {
      return field.display_name ? field.display_name : field.name;
    },
    getDescription(field) {
      return field.description;
    },
    onTreeSelected({ field, item, names }) {
      const { category, id, name, type } = field;
      const { score } = item;
      const value = JSON.stringify(names);
      const selected = { category, id, score, type, result: { key: name, value } }; // prettier-ignore
      this.handleUpdate(selected);
    },
    onOxAddrChange({ field, item }) {
      const { category, id, name, type } = field;
      const { score, value, nation_info } = item;
      const selected = { category, id, score,type,  result: nation_info ? { key: name, value, nation_info } : null }; // prettier-ignore
      this.handleUpdate(selected);
    },
    onOxSelected({ field, item }) {
      const { category, id, name, type } = field;
      const { score, value } = item;
      const selected = { category, id, score,type,  result: { key: name, value } }; // prettier-ignore
      this.handleUpdate(selected);
    },
    onCountryChange({ field, item }) {
      const { category, id, name, type } = field;
      const { score, name_kr, id: country_id, iso_code3 } = item;
      const selected = { category, id, score, type, result: { key: name, value: name_kr, country_id, iso_code3 } }; // prettier-ignore
      this.handleUpdate(selected);
    },
    handleUpdate(updatedField) {
      const selectedEddFieldList = this.selectedEddFieldList.map((field) => field.id === updatedField.id ? { ...field, ...updatedField } : field ); // prettier-ignore
      this.$set(this, 'selectedEddFieldList', selectedEddFieldList);
      this.$emit('update', selectedEddFieldList);
    },
  },
};
</script>
<style lang="scss" scoped>
.additional-input-fields-container {
  article {
    margin-bottom: 24px;

    .additional-input-label {
      font-size: 16px;
      color: var(--surface-medium);
      margin-bottom: 8px;

      &.description {
        font-size: 14px;
      }
    }
  }
}
</style>

<template>
  <div
    class="container"
    id="verifyTryOut_container"
    style="
      padding: 0 30px 40px;
      flex: 1;
      display: flex;
      flex-direction: column;
      height: 100%;
    "
    :style="appData.hasLogo ? 'padding-top:32px;' : ''"
  >
    <div class="card-result-title">{{ $t('계좌.계좌인증') }}</div>
    <div class="openbank-spacer" style="padding-top: 100px" />
    <div class="body-container" style="text-align: center; margin-bottom: 0rem">
      <Verify_FailVue
        max-width:234
        max-height:181
        style="margin: 0 auto"
        :transition="false"
      />
      <h2 class="bank-title">{{ $t('신분증.본인 인증 실패') }}</h2>
      <div v-if="appData.errorType === 'tryout'">
        <label
          class="bank-label"
          v-html="
            $t(
              '계좌.입력코드 입력 횟수가 초과되었습니다.<br />인증코드를 다시 요청 해주세요.'
            )
          "
        />
        <div class="card-count warn">{{ $t('신분증.시도 횟수') }} : 3/3</div>
      </div>
      <div v-else>
        <label class="bank-label">{{
          $t('계좌.유효시간 만료로 인해 인증에 실패하였습니다.')
        }}</label>
      </div>
    </div>
    <div class="button-container">
      <v-btn
        id="verifyTryOut_cancel_button"
        @click="onClickBack"
        block
        color="var(--primary-100)"
      >
        {{ $t('버튼.종료') }}
      </v-btn>
    </div>
  </div>
</template>
<script>
import Verify_FailVue from '../auth/DynamicCt_imgs/Verify_Fail.vue';

export default {
  components: { Verify_FailVue },
  props: {
    appData: Object,
  },
  data() {
    return {};
  },
  mounted() {
    this.$log.debug(this.appData.errorType);
  },
  methods: {
    onClickBack() {
      this.$emit('cancel');
    },
  },
};
</script>

<style lang="scss" scoped>
@media (min-width: 800px) {
  .container {
    width: 800px !important;
    margin: auto !important;
  }
}
.body-container {
  .bank-title {
    font-style: normal;
    font-weight: bold;
    font-size: 1.5rem;
    line-height: 42px;

    color: var(--surface-high);
    margin-top: 30px;
    margin-bottom: 1.5rem;
  }
  .bank-label {
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 26px;

    color: var(--surface-high);
  }
}
.card-result-title {
  font-size: 20px;
  line-height: 32px;
  font-weight: 500;
  margin-top: 32px;
  text-align: center;
}
.card-count {
  /* KR / Body 3 - 16 */

  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  color: var(--surface-medium);
  margin-top: 8px;

  &.warn {
    color: var(--error-100);
  }
}

.button-container {
  margin-top: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0px 0px 40px;
  /* height: 116px; */
  cursor: pointer;
  background-color: white;
  button {
    height: 60px !important;
    font-size: 1rem;
    border-radius: 8px;
    color: var(--font-color);
    flex: 1;
  }
}
</style>

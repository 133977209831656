<template>
  <ViewContainer
    id="cardNotMatched_container"
    :paddingTop="appData.hasLogo ? 48 : 32"
  >
    <img
      @click="stopScan = true"
      src="@/assets/icon-close-32.svg"
      class="icon-close"
      :style="appData.hasLogo ? 'top:48px' : 'top:32px;'"
    />
    <div class="card-not-matched-title">
      {{ $t('온보딩.신분증 인증') }}
    </div>
    <div
      class="card-not-matched-info1"
      v-html="
        $t('신분증.고객님의 기본 정보를 확인해주세요. 올바른 정보인가요?')
      "
    ></div>
    <div class="card-not-matched-container">
      <div class="flex-row title">
        <div>{{ $t('신분증.고객 정보') }}</div>
      </div>
      <div v-if="showNameInput" class="flex-row name">
        <div class="label">{{ $t('개인정보입력.이름') }}</div>
        <div class="content">{{ showNameInputValue }}</div>
      </div>
      <div v-if="showBirthdayInput" class="flex-row">
        <div class="label">{{ $t('개인정보입력.생년월일') }}</div>
        <div class="content">{{ showBirthdayInputValue }}</div>
      </div>
    </div>
    <div v-if="!appData.userInputPass" class="card-not-matched-subtext">
      <img src="@/assets/info_20.svg" />
      <span>{{
        $t(`신분증.잘못된 정보라면 '아니오'를 눌러 직접 수정해 주세요.`)
      }}</span>
    </div>
    <div class="button-container">
      <div
        v-ripple
        @click="onClickDecline"
        id="cardNotMatched_cancel"
        class="button decline"
      >
        {{ $t('버튼.아니오') }}
      </div>
      <div
        v-ripple
        @click="onClickAccept"
        id="cardNotMatched_submit"
        class="button accept"
      >
        {{ $t('버튼.맞습니다') }}
      </div>
    </div>
    <EditInfoDialog
      :show="showEditInfoDialog"
      :editType="editType"
      :editFields="editFields"
      @ok="onEditComplete($event)"
      :disabledProp="!showEditInfoDialog"
      id="cardNotMatched_editInfoPopup"
    />
    <ExitDialog
      v-model="stopScan"
      @ok="onClickBack"
      id="cardNotMatched_exitPopup"
      :disabledProp="!stopScan"
    ></ExitDialog>
    <CustomerParameterContactInfoDialog
      :show="showCustomerParameterContactInfoDialog"
      @ok="onCloseCustomerParameterContactInfoDialog"
      id="cardNotMatched_customParameterContactInfoPopup"
      :disabledProp="!showCustomerParameterContactInfoDialog"
    />
  </ViewContainer>
</template>

<script>
import { mapState } from 'vuex';
import CustomerParameterContactInfoDialog from '../dialog/CustomerParameterContactInfoDialog';
import EditInfoDialog from '../dialog/EditInfoDialog';
import ExitDialog from '../dialog/ExitDialog';
import ViewContainer from '../../../layout/auth/ViewContainer.vue';

export default {
  components: {
    CustomerParameterContactInfoDialog,
    EditInfoDialog,
    ViewContainer,
    ExitDialog,
  },
  props: {
    appData: Object,
    /**
     * emit events
     * cancel
     * next
     */
  },
  data: function () {
    return {
      stopScan: false,
      showEditInfoDialog: false,
      editType: '',
      editFields: [],
      showCustomerParameterContactInfoDialog: false,
      showNameInput: false,
      showNameInputValue: '',
      showBirthdayInput: false,
      showBirthdayInputValue: '',
    };
  },
  computed: {
    ...mapState(['companyPhoneNumber']),
  },
  mounted() {
    this.$log.debug('CardNotMatched#mounted appData', this.appData);

    if (this.appData.userName !== this.appData.ocr.userName) {
      this.showNameInput = true;
      this.showNameInputValue = this.appData.userName;
    }
    if (this.appData.birthDate !== this.appData.scannedFormattedDate) {
      this.showBirthdayInput = true;
      this.showBirthdayInputValue = this.appData.birthDate;
    }
  },
  methods: {
    initialize() {},
    onClickBack() {
      this.$emit('cancel');
    },
    onClickEdit(type) {
      this.editType = type;
      this.editFields = [];
      if (this.appData.userName !== this.appData.ocr.userName) {
        this.editFields.push('name');
      }
      if (this.appData.birthDate !== this.appData.scannedFormattedDate) {
        this.editFields.push('birthdate');
      }
      this.showEditInfoDialog = true;
    },
    onEditComplete(payload) {
      if (payload) {
        this.$log.debug('CardNotMatched#onEditComplete', payload);
        this.$emit('next', {
          userinfoChanged: true,
          updatedName: payload.userName,
          updatedBirthDate: payload.birthDate,
          updatedEditType: payload.editType,
          updatedFields: payload.updatedFields,
        });
      }

      this.showEditInfoDialog = false;
    },
    onClickDecline() {
      if (!this.appData.identification_config_enabled) {
        this.showCustomerParameterContactInfoDialog = true;
      }
      this.onClickEdit('user-info');
    },
    onCloseCustomerParameterContactInfoDialog() {
      this.showCustomerParameterContactInfoDialog = false;
      this.$emit('cancel');
    },
    onClickAccept() {
      let emitPayload = {
        userinfoChanged: false,
        updatedEditType: 'id-result',
        updatedFields: [],
      };
      if (this.appData.userName !== this.appData.ocr.userName) {
        emitPayload.updatedFields.push('name');
        emitPayload.updatedName = this.appData.userName;
      }
      if (this.appData.birthDate !== this.appData.scannedFormattedDate) {
        emitPayload.updatedFields.push('birthdate');
        emitPayload.updatedBirthDate = this.appData.birthDate;
      }
      this.$emit('next', emitPayload);
    },
  },
};
</script>

<style lang="scss" scoped>
.root-container {
  position: relative;
  display: flex;
  flex-direction: column;
  color: var(--surface-high);
  align-items: center;
}

.icon-close {
  position: absolute;
  width: 32px;
  right: 32px;
  top: 32px;
  cursor: pointer;
}

.card-not-matched-title {
  font-size: 20px;
  line-height: 32px;
  font-weight: 500;
  margin-bottom: 56px;
  text-align: center;
}

.card-not-matched-info1 {
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  text-align: center;
  margin-bottom: 40px;
}

.card-not-matched-info2 {
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: var(--surface-medium);
}

.card-not-matched-container {
  display: flex;
  flex-direction: column;
  padding: 24px 28px;
  max-width: 328px;
  border: 1px solid var(--gray-200);
  border-radius: 8px;
  margin: 0 auto 16px;

  .flex-row {
    display: flex;
    align-items: flex-start;
    margin: 0 4px;
    &.title {
      height: 32px;
      margin-bottom: 16px;
    }

    &.name {
      margin-bottom: 8px;
    }
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 96px;
    height: 32px;
    background: #ebecff;
    border-radius: 16px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: var(--primary-80);
  }

  .label {
    width: 60px;
    min-width: 60px;
    margin-right: 40px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--surface-medium);
  }

  .content {
    font-size: 16px;
    line-height: 24px;
    color: var(--surface-high);
  }

  .user-input-invalid {
    display: flex;
    flex-direction: column;
    padding: 15px 19px;
    background: var(--gray-100);
    border-radius: 8px;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
  }
}

.card-not-matched-subtext {
  display: flex;
  justify-content: center;
  align-content: flex-start;

  & > img {
    margin-right: 8px;
    width: 20px;
    height: 20px;
  }

  & > span {
    font-size: 13px;
  }
}

.button-container {
  margin-top: 56px;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  cursor: pointer;
  background-color: white;

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    font-weight: 500;
    font-size: 1rem;
    height: 60px;
  }

  .decline {
    flex-grow: 3.5;

    margin-right: 10px;
    background-color: var(--gray-100);
    color: var(--surface-medium);
  }

  .accept {
    flex-grow: 6.5;

    background-color: var(--primary-100);
    color: var(--font-color);
  }
}
</style>

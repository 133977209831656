<template>
  <div id="google_place__autocomplete">
    <v-bottom-sheet scrollable inset v-model="nationSheet">
      <template v-slot:activator="{ on, attrs }">
        <button :class="['field__button', { selected: !!nationality }]">
          <input
            type="text"
            :value="nationality"
            v-on="on"
            v-bind="attrs"
            readonly
            placeholder="국가"
          />
        </button>
      </template>
      <div class="field__list__wrapper">
        <div />
        <div style="overflow: auto; height: 100%; position: relative">
          <v-list class="field__list__container" style="min-height: 736px">
            <v-list-item :ripple="false" class="field__search">
              <InputTextField
                style="width: 100%"
                v-model="nationalitySearch"
                :append="focus ? iconClear : ''"
                @focus="onFocus"
                @blur="onBlur"
                @append="nationalitySearch = ''"
                placeholder="국가"
              />
            </v-list-item>
            <v-list-item
              v-for="nation in nationList"
              :key="`${nation.iso_code3}_${nation.name_kr}`"
              :ripple="false"
              @click="onNationClick(nation)"
            >
              {{ nation.name_kr }}
            </v-list-item>
          </v-list>
        </div>
      </div>
    </v-bottom-sheet>
    <v-bottom-sheet scrollable inset v-model="addrSheet">
      <template v-slot:activator="{ on, attrs }">
        <button
          :class="['field__button', { selected: !!address }]"
          @click="address === '' ? (googlePlaceList = []) : ''"
        >
          <input
            type="text"
            :value="address"
            v-on="on"
            v-bind="attrs"
            readonly
            placeholder="주소"
          />
        </button>
      </template>
      <div class="field__list__wrapper">
        <div />
        <div style="overflow: auto; height: 100%">
          <v-list class="field__list__container">
            <v-list-item :ripple="false" class="field__search">
              <InputTextField
                ref="addrRef"
                style="width: 100%"
                v-model="addressSearch"
                :append="focus ? iconClear : ''"
                :triggerOnBlur="false"
                @focus="onFocus"
                @blur="onBlur"
                @onupdate="onQuerySearch"
                @append="
                  () => {
                    this.address = '';
                    this.addressSearch = '';
                    this.subAddress = '';
                    this.googlePlaceList = [];
                  }
                "
                errorMessage="주소를 입력해주세요."
                placeholder="주소"
              />
            </v-list-item>
            <v-list-item
              v-for="(place, idx) in googlePlaceList"
              :key="`${place}_${idx}`"
              :ripple="false"
              @click="onPlaceClick(place)"
            >
              {{ place }}
            </v-list-item>
          </v-list>
        </div>
      </div>
    </v-bottom-sheet>
    <InputTextField
      style="margin-top: 8px"
      v-model="subAddress"
      :append="focus ? iconClear : ''"
      @focus="onFocus"
      @blur="onBlur"
      @onupdate="onSubAddrChange"
      @append="subAddress = ''"
      placeholder="상세주소"
    />
  </div>
</template>
<script>
import { initGoogleAutoCompleteService } from './googlePlacesAutoComplete';
import rules from '../../rules';
import util from '@/util';
import InputTextField from '../InputTextField';

export default {
  components: {
    InputTextField,
  },
  props: {
    value: {
      type: Object,
      default: function () {
        return { nationality: '', address: '', subAddress: '' };
      },
    },
    append: { type: String, default: '' },
    nations: Array,
  },

  data() {
    return {
      rules,
      iconClear: require('@/assets/delete_24.svg'),
      focus: false,
      allNations: Object.entries(this.nations),
      nationality: '',
      nationalitySearch: '',
      nationCode: 'KOR',
      nationSheet: false,
      address: '',
      addressSearch: '',
      subAddress: '',
      addrSheet: false,
      googlePlaceList: [],
      getQuery: null,
    };
  },
  created() {
    const { nationality, address, subAddress } = this.value;
    this.nationality = nationality;
    this.address = address;
    this.addressSearch = address;
    this.subAddress = subAddress;

    const getQuery = initGoogleAutoCompleteService(this.onQuerySuccess);
    this.$set(this, 'getQuery', util.throttle(getQuery));
  },
  computed: {
    nationList() {
      const filteredNations = this.nations.filter(
        (nation) => nation.name_kr.indexOf(this.nationalitySearch) > -1
      );
      return this.nationalitySearch.length ? filteredNations : this.nations;
    },
  },
  watch: {
    value: function (newValue) {
      const { nationality, address, subAddress } = newValue;
      this.nationality = nationality;
      this.address = address;
      this.subAddress = subAddress;
    },
    nationality: function () {
      this.address = '';
      this.subAddress = '';
      this.googlePlaceList = [];
    },
  },
  methods: {
    onFocus() {
      this.focus = true;
      this.$emit('focus');
    },
    onBlur() {
      this.focus = false;
      this.$emit('blur');
    },
    onNationClick(nation) {
      this.nationSheet = false;
      this.nationality = nation.name_kr;
      this.nationalitySearch = '';
      this.nationCode = nation.iso_code3;

      const updatedValue = {
        nationality: nation.name_kr,
        address: '',
        subAddress: '',
      };
      this.$emit('input', updatedValue);
      this.$emit('update');
    },
    onQuerySearch() {
      this.$log.debug('GooglePlaceAutocomplete #onQuerySearch', { addressSearch: this.addressSearch, nationCode: this.nationCode }); // prettier-ignore
      if (this.addressSearch.length) {
        this.getQuery(this.addressSearch, this.nationCode);
      }
    },
    onQuerySuccess(queryResult) {
      this.$log.debug('GooglePlaceAutocomplete #onQuerySuccess', { queryResult }); // prettier-ignore
      this.googlePlaceList = queryResult;
    },
    onPlaceClick(place) {
      this.addrSheet = false;
      this.addressSearch = '';
      this.$log.debug('GooglePlaceAutocomplete #onPlaceClick', { place });
      const updatedValue = { nationality: this.nationality, address: place, subAddress: '',}; // prettier-ignore
      this.$emit('input', updatedValue);
      this.$emit('update');
    },
    onSubAddrChange() {
      const { nationality, address, subAddress } = this;
      const updatedValue = { nationality, address, subAddress };
      this.$emit('input', updatedValue);
      this.$emit('update');
    },
  },
};
</script>
<style lang="scss" scoped>
.field__list__wrapper {
  background-color: var(--surface-100);
  border-radius: 8px 8px 0 0;
  position: relative;
  & > div:first-child {
    padding: 16px;
    &::after {
      content: '';
      display: block;
      height: 4px;
      width: 60px;
      border-radius: 2px;
      background: #c4c4c4;
      margin: 0 auto;
    }
  }
  .v-sheet.field__list__container {
    overflow: auto;
    height: calc(100% - 36px);
    // min-height: calc(100vh - 36px);
    min-height: 538px;
    padding: 0 0 8px 0;

    .field__search {
      position: sticky;
      top: 0px;
      z-index: 1;
      padding: 16px 16px 8px;
      background-color: var(--surface-100);
    }

    .v-list-item.v-list-item--link {
      font-size: 20px;
      font-weight: 400;
      padding: 16px 24px;
      justify-content: space-between;
      img {
        height: 30px;
        width: 30px;
        color: var(--primary-100);
      }
      &:after {
        content: unset;
      }
    }
  }
}
</style>
<style lang="scss">
/* Google Place Autocomplete */
.pac-container {
  .pac-item {
    height: 60px;
    line-height: 60px;
    font-size: 13px;
    .pac-icon {
      margin-top: 20px;
    }
    .pac-item-query {
      font-size: 16px;
    }
  }
}

#google_place__autocomplete {
  .field__button {
    width: 100%;
    background-color: var(--surface-100);
    box-shadow: none;
    border: 1px solid var(--gray-300);
    border-radius: 8px;
    padding: 16px 24px;
    height: auto;
    display: flex;
    justify-content: flex-start;
    color: var(--surface-medium);
    font-size: 20px;
    font-weight: 400;
    outline: none;
    &.selected {
      color: var(--surface-high);
    }
    &:hover:before,
    &:focus:before {
      opacity: 0;
    }
    &:nth-of-type(2) {
      margin-top: 8px;
    }

    input {
      outline: none;
      width: 100%;
    }
  }
  input::placeholder {
    color: var(--surface-medium);
  }
}
</style>

<template>
  <ViewContainer
    :paddingTop="appData.hasLogo ? 48 : 32"
    :paddingHorizontal="30"
    id="user-info-input--container"
    ref="root"
  >
    <div class="card-title-area">
      <div class="empty-div" />
      <div class="card-title-text">{{ $t('개인정보입력.개인정보 입력') }}</div>
      <img
        class="close-icon"
        :style="appData.hasLogo ? 'top:48px' : 'top:32px'"
        width="30"
        src="@/assets/icon-close-32.svg"
        @click="exitDialog = true"
      />
    </div>

    <div class="userinput-label">{{ $t('개인정보입력.이름') }}</div>
    <InputTextField
      v-model="userinfo.userName"
      :rules="[rules.required, errorRuleName, rules.koreanAndUpperEng]"
      :transform="rules.transformOnlyUpperCase"
      :append="clearIconUserName"
      @append="userinfo.userName = ''"
      @onupdate="validateForm"
      @focus="showUsernameClearIcon = true"
      @blur="showUsernameClearIcon = false"
      :maxlength="45"
      :placeholder="$t('placeholder.이름을 입력하세요.')"
      :error="errorInvalidName"
      :errorMessage="
        !userinfo.userName
          ? $t('placeholder.이름을 입력하세요.')
          : errorInvalidName
          ? errorDefaultMessageBirthDate
          : errorDefaultMessageName
      "
      class="userinput-input"
      :id="'userInfoInput_name'"
    />
    <span class="userinput-label userinput-label--detail">
      {{
        $t(
          '개인정보입력.*외국인의 경우 Lastname Firstname Middlename 순으로 입력하세요.'
        )
      }}
    </span>

    <div class="userinput-label">{{ $t('개인정보입력.휴대전화 번호') }}</div>
    <InputTextField
      v-model="userinfo.phoneNumber"
      :rules="[rules.required, rules.phoneNumber]"
      @onupdate="validateForm"
      :transform="rules.transformOnlyNumber"
      type="tel"
      pattern="\d*"
      :append="clearIconPhoneNumber"
      @append="userinfo.phoneNumber = ''"
      @focus="showPhoneNumberClearIcon = true"
      @blur="showPhoneNumberClearIcon = false"
      :maxlength="45"
      :placeholder="$t('placeholder.\'-\'를 제외하고 입력하세요.')"
      :errorMessage="
        !userinfo.phoneNumber
          ? $t('placeholder.휴대전화 번호를 입력하세요.')
          : $t('개인정보입력.입력 형식이 올바르지 않습니다.')
      "
      class="userinput-input"
      :id="'userInfoInput_phone'"
    />
    <div class="userinput-label">{{ $t('개인정보입력.생년월일') }}</div>
    <InputTextField
      :placeholder="$t('placeholder.YYYY-MM-DD')"
      v-model="userinfo.birthDate"
      :error="errorInvalidBirthDate"
      :errorMessage="
        !userinfo.birthDate ? errorDefaultMessageBirthDate : errorInvalidInput
      "
      @keyup="bithDayInputKeyUp"
      :rules="[
        rules.required,
        errorRuleBirthDate,
        rules.date,
        rules.isPastThanFuture,
      ]"
      :transform="rules.transformDateFormat"
      :onblurTransform="rules.onblurTransformDateFormat"
      @onupdate="validateForm"
      class="userinput-input"
      :id="'userInfoInput_bod'"
      type="text"
      pattern="\d*"
    />
    <div class="userinput-label">{{ $t('개인정보입력.이메일') }}</div>
    <InputTextField
      v-model="userinfo.email"
      :rules="[rules.required, rules.email]"
      @onupdate="validateForm"
      :maxlength="45"
      :placeholder="$t('placeholder.example@email.com')"
      :errorMessage="
        !userinfo.email
          ? $t('placeholder.이메일을 입력하세요.')
          : $t('개인정보입력.입력 형식이 올바르지 않습니다.')
      "
      class="userinput-input"
      :id="'userInfoInput_email'"
    />
    <div data-description="fixed-button-area" style="min-height: 56px"></div>
    <div class="button-container">
      <div
        :id="'userInfoInput_button_next'"
        v-ripple
        @click="onClickNext"
        :class="{ button: true, next: true, disabled }"
      >
        {{ $t('버튼.다음') }}
      </div>
    </div>
    <ServerErrorDialog
      id="userInfoInput_server_error_dialog"
      :disabledProp="!serverError"
      v-model="serverError"
      :icon="errorIcon"
      :title="errorMessageTitle"
      :message="errorMessage"
      :errorCode="errorCode"
      :button="errorButton"
      @minorBtn="onClickBack"
      @majorBtn="onClickCloseServerError"
    />
    <ExitDialog
      v-model="exitDialog"
      @ok="$emit('cancel')"
      :disabledProp="!exitDialog"
      id="userInfoInput_exit_dialog"
      @cancel="exitDialog = false"
    />
  </ViewContainer>
</template>

<script>
import InputTextField from './InputTextField';
import ServerErrorDialog from '../dialog/ServerErrorDialog';
import ExitDialog from '../dialog/ExitDialog';
import server from '@/api/server';
import rules from '../rules';
import { mapState } from 'vuex';
import ViewContainer from '../../../layout/auth/ViewContainer.vue';

export default {
  components: {
    InputTextField,
    ServerErrorDialog,
    ExitDialog,
    ViewContainer,
  },
  props: {
    appData: Object,
    /**
     * emit events
     * cancel
     * next
     */
  },
  data: function () {
    return {
      rules,
      userinfo: {
        userName: '',
        phoneNumber: '',
        birthDate: '',
        email: '',
      },
      systemError: false,
      serverError: false,
      errorIcon: {},
      errorMessageTitle: [],
      errorMessage: [],
      errorCode: '',
      errorButton: [],
      disabled: true,
      loading: false,
      iconChecked: require('@/assets/icon-check-green.svg'),
      iconClear: require('@/assets/delete_24.svg'),
      errorInvalidName: false,
      errorInvalidBirthDate: false,
      errorInvalidMessage: '정보가 일치하지 않습니다. 확인 후 수정해 주세요.',
      showUsernameClearIcon: false,
      showPhoneNumberClearIcon: false,
      isBirthDayFutureError: false,
      exitDialog: false,
    };
  },
  computed: {
    ...mapState(['companyPhoneNumber']),
    errorDefaultMessageName() {
      return this.$t('개인정보입력.국문 또는 영문만 입력 가능합니다.');
    },
    errorInvalidInput() {
      return this.$t('개인정보입력.입력 형식이 올바르지 않습니다.');
    },

    errorDefaultMessageBirthDate() {
      return this.$t('개인정보입력.생년월일을 입력해 주세요.');
    },
    clearIconUserName() {
      return this.showUsernameClearIcon && this.userinfo.userName
        ? this.iconClear
        : '';
    },
    clearIconPhoneNumber() {
      return this.showPhoneNumberClearIcon && this.userinfo.phoneNumber
        ? this.iconClear
        : '';
    },
  },
  created() {
    this.$log.debug('UserInfoInput#created appData', this.appData);
    if (this.appData.userName) {
      this.userinfo = {
        userName: this.appData.userName,
        phoneNumber: this.appData.phoneNumber,
        birthDate: this.appData.birthDate,
        email: this.appData.email,
      };
    }
  },
  mounted() {
    // this.userinfo = {
    //   userName: '이보영',
    //   phoneNumber: '01071796841',
    //   birthDate: '1991-12-19',
    //   email: 'ee@ee.com',
    // };
    // this.disabled = false;
    if (this.appData.userName) {
      this.$log.debug('UserInfoInput mounted #', this.appData);
    }
  },
  methods: {
    bithDayInputKeyUp(e) {
      const {
        target: { value },
      } = e;
      if (rules.isPastThanFuture(value)) {
        this.isBirthDayFutureError = true;
      } else {
        this.isBirthDayFutureError = false;
      }
    },
    errorRuleName() {
      return !this.errorInvalidName;
    },
    errorRuleBirthDate() {
      return !this.errorInvalidBirthDate;
    },
    validateForm() {
      this.errorInvalidName = false;
      this.errorInvalidBirthDate = false;
      this.$nextTick(() => {
        if (
          this.userinfo.userName &&
          this.userinfo.phoneNumber &&
          this.userinfo.birthDate &&
          this.userinfo.email
        ) {
          const result = this.$refs.root.$children
            .map((comp) => (comp.valid ? comp.validFlag : true))
            .every((val) => val);
          this.disabled = !result;
        } else {
          this.disabled = true;
        }
      });
    },
    onClickCloseServerError() {
      if (this.systemError) {
        this.onClickBack();
      } else {
        this.serverError = false;
      }
    },
    onClickBack() {
      this.serverError = false;
    },
    async onClickNext() {
      try {
        if (this.disabled || this.loading) return;
        this.loading = true;
        let formData = new FormData();
        formData.append('name', this.userinfo.userName);
        formData.append('phone_number', this.userinfo.phoneNumber);
        formData.append('birthday', this.userinfo.birthDate);
        formData.append('email', this.userinfo.email);
        const data = await server.startApplication(formData);
        this.$emit('next', {
          userName: this.userinfo.userName,
          phoneNumber: this.userinfo.phoneNumber,
          birthDate: this.userinfo.birthDate,
          email: this.userinfo.email,
          transaction_id: data.form.transaction_id,
          progress: data.form.progress,
          module: data.form.module,
        });
        this.loading = false;
      } catch (e) {
        this.$log.debug(e);
        this.loading = false;
        this.systemError = true;
        this.serverError = true;
        this.errorIcon = {};
        this.errorMessageTitle = [
          this.$t('에러.시스템 에러가 발생하였습니다.'),
          this.$t('에러.잠시 후 다시 이용해 주세요.'),
        ];
        this.errorMessage = [
          this.$t('에러.계속해서 문제가 발생한다면'),
          this.$t('에러.고객센터로 문의해주세요.', {
            companyPhoneNumber: this.companyPhoneNumber,
          }),
        ];
        this.errorButton = ['', this.$t('버튼.확인')];
        this.errorCode = e.errorCode
          ? `${this.$t('에러.에러코드')} : ${e.errorCode}`
          : '';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.card-title-area {
  width: 100%;
  display: flex;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  padding: 0px 3px 16px;
  .empty-div {
    height: 10px;
    width: 30px;
  }

  .close-icon {
    cursor: pointer;
  }
  .card-title-text {
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    color: var(--surface-high);
  }
}

.icon-close {
  position: absolute;
  width: 32px;
  right: 32px;
  top: 32px;
  cursor: pointer;
}

.userinput-title {
  font-size: 20px;
  line-height: 32px;
  font-weight: 500;
  margin-top: calc(128px - 55px);
  margin-bottom: 8px;
  text-align: center;
}

.userinput-label {
  font-size: 16px;
  color: var(--surface-medium);
  margin-bottom: 8px;
  margin-top: 24px;
  &:nth-child(2) {
    margin-top: 0;
  }

  &.userinput-label--detail {
    margin-top: 8px;
    font-size: 0.75rem;
    line-height: 0.75rem;
    margin-bottom: 0px;
    color: var(--surface-medium);
  }
}

.button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  cursor: pointer;
  background-color: white;
  // border-top: 1px solid var(--gray-100);

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    font-weight: 500;
    font-size: 1rem;
    height: 60px;
  }

  .cancel {
    flex-grow: 3.5;
    margin-left: 30px;
    margin-right: 10px;
    background-color: var(--gray-100);
    color: var(--surface-medium);
  }

  .next {
    flex-grow: 6.5;
    background-color: var(--primary-100);
    color: var(--font-color);
  }

  .disabled {
    background-color: var(--primary-20);
  }
}
</style>

<template>
  <div
    :class="['view-container', { logo: appData.hasLogo }]"
    id="onBoardingScreen_container"
  >
    <div style="height: 100%; width: 100%">
      <div class="card-title-area">
        <img
          src="@/assets/back_32.svg"
          alt="back"
          width="30"
          @click="$emit('cancel', { prev: true })"
          v-if="appData.identification_config_enabled"
        />
        <div v-else class="empty-div" />
        <div class="card-title-text">{{ $t('온보딩.인증 안내') }}</div>
        <img
          class="close-icon"
          width="30"
          src="@/assets/icon-close-32.svg"
          @click="exitDialog = true"
        />
      </div>
      <div class="card-text-area">
        <div
          class="card-text-message"
          v-html="$t('온보딩.고객 신원 확인은 아래와 같은 순서로 진행됩니다.')"
        />
        <div class="steps-container">
          <div v-for="(step, i) in filteredSteps" :key="i" class="step">
            <div class="step-icon-container">
              <div class="step-icon-bg">
                <component :is="step.iconSrc" />
              </div>
            </div>
            <div class="step-text-container">
              <div class="step-text-main">
                {{ `${i + 1}${$t('온보딩.단계')} | ${step.mainText}` }}
              </div>
              <div class="step-text-sub">{{ step.subText }}</div>
            </div>
          </div>
        </div>

        <v-btn
          class="btn-start"
          id="onBoardingScreen_submit_button"
          height="60"
          ripple
          text
          color="var(--font-color)"
          style
          @click="$emit('next')"
          >{{ $t('버튼.시작') }}</v-btn
        >
      </div>
    </div>
    <ExitDialog
      v-model="exitDialog"
      @ok="$emit('cancel')"
      @cancel="exitDialog = false"
    />
  </div>
</template>

<script>
import Constants from '@/constants.js';
import ExitDialog from '../dialog/ExitDialog';
import Icon_OnBoardingIdcardVue from './DynamicCt_imgs/Icon_OnBoarding_Idcard.vue';
import Icon_OnBoardingFaceVue from './DynamicCt_imgs/Icon_OnBoardingFace.vue';
import Icon_OnBoardingBankVue from './DynamicCt_imgs/Icon_OnBoardingBank.vue';
import CustomField_SmVue from './DynamicCt_imgs/CustomField_Sm.vue';

export default {
  name: 'OnBoardingScreen',
  components: { ExitDialog },

  props: {
    appData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      exitDialog: false,
    };
  },
  computed: {
    moduleSettings() {
      if (!this.appData.moduleName || this.appData.moduleName === '') {
        return [];
      }
      return this.appData.moduleName.split('+');
    },
    filteredSteps() {
      this.$log.debug(this.steps, this.moduleSettings);
      return this.steps.filter((step) =>
        this.moduleSettings.includes(step.tag)
      );
    },
    steps() {
      return [
        {
          tag: Constants.MODULE.OCR,
          iconSrc: Icon_OnBoardingIdcardVue,
          mainText: this.$t('온보딩.신분증 인증'),
          subText: this.$t('온보딩.본인 명의 신분증 촬영 및 확인'),
        },
        {
          tag: Constants.MODULE.FACE,
          iconSrc: Icon_OnBoardingFaceVue,
          mainText: this.$t('온보딩.얼굴 인증'),
          subText: this.$t('온보딩.전면 카메라를 이용한 얼굴 촬영'),
        },
        {
          tag: Constants.MODULE.ACCOUNT,
          iconSrc: Icon_OnBoardingBankVue,
          mainText: this.$t('온보딩.계좌 인증'),
          subText: this.$t('온보딩.1원 송금 인증코드 입력'),
        },
        {
          tag: Constants.MODULE.EDD_FIELD,
          iconSrc: CustomField_SmVue,
          mainText: '추가 인증',
          subText: '세부 심사를 위한 정보 제공',
        },
        {
          tag: Constants.MODULE.CUSTOM_FIELD,
          iconSrc: CustomField_SmVue,
          mainText: '추가 인증',
          subText: '세부 심사를 위한 정보 제공',
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.view-container {
  height: 100%;
  width: 100%;
  position: fixed;
  background-color: var(--surface-100);
  z-index: 2;
  overflow: auto;
  &.logo {
    padding-top: 16px;
  }
}

.empty-div {
  height: 10px;
  width: 30px;
}

.close-icon {
  cursor: pointer;
}

.card-title-area {
  height: 100px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  padding: 32px;
}
.card-title-text {
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  color: var(--surface-high);
}

.card-text-area {
  /* height: calc(100% - 100px); */
  padding: 40px 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card-text-message {
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  text-align: center;
  color: var(--surface-high);
  margin-bottom: 62px;
}

.steps-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.step {
  display: flex;
  width: 300px;
  margin-bottom: 38px;
}

.step-icon-container {
  height: 60px;
  padding: 0 16px 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.step-icon-bg {
  height: 60px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background-color: var(--primary-5);

  img {
    stroke: var(--primary-80);
    fill: var(--primary-80);
  }
}

.step-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.step-text-main {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--surface-high);
}

.step-text-sub {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: var(--surface-medium);
}

.btn-start {
  background-color: var(--primary-100);
  /* background-color: red; */
  display: flex;
  padding: 15px 0px !important;
  border-radius: 8px;
}
</style>

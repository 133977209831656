<template>
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M27.25 14C27.25 21.3178 21.3178 27.25 14 27.25C6.68223 27.25 0.75 21.3178 0.75 14C0.75 6.68223 6.68223 0.75 14 0.75C21.3178 0.75 27.25 6.68223 27.25 14Z"
      stroke="var(--primary-80)"
      stroke-width="1.5"
    />
    <path
      d="M7.89844 9.49219L10.7918 19.7796L13.6851 9.49219"
      stroke="var(--primary-80)"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.6875 9.49219L16.5808 19.7796L19.4742 9.49219"
      stroke="var(--primary-80)"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.25781 13.2793H13.6874H20.1171"
      stroke="var(--primary-80)"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {};
</script>

<template>
  <ViewContainer id="cardGuide_container">
    <div class="container-phase-guide">
      <Step v-if="showStepper" :phase="phase" :items="phaseItems"></Step>

      <div
        class="title"
        v-html="$t('신분증.본인 확인을 위해 신분증의 원본을 준비해 주세요.')"
      ></div>

      <Icon_Card_GuideVue
        class="icon-card-guide-img"
        width="177px"
        style="margin: 24px auto 42px"
      />
      <div style="align-self: center">
        <div class="text-title-tip">
          {{ $t('신분증.올바른 촬영 TIP') }}
        </div>
        <div class="tip-container">
          <img
            src="@/assets/icon-check.svg"
            height="10px"
            style="margin-right: 16px"
          />
          <div
            v-html="
              $t(
                '신분증.신분증의 앞면이 보이도록 놓아주세요. 어두운 바닥에 놓으면 더 잘 인식됩니다.'
              )
            "
          ></div>
        </div>
        <div class="tip-container">
          <img
            src="@/assets/icon-check.svg"
            height="10px"
            style="margin-right: 16px"
          />
          <div v-html="$t('신분증.가이드 영역에 맞추어 반드시')"></div>
        </div>
        <div class="tip-container">
          <img
            src="@/assets/icon-check.svg"
            height="10px"
            style="margin-right: 16px"
          />
          <div
            v-html="
              $t(
                '신분증.빛이 반사되지 않도록 주의하세요. 훼손이 심한 신분증은 거절될 수도 있습니다.'
              )
            "
          ></div>
        </div>
      </div>

      <div class="button-container">
        <div
          @click="$emit('cancel', { prev: true })"
          class="button cancel"
          id="cardGuide_cancel_button"
        >
          {{ $t('버튼.이전') }}
        </div>
        <div
          @click="$emit('next')"
          class="button ok"
          id="cardGuide_submit_button"
        >
          {{ $t('버튼.신분증 촬영') }}
        </div>
      </div>
    </div>
  </ViewContainer>
</template>

<script>
import Step from './Step';
import ViewContainer from '../../../layout/auth/ViewContainer.vue';
import Icon_Card_GuideVue from './DynamicCt_imgs/Icon_Card_Guide.vue';

export default {
  components: { Step, ViewContainer, Icon_Card_GuideVue },
  props: {
    phase: Number,
    phaseItems: Array,
    showStepper: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.root-container {
  position: relative;
  height: 100%;
  color: var(--surface-high);
  overflow: auto;
}

.container-phase-guide {
  display: flex;
  flex-direction: column;
  height: 100%;

  .title {
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    margin-bottom: 27px;
    text-align: center;
  }

  .text-title-tip {
    text-align: left;

    margin-bottom: 24px;
    font-weight: 500;
    font-size: 20px;
  }

  .tip-container {
    display: flex;
    align-items: baseline;
    text-align: left;

    margin-bottom: 16px;
    font-size: 16px;
  }

  .bold {
    color: var(--primary-80);
  }
}

.spacer {
  flex-grow: 1;
}

.button-container {
  margin-top: 56px;
  width: 100%;
  bottom: 0;
  display: flex;
  padding: 0px auto 40px;
  background-color: white;

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    font-weight: 500;
    font-size: 1rem;
    height: 60px;
    cursor: pointer;
    user-select: none;

    &.cancel {
      flex-grow: 0.65;
      background: var(--gray-100);
      color: var(--surface-medium);
      margin-right: 10px;
    }

    &.ok {
      flex-grow: 1;
      color: var(--font-color);
      background: var(--primary-100);
    }
  }
}
</style>

<template>
  <div
    :class="[
      'root-container',
      {
        logo:
          appData.hasLogo &&
          !errorCode.includes(Constants.CLIENT.ERROR_CODE.FACE_LIVENESS_FAILED),
      },
    ]"
    id="faceScan_container"
  >
    <!-- phase1: face capture -->
    <div v-if="phase === PHASE_SCAN" class="container-phase-scan">
      <video
        playsinline
        ref="video"
        autoplay
        style="position: absolute; left: 0; top: 0; z-index: -1; display: none"
        controls
      >
        Video stream not available.
      </video>
      <canvas ref="canvas" style="position: absolute; left: 0; top: 0"></canvas>
      <canvas id="canvas_crop" ref="canvas_crop" style="display: none"></canvas>
      <div style="height: 100%">
        <svg
          width="100%"
          height="100%"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style="position: absolute; left: 0"
        >
          <mask id="mask-circle">
            <rect
              x="0"
              y="0"
              :width="maskCircleWidth"
              height="100%"
              fill="white"
            ></rect>
            <rect
              :x="circleX + 12"
              :y="circleY + 12"
              :width="circleWidth ? circleWidth - 24 : 0"
              :height="circleWidth ? circleWidth - 24 : 0"
              rx="100%"
              fill="black"
              stroke="black"
              :stroke-width="circleStrokeWidth"
            />
          </mask>
          <rect
            x="0"
            y="0"
            width="100%"
            height="100%"
            fill="#202023B2"
            mask="url(#mask-circle)"
          ></rect>
          <rect
            :x="circleX"
            :y="circleY"
            :width="circleWidth"
            :height="circleWidth"
            rx="100%"
            stroke="var(--secondary-100)"
            :stroke-width="circleStrokeWidth"
          />
          <rect
            :x="circleX"
            :y="circleY"
            :width="circleWidth"
            :height="circleWidth"
            rx="100%"
            :stroke="circleColor"
            stroke-linecap="round"
            :stroke-width="circleStrokeWidth"
            :stroke-dasharray="circlePercentage"
            :style="circlePercentageStyle"
          />
        </svg>
      </div>
      <div class="text-info-container">
        <div class="text-info">{{ textInfo1 }}<br />{{ textInfo2 }}</div>
      </div>
      <!-- <div style="position: absolute; left: 0; top: 0; color: white">
        {{ status }}
      </div> -->
      <div class="header">
        <img
          src="@/assets/back_32_white.svg"
          alt="back"
          class="prev"
          @click="$emit('cancel', { prev: true })"
        />
        <h2>{{ $t('온보딩.얼굴 인증') }}</h2>
        <img
          ref="capture"
          @click="stopScan = true"
          id="faceScan_close_button"
          src="@/assets/icon-close.svg"
          class="close"
        />
      </div>
    </div>
    <!-- phase2: loading -->
    <div v-if="phase === PHASE_LOADING" class="container-phase-loading">
      <LoadingImgVue class="loading" />
      <img src="@/assets/Loading_40px.svg" class="loading small" />
      <div class="message">{{ $t('로딩.잠시만 기다려 주세요.') }}</div>
      <div class="message small" style="">
        {{ $t('로딩.얼굴 정보를 확인중입니다.') }}
      </div>
    </div>
    <!-- modal -->
    <!-- @cancel="onClickBack" -->
    <ServerErrorDialog
      v-model="serverError"
      :icon="errorIcon"
      :title="errorMessageTitle"
      :message="errorMessage"
      :errorCode="errorCode"
      :button="errorButton"
      @minorBtn="onConfirmExit"
      @majorBtn="onClickNext"
      id="faceScan_serverErrorPopup"
      :disabledProp="!serverError"
    />
    <ExitDialog
      v-model="stopScan"
      @ok="onClickBack"
      @cancel="onCancelExit"
      id="faceScan_exitPopup"
      :disabledProp="!stopScan"
    />
    <FaceScanFail
      v-if="
        errorCode.includes(Constants.CLIENT.ERROR_CODE.FACE_LIVENESS_FAILED)
      "
      :errorCode="Constants.CLIENT.ERROR_CODE.FACE_LIVENESS_FAILED"
      id="faceScan_scanFailPopup"
      @ok="onClickBack"
    />
  </div>
</template>

<script>
import Constants from '@/constants';
import server from '@/api/server';
import util from '@/util';
import ServerErrorDialog from '../dialog/ServerErrorDialog';
import ExitDialog from '../dialog/ExitDialog';
import FaceScanFail from './FaceScanFail';
import * as faceApi from './face-api-impl.js';
import jszip from 'jszip';
import { mapState } from 'vuex';
import LoadingImgVue from './DynamicCt_imgs/Loading.vue';

export default {
  components: { ServerErrorDialog, ExitDialog, FaceScanFail, LoadingImgVue },
  props: {
    appData: Object,
    /**
     * emit events
     * cancel
     * next
     * success
     */
  },
  data: function () {
    return {
      origin: '',
      PHASE_SCAN: 1,
      PHASE_LOADING: 2,
      phase: 1,
      Constants,
      loading: false,
      stopScan: false,
      systemError: false,
      serverError: false,
      inErrorFlow: false,
      // errorIcon: '',
      errorIcon: {},
      errorMessageTitle: [],
      errorMessage: [],
      errorCode: '',
      errorButton: [],
      error_response: null,
      iconError: require('@/assets/Error.svg'),
      errorMessageDefine: {
        F611: {
          title: [this.$t('얼굴.얼굴 감지 실패')],
          message: [
            this.$t('얼굴.촬영된 사진에서 얼굴을 감지하지 못했습니다.'),
            this.$t('얼굴.재시도 하시겠습니까?'),
          ],
          buttons: [this.$t('버튼.종료'), this.$t('버튼.재시도')],
        },
        F612: {
          title: [this.$t('얼굴.얼굴 감지 실패')],
          message: [
            this.$t('얼굴.촬영된 사진에서 두 개 이상의 얼굴이 검출되었습니다.'),
            this.$t('얼굴.재시도 하시겠습니까?'),
          ],
          buttons: [this.$t('버튼.종료'), this.$t('버튼.재시도')],
        },
        // front-end defined error code: is_masked
        CE005: {
          title: [this.$t('얼굴.얼굴 인증 실패')],
          message: [
            this.$t('얼굴.마스크 등'),
            this.$t('얼굴.얼굴 가림이 감지되었습니다.'),
            this.$t('얼굴.재시도 하시겠습니까?'),
          ],
          buttons: [this.$t('버튼.종료'), this.$t('버튼.재시도')],
        },
        // front-end defined error code: is_live
        CE004: {
          title: [this.$t('얼굴.얼굴 인증 실패')],
          message: [
            this.$t('얼굴.얼굴 위변조 여부 판정 결과'),
            this.$t('얼굴.실제 얼굴 여부 인증에 실패하였습니다.'),
          ],
          buttons: ['', this.$t('버튼.종료')],
        },
        default: {
          title: [this.$t('얼굴.얼굴 인증 실패')],
          message: [
            this.$t('얼굴.얼굴 진위 확인에 실패하였습니다.하였습니다.'),
          ],
          buttons: ['', this.$t('버튼.종료')],
        },
      },
      streaming: false,
      facingMode: 'user' /** front: user, back: environment */,
      stream: null,
      face: { x: 0, y: 0, w: 0, h: 0 },
      // https://github.com/justadudewhohacks/face-api.js
      status: '',
      // styles
      CIRCLE_COLOR_IDLE: '#FF0000',
      CIRCLE_COLOR_SLOW: '#FFFF00',
      CIRCLE_COLOR_DETECT: 'var(--success-100)',
      circleColor: '#FF0000',
      circleStrokeWidth: 4,
      circleX: 2,
      circleY: 2,
      circleWidth: 0,
      circlePercent: 0,
      maskCircleWidth: '0%',
      intervalObj: null,
      // face status
      WAIT_COUNT: 3,
      LIEVENESS_IMAGES_LENGTH: 3,
      liveness_image: null,
      SLOW_DEVICE_STANDARD_TIME: 800, // 800ms
      isSlowDevice: false,
      count: 0,
      countTimeout: null,
      permissionTimeout: null,
      textInfo1: '',
      textInfo2: '',
      DETECT: this.$t('얼굴.잠시 움직이지 말아주세요.'),
      FIT_FACE: this.$t('얼굴.화면 영역 안으로 얼굴을 맞춰주세요.'),
      INFO_1: this.$t('얼굴.자동으로 촬영이 진행됩니다.'),
      INFO_2: this.$t('얼굴.자동 촬영이 시작됩니다.'),
      callbackWindowResize: null,
    };
  },
  computed: {
    ...mapState(['companyPhoneNumber']),
    circlePercentage() {
      return this.circlePercent && !this.isSlowDevice
        ? `${((this.circleWidth * this.circlePercent) / 100) * 3.14} 99999`
        : 0;
    },
    circlePercentageStyle() {
      return this.circlePercent && !this.isSlowDevice
        ? 'transition: stroke-dasharray 1s linear;transform-origin:center;transform: scale(-1, 1);'
        : 'transform-origin:center;transform: scale(-1, 1);';
    },
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    },
  },
  created() {
    this.initialize();
  },
  mounted() {
    this.$log.debug('FaceScan#mounted appData', this.appData);
    this.callbackWindowResize = () => this.adjustStyles();
    window.addEventListener('resize', this.callbackWindowResize);
    this.adjustStyles();
  },
  beforeDestroy() {
    this.closeCamera();
    this.liveness_image = null;
    window.removeEventListener('resize', this.callbackWindowResize);
  },
  methods: {
    initialize() {
      this.count = 0;
      this.textInfo1 = '';
      this.textInfo2 = '';
      this.streaming = false;
      this.systemError = false;
      this.serverError = false;
      this.errorCode = '';
      const supports = navigator.mediaDevices.getSupportedConstraints();
      this.$log.debug('FaceScan#initialize supports', supports);
      navigator.mediaDevices
        .getUserMedia({
          video: {
            facingMode: this.facingMode,
            width: { ideal: 1920 },
            height: { ideal: 1080 },
            zoom: supports?.zoom,
          },
          audio: false,
        })
        .then((stream) => {
          this.$log.debug('FaceScan#initialize stream', stream);
          const video = this.$refs.video;
          const canvas = this.$refs.canvas;
          const canvas_crop = this.$refs.canvas_crop;
          this.stream = stream;
          if (video) {
            if (supports?.zoom) {
              const [track] = stream.getVideoTracks();
              const capability = track.getCapabilities();
              this.$log.debug('FaceScan#initialize capability', capability);
              if (capability?.zoom) {
                track.applyConstraints({
                  advanced: [{ zoom: capability.zoom.min }],
                });
              }
              if (capability?.focusMode) {
                const focusMode = capability?.focusMode;
                this.$log.debug('FaceScan#initialize focusMode', focusMode);
                if (focusMode.some((v) => v === 'continuous')) {
                  track.applyConstraints({
                    advanced: [{ focusMode: 'continuous' }],
                  });
                }
              }
              if (capability?.whiteBalanceMode) {
                const whiteBalanceMode = capability?.whiteBalanceMode;
                this.$log.debug(
                  'FaceScan#initialize whiteBalanceMode',
                  whiteBalanceMode
                );
                if (whiteBalanceMode.some((v) => v === 'continuous')) {
                  track.applyConstraints({
                    advanced: [{ whiteBalanceMode: 'continuous' }],
                  });
                }
              }
            }
            if ('srcObject' in video) {
              video.srcObject = stream;
            } else {
              // Avoid using this in new browsers, as it is going away.
              video.src = window.URL.createObjectURL(stream);
            }
            video.addEventListener('loadedmetadata', () => {
              this.$log.debug('FaceScan#initialize~onloadedmetadata');
              video.play();
            });
            video.addEventListener('canplay', () => {
              this.$log.debug('FaceScan#initialize~canplay', this.streaming);
              if (!this.streaming) {
                canvas.setAttribute('width', video.videoWidth);
                canvas.setAttribute('height', video.videoHeight);
                canvas_crop.setAttribute('width', video.videoWidth);
                canvas_crop.setAttribute('height', video.videoHeight);
                this.adjustStyles();
                this.render();
                this.$log.debug(
                  'FaceScan#initialize~canplay',
                  video.videoWidth,
                  video.videoHeight,
                  video.clientWidth,
                  video.clientHeight,
                  this.circleWidth
                );
                this.streaming = true;
              }
            });

            video.webkitExitFullscreen();
          } else {
            this.closeCamera();
          }
        })
        .catch((e) => {
          this.$log.error('alcherakyc error', e.name, e);
          if (e.name === 'NotAllowedError') {
            this.checkCameraPermission();
          } else if (e.name === 'NotReadableError') {
            this.stopStream();
            this.checkCameraPermission();
          }
        });
    },
    checkCameraPermission() {
      this.stopCount();
      clearTimeout(this.permissionTimeout);
      this.permissionTimeout = setTimeout(() => {
        this.initialize();
      }, 1000);
    },
    checkCameraPlay() {
      this.$log.debug('FaceScan#checkCameraPlay');
      this.stopCount();
      if (this.phase === this.PHASE_SCAN) {
        this.stopStream();
        this.initialize();
      }
    },
    adjustStyles() {
      const video = this.$refs.video;
      const canvas = this.$refs.canvas;
      const parentElement = this.$refs.canvas.parentElement;
      let cssText = '';
      const ratio = video.clientWidth / video.clientHeight;
      const current = parentElement.clientWidth / parentElement.clientHeight;
      this.maskCircleWidth = '100%';
      this.$log.debug('FaceScan#adjustStyles', ratio, current);
      video.style.display = '';
      if (current < 1) {
        video.style.width = '';
        canvas.style.width = '';
        cssText = 'height: 100%;';
        video.style.cssText += cssText;
        canvas.style.cssText += cssText;
        cssText = `transform: translateX(${
          -(video.clientWidth - parentElement.clientWidth) / 2
        }px) scaleX(-1)`;
        video.style.cssText += cssText;
        canvas.style.cssText += cssText;
        this.circleWidth = parentElement.clientWidth * 0.9;
      } else {
        video.style.height = '';
        canvas.style.height = '';
        cssText = 'width: 100%;';
        video.style.cssText += cssText;
        canvas.style.cssText += cssText;
        cssText = `transform: translateY(${
          -(video.clientHeight - parentElement.clientHeight) / 2
        }px) scaleX(-1)`;
        video.style.cssText += cssText;
        canvas.style.cssText += cssText;
        this.circleWidth = parentElement.clientHeight * 0.9;
      }
      this.circleX =
        parentElement.clientWidth / 2 -
        this.circleWidth / 2 +
        this.circleStrokeWidth / 2;
      this.circleY =
        parentElement.clientHeight / 2 -
        this.circleWidth / 2 +
        this.circleStrokeWidth / 2; // center
    },
    render() {
      const videoEl = this.$refs.video;
      const canvas = this.$refs.canvas;
      const canvas_crop = this.$refs.canvas_crop;
      const context = canvas.getContext('2d');
      const context_crop = canvas_crop.getContext('2d');

      let loop = async () => {
        if (
          videoEl.paused ||
          videoEl.ended ||
          !faceApi.isFaceDetectionModelLoadedSync() ||
          this.stopScan
        ) {
          // nothing
          this.face = { x: 0, y: 0, w: 0, h: 0 };
          this.stopCount();
        } else {
          context_crop.drawImage(videoEl, 0, 0);
          const imageData = context_crop.getImageData(
            0,
            0,
            videoEl.videoWidth,
            videoEl.videoHeight
          );
          const start = Date.now();
          const result = await faceApi.detectSingleFace(imageData, true);
          const detectTime = Date.now() - start;
          if (
            !this.isSlowDevice &&
            detectTime > this.SLOW_DEVICE_STANDARD_TIME
          ) {
            this.isSlowDevice = true;
          }
          this.status = faceApi.getTimeStats();
          if (result) {
            const resizedResult = faceApi.matchDimensionsAndResize(
              canvas,
              videoEl,
              result
            );
            const box = resizedResult.detection.box;
            this.face = { x: box.x, y: box.y, w: box.width, h: box.height };
            faceApi.drawCanvas(canvas, resizedResult);
          } else {
            this.face = { x: 0, y: 0, w: 0, h: 0 };
            context.clearRect(0, 0, canvas.width, canvas.height);
          }
          this.processDetectLogic(videoEl);
        }
        cancelAnimationFrame(this.requestAnimationFrameId);
        this.requestAnimationFrameId = requestAnimationFrame(loop);
      };
      cancelAnimationFrame(this.requestAnimationFrameId);
      this.requestAnimationFrameId = requestAnimationFrame(loop);
    },
    cropImage() {
      let video = this.$refs.video;
      let canvas_crop = this.$refs.canvas_crop;

      const width = video.videoWidth;
      const height = video.videoHeight;

      let image;
      let ctx = canvas_crop.getContext('2d');
      const face = this.face;
      const padding = 100;
      canvas_crop.width = face.w + padding;
      canvas_crop.height = face.h + padding;
      ctx.drawImage(
        video,
        face.x - padding / 2,
        face.y - padding / 2,
        face.w + padding,
        face.h + padding,
        0,
        0,
        face.w + padding,
        face.h + padding
      );
      image = canvas_crop.toDataURL('image/jpeg');
      canvas_crop.setAttribute('width', width);
      canvas_crop.setAttribute('height', height);
      return image;
    },
    stopStream() {
      cancelAnimationFrame(this.requestAnimationFrameId);
      if (this.stream) {
        this.stream.stop && this.stream.stop();
        let tracks = this.stream.getTracks && this.stream.getTracks();
        this.$log.debug('FaceScan#stopStream', tracks);
        if (tracks && tracks.length) {
          tracks.forEach((track) => track.stop());
        }
        this.stream = null;
      }
    },
    closeCamera() {
      this.stopCount();
      clearTimeout(this.permissionTimeout);
      this.stopStream();
    },
    onConfirmExit() {
      this.$log.debug('onConfirmExit clicked');
      this.stopScan = true;
      this.serverError = false;
      this.inErrorFlow = true;
    },
    onCancelExit() {
      if (this.inErrorFlow) {
        this.inErrorFlow = false;
        this.stopScan = false;
        this.serverError = true;
      } else {
        this.stopScan = false;
      }
    },
    onClickBack() {
      if (this.phase === this.PHASE_SCAN || this.phase === this.PHASE_LOADING) {
        if (this.errorCode.includes('F')) {
          this.$emit('cancel', { fail: true, response: this.error_response });
        }
        this.$emit('cancel');
      }
    },
    onClickNext() {
      if (this.systemError) {
        this.initialize();
        this.phase = this.PHASE_SCAN;
        return;
      }
      if (this.systemError || this.errorCode.includes('N')) {
        this.onClickBack();
      } else {
        this.initialize();
        this.phase = this.PHASE_SCAN;
      }
    },
    getBirthday() {
      let birthday;
      const ocr = this.appData.ocrModified;
      if (ocr.birthDate) {
        birthday = ocr.birthDate;
      } else if (ocr.issueNo1) {
        birthday = util.getBirthday(ocr.issueNo1, ocr.issueNo2);
      } else if (ocr.juminNo1) {
        birthday = util.getBirthday(ocr.juminNo1, ocr.juminNo2);
      }

      return `${birthday.substring(0, 4)}-${birthday.substring(
        4,
        6
      )}-${birthday.substring(6, 8)}`;
    },
    async capture() {
      if (this.loading) return;
      // eslint-disable-next-line
      const image = this.cropImage();
      // if (image) { // for test
      //   this.$refs.capture.src = image;
      //   this.stopCount();
      //   return;
      // }
      this.loading = true;
      this.phase = this.PHASE_LOADING;
      this.closeCamera();

      const selfie_image = util.dataURItoBlob(image);

      let formData = new FormData();
      formData.append('transaction_id', this.appData.transaction_id);
      formData.append('selfie_image', selfie_image);

      this.$log.debug('FaceScan#capture', !!this.liveness_image, !!image);
      if (this.liveness_image && image) {
        this.$log.debug('FaceScan#capture zip start');
        let zip = new jszip();
        zip.file('1.jpeg', util.dataURItoBlob(this.liveness_image[0]), {
          base64: true,
        });
        zip.file('2.jpeg', util.dataURItoBlob(this.liveness_image[1]), {
          base64: true,
        });
        zip.file('3.jpeg', util.dataURItoBlob(this.liveness_image[2]), {
          base64: true,
        });
        zip.file('4.jpeg', util.dataURItoBlob(image), {
          base64: true,
        });
        this.$log.debug('FaceScan#capture zip end');
        zip.generateAsync({ type: 'blob' }).then((blob) => {
          formData.append('liveness_image', blob, 'liveness_image.zip');
          this.$log.debug('FaceScan#capture requestServer zip');
          this.requestServer(formData);
          this.loading = false;
        });
      } else {
        this.$log.debug('FaceScan#capture requestServer');
        await this.requestServer(formData);
        this.loading = false;
      }
    },
    async requestServer(formData) {
      try {
        // eslint-disable-next-line no-unreachable
        const response = await server.startApplicationFace(formData);

        /**
         * liveness === null  && is_same_person === true  : (OK)자동승인
         * liveness === null  && is_same_person === false : (OK)수동심사
         * liveness === true  && is_same_person === true  : (OK)자동승인
         * liveness === true  && is_same_person === false : (OK)수동심사
         * liveness === false && is_same_person === true  : (ERR-CE004)진위확인실패로 인증 종료
         * liveness === false && is_same_person === false : (ERR-CE004)진위확인실패로 인증 종료
         */
        if (
          response?.review_result?.face_check &&
          response.review_result.face_check.is_live === false
        ) {
          throw new server.AlcheraError(
            Constants.CLIENT.ERROR_CODE.FACE_LIVENESS_FAILED,
            '',
            response
          );
        } else if (
          response?.review_result?.face_check &&
          response.review_result.face_check.is_masked
        ) {
          throw new server.AlcheraError(
            Constants.CLIENT.ERROR_CODE.FACE_RECOGNITION_FAILED_AFTER_SCAN,
            '',
            response
          );
        } else {
          this.$emit('next', { response });
        }
      } catch (e) {
        this.error_response = e.response;
        if (
          e.errorCode &&
          typeof e.errorCode === 'string' &&
          (e.errorCode.startsWith('F') ||
            e.errorCode.startsWith('N') ||
            e.errorCode.startsWith('CE'))
        ) {
          if (
            e.errorCode.includes(
              Constants.CLIENT.ERROR_CODE.FACE_LIVENESS_FAILED
            )
          ) {
            this.errorCode = e.errorCode;
            this.$emit('next', { fail: true, response: this.error_response });
          } else {
            this.serverError = true;
            this.errorIcon = this.iconError;
            const message = this.errorMessageDefine[e.errorCode]
              ? this.errorMessageDefine[e.errorCode]
              : this.errorMessageDefine.default;
            this.errorMessageTitle = message.title;
            this.errorMessage = message.message;
            this.errorButton = message.buttons;
            if (
              e.errorCode &&
              e.errorCode !==
                Constants.CLIENT.ERROR_CODE.FACE_RECOGNITION_FAILED_AFTER_SCAN
            ) {
              this.errorCode = `${this.$t('에러.에러코드')} : ${e.errorCode}`;
            } else {
              this.errorCode = '';
            }
          }
        } else {
          this.systemError = true;
          this.serverError = true;
          // this.errorIcon = '';
          this.errorIcon = {};
          this.errorMessageTitle = this.$t(
            '에러.시스템 에러가 발생하였습니다.'
          ).split('<br />');
          this.errorMessage = this.$t('에러.고객센터로 문의해주세요.', {
            companyPhoneNumber: this.companyPhoneNumber,
          }).split('<br />');
          this.errorButton = ['', this.$t('버튼.확인')];
          this.errorCode = e.errorCode
            ? `${this.$t('에러.에러코드')} : ${e.errorCode}`
            : e;
        }
      }
    },
    processDetectLogic(video) {
      const face = this.face;
      const transRatioCircleWidth =
        (this.circleWidth * video.videoWidth) / video.clientWidth;
      const validSize =
        face.h < transRatioCircleWidth && face.h > transRatioCircleWidth / 2;
      const distance = faceApi.euclideanDistance(
        face.x + face.w / 2,
        face.y + face.h / 2,
        video.videoWidth / 2,
        video.videoHeight / 2
      );
      const validPosition = distance < transRatioCircleWidth / 2;
      if (validSize && validPosition) {
        if (this.countTimeout === null && this.phase === this.PHASE_SCAN) {
          this.textInfo1 = this.INFO_2;
          this.textInfo2 = this.DETECT;
          this.circleColor = this.isSlowDevice
            ? this.CIRCLE_COLOR_SLOW
            : this.CIRCLE_COLOR_DETECT;
          this.startCount(this.WAIT_COUNT);
        }
        this.captureForLiveness();
      } else {
        this.textInfo1 = this.FIT_FACE;
        this.textInfo2 = this.INFO_1;
        this.circleColor = this.CIRCLE_COLOR_IDLE;
        this.liveness_image = null;
        this.stopCount();
      }
    },
    startCount(count) {
      this.count = count;
      this.circlePercent =
        ((this.WAIT_COUNT + 1 - count) / this.WAIT_COUNT) * 100;
      clearTimeout(this.countTimeout);
      this.countTimeout = setTimeout(() => {
        this.$log.debug(
          `FaceScan#startCount WAIT_COUNT ${this.WAIT_COUNT} count ${this.count}`
        );
        if (count > 1) {
          this.startCount(count - 1);
        } else if (
          this.liveness_image &&
          this.liveness_image.length !== this.LIEVENESS_IMAGES_LENGTH
        ) {
          this.WAIT_COUNT++;
          this.count++;
          this.startCount(this.count);
        } else {
          this.circleColor = this.CIRCLE_COLOR_DETECT;
          this.circlePercent = 100;
          this.capture();
        }
      }, 1000);
    },
    stopCount() {
      clearTimeout(this.countTimeout);
      this.countTimeout = null;
      this.count = 0;
      this.circlePercent = 0;
    },
    captureForLiveness() {
      if (this.appData.moduleName.includes(Constants.MODULE.LIVENESS)) {
        let first = false;
        if (!this.liveness_image) {
          first = true;
          this.liveness_image = [];
          this.then = Date.now();
        }
        this.elapsed = Date.now() - this.then;
        // 30 fps
        if (first || this.elapsed > 33) {
          this.then = Date.now();
          // eslint-disable-next-line
          const image = this.cropImage();
          if (this.liveness_image.length > this.LIEVENESS_IMAGES_LENGTH - 1) {
            this.liveness_image.shift();
          }
          this.liveness_image.push(image);
        }
      }
    },
  },
};
</script>

<style>
svg rect {
  transform-box: fill-box;
}
</style>

<style lang="scss" scoped>
.root-container {
  position: relative;
  height: 100%;
  overflow: hidden;
  color: var(--surface-high);

  &.logo {
    z-index: 3;
  }
}

.step-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 45px;
  font-size: 14px;

  .dot {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background: var(--primary-100);
  }

  .line {
    width: 112px;
    border-top: 1px solid var(--primary-100);
  }

  .dash {
    width: 112px;
    border-top: 1px dashed var(--surface-high);
  }

  .current {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    border: 2px solid var(--primary-100);
  }

  .text-info {
    position: absolute;
    width: 100px;
    top: 22px;
    left: -45px;
    text-align: center;
  }
}

.container-phase-guide {
  display: flex;
  flex-direction: column;
  height: 100%;

  .text-title-tip {
    text-align: left;
    margin-left: 30px;
    margin-bottom: 24px;
    font-weight: 500;
    font-size: 20px;
  }

  .tip-container {
    display: flex;
    align-items: baseline;
    text-align: left;
    margin-left: 36px;
    margin-right: 36px;
    margin-bottom: 16px;
    font-size: 16px;
  }
}

.spacer {
  flex-grow: 1;
}

.button-container {
  display: flex;
  padding: 0 30px 39px;

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    font-weight: 500;
    font-size: 1rem;
    height: 60px;
    cursor: pointer;
    user-select: none;

    &.cancel {
      flex-grow: 0.65;
      background: var(--gray-100);
      color: var(--surface-medium);
      margin-right: 10px;
    }

    &.ok {
      flex-grow: 1;
      color: var(--font-color);
      background: var(--primary-100);
    }
  }
}

.container-phase-scan {
  height: 100%;

  .header {
    .prev {
      position: absolute;
      top: 32px;
      left: 32px;
      width: 30;
    }

    h2 {
      font-weight: 500;
      font-size: 1.25rem;
      line-height: 2rem;
      position: absolute;
      color: #fff;
      top: 32px;
      left: 50%;
      transform: translateX(-50%);
    }
    .close {
      position: absolute;
      top: 32px;
      right: 32px;
      width: 48px;
    }
  }

  .text-info-container {
    position: absolute;
    width: 100%;
    bottom: 50%;
  }

  .text-info {
    position: absolute;
    top: 0;
    transform: translateY(-50%);
    width: 100%;
    text-align: center;
    font-size: 1rem;
    line-height: 32px;
    color: white;
  }
}

.container-phase-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: var(--secondary-100);
  color: white;

  .loading {
    width: 172px;
    margin-bottom: 50px;

    &.small {
      width: 35px;
      margin-bottom: 16px;
    }
  }

  .message {
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 8px;
    &.small {
      font-size: 16px;
      color: var(--surface-medium);
    }
  }
}

.container-phase-complete {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}
</style>

export default Object.freeze({
  ROUTE_NAME: {
    FIND_ACCOUNT: 'FindAccount',
    MAIN: 'Main',
    AUTH: 'Auth',
    ANALYSIS: 'Analysis',
    DASHBOARD: 'Dashboard',
    OPENBANK: 'Openbank',
    REQUIRED_MANAGEMENT: 'RequiredManagement',

    EVALUATION_REVIEW: 'EvaluationReview',
    EVALUATION_RESULT: 'EvaluationResult',

    PAGE_404: 'Page404',
  },
  USERNAME: 'username',
  PASSWORD: 'password',
  CARD_IDTYPE: {
    JUMIN: 1,
    DRIVER: 2,
    PASSPORT_KOR: 3,
    PASSPORT_ALIEN: 4,
  },
  MODULE: {
    OCR: 'ocr',
    STATUS: 'status',
    IDCARD: 'ocr+status',
    FACE: 'face',
    LIVENESS: 'liveness',
    ACCOUNT: 'account',
    EDD_FIELD: 'edd',
    CUSTOM_FIELD: 'custom',
  },
  APP_CARD_INDEX: {
    JUMIN: 0,
    DRIVER: 1,
    PASSPORT_KOREAN: 2,
    PASSPORT_ALIEN: 3,
    ALIEN: 4,
    ALIEN_REGI: 1, // 등록증
    ALIEN_RESI: 2, // 거소증
    ALIEN_PERM: 3, // 영주증
  },
  DISPLAY_MODULE: {
    //1원 계좌 인증
    ONE_POINT: 1,
    //신분증 OCR / 진위확인
    OCR_CHECK: 2,
    //신분증 사진 및 얼굴 비교
    ID_CARD_COMPARE: 3,
    PASSIVE_LIVENESS: 4,
    ONE_OCR_CHECK: 5,
    ONE_ID_CARD_COMPARE: 6,
    ONE_PASSIVE_LIVENESS: 7,
  },
  PHASES: {
    PHASE_INIT_LOADING: -1,
    PHASE_EMPTY_PAGE: 0,
    PHASE_USER_INFO_INPUT: 1,
    PHASE_CARD_SELECT: 2,
    PHASE_CARD_GUIDE: 3,
    PHASE_CARD_SCAN: 4,
    PHASE_CARD_NOT_MATCHED: 5,
    PHASE_CARD_RESULT: 6,
    PHASE_FACE_GUIDE: 7,
    PHASE_FACE_SCAN: 8,
    PHASE_BANK_HOLDER: 9,
    PHASE_BANK_SEND: 10,
    PHASE_BANK_VERIFY: 11,
    PHASE_ADDITIONAL_GUIDE: 12,
    PHASE_ADDITIONAL_INPUT: 13,
    PHASE_VERIFY_FAIL: 14,
    PHASE_RESULT_SUCCESS: 15,
    PHASE_ACCESS_DENIED: 16,
    PHASE_CARD_LOCKED: 17,
  },
  SERVER: {
    ERROR_CODE: {
      CARD_SCAN_IDCARD_NO_MASKING_IMAGE: 'SE001',
    },
  },
  WASM: {
    ERROR_CODE: {
      CARD_SCAN_IDCARD_DETECTION_ERROR: 'WA001',
      CARD_SCAN_IDCARD_RECOGNITION_ERROR: 'WA002',
      CARD_SCAN_IDCARD_TYPE_NOT_MATCH: 'WF003',
      CARD_SCAN_FACE_RECOGNITION_FAILED: 'WF004',
      UNKNOWN_ERROR: 'WA999',
    },
  },
  CLIENT: {
    ERROR_CODE: {
      REVIEW_RESULT_EMPTY: 'CE001',
      CARD_SCAN_FACE_RECOGNITION_FAILED: 'CF002',
      CARD_UPLOAD_FACE_RECOGNITION_FAILED: 'CF003',
      FACE_LIVENESS_FAILED: 'CE004', // TODO: FaceSan에서 하드코딩중
      FACE_RECOGNITION_FAILED_AFTER_SCAN: 'CE005', // TODO: FaceSan에서 하드코딩중
      ONBOARDING_EVT_MSG_FORMAT_INVALID: 'CE010',
      ONBOARDING_SIGIN_IN_FAILED: 'CE011',
      ONBOARDING_ACCESS_TOKEN_INVALID: 'CE012',
      ONBOARDING_ENDUSER_INFO_SOME_EMPTY: 'CE013',
      ONBOARDING_ENDUSER_INFO_FORMAT_INCORRECT: 'CE014',
      ONBOARDING_CUSTOM_FIELD_SETTING_ERROR: 'CE015',
      ONBOARDING_ALL_MODULES_DISABLED: 'CE019',
      ACCOUNT_REALNAME_FAIL: 'CF016',
      WEBSOCKET_CONNECTION_FAILED: 'CE017',
      CARD_SCAN_IDCARD_TYPE_NOT_MATCH: 'CF018',
      UNKNOWN_ERROR: 'CE999',
    },
  },
  INDUSTRY: {
    NON_FINANCE: 1,
    FINANCE: 2,
    VASP: 3,
    P2P: 4,
  },
  DELIMITER: '$useb$',
});

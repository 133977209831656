var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ViewContainer',{attrs:{"id":"cardGuide_container"}},[_c('div',{staticClass:"container-phase-guide"},[(_vm.showStepper)?_c('Step',{attrs:{"phase":_vm.phase,"items":_vm.phaseItems}}):_vm._e(),_c('div',{staticClass:"title",domProps:{"innerHTML":_vm._s(_vm.$t('신분증.본인 확인을 위해 신분증의 원본을 준비해 주세요.'))}}),_c('Icon_Card_GuideVue',{staticClass:"icon-card-guide-img",staticStyle:{"margin":"24px auto 42px"},attrs:{"width":"177px"}}),_c('div',{staticStyle:{"align-self":"center"}},[_c('div',{staticClass:"text-title-tip"},[_vm._v(" "+_vm._s(_vm.$t('신분증.올바른 촬영 TIP'))+" ")]),_c('div',{staticClass:"tip-container"},[_c('img',{staticStyle:{"margin-right":"16px"},attrs:{"src":require("@/assets/icon-check.svg"),"height":"10px"}}),_c('div',{domProps:{"innerHTML":_vm._s(
            _vm.$t(
              '신분증.신분증의 앞면이 보이도록 놓아주세요. 어두운 바닥에 놓으면 더 잘 인식됩니다.'
            )
          )}})]),_c('div',{staticClass:"tip-container"},[_c('img',{staticStyle:{"margin-right":"16px"},attrs:{"src":require("@/assets/icon-check.svg"),"height":"10px"}}),_c('div',{domProps:{"innerHTML":_vm._s(_vm.$t('신분증.가이드 영역에 맞추어 반드시'))}})]),_c('div',{staticClass:"tip-container"},[_c('img',{staticStyle:{"margin-right":"16px"},attrs:{"src":require("@/assets/icon-check.svg"),"height":"10px"}}),_c('div',{domProps:{"innerHTML":_vm._s(
            _vm.$t(
              '신분증.빛이 반사되지 않도록 주의하세요. 훼손이 심한 신분증은 거절될 수도 있습니다.'
            )
          )}})])]),_c('div',{staticClass:"button-container"},[_c('div',{staticClass:"button cancel",attrs:{"id":"cardGuide_cancel_button"},on:{"click":function($event){return _vm.$emit('cancel', { prev: true })}}},[_vm._v(" "+_vm._s(_vm.$t('버튼.이전'))+" ")]),_c('div',{staticClass:"button ok",attrs:{"id":"cardGuide_submit_button"},on:{"click":function($event){return _vm.$emit('next')}}},[_vm._v(" "+_vm._s(_vm.$t('버튼.신분증 촬영'))+" ")])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-dialog v-model="dialog" max-width="384px" persistent>
    <v-card>
      <v-card-text :id="id" :class="{ disabled: disabledProp }">
        <component class="icon-img" v-if="icon" :is="icon" />
        <div
          :class="{ 'server-error-title': true, icon }"
          :style="[icon.beforeCreate ? '' : systemError]"
        >
          <div
            v-for="(msg, index) in title"
            :key="index"
            :style="customTitleColor !== '' ? `color: ${customTitleColor}` : ''"
          >
            {{ msg }}
          </div>
        </div>
        <div
          v-if="customMessageHtmlFormat !== ''"
          class="custom-message-html-format"
        >
          <span v-html="customMessageHtmlFormat" />
        </div>
        <div
          v-else
          :class="{ 'message-container': true, 'error-code': errorCode }"
        >
          <div v-for="(msg, index) in message" :key="index" v-html="msg"></div>
        </div>
        <div v-if="errorCode" class="text-error-code">
          {{ errorCode }}
        </div>
        <div
            v-if="detail !== ''"
            style="margin-bottom: 16px; font-size: 11px; line-height: 16px; color: var(--surface-disabled); text-align: center;"
        >
          ※ {{ detail }}
        </div>
        <div class="button-container">
          <div
            v-if="button[0] && button[0] !== ''"
            @click="minorBtn"
            class="button minor"
          >
            {{ button[0] }}
          </div>
          <div v-if="button[1]"
            @click="majorBtn"
            class="button major">
            {{ button[1] }}
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: Object,
      default: () => {
        return {};
      },
    },
    title: {
      type: Array,
      default: () => [],
    },
    customTitleColor: {
      type: String,
      default: '',
    },
    message: {
      type: Array,
      default: () => [],
    },
    customMessageHtmlFormat: {
      type: String,
      default: '',
    },
    errorCode: {
      type: String,
      default: '',
    },
    detail: {
      type: String,
      default: '',
    },
    button: {
      type: Array,
      default: () => [],
    },
    id: {
      type: String,
    },
    disabledProp: {
      type: Boolean,
    },
  },
  data() {
    return {
      dialog: this.value,
      systemError: {
        marginTop: '40px',
      },
    };
  },
  watch: {
    value(val) {
      this.dialog = val;
    },
  },
  methods: {
    close() {
      this.dialog = false;
      this.$emit('input', false);
    },
    minorBtn() {
      this.close();
      this.$emit('minorBtn');
    },
    majorBtn() {
      this.close();
      this.$emit('majorBtn');
    },
  },
};
</script>

<style lang="scss" scoped>
.v-card__text {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;

  .icon-img {
    width: 60px;
    margin: 40px auto 16px;
    width: 86px;
    height: 60px;
  }
}

.server-error-title {
  margin-top: 40px;
  margin-bottom: 8px;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  color: var(--surface-high);
  text-align: center;

  &.icon {
    margin-top: 0;
  }
}

.custom-message-html-format {
  padding: 0 20px;
  margin-bottom: 32px;
  font-size: 16px;
  line-height: 26px;
  color: var(--surface-high);
  text-align: center;
  word-break: keep-all;
}

.message-container {
  padding: 0 20px;
  margin-bottom: 32px;
  font-size: 16px;
  line-height: 26px;
  color: var(--surface-high);
  text-align: center;
  word-break: keep-all;

  &.error-code {
    margin-bottom: 17px;
  }

  &:last-child {
    margin-bottom: 13px;
  }
}

.text-error-code {
  margin-bottom: 32px;
  font-size: 16px;
  line-height: 26px;
  color: var(--surface-medium);
  text-align: center;
  word-break: keep-all;
}

.button-container {
  display: flex;
  padding: 0 30px;
  margin-bottom: 32px;

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    font-weight: 500;
    font-size: 1rem;
    height: 60px;
    cursor: pointer;
    user-select: none;

    &.minor {
      flex-grow: 0.65;
      background: var(--gray-100);
      color: var(--surface-medium);
      margin-right: 10px;
    }

    &.major {
      flex-grow: 1;
      color: var(--font-color);
      background: var(--primary-100);
    }
  }
}
</style>

<template>
  <div :style="{ color: color, ...st }">
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 9.75L8.75 13.5L15 6"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
</template>
<script>
export default {
  props: {
    color: { type: String, default: '#373EFF' },
    st: {
      type: Object,
      default: () => ({ width: '20px', height: '20px' }),
    },
  },
};
</script>

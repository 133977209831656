import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import VueLogger from 'vuejs-logger';
import { initializeI18Next, i18n } from './i18n';
import I18NextVue from 'i18next-vue';
import 'material-design-icons-iconfont/dist/material-design-icons.css';

const lang = navigator.language.split('-')[0];
initializeI18Next(lang);
// initializeI18Next('en');

Vue.use(I18NextVue, { i18next: i18n });

Vue.config.productionTip = false;

Vue.use(VueLogger, {
  logLevel: process.env.NODE_ENV === 'production' ? 'error' : 'debug',
});

/**
 * if you change log level on qa or live server, refer to execute console print
 */
// eslint-disable-next-line no-undef
if (window) {
  // eslint-disable-next-line no-undef
  window.setLogLevel = function (level) {
    VueLogger.install(Vue, { logLevel: level });
  };
}

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount('#app');

console.log(`version ${process.env.VUE_APP_VERSION}`);
// if (module.hot) {
//   module.hot.accept(
//     ['./i18n/locales/ko/ko', './i18n/locales/en/en'],
//     function () {
//       const ko = require('./i18n/locales/ko/ko').default;
//       const en = require('./i18n/locales/en/en').default;
//       console.log({ ko, en });
//       i18n.setLocaleMessage('ko', ko);
//       i18n.setLocaleMessage('en', en);
//     }
//   );
// }

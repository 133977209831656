<template>
  <div class="additional-input-fields-container">
    <div v-for="field in displayField" :key="field.name">
      <div class="additional-input-label">
        {{ `${field.name}${!field.required ? ' (선택)' : ''}` }}
      </div>
      <div v-if="field.type === 'text'">
        <InputTextField
          style="margin-top: 8px"
          v-model="selectedCustomField[field.name].value"
          :append="appendObject[field.name] ? iconClear : ''"
          @focus="appendObject[field.name] = true"
          @blur="appendObject[field.name] = false"
          @append="selectedCustomField[field.name].value = ''"
          @input="(value) => onTextInput(field, value)"
        />
      </div>
      <div v-else-if="field.type === 'file'">
        <FileInputField
          v-model="fileUploadManager[field.name].file"
          @input="onFileInput(field, $event)"
          @remove="onRemove(field)"
          :loading="fileUploadManager[field.name].loading"
        />
      </div>
      <div v-else-if="field.type === 'ox'">
        <RadioField
          :field="field"
          v-model="selectedCustomField[field.name].value"
          @change="onOxSelected"
        >
          <div v-for="item in field.tree" :key="`${item.name}`">
            <div :value="item.name" :score="item.score">{{ item.name }}</div>
          </div>
        </RadioField>
      </div>
      <div v-else-if="field.type === 'addr'">
        <GooglePlaceWrapper>
          <GooglePlaceAutoComplete
            v-model="addressObj[field.id]"
            :nations="appData.countries"
            @update="onAddressChange(field)"
          />
        </GooglePlaceWrapper>
      </div>
      <div v-else-if="field.type === 'tree'">
        <LinkedList
          :field="field"
          v-model="selectedCustomField[field.name].value"
          :primaryColor="appData.colorPalette.tint_100"
          @selected="onTreeSelected"
        />
      </div>
    </div>
    <FileUploadErrorDialog
      :show="showFileUploadErrorDialog"
      :error-type="fileUploadErrorType"
      @ok="showFileUploadErrorDialog = false"
    />
  </div>
</template>
<script>
import server from '@/api/server';
import util from '@/util';
import FileUploadErrorDialog from '../dialog/FileUploadErrorDialog.vue';
import InputTextField from './InputTextField';
import FileInputField from './FileInputField.vue';
import LinkedList from './LinkedList.vue';
import RadioField from './RadioField.vue';
import GooglePlaceWrapper from './googlePlace/GooglePlaceWrapper.vue';
import GooglePlaceAutoComplete from './googlePlace/GooglePlaceAutoComplete.vue';

export default {
  components: {
    FileUploadErrorDialog,
    InputTextField,
    FileInputField,
    LinkedList,
    RadioField,
    GooglePlaceWrapper,
    GooglePlaceAutoComplete,
  },
  props: {
    pageOffset: { type: Object, default: () => ({ current: 0, last: 0 }) },
    customField: { type: Array, default: () => [] },
    // customField: Array,
    appData: Object,
  },
  data: function () {
    return {
      showFileUploadErrorDialog: false,
      fileUploadErrorType: '',
      iconClear: require('@/assets/delete_24.svg'),
      localFileObject: {},
      fileUploadManager: {},
      selectedCustomField: {},
      appendObject: {},
      selectedCustomFieldList: [],
      addressObj: {},
    };
  },
  computed: {
    displayField() {
      const cnt = 5;
      const { current } = this.pageOffset;
      const start = (current - 1) * 5;
      const end = start + cnt;
      return this.customField.slice(start, end);
    },
  },
  created() {
    this.initialize();
    this.$log.debug('CtAdditionalSurvey #created');
  },
  mounted() {
    const { customField, fileUploadManager, selectedCustomField, appendObject, displayField, addressObj } = this; // prettier-ignore
    this.$log.debug('CtAdditionalSurvey #mounted', { customField, fileUploadManager, selectedCustomField, appendObject, displayField, addressObj }); // prettier-ignore
  },
  updated() {
    const { customField, fileUploadManager, selectedCustomField, appendObject, displayField, addressObj } = this; // prettier-ignore
    this.$log.debug('CtAdditionalSurvey #updated', { customField, fileUploadManager, selectedCustomField, appendObject, displayField, addressObj }); // prettier-ignore
  },
  methods: {
    initialize() {
      this.customField.forEach((field) => {
        if (field.type === 'file') {
          this.$set(this.fileUploadManager, field.name, { loading: false, file: null }); // prettier-ignore
        }
        if (field.type === 'address') {
          this.$set(this.addressObj, field.id, { nationality: '', addr: '', subAddr: '' }); // prettier-ignore
        }
        this.$set(this.selectedCustomField, field.name, {
          field_name: field.name,
          field_type: field.type,
          ...(field.type !== 'file' && { value: field.value || '' }),
        });
        this.$set(this.appendObject, field.name, false);
      });
    },
    onTextInput(field, value) {
      const { id, name } = field;
      this.handleUpdate({ id, name, value });
    },
    async onFileInput(field, file) {
      const { id, name } = field;
      this.$set(this.fileUploadManager[name], 'loading', true);

      const formData = new FormData();
      formData.append('attachment', file);

      try {
        const attachment_id = await server.postAttachMents(formData);
        this.$set(this.fileUploadManager[name], 'file', file);
        this.$set(this.fileUploadManager[name], 'loading', false);
        this.$set(this.selectedCustomField[name], 'id', attachment_id);

        const base64CardImage = await this.fileToBase64(name, file);
        this.$set(this.localFileObject, name, { file_name: file.name, value: base64CardImage }); // prettier-ignore

        this.$emit('update', { id, name, result: { key: name, attachment_id } }); // prettier-ignore
        this.$emit('fileUpdate', { fieldName: name, ...this.localFileObject[name] }); // prettier-ignore
      } catch (error) {
        this.$set(this.fileUploadManager[name], 'file', null);
        this.$set(this.fileUploadManager[name], 'loading', false);
        this.fileUploadErrorType = 'format';
        this.showFileUploadErrorDialog = true;
        this.$log.debug(error);
      }
      this.$log.debug('fileUploadManager', { fileUploadManager: this.fileUploadManager, selectedCustomField: this.selectedCustomField }); // prettier-ignore
      // this.validateForm();
    },
    async fileToBase64(fieldName, file) {
      const base64Card = await util.blobToDataURL(file);
      const base64CardImage = base64Card.split(',')[1]; // remove mime
      return base64CardImage;
    },
    async onRemove(field) {
      const { id, name } = field;
      this.$set(this.fileUploadManager[name], 'file', null);
      await server.deleteAttachMents(this.selectedCustomField[name].id);
      this.$set(this.selectedCustomField[name], 'id', null);
      this.$emit('update', { id, name, result: { key: name, attachment_id: null } }); // prettier-ignore
      this.$emit('fileUpdate', { name, file_name: '', value: '' });
    },
    onTreeSelected({ field, names }) {
      const { id, name } = field;
      const value = JSON.stringify(names);
      this.handleUpdate({ id, name, value });
    },
    onOxSelected({ field, item }) {
      const { id, name } = field;
      const { value } = item;
      this.handleUpdate({ id, name, value });
    },
    onAddressChange(field) {
      const { id, name } = field;
      const { nationality, address, subAddress } = this.addressObj[id];
      const value = JSON.stringify([nationality, address, subAddress]);
      this.handleUpdate({ id, name, value });
    },
    handleUpdate({ id, name, value }) {
      this.$set(this.selectedCustomField, name, { value });
      this.$emit('update', { id, name, result: { key: name, value } });
    },
  },
};
</script>
<style lang="scss" scoped>
.additional-input-fields-container {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .additional-input-label {
    font-size: 16px;
    color: var(--surface-medium);
    margin-bottom: 8px;
  }
}
</style>

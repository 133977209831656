<template>
  <ViewContainer id="cardUpload_container">
    <canvas id="canvas_crop" ref="canvas_crop" style="display: none"></canvas>
    <!-- phase1: card capture -->
    <div v-if="phaseUpload === PHASE_SCAN" class="container-phase-guide">
      <Step v-if="showStepper" :phase="phase" :items="phaseItems"></Step>
      <!-- <div class="spacer" style="padding-top:50px;"></div> -->
      <div
          class="title"
          v-html="$t('신분증.본인 확인을 위해 신분증 사진을 첨부해 주세요.')"
      />
      <Icon_Card_GuideVue class="icon-card-guide-img" />
      <div class="upload-input-container">
        <div class="upload-input-wrapper">
          <input
              type="file"
              accept=".jpg,.jpeg,.png,.pdf"
              id="cardUpload_input"
              ref="cardInput"
              @change="onChangeFile"
              class="upload-input"
          />
          <div
              v-if="!uploadFile"
              @click="clickInput"
              class="input-status-container"
              id="cardUpload_input_container"
          >
            <div class="upload-textholoer">
              {{ $t('신분증.사진 첨부') }}
              <span>{{
                  $t('신분증.(JPG, JPEG, PNG, PDF / maxSize 이하만 가능)', {
                    maxSize: `${this.ACCEPTED_FILE_SIZE / 1024 / 1024}MB`,
                  })
                }}</span>
            </div>
            <div class="upload-input-image">
              <img src="@/assets/upload_plus.svg" alt="upload" />
            </div>
          </div>
          <div @click="clickInput" class="input-status-container" v-else>
            <div class="upload-file-name">
              {{ uploadFile.name }}
            </div>
            <div class="upload-input-image" @click="clickClear">
              <img src="@/assets/cardClear.svg" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="tip-container">
        <div class="tip-title">{{ $t('온보딩.올바른 사진 TIP') }}</div>
        <div class="tip-item">
          <img src="@/assets/icon-check.svg" />
          <div
              v-html="
              $t(`온보딩.반드시 신분증 원본 앞면이 촬영된 사진으로 제출하세요.`)
            "
          />
        </div>
        <div class="tip-item">
          <img src="@/assets/icon-check.svg" />
          <div
              v-html="$t(`온보딩.빛 반사, 흔들림이 없는 사진인지 확인해 주세요.`)"
          />
        </div>
      </div>
      <!-- <div class="spacer" style="padding-bottom: 100px;"></div> -->
      <div class="button-container">
        <div
            :id="'cardUpload_button_cancel'"
            @click="$emit('cancel', { prev: true })"
            class="button cancel"
        >
          {{ $t('버튼.이전') }}
        </div>
        <div
            @click="onSubmit"
            :class="{ button: true, ok: true, disabled: !this.uploadFile }"
            :id="'cardUpload_button_submit'"
        >
          {{ $t('버튼.신분증 제출') }}
        </div>
      </div>
    </div>
    <!-- phase2: loading -->
    <div v-if="phaseUpload === PHASE_LOADING" class="container-phase-loading">
      <LoadingImgVue class="loading" />
      <img src="@/assets/Loading_40px.svg" class="loading small" />
      <div class="message">{{ $t('로딩.잠시만 기다려 주세요.') }}</div>
      <div class="message small">
        {{ $t('로딩.신분증 정보를 확인 중입니다.') }}
      </div>
    </div>
    <!-- modal -->
    <ServerErrorDialog
        v-model="serverError"
        :icon="errorIcon"
        :title="errorMessageTitle"
        :message="errorMessage"
        :errorCode="errorCode"
        :button="errorButton"
        @minorBtn="onClickBack"
        @majorBtn="onClickRetry"
        :id="'cardUpload_serverErrorPopup'"
        :disabledProp="!serverError"
    />
    <CardScanConfirmDialog
        v-model="confirmDialog"
        :disabledProp="!confirmDialog"
        :upload="true"
        :image="base64MaskCardImage"
        @retry="onClickRetry"
        @ok="onConfirmOk"
        id="cardUpload_confirmPopup"
    />
    <FileUploadErrorDialog
        :show="showFileUploadErrorDialog"
        :error-type="fileUploadErrorType"
        @ok="onConfirmFileUploadError()"
        id="cardUpload_fileUploadErrorPopup"
        :disabledProp="!showFileUploadErrorDialog"
        :ACCEPTED_FILE_SIZE="ACCEPTED_FILE_SIZE / 1024 / 1024"
    />
    <ExitDialog
        v-model="exitDialog"
        @ok="$emit('cancel')"
        @cancel="exitCancel"
        :id="'cardUpload_exitPopup'"
        :disabledProp="!exitDialog"
    />
  </ViewContainer>
</template>
<script>
import Constants from '@/constants';
import util from '@/util';
import useb from '@/api/useb';
import Step from './Step';
import ServerErrorDialog from '../dialog/ServerErrorDialog';
import ExitDialog from '../dialog/ExitDialog';
import CardScanConfirmDialog from '../dialog/CardScanConfirmDialog';
import FileUploadErrorDialog from '../dialog/FileUploadErrorDialog';
import * as faceApi from './face-api-impl.js';
import * as netfunnel from './netfunnel.js';
import * as pdfjs from 'pdfjs-dist/legacy/build/pdf';
//import * as netfunnelSkin from "./netfunnel-skin.js";
//console.log(netfunnelSkin);   // prevent build error for unused import file
import { mapState } from 'vuex';
import ViewContainer from '../../../layout/auth/ViewContainer.vue';
import Icon_Card_GuideVue from './DynamicCt_imgs/Icon_Card_Guide.vue';
import LoadingImgVue from './DynamicCt_imgs/Loading.vue';

export default {
  components: {
    Step,
    ServerErrorDialog,
    CardScanConfirmDialog,
    FileUploadErrorDialog,
    ExitDialog,
    ViewContainer,
    Icon_Card_GuideVue,
    LoadingImgVue,
  },
  props: {
    phase: Number,
    phaseItems: Array,
    appData: Object,
    showStepper: Boolean,
    isIDCardFaceDetectMode: {
      type: Boolean,
      required: true,
      default: false,
    }
    /**
     * emit events
     * cancel
     * next
     */
  },
  data: function () {
    return {
      PHASE_SCAN: 1,
      PHASE_LOADING: 2,
      phaseUpload: 1,
      confirmDialog: false,
      base64MaskCardImage: '',
      tmpBase64CardImage: '',
      tmpAppData: {},
      serverError: false,
      exitDialog: false,
      errorIcon: {},
      errorMessageTitle: [],
      errorMessage: [],
      errorCode: '',
      errorCodeOrigin: '',
      errorButton: [],
      errorCodeControlList: [
        'O003', 'O005', 'O010', 'M003', 'O021', 'O022',
        Constants.CLIENT.ERROR_CODE.CARD_SCAN_IDCARD_TYPE_NOT_MATCH,
        Constants.SERVER.ERROR_CODE.CARD_SCAN_IDCARD_NO_MASKING_IMAGE,
      ],
      cardGuideIcon: Icon_Card_GuideVue,
      card: { ltX: 0, ltY: 0, rtX: 0, rtY: 0, lbX: 0, lbY: 0, rb: 0, rbY: 0 },
      uploadFile: null,
      showFileUploadErrorDialog: false,
      fileUploadErrorType: '',
      ACCEPTED_FILE_SIZE: 1024 * 1024 * 5, // 5MB
    };
  },
  created() {
    pdfjs.GlobalWorkerOptions.workerSrc = 'pdf/pdf.worker-3.3.122.js';
  },
  mounted() {
    this.$log.debug('CardUpload #mounted', { pdfjs });
  },
  computed: {
    ...mapState(['companyPhoneNumber', 'isMobile']),
  },
  methods: {
    cardIndexFormatterForI18N(cardIndex) {
      switch (cardIndex) {
        case 0:
          return this.$t('신분증.주민등록증');
        case 1:
          return this.$t('신분증.운전면허증');
        case 2:
          return this.$t('신분증.한국여권');
        case 3:
          return this.$t('신분증.외국여권');
        case 4:
        case '4-1':
        case '4-2':
        case '4-3':
          return this.$t('신분증.외국인 등록증');
      }
    },
    exitCancel() {
      this.exitDialog = false;
      this.serverError = true;
    },
    clickInput() {
      this.$refs.cardInput.click();
    },
    clickClear(e) {
      e.stopPropagation();
      this.uploadFile = null;
    },
    async readFileAsPdf(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        try {
          reader.onload = async () => {
            const typedArray = new Uint8Array(reader.result);
            try {
              const loadingTask = pdfjs.getDocument(typedArray);
              const pdf = await loadingTask.promise;

              // Fetch the first page
              const pageNumber = 1;
              const page = await pdf.getPage(pageNumber);

              const scale = 1;
              const viewport = page.getViewport({ scale: scale });

              // Prepare canvas using PDF page dimensions
              const canvas = document.createElement('canvas');
              const context = canvas.getContext('2d');
              canvas.height = viewport.height;
              canvas.width = viewport.width;

              // Render PDF page into canvas context
              const renderContext = { canvasContext: context, viewport };
              const renderTask = page.render(renderContext);
              await renderTask.promise;

              // Get base64 string from pdf canvas
              const base64Card = canvas.toDataURL('image/jpeg');
              this.$log.debug('readFileAsPdf', { base64Card });
              resolve(base64Card);
            } catch (error) {
              this.$log.error('CardUpload#onSubmit#imageError: ', error);
              this.fileUploadErrorType = 'invalid-image';
              this.showFileUploadErrorDialog = true;
              reject(error);
            }
          };
          reader.readAsArrayBuffer(file);
        } catch (e) {
          this.$log.error('CardUpload#onSubmit#readerError: ', e);
          reject(e);
        }
      });
    },
    async readFileAsImage(file) {
      // 이미지를 캔버스에 대입해서 이미지가 유효한지 검증
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          const image = new Image();
          image.src = reader.result;
          image.onload = () => {
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');
            canvas.width = image.width;
            canvas.height = image.height;
            context.drawImage(image, 0, 0, image.width, image.height);
            const base64Card = canvas.toDataURL('image/jpeg');
            resolve(base64Card);
          };
          image.onerror = (error) => {
            this.$log.error('CardUpload#onSubmit#imageError: ', error);
            this.fileUploadErrorType = 'invalid-image';
            this.showFileUploadErrorDialog = true;
            reject(error);
          };
        };
        reader.onerror = (error) => {
          this.$log.error('CardUpload#onSubmit#readerError: ', error);
          this.fileUploadErrorType = 'invalid-image';
          this.showFileUploadErrorDialog = true;
          reject(error);
        };
      });
    },
    async onChangeFile(event) {
      this.$log.debug(event);
      const file = event.target.files[0];
      this.uploadFile = file;
      this.$log.debug('CardUpload#onChangeFile', file);

      // 파일 크기가 크면 image onload 하는데 시간이 걸리기 때문에 일차적으로 확장자 파일 크기 점검
      const ACCEPTED_FILE_FORMATS =
          /(.*?)\.(jpg|jpeg|png|pdf|JPG|JPEG|PNG|PDF)$/;
      if (file && !this.captureProcess) {
        const isValidFileSize = file.size <= this.ACCEPTED_FILE_SIZE;
        const isValidFileFormat = file.name.match(ACCEPTED_FILE_FORMATS);

        this.$log.debug(
            'file#onChangeFile()',
            !this.captureProcess,
            isValidFileSize,
            isValidFileFormat
        );

        if (!isValidFileSize || !isValidFileFormat) {
          this.fileUploadErrorType = !isValidFileSize ? 'size' : 'format';
          this.$log.debug('open dialog');
          this.showFileUploadErrorDialog = true;
          return;
        }
      }
      this.$log.debug('파일 확장자 및 용량 검사 진행 완료');

      if (/\.(pdf|PDF)$/.test(file.name)) {
        await this.readFileAsPdf(file);
      } else {
        await this.readFileAsImage(file);
      }

      this.uploadFile = file;
    },
    onConfirmFileUploadError() {
      this.uploadFile = null;
      this.showFileUploadErrorDialog = false;
    },
    async cropFaceImage(image) {
      let cardImage = new Image();
      cardImage.src = image;
      const result = await faceApi.detectSingleFaceForCard(cardImage);
      if (!result) {
        throw new useb.UsebError(
            Constants.CLIENT.ERROR_CODE.CARD_UPLOAD_FACE_RECOGNITION_FAILED,
            this.$t('얼굴.얼굴 감지 실패')
        );
      }
      const box = result.detection.box;
      const face = { x: box.x, y: box.y, w: box.width, h: box.height };
      const padding = 100;
      const canvas_crop = this.$refs.canvas_crop;
      const ctx = canvas_crop.getContext('2d');
      canvas_crop.width = face.w + padding;
      canvas_crop.height = face.h + padding;
      const cropSizeX = face.x - padding / 2;
      const cropSizeY = face.y - padding / 2;
      ctx.drawImage(
          cardImage,
          cropSizeX < 0 ? 0 : cropSizeX,
          cropSizeY < 0 ? 0 : cropSizeY,
          face.w + padding,
          face.h + padding,
          0,
          0,
          face.w + padding,
          face.h + padding
      );
      return canvas_crop.toDataURL('image/jpeg');
    },
    onClickBack() {
      if (
          this.phaseUpload === this.PHASE_SCAN ||
          this.phaseUpload === this.PHASE_LOADING
      ) {
        // this.$emit('cancel', { prev: true });
        // this.$emit('cancel');
        this.exitDialog = true;
      }
    },
    retry() {
      this.uploadFile = null;
      this.phaseUpload = this.PHASE_SCAN;
    },
    onClickRetry() {
      if (
          this.errorCodeOrigin &&
          this.errorCodeControlList.includes(this.errorCodeOrigin)
      ) {
        this.$emit('cancel', { prev: true });
      } else {
        this.retry();
      }
    },
    async onSubmit() {
      if (this.uploadFile) {
        if (/\.(pdf|PDF)$/.test(this.uploadFile.name)) {
          const base64Card = await this.readFileAsPdf(this.uploadFile);
          await this.capture(base64Card);
        } else {
          const base64Card = await this.readFileAsImage(this.uploadFile);
          await this.capture(base64Card);
        }
      }
    },
    async capture(base64Card) {
      try {
        if (this.captureProcess) return;
        this.captureProcess = true;
        this.phaseUpload = this.PHASE_LOADING;
        let base64CardImage, base64Face, mime;
        this.tmpBase64CardImage = base64Card;
        if (base64Card) {
          const splitedImageData = base64Card.split(',');
          mime = splitedImageData[0];
          base64CardImage = splitedImageData[1]; // remove mime
        }

        if (
            base64Card &&
            this.appData.moduleName.includes(Constants.MODULE.FACE)
            ||
            this.isIDCardFaceDetectMode
        ) {
          base64Face = await this.cropFaceImage(base64Card);
        }

        this.tmpAppData = { base64Card, base64Face, mime, base64CardImage };
        this.base64MaskCardImage = base64Card;
        this.confirmDialog = true;
        this.captureProcess = false;
      } catch (e) {
        this.$log.debug('CardScan#capture error', e.errorCode, e.message);
        this.captureProcess = false;
        if (
            e.errorCode &&
            typeof e.errorCode === 'string' &&
            e.errorCode.startsWith(
                Constants.CLIENT.ERROR_CODE.CARD_UPLOAD_FACE_RECOGNITION_FAILED
            )
        ) {
          this.serverError = true;
          this.errorIcon = this.cardGuideIcon;
          this.errorMessageTitle = [e.message];
          this.errorMessage = [
            this.$t('신분증.신분증 사진에서'),
            this.$t('신분증.얼굴을 감지하지 못하였습니다.'),
            this.$t('신분증.재시도 하시겠습니까?'),
          ];
          this.errorButton = [this.$t('버튼.종료'), this.$t('버튼.재시도')];
        }
        this.errorCodeOrigin = e.errorCode;
        this.errorCode = e.errorCode
            ? `${this.$t('에러.에러코드')} : ${e.errorCode}`
            : '';
      }
    },
    async onConfirmOk() {
      // netfunnel for waiting queue
      const _this = this;

      const __impl = async () => {
        try {
          // let testabc = null;
          // if (testabc === null) {   // 시스템 에러팝업 테스트를 위한 강제 에러
          //   throw new useb.UsebError("E123", "test error occurred!");
          // }

          // eslint-disable-next-line no-unreachable
          const { base64Card, base64Face, mime } = this.tmpAppData;
          const cardIndex = this.appData.cardIndex;
          const pdfToImage = util.dataURItoBlob(base64Card);
          const formData = new FormData();
          formData.append('image', pdfToImage);
          formData.append('mask_mode', true);

          if (cardIndex === Constants.APP_CARD_INDEX.JUMIN) {
            // 주민등록증
            // const ocr = await useb.getOcrIdcard(base64CardImage);
            const ocr = await useb.getOcrIdcardFile(formData);
            if (!ocr.image_base64_mask) {
              throw new useb.UsebError(Constants.SERVER.ERROR_CODE.CARD_SCAN_IDCARD_NO_MASKING_IMAGE);
            }
            this.$log.debug('carduplaod ocr is ', ocr.juminNo2[0]);
            this.$log.debug(
                'carduplaod ocr is ',
                ![1, 2, 3, 4].includes(ocr.juminNo2[0])
            );
            if (!['1', '2', '3', '4'].includes(ocr.juminNo2[0])) {
              throw new useb.UsebError('O003');
            }
            if (ocr.idType === '1') {
              this.success(ocr, `${mime},${ocr.image_base64_mask}`, base64Face);
            } else {
              throw new useb.UsebError(Constants.CLIENT.ERROR_CODE.CARD_SCAN_IDCARD_TYPE_NOT_MATCH);
            }
          } else if (cardIndex === Constants.APP_CARD_INDEX.DRIVER) {
            // 운전면허증
            const ocr = await useb.getOcrDriverFile(formData);
            if (!ocr.image_base64_mask) {
              throw new useb.UsebError(Constants.SERVER.ERROR_CODE.CARD_SCAN_IDCARD_NO_MASKING_IMAGE);
            }
            if (ocr.idType === '2') {
              this.success(ocr, `${mime},${ocr.image_base64_mask}`, base64Face);
            } else {
              throw new useb.UsebError(Constants.CLIENT.ERROR_CODE.CARD_SCAN_IDCARD_TYPE_NOT_MATCH);
            }
          } else if (cardIndex === Constants.APP_CARD_INDEX.PASSPORT_KOREAN) {
            // 한국 여권
            this.$log.debug('한국 여권 진입점');
            const ocr = await useb.getOcrPassportFile(formData);
            if (!ocr.image_base64_mask) {
              throw new useb.UsebError(Constants.SERVER.ERROR_CODE.CARD_SCAN_IDCARD_NO_MASKING_IMAGE);
            }
            if (!ocr.userName) {
              throw new useb.UsebError('O003');
            }
            if (ocr._juminNo2) {
              this.success(ocr, `${mime},${ocr.image_base64_mask}`, base64Face);
            } else {
              this.success(ocr, base64Card, base64Face);
            }
          } else if (cardIndex === Constants.APP_CARD_INDEX.PASSPORT_ALIEN) {
            // 외국 여권
            this.$log.debug('외국인 여권 진입점');
            const ocr = await useb.getOcrPassportOverseasFile(formData);
            this.$log.debug(ocr);
            if (/KOR|K0R/.test(ocr.nationality)) {
              throw new useb.UsebError(Constants.CLIENT.ERROR_CODE.CARD_SCAN_IDCARD_TYPE_NOT_MATCH);
            }
            this.success(ocr, base64Card, base64Face);
          } else if (cardIndex === Constants.APP_CARD_INDEX.ALIEN) {
            // 외국인등록증
            const ocr = await useb.getOcrAlienFile(formData);
            if (!ocr.image_base64_mask) {
              throw new useb.UsebError(Constants.SERVER.ERROR_CODE.CARD_SCAN_IDCARD_NO_MASKING_IMAGE);
            }
            this.success(ocr, `${mime},${ocr.image_base64_mask}`, base64Face);
          }
        } catch (e) {
          errorPopupProcess.call(this, e);
          return 'error: ' + e;
        }
        // eslint-disable-next-line no-unreachable
        return 'success';
      };

      const errorPopupProcess = (e) => {
        this.$log.debug('CardScan#onConfirmOk error', e.errorCode, e.message);
        if (
            e.errorCode &&
            typeof e.errorCode === 'string' &&
            this.errorCodeControlList.includes(e.errorCode)
        ) {
          this.serverError = true;
          this.errorIcon = this.cardGuideIcon;
          this.errorButton = [this.$t('버튼.종료'), this.$t('버튼.재시도')];
          this.errorMessageTitle = [this.$t('신분증.정보 감지 실패')];

          switch (e.errorCode) {
            case Constants.CLIENT.ERROR_CODE.CARD_SCAN_IDCARD_TYPE_NOT_MATCH:
              this.errorMessage = [
                this.$t('신분증.선택하신 신분증 종류와', {
                  idcardType: `${this.cardIndexFormatterForI18N(this.appData.cardIndex)}`
                }),
                this.$t('신분증.제출된 신분증이 일치하지 않습니다.'),
                this.$t('신분증.재시도 하시겠습니까?'),
              ];
              break;
            case Constants.CLIENT.ERROR_CODE.CARD_SCAN_FACE_RECOGNITION_FAILED:
              this.errorMessage = [
                this.$t('신분증.신분증 사진에서'),
                this.$t('신분증.얼굴을 감지하지 못하였습니다.'),
                this.$t('신분증.재시도 하시겠습니까?'),
              ];
              this.errorButton = [this.$t('버튼.종료'), this.$t('버튼.재시도')];
              break;
            default:
              this.errorMessage = [
                this.$t('신분증.신분증 사진에서'),
                this.$t('신분증.신분증을 인식하지 못하였습니다.'),
                this.$t('신분증.재시도 하시겠습니까?'),
              ];
              break;
          }
        } else {
          this.$log.debug(e);
          this.serverError = true;
          this.errorIcon = {};
          this.errorMessageTitle = [
            this.$t('에러.시스템 에러가 발생하였습니다.'),
            this.$t('에러.잠시 후 다시 이용해 주세요.'),
          ];
          this.errorMessage = this.companyPhoneNumber
            ? [
              this.$t('에러.계속해서 문제가 발생한다면'),
              this.$t('에러.고객센터로 문의해주세요.', {
                companyPhoneNumber: this.companyPhoneNumber,
              }),
            ]
            : [];
          this.errorButton = ['', this.$t('버튼.확인')];
        }
        this.errorCodeOrigin = e.errorCode;
        this.errorCode = e.errorCode
            ? `${this.$t('에러.에러코드')} : ${e.errorCode}`
            : '';
      };

      if (netfunnel.isUsed) {
        netfunnel.NetFunnel_Action(
            { action_id: 'idcard_ocr' },
            {
              success: function () {
                //대기가 없거나, 대기 종료 후에 넷퍼넬 자원 할당을 받을 때 호출
                __impl().then((ret) => {
                  _this.$log.fatal('NetFunnel_Action Result : ' + ret);
                  netfunnel.NetFunnel_Complete();
                });
              },
              continued: function () {
                //대기 단계에서 호출
                // nothing to do
              },
              stop: function () {
                //대기창의 중지 버튼 클릭 시 호출
                netfunnel.NetFunnel_Complete();
                _this.phaseUpload = _this.PHASE_SCAN;
              },
              error: function (ev, ret) {
                //넷퍼넬 서버에서 error응답을 받았을 때
                //(default: error callbcak생략시, error도 success로 동작)
                netfunnel.NetFunnel_Complete();
                try {
                  if (ret?.code == '513') {
                    _this.$log.error(
                        'NetFunnel_Action Skip (cause : ' + ret?.data?.msg
                    );
                    __impl();
                    return;
                  }

                  let error_code = 'NFN_ERR';
                  if (ret?.code) {
                    error_code += '_' + ret?.code;
                  }
                  if (ret?.data?.msg) {
                    error_code += '_' + ret?.data?.msg;
                  }

                  throw new useb.UsebError(
                      error_code,
                      'netFunnel Error\n - ' + JSON.stringify(ret)
                  );
                } catch (e) {
                  errorPopupProcess.call(_this, e);
                }
              },
              /*
            bypass: function(ev, ret){
              //넷퍼넬 관리자페이지에서 해당 actionID를 bypass(우회) 설정 시 호출
            },
            block: function(ev, ret){
              //넷퍼넬 관리자페이지에서 해당 actionID를 block(차단) 설정 시 호출
            },
            ipblock: function(ev, ret){
              //엑세스 제어기능을 통해, 제어룰에 해당되어 차단되는 경우에 호출
            },
            expressnumber: function(ev, ret){
              //VIP 설정(ip, id)에 등록된 사용자의 요청이 있을 경우에 호출 bypass
            }
            */
            }
        );
      } else {
        await __impl();
      }
    },

    success(ocr, base64Card, base64Face) {
      this.$log.debug('CardScan#success', ocr);
      delete ocr.image_base64_mask;
      this.$emit('next', {
        ocr,
        base64Card,
        base64Face,
        base64CardOrigin: this.tmpAppData.base64CardImage,
      });
      this.$emit('uploadtype', this.isMobile ? 'mobile' : 'pc');
    },
  },
};
</script>

<style lang="scss" scoped>
.root-container {
  position: relative;
  height: 100%;
  color: var(--surface-high);
  overflow: auto;
}

.container-phase-guide {
  display: flex;
  flex-direction: column;
  height: 100%;

  .title {
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    margin: 0px auto 27px;
    text-align: center;
  }

  .icon-card-guide-img {
    width: 177px;
    margin: 24px auto 42px;
  }

  .tip-title {
    text-align: left;
    margin-left: 30px;
    margin-bottom: 24px;
    font-weight: 500;
    font-size: 20px;
  }

  .tip-container {
    align-self: center;
  }

  .tip-item {
    display: flex;
    align-items: baseline;
    text-align: left;
    margin-left: 36px;
    margin-right: 36px;
    margin-bottom: 16px;
    font-size: 16px;
    & > img {
      height: 10px;
      margin-right: 16px;
    }
  }

  .upload-input-container {
    display: flex;
    justify-content: center;

    .upload-input-wrapper {
      position: relative;
      margin-bottom: 32px;
      width: clamp(200px, 100%, 500px);

      .upload-input {
        visibility: hidden;
        border-radius: 8px;
        width: calc(100% - 36px);
        height: 40px;
        position: absolute;
      }
    }
  }
}
.bold {
  color: var(--primary-80);
}

.spacer {
  flex-grow: 1;
}

// v-input custom
.input-status-container {
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  padding: 8px 24px;
  background: var(--gray-100);

  .upload-textholoer {
    font-size: 1rem;
    font-weight: 500;
    color: var(--surface-medium);
    & > span {
      font-size: 13px;
      font-weight: 400;
      color: var(--surface-medium);
    }
  }

  .upload-file-name {
    flex: 1;
    height: 100%;
  }
}

.v-input__slot {
  border-radius: 8px !important;
}

.button-container {
  margin-top: 56px;
  width: 100%;
  bottom: 0;
  display: flex;
  padding: 0px auto 40px;
  background-color: white;

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    font-weight: 500;
    font-size: 1rem;
    height: 60px;
    cursor: pointer;
    user-select: none;

    &.cancel {
      flex-grow: 0.65;
      background: var(--gray-100);
      color: var(--surface-medium);
      margin-right: 10px;
    }

    &.ok {
      flex-grow: 1;
      color: var(--font-color);
      background: var(--primary-100);
    }

    &.disabled {
      background-color: var(--primary-20);
    }
  }
}

.container-phase-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 3;

  background-color: var(--secondary-100);
  color: white;

  .loading {
    width: 172px;
    margin-bottom: 50px;
    &.small {
      width: 40px;
      margin-bottom: 16px;
    }
  }

  .message {
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 8px;
    &.small {
      font-size: 16px;
      color: var(--surface-medium);
    }
  }
}
</style>

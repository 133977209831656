<template>
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M27.25 14C27.25 21.3178 21.3178 27.25 14 27.25C6.68223 27.25 0.75 21.3178 0.75 14C0.75 6.68223 6.68223 0.75 14 0.75C21.3178 0.75 27.25 6.68223 27.25 14Z"
      stroke="var(--primary-80)"
      stroke-width="1.5"
    />
    <path
      d="M8 14H20"
      stroke="var(--primary-80)"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M14 20L14 8"
      stroke="var(--primary-80)"
      stroke-width="2"
      stroke-linecap="round"
    />
  </svg>
</template>

<script>
export default {};
</script>

<template>
  <v-dialog v-model="dialog" max-width="384px" persistent>
    <v-card>
      <v-card-text
        style="display: flex; flex-direction: column; padding: 0; margin: 0"
      >
        <img
          v-if="icon"
          :src="icon"
          width="60px"
          style="margin: 40px auto 16px"
        />
        <div :class="{ 'custom-ui-title': true, icon }">
          <div v-for="(msg, index) in title" :key="index">{{ msg }}</div>
        </div>
        <div :class="{ 'message-container': true, 'error-code': errorCode }">
          <div v-for="(msg, index) in message" :key="index">{{ msg }}</div>
        </div>
        <div v-if="errorCode" class="text-error-code">
          {{ errorCode }}
        </div>
        <div class="button-container" style="margin-bottom: 32px">
          <div v-if="button[0]" @click="cancel" class="button cancel">
            {{ button[0] }}
          </div>
          <div v-if="button[1]" @click="ok" class="button ok">
            {{ button[1] }}
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: '',
    },
    title: {
      type: Array,
      default: () => [],
    },
    message: {
      type: Array,
      default: () => [],
    },
    errorCode: {
      type: String,
      default: '',
    },
    button: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      dialog: this.value,
    };
  },
  watch: {
    value(val) {
      this.dialog = val;
    },
  },
  methods: {
    close() {
      this.dialog = false;
      this.$emit('input', false);
    },
    cancel() {
      this.close();
      this.$emit('cancel');
    },
    ok() {
      this.close();
      this.$emit('ok');
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-ui-title {
  margin-top: 40px;
  margin-bottom: 8px;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  color: var(--primary-100);
  text-align: center;

  &.icon {
    margin-top: 0;
  }
}

.message-container {
  padding: 0 20px;
  margin-bottom: 32px;
  font-size: 16px;
  line-height: 26px;
  color: var(--surface-high);
  text-align: center;

  &.error-code {
    margin-bottom: 17px;
  }

  &:last-child {
    margin-bottom: 13px;
  }
}
.text-error-code {
  margin-bottom: 32px;
  font-size: 16px;
  line-height: 26px;
  color: var(--surface-medium);
  text-align: center;
}
.button-container {
  display: flex;
  margin-bottom: 39px;
  padding: 0 30px;

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    font-weight: 500;
    font-size: 1rem;
    height: 60px;
    cursor: pointer;
    user-select: none;

    &.cancel {
      flex-grow: 0.65;
      background: var(--gray-100);
      color: var(--surface-medium);
      margin-right: 10px;
    }

    &.ok {
      flex-grow: 1;
      color: var(--surface-100);
      background: var(--primary-100);
    }
  }
}
</style>

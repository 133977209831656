<template>
  <svg
    width="256"
    height="180"
    viewBox="0 0 256 180"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="48.5078" cy="144" r="36" fill="var(--primary-5)" />
    <g style="mix-blend-mode: multiply">
      <circle cx="18.9663" cy="126.599" r="18.9663" fill="var(--primary-5)" />
    </g>
    <circle cx="206.048" cy="70.6896" r="27.9504" fill="var(--primary-5)" />
    <path
      d="M205.854 112.965H52.918V67.5656C52.9158 66.4816 53.1181 65.4147 53.5059 64.4653C53.8937 63.5159 54.4543 62.7152 55.1348 62.1384L126.841 0.988791C127.604 0.342731 128.486 0 129.386 0C130.287 0 131.169 0.342731 131.931 0.988791L203.637 62.1384C204.318 62.7152 204.879 63.5159 205.266 64.4653C205.654 65.4147 205.856 66.4816 205.854 67.5656V112.965Z"
      fill="var(--primary-10)"
    />
    <g filter="url(#filter0_dd_10999_94401)">
      <path
        d="M181.548 115.837H76.4512C75.1779 115.836 73.9571 115.158 73.0567 113.953C72.1563 112.748 71.6499 111.114 71.6484 109.41V17.9156C71.6499 16.2116 72.1563 14.5779 73.0567 13.3729C73.9571 12.168 75.1779 11.4902 76.4512 11.4883H181.548C182.821 11.4902 184.042 12.168 184.942 13.3729C185.843 14.5778 186.349 16.2116 186.351 17.9156V109.41C186.349 111.114 185.843 112.748 184.942 113.953C184.042 115.158 182.821 115.836 181.548 115.837Z"
        fill="white"
      />
    </g>
    <circle cx="129.77" cy="56.7582" r="12.977" fill="var(--primary-20)" />
    <path
      d="M129.587 72.8438C114.527 72.8438 102.287 83.0165 102.009 95.6543C102.002 95.9432 102.057 96.2246 102.194 96.4789C103.262 98.4559 109.204 107.368 129.587 107.368C149.969 107.368 155.911 98.4559 156.979 96.4789C157.116 96.2246 157.171 95.9432 157.164 95.6543C156.887 83.0165 144.646 72.8438 129.587 72.8438Z"
      fill="var(--primary-20)"
    />
    <path
      d="M129.403 72.6943C114.344 72.6943 102.103 82.8671 101.825 95.5048C101.819 95.7938 101.873 96.0752 102.011 96.3295C103.078 98.3065 109.02 107.218 129.403 107.218C149.786 107.218 155.728 98.3065 156.795 96.3295C156.933 96.0752 156.987 95.7938 156.981 95.5048C156.703 82.8671 144.462 72.6943 129.403 72.6943Z"
      fill="var(--primary-20)"
    />
    <path
      d="M201.093 151.267H57.6797C56.4173 151.265 55.2069 150.59 54.3142 149.389C53.4215 148.187 52.9194 146.559 52.918 144.86V74.1476C52.9194 72.4488 53.4215 70.8202 54.3142 69.6191C55.2069 68.4179 56.4173 67.7421 57.6797 67.7402L57.7886 67.7701L120.887 103.589C123.579 105.106 126.468 105.888 129.386 105.888C132.304 105.888 135.194 105.106 137.885 103.589L201.036 67.7403H201.093C202.355 67.7422 203.565 68.4179 204.458 69.6191C205.351 70.8202 205.853 72.4489 205.854 74.1476V144.86C205.853 146.559 205.351 148.187 204.458 149.389C203.565 150.59 202.355 151.265 201.093 151.267Z"
      fill="var(--primary-10)"
    />
    <rect
      x="83.2422"
      y="32.3154"
      width="66.7133"
      height="3.12719"
      rx="1.56359"
      fill="#F7F7F7"
    />
    <rect
      x="83.2422"
      y="23.9766"
      width="35.4415"
      height="3.12719"
      rx="1.56359"
      fill="#F7F7F7"
    />
    <circle cx="128" cy="151" r="21" fill="#12B78F" />
    <circle cx="119" cy="151" r="2" fill="white" />
    <circle cx="128" cy="151" r="2" fill="white" />
    <circle cx="137" cy="151" r="2" fill="white" />
    <defs>
      <filter
        id="filter0_dd_10999_94401"
        x="67.8051"
        y="8.60577"
        width="122.389"
        height="112.036"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="0.960839" />
        <feGaussianBlur stdDeviation="1.92168" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_10999_94401"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="0.960839" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_10999_94401"
          result="effect2_dropShadow_10999_94401"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_10999_94401"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
</template>

<script>
export default {};
</script>

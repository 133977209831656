import axios from 'axios';
import {
  getBearerToken,
  setToken,
  getToken,
  makeAlcheraError,
  getUserInfo,
  getCompanyPhoneNumber,
  getCompanyName,
  logout,
  destroyPermanentTokens,
  AlcheraError,
} from '@/api/auth';
import Vue from 'vue';

const api = axios.create({
  baseURL: process.env.VUE_APP_SERVER_URL || 'https://kyc-dev.aiir.ai:446/',
});
api.interceptors.request.use(getBearerToken);
api.interceptors.response.use(
  (response) => {
    const data = response.data;
    if (
      data?.api_response?.result_code === 'N100' ||
      data?.result_code === 'N100' ||
      data?.code == 200
    ) {
      return data;
    } else {
      //ID already exists.
      if (
        data?.api_response?.result_code == 'F602' ||
        data?.api_response?.result_code == 'F606'
      ) {
        return data;
      }
      throw makeAlcheraError({ response: { data } });
    }
  },
  (error) => {
    Vue.$log.debug('server#interceptorErrorHandle~', error);
    throw makeAlcheraError(error);
  }
);

async function signin({ customer_id, username, password }) {
  const data = await api.post(`sign-in`, {
    customer_id,
    username,
    password,
  });
  setToken({ ...data, customer_id: customer_id });
  return getToken();
}

/**
 *
 * @param {FormData} params
 * @param {String} params.name
 * @param {String} params.phone_number
 * @param {String} params.birthday
 */
async function startApplication(params) {
  // params.append('reuse', '0');
  return await api.post(`application`, params);
}

/**
 * @param {FormData} params
 * @param {String} params.transaction_id
 * @param {File} params.id_card_image
 * @param {File} [params.id_card_origin] [optional] card image w/o masking
 * @param {File} [params.id_crop_image] [optional] 얼굴 모듈 ON시
 * @param {Number} params.ocr_modified 0: false, 1: true
 * @param {Number} params.verified_id_card 0: false, 1: true
 * @param {String} params.ori_ocr_data
 * @param {String} params.mod_ocr_data
 * @param {String} params.is_manual_input
 * @param {String} [params.is_uploaded] [optional] "true" | "false"
 * @param {String} params.uploaded_type "camera" | "pc" | "mobile"
 */
async function startApplicationIDCard(params) {
  return await api.post(`application/idcard`, params);
}
async function getIdCards() {
  const data = await api.get(`idcards`);
  return data.list;
}
/**
 *
 * @param {FormData} params
 * @param {String} params.transaction_id
 * @param {File} params.selfie_image
 * @param {File} [params.liveness_image] [optional] Liveness 모듈 ON
 */
async function startApplicationFace(params) {
  return await api.post(`application/facecheck`, params);
}

/**
 *
 * @param {FormData} params
 * @param {String} params.transaction_id
 * @param {Number} params.verified_account
 */
async function startApplicationAccount(params) {
  return await api.post(`application/account`, params);
}

/**
 *
 * @param {json} params
 * @param {String} params.transaction_id
 * @param {String} params.json_key
 */
async function startApplicationCustom(params) {
  return await api.post(`application/custom`, params);
}
async function startApplicationEdd(params) {
  return await api.post(`application/edd`, params);
}

async function getUserModule() {
  const data = await api.get(`modules`);
  return data.module;
}

async function getCustomFields() {
  const data = await api.get(`field/survey`);
  return data.list;
}

async function getChangeCountHistoryAccount(transaction_id) {
  const data = await api.get(
    `histories/account/count?transaction_id=${transaction_id}`
  );
  return data.total_count;
}

async function postHistoryAccount(params) {
  const data = await api.post(`histories/account`, params);
  return data.id;
}

async function getTokenUseb() {
  const data = await api.get(`useb/token`);
  return data.token;
}

async function postAttachMents(formData) {
  const data = await api.post('attachments', formData);
  return data.id;
}

async function deleteAttachMents(id) {
  const data = await api.delete(`attachments/${id}`);
  return data;
}

/**
 *
 * @returns {{identification: boolean, api_response: {result_code: string, result_message: string}}}
 */
async function getConfigs() {
  return await api.get(`configs`);
}

/**
 * PUT/ HMAC
 * @param {}
 */
async function issueHmac() {
  return await api.put('secrets/hmac');
}

/**
 *
 * @param {}
 */
async function deleteHmac() {
  return await api.delete('secrets/hmac');
}

/**
 *
 * @param {}
 */
async function getHmac() {
  return await api.get('secrets/hmac');
}

async function getRootConfigs(payload) {
  return await api.get(`customers/${payload.id}/configs`);
}
async function getIdCardKind(payload) {
  return await api.get(`customers/${payload.id}/idcards`);
}
async function getClientModule(payload) {
  const data = await api.get(`modules/${payload.id}`);
  return data;
}
async function getClientDetailInfo(payload) {
  return await api.get(`customers/${payload.id}`);
}

/**
 * 위험 카테고리를 조회함
 * @return { { id: number, name: string, weight: number }[] }
 */
async function getEddCategory() {
  const { list } = await api.get(`field/edd_category`);
  return list;
}
/**
 * 전체 국가 리스트
 * @returns { { id: number, iso_code2: string, iso_code3: string, name_en: string, name_kr: string, score: number, warn_labels: { name_en: string, name_kr: string }[] }[] }
 */
async function getCountry() {
  const { list } = await api.get(`country`);
  return list;
}
/**
 * Admin에서 설정한 EDD 국가 리스트
 * @returns { { id: number, iso_code2: string, iso_code3: string, name_en: string, name_kr: string, score: number, warn_labels: { name_en: string, name_kr: string }[] }[] }
 * */
async function getEddCountry() {
  const { list } = await api.get(`edd_countries`);
  return list;
}
/**
 * EDD Field 전체 리스트를 조회함
 * @returns { {id: number,  name: string, type: string ,active: boolean, category: string, score: number, deletable: boolean, mutable: boolean, order: number, display_name: string, description: string, tree: { name: string, score: number, tree: tree[] }[] } }
 */
async function getEddFields() {
  const { list } = await api.get(`field/edd_fields`);
  return list;
}
/**
 * edd_category에 해당하는 EDD Field리스트를 조회함
 * @returns { [number, number] }
 */
async function getRaThreshold() {
  const { value } = await api.get(`ra_threshold`);
  return value;
}

export default {
  //User Mobile//
  startApplication,
  startApplicationIDCard,
  startApplicationFace,
  startApplicationAccount,
  startApplicationCustom,
  startApplicationEdd,
  getUserModule,
  getCustomFields,
  getTokenUseb,
  getIdCards,
  getChangeCountHistoryAccount,
  getCountry,
  getEddCountry,
  getEddCategory,
  getEddFields,
  getRaThreshold,
  postHistoryAccount,
  postAttachMents,
  deleteAttachMents,

  //Token Interceptor//
  AlcheraError,
  makeAlcheraError,
  setToken,
  getToken,
  getUserInfo,
  getCompanyPhoneNumber,
  getCompanyName,
  logout,
  destroyPermanentTokens,

  //Admin&Reviewer Dashboard
  signin,

  //Admin Dashboard
  getConfigs,
  getHmac,
  issueHmac,
  deleteHmac,

  //Root Dashboard
  getClientModule,
  getRootConfigs,

  //Root Dashboard
  getIdCardKind,
  getClientDetailInfo,
};

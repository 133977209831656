<template>
  <div v-if="isDebugMode" class="wasm-preloading-root">
    preloading status : {{message}}
  </div>
</template>


<script>
import UseBOCR from "./ocr.js";

export default {
  props: {
    isWasmOCRMode: {
      type: Boolean,
      default: false,
    },
    isWasmFaceMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      wasmOCR: null,
      wasmFace: null,
      message: "preloading ... ",
      isDebugMode: false,
      ocr_license_key: process.env.VUE_APP_WASM_OCR_LICENSE_KEY,
    };
  },

  created() {
    this.$log.debug("WASMPreloading - created() START");
    if (this.isWasmOCRMode) {
      this.initOCR();
    }

    if (this.isWasmFaceMode) {
      this.initFace();
    }

    const data = {ocr : this.wasmOCR, face : this.wasmFace}
    this.$emit("onInitialized", data);
    this.$log.debug("WASMPreloading - created() END");
  },
  async mounted() {
    this.$log.debug("WASMPreloading - mounted() START");
    if (this.isWasmOCRMode) {
      await this.preloadOCR();
    }
    if (this.isWasmFaceMode) {
      await this.preloadFace();
    }
    const data = {ocr : this.wasmOCR, face : this.wasmFace}
    this.$emit("onPreloaded", data);
    this.$log.debug("WASMPreloading - mounted() END");
  },
  methods: {
    initOCR() {
      this.wasmOCR = new UseBOCR();

      const ocrSettings = {
        licenseKey: this.ocr_license_key,
        resourceBaseUrl: window.location.origin,
        frameBorderStyle: {
          width: 10,
          style: "solid",
          radius: 20,
          not_ready: '#000000', // 검정
          ready: '#b8b8b8', // 회색
          detect_failed: '#725b67', // 보라
          detect_success: 'var(--primary-40)', // 하늘
          recognized: 'var(--primary-100)', // 파랑
          recognized_with_ssa: 'var(--primary-100)', // 파랑
          ocr_failed: '#FA113D', // 빨강
          ocr_success: '#14b00e', // 초록
          ocr_success_with_ssa: '#14b00e', // 초록
        },
        usePreviewUI: false,
        ssaMaxRetryCount: 2,
        // showClipFrame: true,          // 디버깅
        // showCanvasPreview: true,      // 디버깅
        // resourceBaseUrl: window.location.origin + "/card",      // 일부러 에러 만들기
      };

      this.wasmOCR.init(ocrSettings);
    },

    initFace() {
      // TODO
    },

    async preloadOCR() {
      this.message = "OCR preloading START";
      await this.wasmOCR.preloading();
      this.message = "OCR preloading DONE";
    },

    async preloadFace() {
      this.message = "Face preloading START";
      // TODO
      this.message = "Face preloading DONE";
    },
  }
};
</script>

<template>
  <div class="file-input-root">
    <div class="file-input-container" v-if="loading">
      <img src="@/assets/Loading_40px_grey.svg" alt="loading" />
    </div>
    <div
      :class="['file-input-container', { readonly, focus, error }]"
      v-else-if="!value"
      @click="$refs.fileInput.click()"
    >
      <span class="file-input-label">파일 첨부</span>
    </div>
    <div v-else :class="['file-input-result', { readonly, focus, error }]">
      <span class="file-input-result--name">{{ value.name }}</span>

      <span class="file-input-result--size">
        ({{ Math.floor((value.size / (1024 * 1024)) * 10) / 10 }}MB)
      </span>
      <img @click="onRemove" src="@/assets/cancel_24.svg" alt="cancel" />
    </div>
    <input
      type="file"
      accept=".jpg, .jpeg, .png, .pdf"
      style="visibility: hidden; display: none"
      ref="fileInput"
      @change="onChangeFile"
    />

    <FileUploadErrorDialog
      :show="showFileUploadErrorDialog"
      :error-type="fileUploadErrorType"
      @ok="onConfirmFileUploadError()"
    />
  </div>
</template>
<script>
import util from '@/util';
import FileUploadErrorDialog from '../dialog/FileUploadErrorDialog.vue';
export default {
  components: { FileUploadErrorDialog },
  props: {
    value: { type: Blob, default: '' },
    readonly: { type: Boolean },
    loading: { type: Boolean },
  },
  data() {
    return {
      fileUploadErrorType: '',
      showFileUploadErrorDialog: false,
      focus: false,
      error: false,
      ACCEPTED_FILE_SIZE: 1024 * 1024 * 10, // 10MG
    };
  },
  methods: {
    async onChangeFile(event) {
      this.$log.debug('[DEBUG] FileInputField #onChangeFile');
      const file = event.target.files[0];
      //   this.uploadFile = file;
      // 파일 크기가 크면 image onload 하는데 시간이 걸리기 때문에 일차적으로 확장자 파일 크기 점검
      const ACCEPTED_FILE_FORMATS =
        /(.*?)\.(jpg|jpeg|png|pdf|JPG|JPEG|PNG|PDF)$/;
      const PDF_FORMATS = /(.*?)\.(pdf|PDF)$/;
      if (file) {
        const isValidFileSize = file.size <= this.ACCEPTED_FILE_SIZE;
        const isValidFileFormat = file.name.match(ACCEPTED_FILE_FORMATS);
        if (!isValidFileSize || !isValidFileFormat) {
          this.fileUploadErrorType = !isValidFileSize ? 'size' : 'format';
          this.showFileUploadErrorDialog = true;
          return;
        }
      }
      // 이미지를 캔버스에 대입해서 이미지가 유효한지 검증
      if (!file.name.match(PDF_FORMATS)) {
        try {
          await util.blobToImageURL(file);
          this.$emit('input', file);
          this.uploadFile = file;
        } catch (error) {
          this.$log.error('FileInputField #onChangeFile #imageError: ', error);
          this.fileUploadErrorType = 'invalid-image';
          this.showFileUploadErrorDialog = true;
        }
      } else {
        this.$emit('input', file);
        this.uploadFile = file;
      }
    },
    onConfirmFileUploadError() {
      this.showFileUploadErrorDialog = false;
    },
    onRemove() {
      this.$refs.fileInput.value = '';
      this.$emit('remove');
    },
  },
};
</script>
<style lang="scss" scoped>
.file-input-root {
  max-height: 64px;
  .file-input-container {
    border: 1px solid var(--gray-300);
    border-radius: 8px;
    max-height: 64px;
    padding: 16px 0px;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;
    .file-input-icon {
      margin-right: 8px;
    }
    .file-input-label {
      font-size: 1.25rem;
      line-height: 1.875rem;
      color: var(--surface-medium);
    }
  }
  .file-input-result {
    position: relative;
    background: var(--gray-100);
    border-radius: 8px;
    font-size: 1rem;
    line-height: 1.5rem;
    padding: 8px 24px;
    color: var(--surface-high);
    display: flex;
    align-items: center;

    .file-input-result--name {
      text-overflow: ellipsis;
      white-space: nowrap;
      display: block;
      overflow: hidden;
      max-width: calc(100% - 112px);
    }
    .file-input-result--size {
      margin-left: 8px;
    }
    img {
      margin-left: auto;
      cursor: pointer;
    }
  }
}
</style>

<template>
  <ViewContainer id="cardSelect_container" :paddingHorizontal="30">
    <div class="container-phase-guide">
      <Step v-if="showStepper" :phase="phase" :items="phaseItems"></Step>
      <!-- <div style="min-height:70px;"></div> -->
      <div class="title">{{ $t('신분증.신분증을 선택해 주세요.') }}</div>
      <div
        class="message"
        v-html="$t('신분증.사진이 포함된 신분증으로 본인 확인을 진행합니다.')"
      />
      <div class="cardSelect_wrapper">
        <div
          v-for="(card, index) in filteredIdCardsList"
          v-bind:id="'cardSelect_button_' + card.name"
          :key="index"
          @click="selectIndex = card.id"
          :class="{ 'select-card': true, selected: selectIndex === card.id }"
        >
          <div class="select-image-area">
            <img :src="card.image" class="select-image" />
          </div>
          <div class="select-name">
            {{ card.name }}
            <span style="font-size: 14px; line-height: 20px"
              ><br />{{ card.sub_name }}</span
            >
          </div>
          <div style="flex-grow: 1"></div>
          <Complete_24Vue
            v-if="selectIndex === card.id"
            style="margin-right: 24px"
          />
        </div>
      </div>
      <div style="min-height: 56px"></div>
      <div class="button-container">
        <div
          @click="onCancel"
          :class="{
            button: true,
            cancel: true,
            disabled: selectIndex === -1,
          }"
          id="cardSelect_button_cancel"
        >
          {{ $t('버튼.이전') }}
        </div>
        <div
          @click="
            selectIndex !== undefined &&
              $emit('next', { cardIndex: selectIndex })
          "
          :class="{
            button: true,
            ok: true,
            disabled: selectIndex === undefined,
          }"
          id="cardSelect_button_submit"
        >
          {{ $t('버튼.선택 완료') }}
        </div>
      </div>
    </div>
    <ExitDialog
      v-model="exitDialog"
      @ok="$emit('cancel')"
      @cancel="exitDialog = false"
      :id="'cardSelect_exitPopup'"
      :disabledProp="!exitDialog"
    />
  </ViewContainer>
</template>
<script>
import Step from './Step';
import CONSTANT from '@/constants.js';
import ViewContainer from '@/layout/auth/ViewContainer.vue';
import ExitDialog from '../dialog/ExitDialog.vue';
import Complete_24Vue from './DynamicCt_imgs/Complete_24.vue';
export default {
  components: { Step, ViewContainer, ExitDialog, Complete_24Vue },
  props: {
    phase: Number,
    phaseItems: Array,
    appData: Object,
    showStepper: Boolean,
    isMultipleModule: Boolean,
  },
  mounted() {
    this.$log.debug('CardSelect phase #', this.appData);
    this.selectIndex = this.appData.cardIndex;
  },
  computed: {
    filteredIdCardsList() {
      const idCardsFilter = this.$store.state.idCardsFilter;
      return this.cardList.filter((card) => {
        return idCardsFilter.some(
          (filterCard) =>
            filterCard.active && filterCard.name === card.filterName
        );
      });
    },
    cardList() {
      return [
        {
          id: CONSTANT.APP_CARD_INDEX.JUMIN,
          image: require('@/assets/ID card_Sm.svg'),
          name: this.$t('신분증.주민등록증'),
          filterName: 'korean-registration',
        },
        {
          id: CONSTANT.APP_CARD_INDEX.DRIVER,
          image: require('@/assets/Driver_Sm.svg'),
          name: this.$t('신분증.운전면허증'),
          filterName: 'korean-driver',
        },
        {
          id: CONSTANT.APP_CARD_INDEX.PASSPORT_KOREAN,
          image: require('@/assets/Passport_Sm.svg'),
          name: this.$t('신분증.한국여권'),
          filterName: 'korean-passport',
        },
        {
          id: CONSTANT.APP_CARD_INDEX.PASSPORT_ALIEN,
          image: require('@/assets/Passport_Sm.svg'),
          name: this.$t('신분증.외국여권'),
          filterName: 'foreign-passport',
        },
        {
          id: CONSTANT.APP_CARD_INDEX.ALIEN,
          image: require('@/assets/Foreigner_Sm.svg'),
          name: this.$t('신분증.외국인 등록증'),
          sub_name: this.$t('신분증.(국내거소증/영주증)'),
          filterName: 'foreign-registration',
        },
      ];
    },
  },
  methods: {
    onCancel() {
      if (this.isMultipleModule) {
        this.$emit('cancel', { prev: true });
      } else if (this.appData.identification_config_enabled) {
        this.$emit('cancel', { prev: true });
      } else {
        this.exitDialog = true;
      }
    },
  },
  data() {
    return {
      selectIndex: -1,
      exitDialog: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.root-container {
  position: relative;
  height: 100%;
  overflow: hidden;
  color: var(--surface-high);
  overflow: auto;
}

.container-phase-guide {
  display: flex;
  flex-direction: column;
  height: 100%;

  .title {
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    margin-bottom: 8px;
    text-align: center;
  }

  .message {
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 40px;
    text-align: center;
    color: var(--surface-medium);
  }

  .cardSelect_wrapper {
    padding: 0 30px;
  }
}

@media screen and (max-width: 768px) {
  .container-phase-guide {
    .cardSelect_wrapper {
      padding: 0;
    }
  }
}

.select-card {
  display: flex;
  align-items: center;
  text-align: center;

  min-height: 72px;
  border: 1px solid var(--gray-300);
  border-radius: 8px;
  margin-bottom: 8px;

  &.selected {
    border: 2px solid var(--primary-100);
  }
}

.select-image-area {
  display: flex;
  align-items: center;
  width: 97px;
  .select-image {
    margin-left: 32px;
  }
}

.select-name {
  text-align: left;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: black;
}

.button-container {
  width: 100%;
  // bottom: 0;
  display: flex;
  padding: 0px auto 40px;
  background-color: white;

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    font-weight: 500;
    font-size: 1rem;
    height: 60px;
    cursor: pointer;
    user-select: none;

    &.cancel {
      flex-grow: 0.65;
      background: var(--gray-100);
      color: var(--surface-medium);
      margin-right: 10px;
    }

    &.ok {
      flex-grow: 1;
      color: var(--font-color);
      background: var(--primary-100);
      &.disabled {
        background-color: var(--primary-20);
      }
    }
  }
}
</style>

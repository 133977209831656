var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{staticStyle:{"width":"calc(100% - 96px)"},attrs:{"width":"calc(100% - 96px)","max-width":"384px","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-text',{class:{ disabled: _vm.disabledProp },staticStyle:{"display":"flex","flex-direction":"column","padding":"0px 30px","padding-top":"2rem","margin":"0","text-align":"center"},attrs:{"id":_vm.id}},[_c('WarningVue',{staticClass:"icon-close",class:{ warn: _vm.verifyCount >= 3 },staticStyle:{"width":"60px","height":"60px","margin":"0px auto 18px","position":"static"},on:{"click":function($event){_vm.stopScan = true}}}),_c('div',{staticClass:"card-title"},[_vm._v(_vm._s(_vm.$t('신분증.본인 인증 실패')))]),(_vm.verifyCount < 3)?_c('div',{staticClass:"card-body",domProps:{"innerHTML":_vm._s(
          _vm.$t(
            '계좌.입력하신 인증코드가 일치하지 않습니다.<br />확인 후 재시도 해주세요.'
          )
        )}}):_c('div',{staticClass:"card-body",domProps:{"innerHTML":_vm._s(
          _vm.$t(
            '계좌.입력코드 입력 횟수가 초과되었습니다.<br />인증코드를 다시 요청 해주세요.'
          )
        )}}),_c('div',{staticClass:"card-count",class:{ warn: _vm.verifyCount >= 3 }},[_vm._v(" "+_vm._s(_vm.$t('신분증.시도 횟수'))+" : "+_vm._s(_vm.verifyCount)+"/3 ")]),_c('div',{staticClass:"button-container",staticStyle:{"margin-bottom":"32px"}},[_c('div',{staticClass:"button cancel",on:{"click":_vm.ok}},[_vm._v(_vm._s(_vm.$t('버튼.종료')))]),_c('div',{staticClass:"button ok",on:{"click":_vm.cancel}},[_vm._v(_vm._s(_vm.$t('버튼.재시도')))])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ViewContainer',{ref:"root",attrs:{"id":"cardResult_container","paddingTop":_vm.appData.hasLogo ? 48 : 32}},[_c('img',{staticClass:"icon-close",style:(_vm.appData.hasLogo ? 'top:48px' : 'top:32px'),attrs:{"src":require("@/assets/icon-close-32.svg")},on:{"click":function($event){_vm.stopScan = true}}}),_c('div',{staticClass:"card-result-title"},[_vm._v(_vm._s(_vm.$t('온보딩.신분증 인증')))]),_c('div',{staticClass:"card-result-info"},[_vm._v(" "+_vm._s(_vm.$t('신분증.신분증 정보를 확인해 주세요.'))+" ")]),(_vm.appData.cardIndex === 0 && this.appData.retry > 0)?_c('div',{staticClass:"card-result-warning-text"},[_c('div',{staticClass:"card-result-warning-text-top"},[_c('img',{attrs:{"src":require("@/assets/info_20_red.svg")}}),_c('div',[_vm._v(" "+_vm._s(_vm.$t('신분증.발급 일자 입력 오류 5회 발생 시 주민등록증 잠김'))+" ")])]),_c('div',{staticClass:"card-result-warning-text-bottom"},[_vm._v(" "+_vm._s(_vm.$t('신분증.(진위 확인 불가)'))+" ")])]):_vm._e(),_c('div',{staticClass:"card-result-label"},[_vm._v(_vm._s(_vm.$t('개인정보입력.이름')))]),_c('InputTextField',{ref:"name",staticClass:"card-result-input",attrs:{"readonly":true,"error":!_vm.ocr.userName,"rules":_vm.rulesName,"errorMessage":_vm.errorMessageName,"id":'cardResult_input_name'},on:{"onupdate":_vm.validateForm},model:{value:(_vm.ocr.userName),callback:function ($$v) {_vm.$set(_vm.ocr, "userName", $$v)},expression:"ocr.userName"}}),(
      [
        _vm.Constants.APP_CARD_INDEX.PASSPORT_KOREAN,
        _vm.Constants.APP_CARD_INDEX.PASSPORT_ALIEN,
      ].includes(_vm.appData.cardIndex)
    )?_c('div',{staticClass:"card-result-label"},[_vm._v(" "+_vm._s(_vm.$t('신분증.여권번호'))+" ")]):_vm._e(),(
      [
        _vm.Constants.APP_CARD_INDEX.PASSPORT_KOREAN,
        _vm.Constants.APP_CARD_INDEX.PASSPORT_ALIEN,
      ].includes(_vm.appData.cardIndex)
    )?_c('InputTextField',{attrs:{"placeholder":_vm.manualInput ? 'A00000000' : _vm.originOcr.passportNo,"maxlength":9,"error":_vm.errorPassport,"errorMessage":_vm.ocr.passportNo
        ? _vm.defaultErrorMessage
        : _vm.$t('신분증.여권번호를 입력해 주세요.'),"id":'cardResult_input_passportNumber'},on:{"onupdate":_vm.validateForm},model:{value:(_vm.ocr.passportNo),callback:function ($$v) {_vm.$set(_vm.ocr, "passportNo", $$v)},expression:"ocr.passportNo"}}):_vm._e(),(_vm.appData.cardIndex === _vm.Constants.APP_CARD_INDEX.DRIVER)?_c('div',[(
        _vm.manualInput && _vm.appData.cardIndex === _vm.Constants.APP_CARD_INDEX.DRIVER
      )?_c('div',{staticClass:"card-result-label"},[_vm._v(" "+_vm._s(_vm.$t('신분증.운전면허번호 형식'))+" ")]):_vm._e(),(
        _vm.manualInput && _vm.appData.cardIndex === _vm.Constants.APP_CARD_INDEX.DRIVER
      )?_c('div',{staticClass:"flex-row-layout dl-format-option-container"},[_c('div',{staticClass:"dl-format-option",on:{"click":function($event){_vm.dlFormatOptionValue = 1}}},[_c('img',{staticClass:"radio-icon",attrs:{"src":_vm.dlFormatOptionValue === 1 ? _vm.onRadioIcon : _vm.offRadioIcon,"id":'cardResult_radio_driverNumberFormat_new'}}),_c('span',[_vm._v(_vm._s(_vm.$t('신분증.신형 (숫자)')))])]),_c('div',{staticClass:"dl-format-option",on:{"click":function($event){_vm.dlFormatOptionValue = 2}}},[_c('img',{staticClass:"radio-icon",attrs:{"src":_vm.dlFormatOptionValue === 2 ? _vm.onRadioIcon : _vm.offRadioIcon,"id":'cardResult_radio_driverNumberFormat_old'}}),_c('span',[_vm._v(_vm._s(_vm.$t('신분증.구형 (한글 + 숫자)')))])])]):_vm._e(),_c('div',{staticClass:"card-result-label"},[_vm._v(" "+_vm._s(_vm.$t('신분증.운전면허번호'))+" ")]),_c('div',{staticClass:"flex-row-layout"},[(
          _vm.isDriverLicenseOld || (_vm.manualInput && _vm.dlFormatOptionValue === 2)
        )?_c('InputTextField',{key:`input-key-driverNo1`,staticStyle:{"min-width":"85px","width":"85px"},attrs:{"placeholder":_vm.manualInput ? '서울' : _vm.originOcr.driverNo1,"maxlength":2,"error":_vm.validRequireDriver,"errorMessage":_vm.ocr.driverNo1
            ? _vm.defaultErrorMessage
            : _vm.$t('신분증.운전면허번호를 입력해 주세요.'),"id":'cardResult_input_driverNo1'},on:{"onupdate":_vm.validateForm},model:{value:(_vm.ocr.driverNo1),callback:function ($$v) {_vm.$set(_vm.ocr, "driverNo1", $$v)},expression:"ocr.driverNo1"}}):_vm._e(),(
          _vm.isDriverLicenseOld || (_vm.manualInput && _vm.dlFormatOptionValue === 2)
        )?_c('div',{staticStyle:{"width":"15px"}}):_vm._e(),_c('InputTextField',{key:`input-key-driverNo2`,style:(_vm.isDriverLicenseOld || (_vm.manualInput && _vm.dlFormatOptionValue === 2)
            ? 'flex-grow:1;'
            : 'flex-grow:1;'),attrs:{"placeholder":_vm.manualInput
            ? _vm.dlFormatOptionValue === 2
              ? '00-000000-00'
              : '00-00-000000-00'
            : _vm.originOcr.driverNo2,"transform":_vm.transformDriverLicense,"error":_vm.validRequireDriver,"errorMessage":_vm.isDriverLicenseOld || (_vm.manualInput && _vm.dlFormatOptionValue === 2)
            ? ''
            : _vm.ocr.driverNo2
            ? _vm.defaultErrorMessage
            : _vm.$t('신분증.운전면허번호를 입력해 주세요.'),"pattern":"\\d*","id":'cardResult_input_driverNo2'},on:{"onupdate":_vm.validateForm},model:{value:(_vm.ocr.driverNo2),callback:function ($$v) {_vm.$set(_vm.ocr, "driverNo2", $$v)},expression:"ocr.driverNo2"}})],1)]):_vm._e(),(_vm.appData.cardIndex === _vm.Constants.APP_CARD_INDEX.PASSPORT_ALIEN)?_c('div',{staticClass:"card-result-label"},[_vm._v(" "+_vm._s(_vm.$t('신분증.국적 코드'))+" ")]):_vm._e(),(_vm.appData.cardIndex === _vm.Constants.APP_CARD_INDEX.PASSPORT_ALIEN)?_c('InputTextField',{key:'input-key-nationality',staticClass:"card-result-input",attrs:{"placeholder":_vm.manualInput ? 'ABC' : _vm.originOcr.nationality,"transform":_vm.rules.transformOnlyUpperCase,"rules":[
      _vm.rules.UpperEng,
      (val) => {
        return val.length > 2;
      },
    ],"errorMessage":_vm.ocr.nationality
        ? _vm.defaultErrorMessage
        : _vm.$t('신분증.국적코드를 입력해 주세요.'),"id":'cardResult_input_nationality'},on:{"onupdate":_vm.validateForm},model:{value:(_vm.ocr.nationality),callback:function ($$v) {_vm.$set(_vm.ocr, "nationality", $$v)},expression:"ocr.nationality"}}):_vm._e(),(
      _vm.appData.cardIndex === _vm.Constants.APP_CARD_INDEX.PASSPORT_KOREAN ||
      _vm.appData.cardIndex === _vm.Constants.APP_CARD_INDEX.PASSPORT_ALIEN
    )?_c('div',{staticClass:"card-result-label"},[_vm._v(" "+_vm._s(_vm.$t('개인정보입력.생년월일'))+" ")]):_vm._e(),(
      _vm.appData.cardIndex === _vm.Constants.APP_CARD_INDEX.PASSPORT_KOREAN ||
      _vm.appData.cardIndex === _vm.Constants.APP_CARD_INDEX.PASSPORT_ALIEN
    )?_c('InputTextField',{key:'input-key-birthDate',ref:"birthday",staticClass:"card-result-input",attrs:{"readonly":true,"placeholder":_vm.manualInput ? 'YYYY-MM-DD' : _vm.originOcr.birthDate,"rules":_vm.rulesBirthday,"errorMessage":_vm.errorMessageBirthday,"transform":_vm.rules.transformDateFormat,"onblurTransform":_vm.rules.onblurTransformDateFormat,"id":'cardResult_input_bod'},on:{"onupdate":_vm.validateForm},model:{value:(_vm.ocr.birthDate),callback:function ($$v) {_vm.$set(_vm.ocr, "birthDate", $$v)},expression:"ocr.birthDate"}}):_vm._e(),(_vm.juminTitle)?_c('div',{staticClass:"card-result-label"},[_vm._v(" "+_vm._s(_vm.juminTitle)+" ")]):_vm._e(),(
      _vm.appData.cardIndex !== _vm.Constants.APP_CARD_INDEX.PASSPORT_KOREAN &&
      _vm.appData.cardIndex !== _vm.Constants.APP_CARD_INDEX.PASSPORT_ALIEN
    )?_c('div',{staticClass:"flex-row-layout not-passport-alien"},[_c('InputTextField',{key:`input-key-juminNo1`,class:{ correctJumin: !_vm.errorJuminFirst },staticStyle:{"width":"calc(50% - 14px)"},attrs:{"readonly":true,"type":"number","pattern":"\\d*","placeholder":_vm.originOcr.juminNo1,"maxlength":6,"transform":_vm.rules.transformOnlyNumber,"error":_vm.errorJuminFirst,"id":'cardResult_input_juminNo1'},on:{"onupdate":_vm.validateForm},model:{value:(_vm.ocr.juminNo1),callback:function ($$v) {_vm.$set(_vm.ocr, "juminNo1", $$v)},expression:"ocr.juminNo1"}}),_c('div',{staticClass:"input-between-dash-style"}),(!_vm.showJumin2)?_c('InputTextField',{key:`input-key-_juminNo2`,staticStyle:{"margin-right":"8px","width":"60px"},attrs:{"type":"number","pattern":"\\d*","placeholder":_vm.manualInput ? '0' : _vm.originOcr._juminNo2,"maxlength":1,"transform":_vm.rules.transformOnlyNumber,"error":_vm.errorJumin_Second,"id":'cardResult_input__juminNo2'},on:{"onupdate":_vm.validateForm},model:{value:(_vm.ocr._juminNo2),callback:function ($$v) {_vm.$set(_vm.ocr, "_juminNo2", $$v)},expression:"ocr._juminNo2"}}):_c('InputTextField',{key:`input-key-juminNo2`,staticStyle:{"width":"calc(50% - 14px)"},attrs:{"type":"password","pattern":"\\d*","placeholder":"0000000","maxlength":7,"transform":_vm.rules.transformOnlyNumber,"error":_vm.errorJuminSecond,"id":'cardResult_input_juminNo2',"color":_vm.appData.colorPalette?.tint_80},on:{"onupdate":_vm.validateForm},model:{value:(_vm.ocr.juminNo2),callback:function ($$v) {_vm.$set(_vm.ocr, "juminNo2", $$v)},expression:"ocr.juminNo2"}}),(!_vm.showJumin2)?_c('div',{staticClass:"hide-jumin"},[_vm._v("******")]):_vm._e()],1):_vm._e(),(
      _vm.appData.cardIndex !== _vm.Constants.APP_CARD_INDEX.PASSPORT_KOREAN &&
      _vm.appData.cardIndex !== _vm.Constants.APP_CARD_INDEX.PASSPORT_ALIEN &&
      _vm.errorJumin
    )?_c('div',{staticClass:"text-error"},[_vm._v(" "+_vm._s(_vm.errorJuminMessage)+" ")]):_vm._e(),(_vm.showIssueDate)?_c('div',{staticClass:"card-result-label"},[_vm._v(" "+_vm._s(_vm.$t('신분증.발급 일자'))+" ")]):_vm._e(),(_vm.showIssueDate)?_c('InputTextField',{key:'input-key-issueDate',staticClass:"card-result-input",attrs:{"placeholder":_vm.manualInput ? 'YYYY-MM-DD' : _vm.originOcr.issueDate,"rules":[_vm.rules.required, _vm.rules.date, _vm.rules.isPastThanFuture],"transform":_vm.rules.transformDateFormat,"onblurTransform":_vm.rules.onblurTransformDateFormat,"error":!_vm.validRequireIssueDate,"errorMessage":_vm.ocr.issueDate
        ? _vm.defaultErrorMessage
        : _vm.$t('신분증.발급일자를 입력해 주세요.'),"id":'cardResult_input_issueDate',"pattern":"\\d*"},on:{"keyup":_vm.validateForm},model:{value:(_vm.ocr.issueDate),callback:function ($$v) {_vm.$set(_vm.ocr, "issueDate", $$v)},expression:"ocr.issueDate"}}):_vm._e(),(_vm.appData.cardIndex === _vm.Constants.APP_CARD_INDEX.PASSPORT_KOREAN)?_c('div',{staticClass:"card-result-label"},[_vm._v(" "+_vm._s(_vm.$t('신분증.만료 일자'))+" ")]):_vm._e(),(_vm.appData.cardIndex === _vm.Constants.APP_CARD_INDEX.PASSPORT_KOREAN)?_c('InputTextField',{key:'input-key-expiryDate',staticClass:"card-result-input",attrs:{"placeholder":_vm.manualInput ? 'YYYY-MM-DD' : _vm.originOcr.expiryDate,"appendWidth":"24px","rules":[_vm.rules.required, _vm.rules.date, _vm.rules.expiryDate],"transform":_vm.rules.transformDateFormat,"onblurTransform":_vm.rules.onblurTransformDateFormat,"error":_vm.errorPassportExpiryDate,"errorMessage":_vm.validRequireExpiryDate && _vm.expiryError
        ? _vm.$t('신분증.만료된 유효기간입니다. 확인 후 수정해 주세요.')
        : _vm.ocr.expiryDate
        ? _vm.defaultErrorMessage
        : _vm.$t('신분증.만료일자를 입력해 주세요.'),"id":'cardResult_input_expiryDate',"pattern":"\\d*"},on:{"onupdate":_vm.validateForm},model:{value:(_vm.ocr.expiryDate),callback:function ($$v) {_vm.$set(_vm.ocr, "expiryDate", $$v)},expression:"ocr.expiryDate"}}):_vm._e(),_c('div',{attrs:{"data-description":"fixed-button-area"}}),_c('div',{staticClass:"button-container"},[_c('div',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"button retry",attrs:{"id":'cardResult_button_retry'},on:{"click":_vm.onClickRetry}},[_vm._v(" "+_vm._s(_vm.$t('버튼.재시도'))+" ")]),_c('div',{directives:[{name:"ripple",rawName:"v-ripple"}],class:{ button: true, next: true, disabled: _vm.disabled },attrs:{"id":'cardResult_button_next'},on:{"click":_vm.nextHandler}},[_vm._v(" "+_vm._s(_vm.$t('버튼.다음'))+" ")])]),(
      _vm.loading && _vm.appData.retry !== _vm.MAX_RETRY_COUNT && !_vm.appData.isIdcardLock
    )?_c('Loading',{attrs:{"success":_vm.loadingSuccess,"messages":_vm.loadingMessages}}):_vm._e(),_c('ExitDialog',{attrs:{"id":'cardResult_exitPopup',"disabledProp":!_vm.stopScan},on:{"cancel":_vm.cancelExit,"ok":function($event){return _vm.$emit('cancel')}},model:{value:(_vm.stopScan),callback:function ($$v) {_vm.stopScan=$$v},expression:"stopScan"}}),_c('ServerErrorDialog',{attrs:{"icon":_vm.errorIcon,"title":_vm.errorMessageTitle,"customTitleColor":_vm.customErrorTitleColor,"message":_vm.errorMessage,"customMessageHtmlFormat":_vm.errorHtmlMessage,"errorCode":_vm.errorCode,"button":_vm.errorButton,"id":'cardResult_serverErrorPopup',"disabledProp":!_vm.serverError},on:{"minorBtn":function($event){return _vm.onClickBack(true)},"majorBtn":_vm.onClickCloseServerError},model:{value:(_vm.serverError),callback:function ($$v) {_vm.serverError=$$v},expression:"serverError"}}),(_vm.appData.retry === _vm.MAX_RETRY_COUNT)?_c('CardResultFail',{attrs:{"id":'cardResult_failPopup'},on:{"ok":function($event){return _vm.$emit('cancel')}}}):_vm._e(),(_vm.appData.isIdcardLock)?_c('CardResultFailFromIdcardLock',{attrs:{"id":'cardResult_failPopup_lock'},on:{"ok":function($event){return _vm.$emit('cancel')}}}):_vm._e(),(_vm.issueDateUserInputDialog)?_c('EditIssueDateDialog',{attrs:{"ocr":_vm.ocr,"id":'editIssueDateDialog'},on:{"ok":_vm.onClickEditIssueDate,"cancel":function($event){return _vm.onClickBack(true)}}}):_vm._e(),(_vm.driverExpiryDateUserInputDialog)?_c('EditExpiryDateDialog',{attrs:{"ocr":_vm.ocr,"id":'editExpiryDateDialog'},on:{"ok":_vm.onClickEditExpiryDate,"cancel":function($event){_vm.driverExpiryDateUserInputDialog = false}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
/* eslint-disable */
/* global-module */
import detector from './helpers/detector.js';
import parser from './helpers/parser.js';
import { simd, threads } from './helpers/wasm-feature-detect.js';

let instance;

class UseBOCR {
  IN_PROGRESS = {
    NONE: 'none',
    NOT_READY: 'not_ready',
    READY: 'ready',
    CARD_DETECT_SUCCESS: 'detect_success',
    CARD_DETECT_FAILED: 'detect_failed',
    OCR_RECOGNIZED: 'recognized',
    OCR_RECOGNIZED_WITH_SSA: 'recognized_with_ssa',
    OCR_SUCCESS: 'ocr_success',
    OCR_SUCCESS_WITH_SSA: 'ocr_success_with_ssa',
    OCR_FAILED: 'ocr_failed',
  }

  OCR_STATUS = {
    NOT_READY: -1,
    READY: 0,
    OCR_SUCCESS: 1,
    DONE: 2,
  }

  PRELOADING_STATUS = {
    NOT_STARTED: -1,
    STARTED: 0,
    DONE: 1,
  }

  /** public properties */

  /** private properties */
  __OCREngine = null;
  __initialized = false;
  __preloaded = false;
  __preloadingStatus = this.PRELOADING_STATUS.NOT_STARTED;
  __license;
  __ocrType = null;
  __ssaMode = false;
  __ocrStatus = this.OCR_STATUS.NOT_READY;
  __ssaRetryCount = 0;
  __detectedCardQueue = [];
  __onSuccess = null;
  __onFailure = null;
  __onInProgressChange = null;
  __ocrTypeList = [
    'idcard',
    'driver',
    'passport',
    'foreign-passport',
    'alien',
    'credit',
    'idcard-ssa',
    'driver-ssa',
    'passport-ssa',
    'foreign-passport-ssa',
    'alien-ssa',
    'credit-ssa',
  ];
  __ocrTypeString = new Map([
    ["1", "idcard"],
    ["2", "driver"],
    ["3", "passport"],
    ["4", "foreign-passport"],
    ["5", "alien"],
    ["5-1", "alien"],
    ["5-2", "alien"],
    ["5-3", "alien"],
  ]);
  __pageEnd = false;
  __ocr;
  __canvas;
  __rotationCanvas;
  __video;
  __videoWrap;
  __guideBox;
  __guideBoxWrap;
  __maskBoxWrap;
  __preventToFreezeVideo;
  __customUIWrap;
  __topUI;
  __middleUI;
  __bottomUI;
  __previewUIWrap;
  __previewUI;
  __previewImage;
  __address = 0;
  __detected = false;
  __Buffer = null;
  __resultBuffer = null;
  __PrevImage = null;
  __stringOnWasmHeap = null;
  __camSetComplete = false;
  __resolutionWidth = 0;
  __resolutionHeight = 0;
  __videoWidth = 0;
  __videoHeight = 0;
  __resourcesLoaded = false;
  __intervalTimer;
  __cameraPermissionTimeoutTimer;
  __requestAnimationFrameId;
  __stream;
  __destroyScannerCallback = null;
  __facingModeConstraint = 'environment';
  __uiOrientation = '';
  __prevUiOrientation = '';
  __videoOrientation = '';
  __throttlingResizeTimer = null;
  __throttlingResizeDelay = 500;
  __maxRetryCountGetAddress = 300;       // 임시
  __retryCountGetAddress = 0;            // 임시
  __deviceInfo;
  __isRotated90or270 = false;
  __inProgressStep = this.IN_PROGRESS.NOT_READY;
  __previousInProgressStep = this.IN_PROGRESS.NONE;
  __isInProgressHandleResize = false;
  __guideBoxRatioByWidth = 1.0;      // 수정불가
  __videoRatioByHeight = 0.9;        // 수정불가
  __guideBoxReduceRatio = 0.8;       // 수정불가
  __cropImageSizeWidth = 0;
  __cropImageSizeHeight = 0;

  /** Default options */
  __options = new Object({
    showClipFrame: false,
    showCanvasPreview: false,
    useTopUI: true,
    useTopUITextMsg: false,
    useMiddleUI: true,
    useMiddleUITextMsg: true,
    useBottomUI: true,
    useBottomUITextMsg: false,
    usePreviewUI: true,
    frameBorderStyle: {
      width: 5,
      style: 'solid',
      radius: 20,
      not_ready: '#000000', // 검정
      ready: '#b8b8b8', // 회색
      detect_failed: '#725b67', // 보라
      detect_success: '#5e8fff', // 하늘
      recognized: '#003ac2', // 파랑
      recognized_with_ssa: '#003ac2', // 파랑
      ocr_failed: '#FA113D', // 빨강
      ocr_success: '#14b00e', // 초록
      ocr_success_with_ssa: '#14b00e', // 초록
    },
    useMaskFrameColorChange: true,
    maskFrameStyle: {
      clip_frame: '#ff00bf',      // 딥퍼플 (수정불가)
      base_color: '#333333',      // 다크그레이 (투명도는 수정불가 ff로 고정)
      not_ready: '#333333',
      ready: '#333333',
      detect_failed: '#333333',
      detect_success: '#222222',
      recognized: '#222222',
      recognized_with_ssa: '#222222',
      ocr_failed: '#111111',
      ocr_success: '#111111',
      ocr_success_with_ssa: '#111111',
    },
    resourceBaseUrl: window.location.origin,
    deviceLabel: '',
    videoTargetId: '',
    rotationDegree: 0,
    mirrorMode: false,
    ssaMaxRetryCount: 0,
  });

  /** constructor */
  constructor() {
    if (instance) return instance;
    instance = this;
    return instance;
  }

  /** public methods */
  async preloading() {
    if (this.isPreloaded()) {
      console.log("!!! PRELOADING SKIP, ALREADY PRELOADED !!!")
    } else {
      console.log("!!! PRELOADING START !!!")
      this.__preloadingStatus = this.PRELOADING_STATUS.STARTED;
      await this.__loadResources();
      this.__preloadingStatus = this.PRELOADING_STATUS.DONE;
      this.__preloaded = true;
      console.log("!!! PRELOADING END !!!")
    }
  }

  isInitialized() {
    return this.__initialized;
  }

  isPreloaded() {
    return this.__preloaded;
  }

  getPreloadingStatus() {
    return this.__preloadingStatus;
  }

  getOCREngine() {
    return this.__OCREngine;
  }

  init(settings) {
    if (!!!settings.licenseKey) throw new Error('License key is empty');

    this.__license = settings.licenseKey;

    const mergedOptions = _.merge({}, this.__options, settings);
    this.setOption(mergedOptions);
    console.log(this.getOption());

    if (!this.isInitialized()) {
      this.__windowEventBind();
      this.__deviceInfo = detector.getOsVersion();
      console.debug('this.__deviceInfo.osSimple :: ' + this.__deviceInfo.osSimple);
      this.__initialized = true;
    }
  }

  setOption(settings) {
    this.__options = settings;
  }

  getOption() {
    return this.__options;
  }

  getOcrType(type) {
    return this.__ocrTypeString.get(type);
  }

  getUIOrientation() {
    return this.__uiOrientation;
  }

  getVideoOrientation() {
    return this.__videoOrientation
  }

  async startOCR(type, onSuccess, onFailure, onInProgressChange =null) {
    if (!!!type || !!!onSuccess || !!!onFailure) {
      console.debug("invalid parameter, so skip to startOCR()");
      return;
    }

    this.__ocrType = type;
    this.__ssaMode = (this.__ocrType.indexOf('-ssa') > -1);
    this.__onSuccess = onSuccess;
    this.__onFailure = onFailure;
    this.__onInProgressChange = onInProgressChange;
    if (onInProgressChange) {
      if (this.__options.useTopUI) {
        this.__topUI = detector.getOCRElements().topUI;
      }
      if (this.__options.useMiddleUI) {
        this.__middleUI = detector.getOCRElements().middleUI;
      }
      if (this.__options.useBottomUI) {
        this.__bottomUI = detector.getOCRElements().bottomUI;
      }
    }
    this.__changeStage(this.IN_PROGRESS.NOT_READY);

    if (!this.isInitialized()) {
      throw new Error('Not initialized!');
    }

    try {
      const preloadingStatus = this.getPreloadingStatus();
      if (!this.isPreloaded() && preloadingStatus === this.PRELOADING_STATUS.NOT_STARTED) {
        console.log("!!! WASM OCR IS NOT STARTED PRELOADING. SO, WILL BE START PRELOADING !!!");
        await this.preloading();
      } else {
        if (preloadingStatus === this.PRELOADING_STATUS.STARTED) {
          console.log("!!! WASM OCR IS STARTED. BUT, IS NOT DONE. SO, WAITING FOR PRELOADING !!!");
          await this.__waitPreloaded();
        } else if (preloadingStatus === this.PRELOADING_STATUS.DONE) {
          console.log("!!! ALREADY WASM OCR IS PRELOADED !!!");
        } else {
          throw new Error(`abnormally preloading status, preloaded: ${this.isPreloaded()} / preloadingStatus: ${this.getPreloadingStatus()}`)
        }
      }

      await this.__startScan();
    } catch (e) {
      console.error('error in startOCR() : ' + e);
    } finally {
      this.stopOCR();
    }
  }

  stopOCR() {
      this.cleanup();
      this.__closeCamera();
      this.__onSuccess = null;
      this.__onFailure = null;
    }

  async restartOCR(ocrType, onSuccess, onFailure, onInProgressChange) {
    // await this.stopOCR();
    this.__closeCamera();
    await this.startOCR(ocrType, onSuccess, onFailure, onInProgressChange);
  }

  /** private methods */
  async __waitPreloaded() {
    let waitingRetryCount = 0;
    return new Promise((resolve) => {
      const check = () => {
        setTimeout(async () => {
          if (this.isPreloaded()) {
            resolve();
          } else {
            waitingRetryCount++;
            console.log("waiting for preloading WASM OCR module : " + waitingRetryCount);
            check();
          }
        }, 500);
      };
      check();
    });
  }

  __windowEventBind() {
    const _this_ = this;

    if (/iphone|ipod|ipad/.test(window.navigator.userAgent.toLowerCase())) {
      const skipTouchActionforZoom = (ev) => {
        if (ev.touches.length > 1) {
          ev.preventDefault();
          ev.stopImmediatePropagation();
        }
      };

      window.addEventListener(
        'touchstart',
        skipTouchActionforZoom,
        { passive: false }
      );
      window.addEventListener(
        'touchmove',
        skipTouchActionforZoom,
        { passive: false }
      );
      window.addEventListener(
        'touchend',
        skipTouchActionforZoom,
        { passive: false }
      );
    }

    window.onbeforeunload = function () {
      _this_.__pageEnd = true;
      _this_.cleanup();
    }

    const handleResize = async () => {
      if (!!!_this_.__ocrType) return;

      if (!_this_.__isInProgressHandleResize) {
        _this_.__isInProgressHandleResize = true;
        _this_.__throttlingResizeTimer = null;
        console.log('!!! RESIZE EVENT !!!');

        _this_.__isInProgressHandleResize = false;
        await _this_.restartOCR(_this_.__ocrType, _this_.__onSuccess, _this_.__onFailure, _this_.__onInProgressChange);
      } else {
        console.log('!!! SKIP RESIZE EVENT - previous resize event process is not completed yet !!!');
      }
    }

    window.addEventListener('resize', async () => {
      if (!!!_this_.__throttlingResizeTimer) {
        _this_.__throttlingResizeTimer = setTimeout(handleResize, _this_.__throttlingResizeDelay);
      }
    });
  };

  __sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  __blobToBase64(blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }
  /** 라이센스 키를 heap 에 allocation */
  __getStringOnWasmHeap() {
    if (!!!this.__license) {
      throw new Error("License Key is empty");
    }
    const lengthBytes = this.__OCREngine.lengthBytesUTF8(this.__license) + 1;
    this.__stringOnWasmHeap = this.__OCREngine._malloc(lengthBytes);
    this.__OCREngine.stringToUTF8(this.__license, this.__stringOnWasmHeap, lengthBytes);
    return this.__stringOnWasmHeap;
  }
  __setVideoResolution(videoElement) {
    let isSupportedResolution = false;
    let resolutionText = 'not ready';

    if (!this.__camSetComplete) {
      return { isSupportedResolution, resolutionText };
    }

    if (videoElement.videoWidth === 0 && videoElement.videoHeight === 0) {
      this.__changeStage(this.IN_PROGRESS.NOT_READY);
      return { isSupportedResolution, resolutionText };
    }

    resolutionText = videoElement.videoWidth + 'x' + videoElement.videoHeight;

    if (
      (videoElement.videoWidth === 1080 && videoElement.videoHeight === 1920) ||
      (videoElement.videoWidth === 1920 && videoElement.videoHeight === 1080)
    ) {
      isSupportedResolution = true;
    } else if (
      (videoElement.videoWidth === 1280 && videoElement.videoHeight === 720) ||
      (videoElement.videoWidth === 720 && videoElement.videoHeight === 1280)
    ) {
      isSupportedResolution = true;
    } else {
      videoElement.srcObject = null;
      isSupportedResolution = false
    }
    this.__videoWidth = videoElement.videoWidth;
    this.__videoHeight = videoElement.videoHeight;
    return { isSupportedResolution, resolutionText };
  }
  __getScannerAddress(ocrType) {
    if (!this.__ocrTypeList.includes(ocrType))
      throw new Error('Unsupported OCR type');

    try {
      let address = 0;
      let destroyCallback = null;

      const stringOnWasmHeap = this.__getStringOnWasmHeap();

      switch (ocrType) {
        // OCR
        case 'idcard':
        case 'driver':
        case 'idcard-ssa':
        case 'driver-ssa':
          address = this.__OCREngine.getIDCardScanner(stringOnWasmHeap);
          destroyCallback = () => this.__OCREngine.destroyIDCardScanner(address);
          break;
        case 'passport':
        case 'foreign-passport':
        case 'passport-ssa':
        case 'foreign-passport-ssa':
          address = this.__OCREngine.getPassportScanner(stringOnWasmHeap);
          destroyCallback = () => this.__OCREngine.destroyPassportScanner(address);
          break;
        case 'alien':
        case 'alien-ssa':
          address = this.__OCREngine.getAlienScanner(stringOnWasmHeap);
          destroyCallback = () => this.__OCREngine.destroyAlienScanner(address);
          break;
        case 'credit':
        case 'credit-ssa':
          address = this.__OCREngine.getCreditScanner(stringOnWasmHeap);
          destroyCallback = () => this.__OCREngine.destroyCreditScanner(address);
          break;
        default:
          throw new Error('Scanner does not exists');
      }
      this.__OCREngine._free(stringOnWasmHeap);

      if (address === 0) {
        if (this.__maxRetryCountGetAddress === this.__retryCountGetAddress) {
          throw new Error("Wrong License Key");
        }
        this.__retryCountGetAddress++;
      }
      return [address, destroyCallback];
    } catch (e) {
      // TODO : License Issue인 경우 에러 값을 받아서 error 로그를 찍을 수 있게 요청필요 (임시 N번 이상 address를 못받으면 강제 에러)
      console.error('getScannerAddressError()');
      console.error(e);
      throw e;
    }
  }
  __getBuffer() {
    if (!this.__Buffer) {
      this.__Buffer = this.__OCREngine._malloc(
        this.__resolutionWidth * this.__resolutionHeight * 4
      );
    }
    if (!this.__resultBuffer) {
      this.__resultBuffer = this.__OCREngine._malloc(256);
    }
    return [this.__Buffer, this.__resultBuffer];
  }

  async __getImageBase64(address, maskMode, cropMode) {
    try {
      this.__OCREngine.encodeJpgDetectedFrameImage(address, maskMode, cropMode);

      const jpgSize = this.__OCREngine.getEncodedJpgSize();
      const jpgPointer = this.__OCREngine.getEncodedJpgBuffer();

      const resultView = new Uint8Array(
        this.__OCREngine.HEAP8.buffer,
        jpgPointer,
        jpgSize
      );
      const result = new Uint8Array(resultView);

      const blob = new Blob([result], { type: 'image/jpeg' });
      return await this.__blobToBase64(blob);
    } catch (e) {
      console.error('error:' + e);
      throw e;
    } finally {
      this.__OCREngine.destroyEncodedJpg();
    }
  }
  /** Free buffer */
  __destroyBuffer() {
    if (this.__Buffer) {
      this.__OCREngine._free(this.__Buffer);
      this.__Buffer = null;
    }
    if (this.__resultBuffer !== null) {
      this.__OCREngine._free(this.__resultBuffer);
      this.__resultBuffer = null;
    }
  }
  /** Free PrevImage buffer */
  __destroyPrevImage() {
    if (this.__PrevImage !== null) {
      this.__OCREngine._free(this.__PrevImage);
      this.__PrevImage = null;
    }
  }
  /** free string heap buffer */
  __destroyStringOnWasmHeap() {
    if (this.__stringOnWasmHeap) {
      this.__OCREngine._free(this.__stringOnWasmHeap);
      this.__stringOnWasmHeap = null;
    }
  }
  /** free scanner address */
  __destroyScannerAddress() {
    if (this.__destroyScannerCallback) {
      this.__destroyScannerCallback();
      this.__destroyScannerCallback = null;
    }
  }
  __isVideoResolutionCompatible(videoElement) {
    const { isSupportedResolution, resolutionText } = this.__setVideoResolution(videoElement);
    if (!isSupportedResolution) {
      if (resolutionText !== 'not ready') {
        console.error('Video Resolution(' + resolutionText + ') is not Supported!');
      }
    }
    return isSupportedResolution;
  }
  __getRotationDegree() {
    return ((this.__options.rotationDegree % 360) + 360) % 360;
  }
  __getMirrorMode() {
    return this.__options.mirrorMode;
  }
  async __cropImageFromVideo() {
    if (!this.__camSetComplete) return [null, null];

    let [calcResolution_w, calcResolution_h] = [this.__resolutionWidth, this.__resolutionHeight];
    const { video, canvas, rotationCanvas } = detector.getOCRElements();

    // source image (or video)
    // ┏━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━┓
    // ┃     ┊ sy                              ┃
    // ┃┈┈┈┈ ┏━━━━━━━━━━━━━━━┓ ┊               ┃
    // ┃ sx  ┃               ┃ ┊               ┃
    // ┃     ┃               ┃ ┊ sHeight       ┃
    // ┃     ┃               ┃ ┊               ┃               destination canvas
    // ┃     ┗━━━━━━━━━━━━━━━┛ ┊               ┃━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━┓
    // ┃     ┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈                 ┃    ┊                           ┃
    // ┃           sWidth                      ┃    ┊ dy                        ┃
    // ┗━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━┛    ┏━━━━━━━━━━━━━━━┓ ┊         ┃
    //                                  ┃┈┈┈┈┈┈┈┈┈┈┈┃               ┃ ┊         ┃
    //                                  ┃    dx     ┃               ┃ ┊ dHeight ┃
    //                                  ┃           ┃               ┃ ┊         ┃
    //                                  ┃           ┗━━━━━━━━━━━━━━━┛ ┊         ┃
    //                                  ┃           ┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈           ┃
    //                                  ┃                 dWidth                ┃
    //                                  ┗━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━┛
    // drawImage(image, dx, dy)
    // drawImage(image, dx, dy, dWidth, dHeight)
    // drawImage(image, sx, sy, sWidth, sHeight, dx, dy, dWidth, dHeight)
    // https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/drawImage

    let calcCanvas = canvas;
    let calcVideoWidth = video.videoWidth;
    let calcVideoHeight = video.videoHeight;
    let calcVideoClientWidth = video.clientWidth;
    let calcVideoClientHeight = video.clientHeight;
    let calcCropImageSizeWidth = this.__cropImageSizeWidth;
    let calcCropImageSizeHeight = this.__cropImageSizeHeight;
    let calcVideoOrientation = this.__videoOrientation;

    if (this.__isRotated90or270) {
      [calcCropImageSizeWidth, calcCropImageSizeHeight] = [calcCropImageSizeHeight, calcCropImageSizeWidth];
      [calcResolution_w, calcResolution_h] = [calcResolution_h, calcResolution_w];
      calcCanvas = rotationCanvas;
      calcVideoOrientation = this.__videoOrientation === 'portrait' ? 'landscape' : 'portrait';
    }

    let calcMaxSWidth = 99999;
    let calcMaxSHeight = 99999;

    if (this.__uiOrientation === 'portrait') {
      if (calcVideoOrientation === this.__uiOrientation) {
        // 세로 UI / 세로 카메라
        calcMaxSWidth = calcVideoWidth;
        calcMaxSHeight = calcVideoHeight;
      } else {
        // 세로 UI / 가로 카메라
        calcMaxSHeight = calcVideoHeight;
      }
    } else {
      if (calcVideoOrientation === this.__uiOrientation) {
        // 가로 UI / 가로 카메라
        calcMaxSHeight = calcVideoHeight;
      } else {
        // 가로 UI / 세로 카메라
        calcMaxSWidth = calcVideoWidth;
        calcMaxSHeight = calcVideoHeight;
      }
    }

    let sx, sy;
    const ratio = (calcVideoWidth / calcVideoClientWidth);
    const sWidth = Math.min(Math.round(calcCropImageSizeWidth * ratio), calcMaxSWidth);
    const sHeight = Math.min(Math.round(calcCropImageSizeHeight * ratio), calcMaxSHeight);

    sx = Math.round(((calcVideoClientWidth - calcCropImageSizeWidth) / 2) * ratio);
    sy = Math.round(((calcVideoClientHeight - calcCropImageSizeHeight) / 2) * ratio);

    const calcContext = calcCanvas.getContext('2d', { willReadFrequently: true });
    // console.debug('sx, sy, sWidth(resolution_w), sHeight(resolution_h), video.videoWidth, video.videoHeight', sx, sy, sWidth, sHeight, video.videoWidth, video.videoHeight);
    calcContext.drawImage(
      video,
      sx,
      sy,
      sWidth,
      sHeight,
      0,
      0,
      calcResolution_w,
      calcResolution_h
    );

    const imgData = calcContext.getImageData(
      0,
      0,
      calcResolution_w,
      calcResolution_h
    );
    const imgDataUrl = calcCanvas.toDataURL('image/jpeg');

    if (this.__isRotated90or270) {
      return await this.__rotate(imgData, imgDataUrl, this.__getRotationDegree());
    } else {
      return [imgData, imgDataUrl];
    }
  }
  async __rotate(imgData, imgDataUrl, degree) {
    return new Promise((resolve) => {
      if (degree === 0) {
        resolve([imgData, imgDataUrl]);
      }

      const img = new Image()
      img.src = imgDataUrl;
      img.addEventListener('load', () => {
        const tempCanvas = document.createElement('canvas')
        // canvas = rotationCanvas;
        const tempContext = tempCanvas.getContext('2d');
        tempCanvas.style.position = "absolute"
        if ([90, 270].includes(degree)) {
          tempCanvas.width = img.height
          tempCanvas.height = img.width
        } else if ([0, 180].includes(degree)) {
          tempCanvas.width = img.width
          tempCanvas.height = img.height
        }
        if (degree === 90) tempContext.translate(img.height, 0)
        else if (degree === 180) tempContext.translate(img.width, img.height)
        else if (degree === 270) tempContext.translate(0, img.width)

        tempContext.rotate(degree * Math.PI / 180)
        tempContext.drawImage(img, 0, 0)
        const newImageData = [90, 270].includes(degree) ? tempContext.getImageData(0, 0, img.height, img.width) : tempContext.getImageData(0, 0, img.width, img.height, tempContext);
        resolve([newImageData, tempCanvas.toDataURL('image/jpeg')])
        tempContext.restore();
      })
    });
  }
  async __isCardboxDetected(address, boxType=0, retryImg=null) {
    if (!address || address < 0) {
      return [false, null];
    }
    try {
      let imgData;

      const [buffer] = this.__getBuffer();
      if (retryImg !== null) {
        imgData = retryImg;
      } else {
        [imgData] = await this.__cropImageFromVideo();
      }

      if (!!!imgData) {
        return [false, null];
      }
      this.__OCREngine.HEAP8.set(imgData.data, buffer);

      const result = this.__OCREngine.detect_idcard(
        buffer,
        this.__resolutionWidth,
        this.__resolutionHeight,
        address,
        boxType
      );
      // console.log('isCardboxDetected result -=-----', result)
      return [!!result, imgData];
    } catch (e) {
      const message = 'Card detection error : ' + e;

      if (e.toString().includes('memory')) {
        console.debug(message);
      } else {
        console.error('Card detection error : ' + e);
        throw e;
      }
    }
  }
  async __startRecognition(address, ocrType, ssaMode) {
    try {
      if (address === null) {
        return '';
      } else if (address === -1) {
        return 'checkValidation Fail';
      }

      let ocrResult = null;

      if (!this.__ocrTypeList.includes(ocrType))
        throw new Error('Unsupported OCR type');

      const [, resultBuffer] = this.__getBuffer();
      switch (ocrType) {
        case 'idcard':
        case 'driver':
        case 'idcard-ssa':
        case 'driver-ssa':
          ocrResult = this.__OCREngine.scanIDCard(address, resultBuffer);
          break;
        case 'passport':
        case 'foreign-passport':
        case 'passport-ssa':
        case 'foreign-passport-ssa':
          ocrResult = this.__OCREngine.scanPassport(address, resultBuffer);
          break;
        case 'alien':
        case 'alien-ssa':
          ocrResult = this.__OCREngine.scanAlien(address, resultBuffer);
          break;
        case 'credit':
        case 'credit-ssa':
          ocrResult = this.__OCREngine.scanCredit(address, resultBuffer);
          break;
        default:
          throw new Error('Scanner does not exists');
      }

      if (ocrResult === null || ocrResult === '' || ocrResult === 'false' || ocrResult[0] === 'false') {
        return [false, null, null];
      } else {
        let cropMode = false;
        if (ocrType.indexOf("credit") > -1) {
          cropMode = true;
        }
        let originImage = await this.__getImageBase64(address, false, cropMode);
        let maskImage = await this.__getImageBase64(address, true, cropMode);
        maskImage = (maskImage === 'data:' ? null : maskImage);

        if (ssaMode) {
          this.__changeStage(this.IN_PROGRESS.OCR_RECOGNIZED_WITH_SSA, false, maskImage);
        } else {
          this.__changeStage(this.IN_PROGRESS.OCR_RECOGNIZED);
        }

        return [ocrResult, originImage, maskImage];
      }
    } catch (e) {
      console.error('startRecognition error:' + e);
      throw e;
    }
  }
  __startTruth(ocrType, address) {
    return new Promise((resolve, reject) => {
      const [, resultBuffer] = this.__getBuffer();
      if (ocrType.indexOf("-ssa") > -1) {
        // TODO: worker를 사용하여 메인(UI 랜더링) 스레드가 멈추지 않도록 처리 필요 (현재 loading UI 띄우면 애니메이션 멈춤)
        // TODO: setTimeout 으로 나누더라도 효과 없음 setTimeout 지우고, worker로 변경 필요
        setTimeout(() => {
          resolve(this.__OCREngine.scanTruth(address, resultBuffer));
        }, 500);
      } else {
        reject(new Error('SSA Mode is true. but, ocrType is invalid : ' + ocrType))
      }
    });
  }
  async __startTruthRetry(ocrType, address, imgData) {
    await this.__isCardboxDetected(address, 0, imgData);
    // await this.__startRecognition(address, ocrType, true);      // for 성능을 위해 진행 X
    return await this.__startTruth(ocrType, address);
  }
  __setCameraPermissionTimeoutTimer() {
    this.__clearCameraPermissionTimeoutTimer();
    this.__cameraPermissionTimeoutTimer = setTimeout(async () => {      // 1초 delay 후 실행
      await this.__proceedCameraPermission();
    }, 1000);
  }
  async __proceedCameraPermission() {
    try {
      this.__closeCamera();
      const isPassport = this.__ocrType.includes('passport');
      await this.__setupVideo(isPassport);

      const { video } = detector.getOCRElements();
      if (video) {
        // const [track] = this.__stream.getVideoTracks();
        // const capability = track.getCapabilities();
        // console.debug('CardScan__initialize capability', capability);
        if ('srcObject' in video) {
          video.srcObject = this.__stream;
        } else {
          // Avoid using this in new browsers, as it is going away.
          video.src = window.URL.createObjectURL(this.__stream);
        }
        video.addEventListener('loadedmetadata', () => {
          // console.debug('proceedCameraPermission - onloadedmetadata');
          video.play();
        });
        video.addEventListener('canplay', () => {
          console.debug('canplay');

          // video element style 설정
          this.__videoOrientation =
            video.videoWidth / video.videoHeight < 1 ? 'portrait' : 'landscape';
          console.debug('this.__deviceInfo.osSimple :: ' + this.__deviceInfo.osSimple);
          console.debug('this.__uiOrientation :: ' + this.__uiOrientation);
          console.debug('this.__videoOrientation :: ' + this.__videoOrientation);

          this.__camSetComplete = true;
          this.__adjustStyle();
        });
        this.__changeStage(this.IN_PROGRESS.READY);
        video.webkitExitFullscreen();
      } else {
        this.__changeStage(this.IN_PROGRESS.NOT_READY);
        this.__closeCamera();
      }
    } catch (e) {
      console.error('error', e.name, e);
      if (e.name === 'NotAllowedError') {
        const errorMessage = 'Camera Access Permission is not allowed';
        console.error(errorMessage);
        console.error(e);
        this.__onFailureProcess("E403", e, errorMessage);
      } else if (e.name === 'NotReadableError') {
        this.__changeStage(this.IN_PROGRESS.NOT_READY);
        this.stopStream();
        this.__setCameraPermissionTimeoutTimer();              // 재귀 호출
      }
    }
  }

  __setStyle(el, style) {
    Object.assign(el.style, style);
  };

  __changeOCRStatus(val) {
    switch (val) {
      // OCR
      case this.IN_PROGRESS.NOT_READY:
        this.__ocrStatus = this.OCR_STATUS.NOT_READY;
        break;
      case this.IN_PROGRESS.READY:
        this.__ocrStatus = this.OCR_STATUS.READY;
        break;
      case this.IN_PROGRESS.OCR_RECOGNIZED:
      case this.IN_PROGRESS.OCR_RECOGNIZED_WITH_SSA:
        this.__ocrStatus = this.OCR_STATUS.OCR_SUCCESS;
        break;
      case this.IN_PROGRESS.OCR_SUCCESS:
      case this.IN_PROGRESS.OCR_SUCCESS_WITH_SSA:
      case this.IN_PROGRESS.OCR_FAILED:
        this.__ocrStatus = this.OCR_STATUS.DONE;
        break;
    }
  }

  __changeStage(val, forceUpdate = false, recognizedImage = null) {
    if (this.__previousInProgressStep === val && forceUpdate === false) {
      return;
    }
    this.__changeOCRStatus(val);
    this.__previousInProgressStep = val;
    this.__inProgressStep = val;

    const { guideBox, maskBoxWrap } = detector.getOCRElements();

    const style = {
      borderWidth: this.__options.frameBorderStyle.width + 'px',
      borderStyle: this.__options.frameBorderStyle.style,
      borderRadius: this.__options.frameBorderStyle.radius + 'px',
      borderColor: this.__options.frameBorderStyle[val],
    };

    if (guideBox) {
      this.__setStyle(guideBox, style);
    }

    if (this.__options.useMaskFrameColorChange) {
      maskBoxWrap?.querySelector("#maskBoxOuter")?.setAttribute("fill", this.__options.maskFrameStyle[val]);
    }

    if (this.__onInProgressChange) {
      if (this.__options.useTopUI || this.__options.useTopUITextMsg) {
        this.__onInProgressChange.call(
          this, this.__ocrType, this.__inProgressStep, this.__topUI, 'top', this.__options.useTopUITextMsg,
          this.__options.usePreviewUI, recognizedImage);
      }
      if (this.__options.useMiddleUI || this.__options.useMiddleUITextMsg) {
        this.__onInProgressChange.call(
          this, this.__ocrType, this.__inProgressStep, this.__middleUI, 'middle', this.__options.useMiddleUITextMsg,
          this.__options.usePreviewUI, recognizedImage);
      }
      if (this.__options.useBottomUI || this.__options.useBottomUITextMsg) {
        this.__onInProgressChange.call(
          this, this.__ocrType, this.__inProgressStep, this.__bottomUI, 'bottom', this.__options.useBottomUITextMsg,
          this.__options.usePreviewUI, recognizedImage);
      }
    }

    if(val === this.IN_PROGRESS.OCR_RECOGNIZED_WITH_SSA) {
      const { video} = detector.getOCRElements();
      this.__setStyle(video, { display: 'none' });

      if (this.__options.usePreviewUI) {
        this.__updatePreviewUI(recognizedImage);
      }
    }

    if(val === this.IN_PROGRESS.OCR_SUCCESS_WITH_SSA) {
      if (this.__options.usePreviewUI) {
        this.__hidePreviewUI();
      }
    }
  }

  __updatePreviewUI(recognizedImage) {
    const { guideBox, previewUI, previewImage } = detector.getOCRElements();
    previewImage.src = recognizedImage;

    const imgStyle = {
      width: guideBox.clientWidth + 'px',
      height: guideBox.clientHeight + 'px',
    }
    this.__setStyle(previewImage, imgStyle);
    this.__setStyle(previewUI, { display: 'block' });
  }

  __hidePreviewUI() {
    const { video, previewUI, previewImage } = detector.getOCRElements();
    this.__setStyle(video, { display: 'block' });
    this.__setStyle(previewUI, { display: 'none' });
    previewImage.src = "";
  }

  async __getInputDevices(kind, label) {
    // throw error if navigator.mediaDevices is not supported
    if (!navigator.mediaDevices) {
      throw new Error('navigator.mediaDevices is not supported');
    }
    const devices = await navigator.mediaDevices.enumerateDevices();
    const videoDevices = devices.filter((device) => {
      if (device.kind === `${kind}input` && device.getCapabilities) {
        const cap = device.getCapabilities();
        if (cap?.facingMode?.includes(this.__facingModeConstraint)) {
          return true
        }
      }
      return false
    });

    return videoDevices.length <= 1
      ? videoDevices
      : videoDevices.filter((device) => (label ? device.label.includes(label) : true));
  }

  checkUIOrientation() {
    const current = detector.getUIOrientation(detector.getOCRElements().ocr);
    let isChanged = false;
    if (current !== this.__prevUiOrientation) {
      this.__uiOrientation = current;
      this.__prevUiOrientation = current;
      isChanged = true;
    }
    return { current, isChanged };
  }

  __clearCustomUI(obj) {
    obj.innerHTML = ''
    obj.removeAttribute('style');
    obj.removeAttribute('class');
    this.__setStyle(obj, {display:'none'});
  }

  __setupDomElements() {
    let { ocr, video, canvas, rotationCanvas,
      guideBox, videoWrap, guideBoxWrap,
      maskBoxWrap, preventToFreezeVideo,
      customUIWrap, topUI, middleUI, bottomUI,
      previewUIWrap, previewUI, previewImage } = detector.getOCRElements();

    if (!ocr) throw new Error("ocr div element is not exist");

    if (videoWrap) videoWrap.remove();
    if (guideBoxWrap) guideBoxWrap.remove();
    if (video) video.remove();
    if (canvas) canvas.remove();
    if (rotationCanvas) rotationCanvas.remove();
    if (guideBox) guideBox.remove();
    if (maskBoxWrap) maskBoxWrap.remove();
    if (preventToFreezeVideo) preventToFreezeVideo.remove();
    if (customUIWrap) customUIWrap.remove();
    // 각 top, middle, bottom UI를 미사용일 경우 안의 내용을 삭제
    if (topUI && !this.__options.useTopUI) this.__clearCustomUI(topUI);
    if (middleUI && !this.__options.useMiddleUI) this.__clearCustomUI(middleUI);
    if (bottomUI && !this.__options.useBottomUI) this.__clearCustomUI(bottomUI);
    if (previewUIWrap) previewUIWrap.remove();
    // preview UI를 미사용일 경우 안의 내용을 삭제
    if (previewUI && !this.__options.usePreviewUI) this.__clearCustomUI(previewUI);

    const rotationDegree = this.__getRotationDegree();
    this.__isRotated90or270 = ([90, 270].includes(rotationDegree));

    let ocrStyle = {
      width: '100%',
      height: '100%',
    };
    this.__setStyle(ocr, ocrStyle);

    const wrapStyle = {
      position: 'absolute',
      display: 'flex',          // vertical align middle
      'align-items': 'center',
      'justify-content': 'center',
      width: '100%',
      height: '100%',
      margin: '0 auto',
      overflow: 'hidden',
    };

    videoWrap = document.createElement('div');
    videoWrap.setAttribute('data-useb-ocr', 'videoWrap');
    if (videoWrap) {
      while (videoWrap.firstChild) {
        videoWrap.removeChild(videoWrap.lastChild);
      }
      this.__setStyle(videoWrap, wrapStyle);
    }
    ocr.appendChild(videoWrap);

    maskBoxWrap = document.createElement('svg');
    maskBoxWrap.setAttribute('data-useb-ocr', 'maskBoxWrap');
    maskBoxWrap.setAttribute('fill', 'none');
    maskBoxWrap.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
    this.__setStyle(maskBoxWrap, wrapStyle);

    let mask_frame = this.__options.maskFrameStyle.base_color + 'ff';
    if (!!this.__options.showClipFrame) {
      mask_frame = this.__options.maskFrameStyle.clip_frame + '55';
    }

    maskBoxWrap.innerHTML = "" +
      "  <svg id='maskBoxContainer' width='100%' height='100%' fill='none' xmlns='http://www.w3.org/2000/svg'>\n" +
      "    <mask id='mask-rect'>\n" +
      "      <rect width='100%' height='100%' fill='white'></rect>\n" +
      "      <svg x='50%' y='50%' overflow='visible'>\n" +
      "          <rect id='maskBoxInner'\n" +
      "            width='400' height='260'\n" +
      "            x='-200' y='-130'\n" +
      "            rx='10' ry='10'\n" +
      "            fill='black' stroke='black'></rect>\n" +
      "      </svg>\n" +
      "    </mask>\n" +
      "    <rect id='maskBoxOuter'\n" +
      "          x='0' y='0' width='100%' height='100%'\n" +
      "          fill='" + mask_frame + "' mask='url(#mask-rect)'></rect>\n" +
      "  </svg>"
    ocr.appendChild(maskBoxWrap);

    video = document.createElement('video');
    video.setAttribute('data-useb-ocr', 'video');
    video.setAttribute('autoplay', 'true');
    video.setAttribute('muted', 'true');
    video.setAttribute('playsinline', 'true');

    let videoStyle = {
      position: 'relative',
      width: '100%'
    }

    const rotateCss = 'rotate(' + rotationDegree + 'deg)';
    const mirrorCss = 'rotateY(180deg)'
    const rotateAndMirrorCss = mirrorCss + ' ' + rotateCss;

    if (this.__isRotated90or270) {
      if (this.__getMirrorMode()) {
        videoStyle = {
          ...videoStyle,
          '-webkit-transform': rotateAndMirrorCss,
          '-moz-transform': rotateAndMirrorCss,
          '-o-transform': rotateAndMirrorCss,
          '-ms-transform': rotateAndMirrorCss,
          'transform': rotateAndMirrorCss,
        };
      } else {
        videoStyle = {
          ...videoStyle,
          '-webkit-transform': rotateCss,
          '-moz-transform': rotateCss,
          '-o-transform': rotateCss,
          '-ms-transform': rotateCss,
          'transform': rotateCss,
        }
      }
    } else {
      if (this.__getMirrorMode()) {
        videoStyle = {
          ...videoStyle,
          '-webkit-transform': mirrorCss,
          '-moz-transform': mirrorCss,
          '-o-transform': mirrorCss,
          '-ms-transform': mirrorCss,
          'transform': mirrorCss,
        }
      }
    }
    this.__setStyle(video, videoStyle);
    videoWrap.appendChild(video);

    guideBoxWrap = document.createElement('div');
    guideBoxWrap.setAttribute('data-useb-ocr', 'guideBoxWrap');
    this.__setStyle(guideBoxWrap, wrapStyle);
    ocr.appendChild(guideBoxWrap);

    guideBox = document.createElement('svg');
    guideBox.setAttribute('data-useb-ocr', 'guideBox');
    guideBox.setAttribute('fill', 'none');
    guideBox.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
    this.__setStyle(guideBox, {
      width: '100%',
      margin: '0 auto',
      position: 'absolute',
    });

    guideBoxWrap.appendChild(guideBox);

    canvas = document.createElement('canvas');
    canvas.setAttribute('data-useb-ocr', 'canvas');

    const canvasStyle = {
      display: this.__options.showCanvasPreview ? (this.__isRotated90or270 ? 'none' : 'display') : 'none',
      width: '25%',
      position: 'absolute',
      left: '0px',
      top: '30px',
      border: 'green 2px solid'
    }
    this.__setStyle(canvas, canvasStyle);

    ocr.appendChild(canvas);

    rotationCanvas = document.createElement('canvas');
    rotationCanvas.setAttribute('data-useb-ocr', 'rotationCanvas');

    this.__setStyle(rotationCanvas, {
      display: this.__options.showCanvasPreview ? (this.__isRotated90or270 ? 'display' : 'none') : 'none',
      height: '25%',
      position: 'absolute',
      right: '0px',
      top: '30px',
      border: 'blue 2px solid'
    });
    ocr.appendChild(rotationCanvas);

    preventToFreezeVideo = document.createElement('div');
    preventToFreezeVideo.setAttribute('data-useb-ocr', 'preventToFreezeVideo');
    this.__setStyle(preventToFreezeVideo, {
      position: 'absolute',
      bottom: '10',
      right: '10',
    });

    preventToFreezeVideo.innerHTML = "" +
      "<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" style=\"margin: auto; background: none; display: block; shape-rendering: auto;\" width=\"32px\" height=\"32px\" viewBox=\"0 0 100 100\" preserveAspectRatio=\"xMidYMid\">\n" +
      "  <circle cx=\"84\" cy=\"50\" r=\"10\" fill=\"#86868600\">\n" +
      "    <animate attributeName=\"r\" repeatCount=\"indefinite\" dur=\"0.5555555555555556s\" calcMode=\"spline\" keyTimes=\"0;1\" values=\"10;0\" keySplines=\"0 0.5 0.5 1\" begin=\"0s\"></animate>\n" +
      "    <animate attributeName=\"fill\" repeatCount=\"indefinite\" dur=\"2.2222222222222223s\" calcMode=\"discrete\" keyTimes=\"0;0.25;0.5;0.75;1\" values=\"#86868600;#86868600;#86868600;#86868600;#86868600\" begin=\"0s\"></animate>\n" +
      "  </circle>" +
      "  <circle cx=\"16\" cy=\"50\" r=\"10\" fill=\"#86868600\">\n" +
      "    <animate attributeName=\"r\" repeatCount=\"indefinite\" dur=\"2.2222222222222223s\" calcMode=\"spline\" keyTimes=\"0;0.25;0.5;0.75;1\" values=\"0;0;10;10;10\" keySplines=\"0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1\" begin=\"0s\"></animate>\n" +
      "    <animate attributeName=\"cx\" repeatCount=\"indefinite\" dur=\"2.2222222222222223s\" calcMode=\"spline\" keyTimes=\"0;0.25;0.5;0.75;1\" values=\"16;16;16;50;84\" keySplines=\"0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1\" begin=\"0s\"></animate>\n" +
      "  </circle>" +
      "  <circle cx=\"50\" cy=\"50\" r=\"10\" fill=\"#86868600\">\n" +
      "    <animate attributeName=\"r\" repeatCount=\"indefinite\" dur=\"2.2222222222222223s\" calcMode=\"spline\" keyTimes=\"0;0.25;0.5;0.75;1\" values=\"0;0;10;10;10\" keySplines=\"0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1\" begin=\"-0.5555555555555556s\"></animate>\n" +
      "    <animate attributeName=\"cx\" repeatCount=\"indefinite\" dur=\"2.2222222222222223s\" calcMode=\"spline\" keyTimes=\"0;0.25;0.5;0.75;1\" values=\"16;16;16;50;84\" keySplines=\"0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1\" begin=\"-0.5555555555555556s\"></animate>\n" +
      "  </circle>" +
      "  <circle cx=\"84\" cy=\"50\" r=\"10\" fill=\"#86868600\">\n" +
      "    <animate attributeName=\"r\" repeatCount=\"indefinite\" dur=\"2.2222222222222223s\" calcMode=\"spline\" keyTimes=\"0;0.25;0.5;0.75;1\" values=\"0;0;10;10;10\" keySplines=\"0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1\" begin=\"-1.1111111111111112s\"></animate>\n" +
      "    <animate attributeName=\"cx\" repeatCount=\"indefinite\" dur=\"2.2222222222222223s\" calcMode=\"spline\" keyTimes=\"0;0.25;0.5;0.75;1\" values=\"16;16;16;50;84\" keySplines=\"0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1\" begin=\"-1.1111111111111112s\"></animate>\n" +
      "  </circle>" +
      "  <circle cx=\"16\" cy=\"50\" r=\"10\" fill=\"#86868600\">\n" +
      "    <animate attributeName=\"r\" repeatCount=\"indefinite\" dur=\"2.2222222222222223s\" calcMode=\"spline\" keyTimes=\"0;0.25;0.5;0.75;1\" values=\"0;0;10;10;10\" keySplines=\"0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1\" begin=\"-1.6666666666666665s\"></animate>\n" +
      "    <animate attributeName=\"cx\" repeatCount=\"indefinite\" dur=\"2.2222222222222223s\" calcMode=\"spline\" keyTimes=\"0;0.25;0.5;0.75;1\" values=\"16;16;16;50;84\" keySplines=\"0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1\" begin=\"-1.6666666666666665s\"></animate>\n" +
      "  </circle>"
      "</svg>";

    ocr.appendChild(preventToFreezeVideo);

    customUIWrap = document.createElement('div');
    customUIWrap.setAttribute('data-useb-ocr', 'customUIWrap');
    const customUIWrapStyle = {...wrapStyle, 'flex-direction': 'column'};
    this.__setStyle(customUIWrap, customUIWrapStyle);
    ocr.appendChild(customUIWrap);

    // 각 top, middle, bottom UI 사용(use)여부와 관계없이 영역을 잡기 위해, div가 없으면 생성
    // adjustStyle() 에서 세부적인 사이즈와 위치값 동적으로 설정됨.
    if (!topUI) {
      topUI = document.createElement('div');
      topUI.setAttribute('data-useb-ocr', 'topUI');
    }
    customUIWrap.appendChild(topUI);

    if (!middleUI) {
      middleUI = document.createElement('div');
      middleUI.setAttribute('data-useb-ocr', 'middleUI');
    }
    customUIWrap.appendChild(middleUI);

    if (!bottomUI) {
      bottomUI = document.createElement('div');
      bottomUI.setAttribute('data-useb-ocr', 'bottomUI');
    }
    customUIWrap.appendChild(bottomUI);


    previewUIWrap = document.createElement('div');
    previewUIWrap.setAttribute('data-useb-ocr', 'previewUIWrap');
    const previewUIWrapStyle = {...wrapStyle, 'flex-direction': 'column'};
    this.__setStyle(previewUIWrap, previewUIWrapStyle);
    ocr.appendChild(previewUIWrap);

    if (this.__options.usePreviewUI) {
      if (!previewUI) {
        previewUI = document.createElement('div');
        previewUI.setAttribute('data-useb-ocr', 'previewUI');
      }
      this.__setStyle(previewUI, { display: 'none' });
      previewUIWrap.appendChild(previewUI);

      if (!previewImage) {
        previewImage = document.createElement('img');
        previewImage.setAttribute('data-useb-ocr', 'previewImage');
        previewUI.appendChild(previewImage);
      }
    }

    // loading UI 위치 자리잡게 하기 위해
    this.__adjustStyle();
    // 화면과도 현상 해결
    this.__setStyle(ocr, {display: ''});

    this.__ocr = ocr;
    this.__canvas = canvas;
    this.__rotationCanvas = rotationCanvas;
    this.__video = video;
    this.__videoWrap = videoWrap;
    this.__guideBox = guideBox;
    this.__guideBoxWrap = guideBoxWrap;
    this.__maskBoxWrap = maskBoxWrap;
    this.__preventToFreezeVideo = preventToFreezeVideo;
    this.__customUIWrap = customUIWrap;
    this.__topUI = topUI;
    this.__middleUI = middleUI;
    this.__bottomUI = bottomUI;
    this.__previewUIWrap = previewUIWrap;
    this.__previewUI = previewUI;
    this.__previewImage = previewImage;

    return { ocr, canvas, rotationCanvas, video, videoWrap,
      guideBox, guideBoxWrap, maskBoxWrap, preventToFreezeVideo,
      customUIWrap, topUI, middleUI, bottomUI,
      previewUIWrap, previewUI, previewImage };
  }

  async __setupVideo(isPassport) {
    // wasm 인식성능 최적화된 해상도
    this.__resolutionWidth = 1080;
    this.__resolutionHeight = 720;

    this.__camSetComplete = false;

    const { video, canvas, rotationCanvas } = this.__setupDomElements();

    let videoDevices = await this.__getInputDevices('video');
    // console.log('videoDevices :: ', videoDevices)
    let deviceIdList = videoDevices.map((device) => device.deviceId);

    this.checkUIOrientation();
    let constraintWidth, constraintHeight;
    if (this.__uiOrientation === 'portrait') {         // ui : portrait
      constraintWidth = {
        ideal: 1920,          // portrait 이지만 카메라는 landscape 인경우
        min: 1080,            // portrait 이고 카메라도 portrait 인경우
      };
      constraintHeight = {
        ideal: 1080,          // portrait 이지만 카메라는 landscape 인경우
        min: 720,             // portrait 이고 카메라도 portrait 인경우
      };
    } else {                                    // ui : landscape
      constraintWidth = {
        ideal: 1920,
        min: 1280,
      };
      constraintHeight = {
        ideal: 1080,
        min: 720,
      };
    }

    const constraints = {
      audio: false,
      video: {
        zoom: { ideal: 1 },
        facingMode: { ideal: this.__facingModeConstraint },
        focusMode: { ideal: 'continuous' },
        whiteBalanceMode: {ideal: 'continuous'},
        deviceId: videoDevices.length
          ? {
            ideal: deviceIdList[deviceIdList.length - 1]
          }
          : null,
        width: constraintWidth,
        height: constraintHeight,
      },
    };

    // 최초 진입 이어서 videoDeivce 리스트를 가져올 수 없으면,
    // getUserMedia를 임의 호출하여 권한을 받은뒤 다시 가져옴
    if (videoDevices.length === 0) {
      this.__stream = await navigator.mediaDevices.getUserMedia(constraints);
      this.stopStream();
      videoDevices = await this.__getInputDevices('video');
      deviceIdList = videoDevices.map((device) => device.deviceId);

      constraints.video.deviceId = videoDevices.length
        ? { ideal: deviceIdList[deviceIdList.length - 1] } : null;
    }

    try {
      // const dumptrack = ([a, b], track) =>
      //   `${a}${track.kind == 'video' ? 'Camera' : 'Microphone'} (${track.readyState}): ${track.label}${b}`;

      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      // console.log('videoTracks :: ', stream.getVideoTracks())
      // const streamSettings = stream.getVideoTracks()[0].getSettings();
      // console.log('streamCapabilities :: ', stream.getVideoTracks()[0].getCapabilities())
      // console.log('stream :: ', stream.getVideoTracks()[0].getConstraints())
      // console.log('streamSettings :: ', streamSettings)
      // console.log('stream width :: ' + streamSettings.width);
      // console.log('stream height :: ' + streamSettings.height);
      // console.log('stream width / height :: ' + streamSettings.width / streamSettings.height);
      // console.log('stream aspectRatio :: ' + streamSettings.aspectRatio);

      [canvas.width, canvas.height] = [this.__resolutionWidth, this.__resolutionHeight];
      if (this.__isRotated90or270) {
        [rotationCanvas.width, rotationCanvas.height] = [this.__resolutionHeight, this.__resolutionWidth];
      }

      video.srcObject = stream;
      this.__stream = stream;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  __adjustStyle() {
    console.debug('adjustStyle - START');
    const { ocr, video, guideBox, maskBoxWrap, topUI, middleUI, bottomUI } = detector.getOCRElements();
    // 기준정보
    const baseWidth = 400;
    const baseHeight = 260;

    const scannerFrameRatio = baseHeight / baseWidth;       // 신분증 비율

    let guideBoxWidth, guideBoxHeight;

    let calcOcrClientWidth = (ocr.clientWidth);
    let calcOcrClientHeight = (ocr.clientHeight);
    let calcVideoWidth = (video.videoWidth);
    let calcVideoHeight = (video.videoHeight);
    let calcVideoClientWidth = (video.clientWidth);
    let calcVideoClientHeight = (video.clientHeight);
    let calcVideoOrientation = this.__videoOrientation;

    const borderWidth = (this.__options.frameBorderStyle.width);
    const borderRadius = (this.__options.frameBorderStyle.radius);

    if (this.__isRotated90or270) {
      [calcVideoWidth, calcVideoHeight] = [calcVideoHeight, calcVideoWidth];
      [calcVideoClientWidth, calcVideoClientHeight] = [calcVideoClientHeight, calcVideoClientWidth];
      calcVideoOrientation = this.__videoOrientation === 'portrait' ? 'landscape' : 'portrait';
    }
    let newVideoWidth = calcVideoClientWidth;
    let newVideoHeight = calcVideoClientHeight;

    const guideBoxRatioByWidth = this.__guideBoxRatioByWidth;
    const videoRatioByHeight = this.__videoRatioByHeight;

    if (this.__uiOrientation === 'portrait') {               // 세로 UI
      // video 가로 기준 100% 유지 (변경없음)
      if (calcVideoOrientation === this.__uiOrientation) {   // 카메라도 세로
        // 세로 UI && 세로 비디오
        // 가로 기준으로 가이드박스 계산
        guideBoxWidth = (calcOcrClientWidth * guideBoxRatioByWidth);
        guideBoxHeight = (guideBoxWidth * scannerFrameRatio);

        // 가이드 박스 가로 기준으로 비디오 확대
        newVideoWidth = (guideBoxWidth);
        newVideoHeight = (newVideoWidth * (calcVideoHeight / calcVideoWidth));
        if (this.__isRotated90or270) {
          [newVideoWidth, newVideoHeight] = [newVideoHeight, newVideoWidth];
        }
      } else {                                                // 카메라는 가로
        // 세로 UI && 가로 비디오
        // 가이드 박스를 비디오 세로 길이에 맞춤
        guideBoxHeight = (calcVideoClientHeight);
        guideBoxWidth = ((guideBoxHeight * baseWidth) / baseHeight);
      }
    } else {
      // 가로 UI
      if (calcVideoOrientation === this.__uiOrientation) {
        // 가로 UI && 가로 비디오
        // 비디오를 가로 UI의 height 기준으로 줄이고
        // 가로 UI height 기준으로 비디오의 width 계산
        newVideoHeight = (calcOcrClientHeight * videoRatioByHeight)
        newVideoWidth = (newVideoHeight * (calcVideoWidth / calcVideoHeight));

        // 가이드박스는 비디오를 세로 기준으로 맞춤
        guideBoxHeight = (newVideoHeight);
        guideBoxWidth = ((guideBoxHeight * baseWidth) / baseHeight);

        // 가이드박스의 가로 크기가 가로 UI width * ratio 값보다 크면,
        if (guideBoxWidth > (calcOcrClientWidth * guideBoxRatioByWidth)) {
          // 계산 방식을 바꾼다 (사유 : 거의 정사각형에 가까운 경우 가이드 박스 가로가 꽉차게 됨.)
          guideBoxWidth = (calcOcrClientWidth * guideBoxRatioByWidth);
          guideBoxHeight = (guideBoxWidth * scannerFrameRatio);

          // 가이드 박스 가로 기준으로 비디오 확대
          newVideoWidth = (guideBoxWidth);
          newVideoHeight = (newVideoWidth * (calcVideoHeight / calcVideoWidth));
        }
      } else {
        // 가로 UI && 세로 비디오
        // 가로 기준으로 가이드박스 계산

        // 가이드박스의 height 크기를 UI의 height 기준에 맞춤
        guideBoxHeight = (calcOcrClientHeight * videoRatioByHeight);
        guideBoxWidth = ((guideBoxHeight * baseWidth) / baseHeight);

        // 가이드박스의 가로 크기가 가로 UI width * ratio 값보다 크면,
        if (guideBoxWidth > (calcOcrClientWidth * guideBoxRatioByWidth)) {
          // 계산 방식을 바꾼다 (사유 : 거의 정사각형에 가까운 경우 가이드 박스 가로가 꽉차게 됨.)
          guideBoxWidth = (calcOcrClientWidth * guideBoxRatioByWidth);
          guideBoxHeight = (guideBoxWidth * scannerFrameRatio);
        }

        // 가이드 박스 가로 기준으로 비디오 축소
        newVideoWidth = (guideBoxWidth);
        newVideoHeight = (newVideoWidth * (calcVideoHeight / calcVideoWidth));
        if (this.__isRotated90or270) {
          [newVideoWidth, newVideoHeight] = [newVideoHeight, newVideoWidth];
        }
      }
    }

    guideBoxWidth += (borderWidth * 2);
    guideBoxHeight += (borderWidth * 2);

    this.__cropImageSizeWidth = Math.min(guideBoxWidth, newVideoWidth);
    this.__cropImageSizeHeight = Math.min(guideBoxHeight, newVideoHeight);

    const reducedGuideBoxWidth = (guideBoxWidth) * this.__guideBoxReduceRatio;
    const reducedGuideBoxHeight = (guideBoxHeight) * this.__guideBoxReduceRatio;

    if (topUI) {
      this.__setStyle(topUI, {
        'padding-bottom': '10px',
        'height': (calcOcrClientHeight - guideBoxHeight) / 2 + 'px',
        'display': 'flex',
        'flex-direction': 'column-reverse',
      })
    }

    if (middleUI) {
      this.__setStyle(middleUI, {
        width: reducedGuideBoxWidth - (borderWidth * 2) + 'px',
        height: reducedGuideBoxHeight - (borderWidth * 2) +  'px',
        'display': 'flex',
        'align-items': 'center',
        'justify-content': 'center',
        'padding': '10px',
      })
    }

    if (bottomUI) {
      this.__setStyle(bottomUI, {
        'padding-top': '10px',
        'height': (calcOcrClientHeight - guideBoxHeight) / 2 + 'px',
        'display': 'flex',
        'flex-direction': 'column',
      });
    }

    if (newVideoWidth !== calcVideoClientWidth) {
      this.__setStyle(video, {
        width: newVideoWidth + 'px',
      });
    }

    if (newVideoHeight !== calcVideoClientHeight) {
      this.__setStyle(video, {
        height: newVideoHeight + 'px',
      });
    }

    const videoInnerGap = 2;                        // 미세하게 maskBoxInner보다 guideBox가 작은것 보정
    this.__setStyle(guideBox, {
      width: (reducedGuideBoxWidth - videoInnerGap) + 'px',
      height: (reducedGuideBoxHeight - videoInnerGap) + 'px',
      backgroundColor: 'transparent',
    });

    const maskBoxInner = maskBoxWrap.querySelector("#maskBoxInner");
    let r = (borderRadius - (borderWidth * 2));
    r = (r < 0) ? 0 : r;
    if (!isNaN(reducedGuideBoxWidth) && !isNaN(reducedGuideBoxHeight) && !isNaN(borderRadius) && !isNaN(borderWidth)) {
      const maskBoxInnerWidth = Math.max(reducedGuideBoxWidth - (borderWidth * 2) - (videoInnerGap), 0);
      const maskBoxInnerHeight = Math.max(reducedGuideBoxHeight - (borderWidth * 2) - (videoInnerGap), 0);

      maskBoxInner.setAttribute('width', maskBoxInnerWidth + '');
      maskBoxInner.setAttribute('height', maskBoxInnerHeight + '');
      maskBoxInner.setAttribute('x', (maskBoxInnerWidth / 2 * -1) + '');
      maskBoxInner.setAttribute('y', (maskBoxInnerHeight / 2 * -1) + '');
      maskBoxInner.setAttribute('rx', r + '');
      maskBoxInner.setAttribute('ry', r + '');
    }

    this.__changeStage(this.__inProgressStep, true);
    console.debug('adjustStyle - END');
  }

  __closeCamera() {
    this.__clearCameraPermissionTimeoutTimer();
    this.stopScan();
    this.stopStream();
  }

  async __loadResources() {
    console.log("loadResources() START")
    if (this.__resourcesLoaded) {
      console.log("loadResource() SKIP, already loaded!")
      return;
    }

    let sdkSupportEnv = 'quram';
    let isSupportSimd = await simd();

    let envInfo = '';
    envInfo += `os : ${this.__deviceInfo.os}\n`;
    envInfo += `osSimple : ${this.__deviceInfo.osSimple}\n`;
    envInfo += `simd(wasm-feature-detect) : ${isSupportSimd}\n`;
    if (this.__deviceInfo.osSimple === 'IOS' || this.__deviceInfo.osSimple === 'MAC') {
      isSupportSimd = false;
    }
    envInfo += `isSupportSimd(final) : ${isSupportSimd}\n`;
    envInfo += `UserAgent : ${navigator.userAgent}\n`;

    if (isSupportSimd) {
      console.log('!!! applied simd !!!');
      sdkSupportEnv += '_simd.js';
      if (window.location.hostname === 'ocr-demo-test.useb.co.kr') {
        alert(`[DEBUG INFO] \n ${envInfo}`);
      }
    } else {
      console.log('!!! not applied simd !!!');
      sdkSupportEnv += '.js';
      if (window.location.hostname === 'ocr-demo-test.useb.co.kr') {
        alert(`[DEBUG INFO] \n ${envInfo}`);
      }
    }

    const url = new URL(sdkSupportEnv, this.__options.resourceBaseUrl);
    let src = await fetch(url.href)
      .then((res) => res.text())
      .then((text) => {
        let regex = /(.*) = Module.cwrap/gm;
        let source = text.replace(regex, 'Module.$1 = Module.cwrap');

        // data(model)
        source = source.replace(
          /^\(function\(\) \{/m,
          'var createModelData = async function() {\n' +
          ' return new Promise(async function (resolve, reject) {\n'
        );
        source = source.replace(
          '   console.error("package error:", error);',
          '   reject();\n' +
          '   console.error("package error:", error);'
        );
        source = source.replace(
          '  }, handleError)',
          '  resolve();\n' +
          '  }, handleError)'
        )
        source = source.replace(
          /^\}\)\(\);/m,
          '\n })\n' +
          '};'
        );

        // wasm
        source = source.replace(
          'quram.wasm',
          new URL('quram.wasm', this.__options.resourceBaseUrl).href
        );
        source = source.replace(
          /REMOTE_PACKAGE_BASE = ['"]quram\.data["']/gm,
          `REMOTE_PACKAGE_BASE = "${new URL('quram.data', this.__options.resourceBaseUrl).href
          }"`
        );
        source = source.replace(
          'function createWasm',
          'async function createWasm'
        );
        source = source.replace(
          'instantiateAsync();',
          'await instantiateAsync();'
        );

        // wasm and data(model) file 병렬로 fetch 하기 위해
        source = source.replace(
          'var asm = createWasm();',
          'console.log("create wasm and data - start")\n' +
          'await (async function() {\n' +
          '  return new Promise(function(resolve) {\n' +
          '    var isCreatedWasm = false;\n' +
          '    var isCreatedData = false;\n' +
          '    createWasm().then(() => {\n' +
          '      isCreatedWasm = true;\n' +
          '      if (isCreatedData) { resolve(); }\n' +
          '    });\n' +
          '    createModelData().then(() => {\n' +
          '      isCreatedData = true;\n' +
          '      if (isCreatedWasm) { resolve(); }\n' +
          '    })\n' +
          '  });\n' +
          '})();\n' +
          'console.log("create wasm and data - end")'
        );
        return source;
      });

    src = `
    (async function() {
      ${src}
      Module.lengthBytesUTF8 = lengthBytesUTF8
      Module.stringToUTF8 = stringToUTF8
      return Module
    })()
        `;
    this.__OCREngine = await eval(src);

    this.__OCREngine.onRuntimeInitialized = async _ => {
      console.log('WASM - onRuntimeInitialized()')
    };
    await this.__OCREngine.onRuntimeInitialized();

    this.__resourcesLoaded = true;
    console.log("loadResources() END")
  }

  __startScanImpl() {
    return new Promise((resolve, reject) => {
      this.__detected = false;
      this.__address = 0;
      this.__pageEnd = false;
      this.__options.ssaMaxRetryCount =
        (isNaN(parseInt(this.__options.ssaMaxRetryCount)) ? 0 : parseInt(this.__options.ssaMaxRetryCount));

      const scan = async () => {
        try {
          let ocrResult = null, imgDataUrl = null, maskImage = null, ssaResult = null, ssaResultList = [];

          // this.__changeStage(IN_PROGRESS.READY);
          if (!this.__OCREngine['asm']) return;

          // TODO : 설정할수 있게 변경 default 값도 제공
          const [resolution_w, resolution_h] = [this.__resolutionWidth, this.__resolutionHeight];
          const { video } = detector.getOCRElements();
          if (resolution_w === 0 || resolution_h === 0) return;

          if (this.__detected) {
            await this.__sleep(100);
            return;
          }
          // console.log('address before ---------', address);
          if (this.__address === 0 && !this.__pageEnd && this.__isVideoResolutionCompatible(video)) {
            [this.__address, this.__destroyScannerCallback] = this.__getScannerAddress(this.__ocrType);
          }

          if (!this.__address || this.__pageEnd) {
            await this.__sleep(100);
            return;
          }
          // console.log('address after ---------', address);

          if (this.__ocrStatus < this.OCR_STATUS.OCR_SUCCESS) {
            // OCR 완료 이전 상태

            // card not detected
            const [isDetectedCard, imgData] = await this.__isCardboxDetected(this.__address, 0);
            if (!isDetectedCard) {
              if (this.__inProgressStep !== this.IN_PROGRESS.READY) {
                this.__changeStage(this.IN_PROGRESS.CARD_DETECT_FAILED);
              }
              return;
            }

            // card is detected
            this.__changeStage(this.IN_PROGRESS.CARD_DETECT_SUCCESS);

            // ssa retry 설정이 되어 있으면, card detect 성공시 이미지 저장
            if (this.__ssaMode && this.__options.ssaMaxRetryCount > 0) {
              this.__enqueueDetectedCardQueue(imgData);
            }

            [ocrResult, imgDataUrl, maskImage] = await this.__startRecognition(this.__address, this.__ocrType, this.__ssaMode);
          }

          if (this.__ocrStatus >= this.OCR_STATUS.OCR_SUCCESS) {
            // ocr 완료 이후 상태

            // failure case
            if (ocrResult === false) {
              throw new Error(`OCR Status is ${this.__ocrStatus}, but ocrResult is false`);
            }

            // success case
            this.__setStyle(video, { display: 'none' });      // OCR 완료 시점에 camera preview off

            if (this.__ssaMode) {
              console.log("!!! ssaRetryCount : " + this.__ssaRetryCount + " !!!");
              // 최초 시도
              ssaResult = await this.__startTruth(this.__ocrType, this.__address);
              if (ssaResult === null) {
                throw new Error("[ERR] SSA MODE is true. but, ssaResult is null");
              }
              ssaResultList.push(ssaResult);

              if (this.__options.ssaMaxRetryCount > 0) {
                let retryStartDate = new Date();
                for (const item of this.__detectedCardQueue) {
                  if (ssaResult.indexOf("FAKE") > -1) {
                    this.__ssaRetryCount++;
                    console.log(`!!! [RETRY++] ${ssaResult}, but, will be retry ${this.__ssaRetryCount} !!!`);
                    ssaResult = await this.__startTruthRetry(this.__ocrType, this.__address, item);
                    if (ssaResult === null) {
                      throw new Error("[ERR] SSA MODE is true. but, ssaResult is null");
                    }
                    ssaResultList.push(ssaResult);
                  } else {
                    break;
                  }
                }
                const retryWorkingTime = new Date() - retryStartDate;
                console.log(`[SSA DONE] ssaResult: ${ssaResult} / retryCount: ${this.__ssaRetryCount} / retry working time: ${retryWorkingTime}`)
              } else {
                console.log(`[SSA DONE / NO RETRY] ssaResult: ${ssaResult} / `)
              }
            }

            console.debug(`result : ${ocrResult}`);
            this.__onSuccessProcess({
              ocr_type: this.__ocrType,
              ocr_data: parser.parseOcrResult(this.__ocrType, this.__ssaMode, ocrResult, ssaResult, this.__ssaRetryCount, ssaResultList),
              ocr_origin_image: imgDataUrl,
              ocr_masking_image: maskImage,
              ssa_mode: this.__ssaMode
            });

            this.__closeCamera();
            this.__detected = true;
            resolve();

          }
        } catch (e) {
          let errorMessage = 'Card detection error';
          if (e.message) {
            errorMessage += ": " + e.message;
          }
          console.error(errorMessage);

          if (e.toString().includes('memory')) {
            await this.__recoveryScan();
          } else {
            this.__onFailureProcess("WA001", e, errorMessage);
            this.__closeCamera();
            this.__detected = true;
            reject();
          }
        } finally {
          if (!this.__detected) {
            setTimeout(scan, 1);     // 재귀
          }
        }
      };

      setTimeout(scan, 1);             // UI 랜더링 blocking 방지 (setTimeout)
    });
  }

  __enqueueDetectedCardQueue(imgData) {
    if (this.__options.ssaMaxRetryCount === 0) {
      return;
    }

    if (this.__detectedCardQueue.length === parseInt(this.__options.ssaMaxRetryCount)) {
      this.__detectedCardQueue.shift();
    }

    this.__detectedCardQueue.push(imgData);
    console.log('this.__cardImgList.length : ' + this.__detectedCardQueue.length);    // should be removed
  }

  __onSuccessProcess(review_result) {
    // 인식 성공 스캔 루프 종료
    if (review_result.ssa_mode) {
      this.__changeStage(this.IN_PROGRESS.OCR_SUCCESS_WITH_SSA);
    } else {
      this.__changeStage(this.IN_PROGRESS.OCR_SUCCESS);
    }
    const result = {
      api_response: {
        "result_code": "N100",
        "result_message": "OK."
      },
      result: "success",
      review_result: review_result,
    };

    if (this.__onSuccess) {
      this.__onSuccess(result);
      this.__onSuccess = null;
    } else {
      console.log("[WARN] onSuccess callback is null, so skip to send result");
    }
  }

  __onFailureProcess(resultCode, e, errorMessage) {
    this.__changeStage(this.IN_PROGRESS.OCR_FAILED);

    let errorDetail = "";
    if (e?.toString()) errorDetail += e.toString();
    if (e?.stack) errorDetail += e.stack;

    const result = {
      api_response: {
        "result_code": resultCode,
        "result_message": errorMessage
      },
      result: "failed",
      review_result: {
        ocr_type: this.__ocrType,
        error_detail: errorDetail,
      }
    };

    if (this.__onFailure) {
      this.__onFailure(result);
      this.__onFailure = null;
    } else {
      console.log("[WARN] onFailure callback is null, so skip to send result");
    }
  }

  async __startScan() {
    this.cleanup();
    await this.__proceedCameraPermission();
    await this.__startScanImpl();
    console.log("SCAN END");
  }
  async __recoveryScan() {
    console.log("!!! RECOVERY SCAN !!!");
    this.__resourcesLoaded = false;
    this.stopScan();
    await this.__startScan();
  }
  stopScan() {
    const { canvas } = detector.getOCRElements();
    if (canvas) {
      const canvasContext = canvas.getContext('2d', {
        willReadFrequently: true,
      });
      canvasContext.clearRect(0, 0, canvas.width, canvas.height);
    }
  }
  stopStream() {
    cancelAnimationFrame(this.__requestAnimationFrameId);
    if (this.__stream) {
      this.__stream.stop && this.__stream.stop();
      let tracks = this.__stream.getTracks && this.__stream.getTracks();
      console.debug('CardScan__stopStream', tracks);
      if (tracks && tracks.length) {
        tracks.forEach((track) => track.stop());
      }
      this.__stream = null;
    }
  }
  /** 메모리 allocation free 함수 */
  cleanup() {
    this.__destroyScannerAddress();
    this.__destroyBuffer();
    this.__destroyPrevImage();
    this.__destroyStringOnWasmHeap();
  }

  __clearCameraPermissionTimeoutTimer() {
    if (this.__cameraPermissionTimeoutTimer) {
      clearTimeout(this.__cameraPermissionTimeoutTimer);
      this.__cameraPermissionTimeoutTimer = null;
    }
  }
}

export default UseBOCR;

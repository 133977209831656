<template>
  <div class="card-result-fail-root">
    <div class="card-result-fail-subject">
      {{ $t('온보딩.신분증 인증') }}
    </div>
    <div class="card-result-fail-info-container">
      <Icon_Card_FailVue />
      <div class="title">{{ $t('신분증.진위 확인 실패') }}</div>
      <div
        class="content"
        v-html="$t('신분증.5회 이상 발급 일자 입력 오류로 인해')"
      ></div>
      <div class="unlock-guide">
        <div class="unlock-guide-title">
          <img src="@/assets/info_20_black.svg" />
          <div>{{ $t('신분증.주민등록증 잠김해제 방법') }}</div>
        </div>
        <ul class="unlock-guide-body">
          <li>{{ $t('신분증.가까운 읍면동 센터 방문') }}</li>
          <li v-html="$t('신분증.정부24 접속')"></li>
        </ul>
      </div>
    </div>
    <div @click="$emit('ok')" class="button-container">
      {{ $t('버튼.확인') }}
    </div>
  </div>
</template>

<script>
import Icon_Card_FailVue from './DynamicCt_imgs/Icon_Card_Fail.vue';

export default {
  components: { Icon_Card_FailVue },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.card-result-fail-root {
  // position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  width: 100%;
  // height: 100%;

  .card-result-fail-subject {
    margin-top: 32px;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    color: var(--surface-high);
  }
}

.card-result-fail-info-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-grow: 1;

  & > img {
    width: 230px;
    margin-bottom: 51px;
  }

  .title {
    font-weight: bold;
    font-size: 28px;
    line-height: 42px;
    color: var(--surface-high);
    margin-bottom: 24px;
  }

  .content {
    font-weight: normal;
    text-align: center;
    font-size: 16px;
    line-height: 26px;
    color: var(--surface-high);
    margin-bottom: 8px;
  }

  .unlock-guide {
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    background-color: #f6f6f6;
    padding: 12px 16px;

    .unlock-guide-title {
      display: flex;
      color: var(--surface-high);
      & > img {
        width: 15px;
        color: black;
      }
      & > div {
        margin-left: 7px;
        font-weight: 500;
      }
    }

    .unlock-guide-body {
      padding-left: 22px;
    }
  }
}

.button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 1rem;
  width: calc(100% - 60px);
  color: var(--font-color);
  background-color: var(--primary-100);
  border-radius: 8px;
  height: 60px;
  margin: 0 30px 48px;
}
</style>

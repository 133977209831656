<template>
  <ViewContainer>
    <div class="container-phase-guide">
      <Step :phase="phase" :items="phaseItems"></Step>
      <div
        style="
          font-weight: 500;
          font-size: 20px;
          line-height: 32px;
          text-align: center;
        "
      >
        세부 심사를 위한<br />추가 인증을 시작합니다.
      </div>
      <Additional_GuideVue style="margin: 40px auto" />
      <div class="content-wrapper">
        <div class="text-title-tip">추가 인증이란?</div>
        <div class="tips">
          고객 신원 확인의 정확도를 높이기 위한 추가 인증 절차입니다.
        </div>
        <div class="tips">
          자금 출처, 주소, 소득증명서, 재직증명서 등의 추가 정보를 요청할 수
          있습니다.
        </div>
      </div>
      <div class="button-container">
        <div @click="exitDialog = true" class="button cancel">이전</div>
        <div @click="$emit('next')" class="button ok">추가 인증</div>
      </div>
    </div>
    <ExitDisabledDialog
      v-model="exitDialog"
      @cancel="exitDialog = false"
      @ok="$emit('cancel')"
      :id="'additionalGuide_exitPopup'"
      :disabledProp="!exitDialog"
    />
  </ViewContainer>
</template>

<script>
import Step from './Step';
import ViewContainer from '../../../layout/auth/ViewContainer.vue';
import ExitDisabledDialog from '../dialog/ExitDisabledDialog.vue';
import Additional_GuideVue from './DynamicCt_imgs/Additional_Guide.vue';

export default {
  components: { Step, ViewContainer, ExitDisabledDialog, Additional_GuideVue },
  props: {
    phase: Number,
    phaseItems: Array,
  },
  data() {
    return {
      exitDialog: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.root-container {
  position: relative;
  height: 100%;
  color: var(--surface-high);
  overflow: auto;
}

.container-phase-guide {
  display: flex;
  flex-direction: column;
  height: 100%;
  .content-wrapper {
    border: 0.5px solid var(--surface-medium);
    padding: 16px 20px;
    border-radius: 8px;
  }

  .text-title-tip {
    text-align: left;
    font-weight: 500;
    margin-bottom: 10px;

    font-size: 1rem;
    line-height: 1.5rem;
    color: var(--surface-medium);
  }
  .tips {
    font-size: 0.875rem;
    line-height: 1.5rem;
    color: var(--surface-medium);
    position: relative;
    margin-left: 24px;
    &::before {
      content: '';
      position: absolute;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background: var(--surface-medium);
      left: -14px;
      top: 10px;
    }
  }
}

.spacer {
  flex-grow: 1;
}

.button-container {
  margin-top: 56px;
  width: 100%;
  bottom: 0;
  display: flex;

  background-color: white;

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    font-weight: 500;
    font-size: 1rem;
    height: 60px;
    cursor: pointer;
    user-select: none;

    &.cancel {
      flex-grow: 0.65;
      background: var(--gray-100);
      color: var(--surface-medium);
      margin-right: 10px;
    }

    &.ok {
      flex-grow: 1;
      color: var(--font-color);
      background: var(--primary-100);
    }
  }
}
</style>

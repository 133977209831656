import Vue from 'vue';
import VueRouter from 'vue-router';
import Constants from '@/constants.js';

import Page404 from '@/views/404.vue';
import Auth from '@/views/Auth';

Vue.use(VueRouter);

let routes = [
  {
    path: '/auth',
    name: Constants.ROUTE_NAME.AUTH,
    component: Auth,
    meta: {
      auth: true,
    },
  },
  {
    path: '/404',
    name: Constants.ROUTE_NAME.PAGE_404,
    component: Page404,
    meta: {
      auth: false,
    },
  },
  {
    path: '*',
    redirect: '/404',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach(function(to, from, next) {
  // console.log(to, from, "every single routing is pending", to.name);
  switch (to.name) {
    case Constants.ROUTE_NAME.AUTH:
      next();
      break;
    case Constants.ROUTE_NAME.PAGE_404:
      next();
      break;
  }
});
// router.beforeResolve(auth.navigationGuard)

export default router;

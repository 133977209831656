<template>
  <div class="kyc-auth-root-layout" :style="layoutStyle">
    <slot />
  </div>
</template>
<script>
export default {
  props: {
    paddingTop: {
      type: Number,
      default: 48,
    },
    paddingHorizontal: {
      type: Number,
      default: 30,
    },
    paddingBottom: {
      type: Number,
      default: 40,
    },
  },
  data() {
    return {
      layoutStyle: {
        // padding: this.padding,
        paddingTop: String(this.paddingTop) + 'px',
        paddingRight: String(this.paddingHorizontal) + 'px',
        paddingLeft: String(this.paddingHorizontal) + 'px',
        paddingBottom: String(this.paddingBottom) + 'px',
      },
    };
  },
};
</script>
<style lang="scss" scoped>
.kyc-auth-root-layout {
  position: relative;
  //   height: 100%;
  overflow: hidden;
  color: var(--surface-high);
  overflow: auto;
}
</style>

import i18next from 'i18next';
import locales from './locales';

const lngs = ['ko', 'en'];
console.log({ locales });
function loadResource(lng) {
  switch (lng) {
    case 'ko':
      return locales.ko;
    case 'en':
      return locales.en;
    default:
      return locales.ko;
  }
}
function getResources(lngs) {
  return lngs.reduce((acc, cur) => {
    acc[cur] = { translation: loadResource(cur) };
    return acc;
  }, {});
}

export function initializeI18Next(lng = 'ko') {
  i18next.init({
    lng,
    fallbackLng: 'ko',
    returnObjects: true, // object, array를 문자열로 변환하지 않고 있는 그대로 받음
    keySeparator: '.',
    interpolation: {
      prefix: '%{',
      suffix: '}%',
    },
    resources: getResources(lngs),
  });
}

export function changeLanguage(lng) {
  return i18next.changeLanguage(lng);
}

export const i18n = i18next;

<template>
  <div class="loading-root">
    <div style="flex-grow: 1"></div>
    <img
      src="@/assets/Loading_40px_black.svg"
      width="35px"
      style="margin-bottom: 24px"
    />
    <div v-html="messages" class="text-info" />
    <div v-html="submessage" class="text-info-sub" />
    <div style="flex-grow: 1"></div>
    <img
      :src="logoSrc"
      style="max-width: 100px; max-height: 20px; margin-bottom: 35px"
    />
  </div>
</template>

<script>
export default {
  props: {
    appData: {
      type: Object,
    },
    hasLogo: {
      type: Boolean,
    },
  },
  computed: {
    logoSrc() {
      if (!this.hasLogo) return '';
      const {
        allow_custom_theme,
        use_custom_theme,
        use_custom_logo,
        custom_logo,
      } = this.appData.config;
      if (
        !allow_custom_theme ||
        !use_custom_theme ||
        (use_custom_theme && !use_custom_logo)
      )
        return require('@/assets/logo-useb.svg');
      if (custom_logo.indexOf('data:image') > -1) return custom_logo;
      return '';
    },
    messages() {return this.$t('로딩.고객 신원 확인을 위한')},
    submessage() {return this.$t('로딩.잠시만 기다려 주세요.')},
  },
};
</script>

<style lang="scss" scoped>
.loading-root {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  .text-info {
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    color: var(--surface-high);
    margin-bottom: 8px;
    text-align: center;
  }
  .text-info-sub {
    font-size: 16px;
    line-height: 26px;
    color: var(--surface-medium);
  }
}
</style>

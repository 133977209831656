<template>
  <div class="country__container">
    <v-bottom-sheet scrollable inset v-model="nationSheet">
      <template v-slot:activator="{ on, attrs }">
        <button :class="['field__button', { selected: !!nationality }]">
          <input
            type="text"
            :value="nationality"
            v-on="on"
            v-bind="attrs"
            readonly
            placeholder="국적"
          />
        </button>
      </template>
      <div class="field__list__wrapper">
        <div />
        <div style="overflow: auto; height: 100%; position: relative">
          <v-list class="field__list__container" style="min-height: 736px">
            <v-list-item :ripple="false" class="field__search">
              <InputTextField
                style="width: 100%"
                v-model="nationalitySearch"
                :append="focus ? iconClear : ''"
                @append="nationalitySearch = ''"
                placeholder="국적"
              />
            </v-list-item>
            <v-list-item
              v-for="nation in nationList"
              :key="`${nation.iso_code3}_${nation.name_kr}`"
              :ripple="false"
              @click="onNationClick(nation)"
            >
              {{ nation.name_kr }}
            </v-list-item>
          </v-list>
        </div>
      </div>
    </v-bottom-sheet>
  </div>
</template>
<script>
import InputTextField from './InputTextField';

export default {
  components: {
    InputTextField,
  },
  props: {
    field: Object,
    nations: { type: Array },
  },
  data: function () {
    return {
      iconClear: require('@/assets/delete_24.svg'),
      focus: false,
      nationality: '',
      nationalitySearch: '',
      nationSheet: false,
    };
  },
  computed: {
    nationList() {
      const filteredNations = this.nations.filter(
        (nation) => nation.name_kr.indexOf(this.nationalitySearch) > -1
      );
      return this.nationalitySearch.length ? filteredNations : this.nations;
    },
  },
  methods: {
    onNationClick(nation) {
      this.nationSheet = false;
      this.nationality = nation.name_kr;
      this.nationalitySearch = '';
      this.$emit('change', { field: this.field, item: nation });
    },
  },
};
</script>
<style lang="scss" scoed>
.field__button {
  width: 100%;
  background-color: var(--surface-100);
  box-shadow: none;
  border: 1px solid var(--gray-300);
  border-radius: 8px;
  padding: 16px 24px;
  height: auto;
  display: flex;
  justify-content: flex-start;
  color: var(--surface-medium);
  font-size: 20px;
  font-weight: 400;
  outline: none;
  &.selected {
    color: var(--surface-high);
  }
  &:hover:before,
  &:focus:before {
    opacity: 0;
  }
  &:nth-of-type(2) {
    margin-top: 8px;
  }

  input {
    outline: none;
    width: 100%;
  }
}
input::placeholder {
  color: var(--surface-medium);
}

.field__list__wrapper {
  background-color: var(--surface-100);
  border-radius: 8px 8px 0 0;
  position: relative;
  & > div:first-child {
    padding: 16px;
    &::after {
      content: '';
      display: block;
      height: 4px;
      width: 60px;
      border-radius: 2px;
      background: #c4c4c4;
      margin: 0 auto;
    }
  }
  .v-sheet.field__list__container {
    overflow: auto;
    height: calc(100% - 36px);
    // min-height: calc(100vh - 36px);
    min-height: 538px;
    padding: 0 0 8px 0;

    .field__search {
      position: sticky;
      top: 0px;
      z-index: 1;
      padding: 16px 16px 8px;
      background-color: var(--surface-100);
    }

    .v-list-item.v-list-item--link {
      font-size: 20px;
      font-weight: 400;
      padding: 16px 24px;
      justify-content: space-between;
      img {
        height: 30px;
        width: 30px;
        color: var(--primary-100);
      }
      &:after {
        content: unset;
      }
    }
  }
}
</style>

<template>
  <svg
    width="32"
    height="22"
    viewBox="0 0 32 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M29.5 1H2.5C1.67157 1 1 1.55964 1 2.25V19.75C1 20.4404 1.67157 21 2.5 21H29.5C30.3284 21 31 20.4404 31 19.75V2.25C31 1.55964 30.3284 1 29.5 1Z"
      stroke="var(--primary-80)"
      stroke-width="1.5"
      stroke-linejoin="round"
    />
    <path
      d="M21.8125 11.3845C22.5584 11.3845 23.2738 11.0806 23.8012 10.5396C24.3287 9.99861 24.625 9.2649 24.625 8.49985C24.625 7.7348 24.3287 7.00109 23.8012 6.46012C23.2738 5.91915 22.5584 5.61523 21.8125 5.61523C21.0666 5.61523 20.3512 5.91915 19.8238 6.46012C19.2963 7.00109 19 7.7348 19 8.49985C19 9.2649 19.2963 9.99861 19.8238 10.5396C20.3512 11.0806 21.0666 11.3845 21.8125 11.3845V11.3845Z"
      stroke="var(--primary-80)"
      stroke-width="1.5"
      stroke-linejoin="round"
    />
    <path
      d="M26.125 16.0002C26.125 14.7761 25.6509 13.6021 24.807 12.7366C23.9631 11.871 22.8185 11.3848 21.625 11.3848C20.4315 11.3848 19.2869 11.871 18.443 12.7366C17.5991 13.6021 17.125 14.7761 17.125 16.0002"
      stroke="var(--primary-80)"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.875 12.1543H13.375"
      stroke="var(--primary-80)"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.875 15.2305H13.375"
      stroke="var(--primary-80)"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.875 7.1543H10.2062"
      stroke="var(--primary-80)"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {};
</script>

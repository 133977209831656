<template>
  <ViewContainer :paddingTop="136">
    <div class="container-phase-complete">
      <div v-if="resultType === 1">
        <FinishVue style="margin-bottom: 30px" />
        <div class="title">{{ this.resultTitle }}</div>
        <div class="message">
          <div v-if="resultConfig.use_custom_end_message">
            {{ resultConfig.custom_end_message }}
          </div>
          <div v-else>
            {{ $t('인증완료.입력하신 KYC 인증이 완료되었습니다.') }}
          </div>
        </div>
      </div>
      <div v-else>
        <KYC_ProgressVue style="margin-bottom: 30px" />
        <div v-if="resultConfig?.use_custom_end_message && resultConfig?.custom_end_message.indexOf('@useb@') > -1"
             class="title">{{ resultConfig?.custom_end_message.split('@useb@')[0] }}</div>
        <div v-else class="title">{{ $t('인증완료.심사 진행 중') }}</div>
        <div class="message">
          <div v-if="resultConfig?.use_custom_end_message && resultConfig?.custom_end_message.indexOf('@useb@') > -1">
            {{ resultConfig?.custom_end_message.split('@useb@')[1] }}
          </div>
          <div v-if="resultConfig?.use_custom_end_message && resultConfig?.custom_end_message.indexOf('@useb@') === -1">
            {{ resultConfig?.custom_end_message }}
          </div>
          <div
            v-if="!(resultConfig?.use_custom_end_message)"
            v-html="
              $t('인증완료.입력하신 KYC 인증 정보 최종 검토 후', {
                resultMessage: $t(`인증완료.${this.resultMessage}`),
              })
            "
          />
        </div>
      </div>
      <div class="button-container">
        <div
          @click="onClickNext"
          class="button ok"
          :id="'resultSuccess_button_ok'"
        >
          {{ $t('버튼.확인') }}
        </div>
      </div>
    </div>
  </ViewContainer>
</template>

<script>
import Constants from '@/constants';
import ViewContainer from '../../../layout/auth/ViewContainer.vue';
import FinishVue from './DynamicCt_imgs/Finish.vue';
import KYC_ProgressVue from './DynamicCt_imgs/KYC_Progress.vue';

export default {
  props: {
    resultConfig: {
      email_notification: Boolean,
      sms_notification: Boolean,
      use_custom_end_message: Boolean,
      custom_end_message: String,
    },
    resultType: {
      type: Number,
    },
    moduleName: { type: String },
  },
  methods: {
    onClickNext() {
      this.$emit('next');
    },
  },
  mounted() {
    this.$log.debug('ResultSuccess #mounted', {
      resultConfig: this.resultConfig,
      resultType: this.resultType,
      moduleName: this.moduleName,
    });
  },
  computed: {
    resultTitle() {
      const { OCR, IDCARD, ACCOUNT, CUSTOM_FIELD } = Constants.MODULE;
      const idcard = [OCR, IDCARD, `${OCR}+${CUSTOM_FIELD}`, `${IDCARD}+${CUSTOM_FIELD}`]; // prettier-ignore
      const account = [ACCOUNT, `${ACCOUNT}+${CUSTOM_FIELD}`];

      if (idcard.some((el) => el === this.moduleName))
        return this.$t('인증완료.신분증 인증 완료');
      if (account.some((el) => el === this.moduleName))
        return this.$t('인증완료.계좌 인증 완료');
      return this.$t('인증완료.본인 인증 완료');
    },
    resultMessage() {
      if (
        this.resultConfig.email_notification &&
        this.resultConfig.sms_notification
      )
        // return '이메일, SMS를 통해 승인 여부를';
        return 'emailandsms';
      if (this.resultConfig.email_notification)
        // return '이메일을 통해 승인 여부를';
        return 'email';
      if (this.resultConfig.sms_notification) {
        // return 'SMS를 통해 승인 여부를';
        return 'sms';
      }
      // return '다시';
      return 'else';
    },
  },
  components: { ViewContainer, FinishVue, KYC_ProgressVue },
};
</script>

<style>
svg rect {
  transform-box: fill-box;
}
</style>

<style lang="scss" scoped>
.root-container {
  position: relative;
  height: 100%;
  overflow: auto;
  color: var(--surface-high);
}

.container-phase-complete {
  height: 100%;
  & > div:not(.button-container) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .title {
    font-weight: bold;
    font-size: 28px;
    color: var(--surface-high);
    margin-bottom: 24px;
  }

  .message div {
    font-size: 16px;
    line-height: 26px;
    color: var(--surface-high);
    text-align: center;
  }
}

.spacer {
  flex-grow: 1;
}

.button-container {
  display: flex;
  padding: 0 auto 40px;
  margin-top: 56px;
  width: 100%;

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    font-weight: 500;
    font-size: 1rem;
    height: 60px;
    cursor: pointer;
    user-select: none;

    &.cancel {
      flex-grow: 0.65;
      background: var(--gray-100);
      color: var(--surface-medium);
      margin-right: 10px;
    }

    &.ok {
      flex-grow: 1;
      color: var(--font-color);
      background: var(--primary-100);
    }
  }
}
</style>

<template>
  <svg
    width="224"
    height="164"
    viewBox="0 0 234 184"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_75_1188"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="224"
      height="164"
    >
      <rect width="224" height="164" fill="#C4C4C4" />
    </mask>
    <g mask="url(#mask0_75_1188)">
      <path
        d="M112.521 79.2919C111.838 82.5345 113.913 85.7216 117.154 86.4103L205.044 105.087C208.285 105.776 211.466 103.705 212.149 100.463L224 44.1707L124.372 23L112.521 79.2919Z"
        fill="#F9F9FF"
      />
      <rect
        width="101.853"
        height="10.7671"
        transform="matrix(0.978159 0.207856 -0.20601 0.97855 122.597 31.4285)"
        fill="var(--primary-10)"
      />
      <path
        d="M178.08 90.5508L180.673 91.1018C182.274 91.442 183.636 91.0141 183.949 89.5311C184.158 88.5357 183.696 87.8552 182.95 87.4991L182.96 87.4494C183.625 87.3619 184.145 86.7445 184.288 86.0677C184.577 84.6941 183.532 84.0041 182.039 83.6869L179.633 83.1755L178.08 90.5508ZM180.453 86.4903L180.847 84.6191L181.712 84.8028C182.586 84.9885 182.964 85.3391 182.827 85.9861C182.704 86.5733 182.221 86.866 181.298 86.6698L180.453 86.4903ZM179.772 89.7251L180.225 87.5752L181.237 87.7902C182.239 88.003 182.704 88.4243 182.553 89.1409C182.392 89.9073 181.776 90.1508 180.784 89.94L179.772 89.7251Z"
        fill="var(--primary-10)"
      />
      <path
        d="M187.155 88.4656C187.533 87.7451 187.925 86.955 188.281 86.1884L188.33 86.1988C188.369 87.0389 188.387 87.9267 188.452 88.7411L188.498 89.5932L186.77 89.2259L187.155 88.4656ZM183.832 91.7732L185.306 92.0863L186.214 90.3037L188.572 90.8046L188.684 92.8042L190.207 93.1277L189.422 85.2557L187.713 84.8926L183.832 91.7732Z"
        fill="var(--primary-10)"
      />
      <path
        d="M191.058 93.3085L192.433 93.6007L193.055 90.6447C193.233 89.7986 193.32 88.85 193.419 88.0392L193.468 88.0496L193.871 89.7887L195.143 94.1768L196.617 94.4899L198.169 87.1146L196.794 86.8224L196.174 89.7685C195.998 90.6046 195.911 91.605 195.818 92.386L195.769 92.3756L195.377 90.6286L194.093 86.2484L192.61 85.9333L191.058 93.3085Z"
        fill="var(--primary-10)"
      />
      <path
        d="M198.414 94.8719L199.868 95.1808L200.304 93.1105L201.519 92.0688L202.707 95.7839L204.298 96.1221L202.626 91.0979L205.458 88.6633L203.857 88.3231L200.776 91.0166L200.746 91.0104L201.421 87.8055L199.967 87.4966L198.414 94.8719Z"
        fill="var(--primary-10)"
      />
      <path
        d="M47.2712 12.9048C47.2712 7.4345 51.7058 3 57.176 3H163.444C168.915 3 173.349 7.43451 173.349 12.9048V81.6667H47.2712V12.9048Z"
        fill="var(--primary-10)"
      />
      <path
        d="M35.5729 135.731C34.764 139.469 37.6124 143 41.4371 143H179.183C183.008 143 185.857 139.469 185.048 135.731L173.349 81.6667H47.2714L35.5729 135.731Z"
        fill="var(--primary-10)"
      />
      <rect
        x="148.134"
        y="14.9997"
        width="19.907"
        height="1.33333"
        rx="0.666667"
        fill="var(--primary-20)"
      />
      <rect
        x="148.134"
        y="12.3326"
        width="19.907"
        height="1.33333"
        rx="0.666667"
        fill="var(--primary-20)"
      />
      <rect
        x="148.134"
        y="9.66766"
        width="19.907"
        height="1.33333"
        rx="0.666667"
        fill="var(--primary-20)"
      />
      <rect
        x="52.58"
        y="20.3339"
        width="21.2342"
        height="61.3333"
        fill="var(--primary-20)"
      />
      <rect
        x="75.1409"
        y="20.3339"
        width="22.5613"
        height="61.3333"
        fill="var(--primary-20)"
      />
      <rect
        x="99.0293"
        y="20.3339"
        width="22.5613"
        height="61.3333"
        fill="var(--primary-20)"
      />
      <rect
        x="122.918"
        y="20.3339"
        width="21.2342"
        height="61.3333"
        fill="var(--primary-20)"
      />
      <rect
        x="145.479"
        y="20.3339"
        width="22.5613"
        height="61.3333"
        fill="var(--primary-20)"
      />
      <path
        d="M52.5597 81.6659H73.6456L68.3472 133.666H41.963L52.5597 81.6659Z"
        fill="var(--primary-20)"
      />
      <path
        d="M75.1114 81.6659H97.663L96.1972 133.666H69.813L75.1114 81.6659Z"
        fill="var(--primary-20)"
      />
      <path
        d="M99.1288 81.6659H121.492L122.958 133.666H97.663L99.1288 81.6659Z"
        fill="var(--primary-20)"
      />
      <path
        d="M122.958 81.6659H144.044L150.808 133.666H124.424L122.958 81.6659Z"
        fill="var(--primary-20)"
      />
      <path
        d="M145.51 81.6659H168.061L178.658 133.666H152.274L145.51 81.6659Z"
        fill="var(--primary-20)"
      />
      <ellipse cx="111.285" cy="143" rx="20.9024" ry="21" fill="#F53C3C" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M104.824 138.115C104.434 137.725 104.434 137.092 104.824 136.701C105.215 136.311 105.848 136.311 106.238 136.701L111.531 141.994L116.824 136.701C117.215 136.311 117.848 136.311 118.238 136.701C118.629 137.092 118.629 137.725 118.238 138.115L112.946 143.408L118.238 148.701C118.629 149.092 118.629 149.725 118.238 150.115C117.848 150.506 117.215 150.506 116.824 150.115L111.531 144.822L106.238 150.115C105.848 150.506 105.215 150.506 104.824 150.115C104.434 149.725 104.434 149.092 104.824 148.701L110.117 143.408L104.824 138.115Z"
        fill="white"
      />
    </g>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>

<template>
  <svg
    width="184"
    height="160"
    viewBox="0 0 184 160"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M184 110C184 124.359 172.359 136 158 136C143.641 136 132 124.359 132 110C132 95.6406 143.641 84 158 84C172.359 84 184 95.6406 184 110Z"
      fill="var(--primary-5)"
    />
    <path
      d="M88 44C88 68.3005 68.3005 88 44 88C19.6995 88 0 68.3005 0 44C0 19.6995 19.6995 0 44 0C68.3005 0 88 19.6995 88 44Z"
      fill="var(--primary-5)"
    />
    <path
      d="M166 77C166 112.346 137.346 141 102 141C66.6538 141 38 112.346 38 77C38 41.6538 66.6538 13 102 13C137.346 13 166 41.6538 166 77Z"
      stroke="var(--primary-20)"
      stroke-width="4"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-dasharray="4 10"
    />
    <path
      d="M149 77.5C149 103.181 128.181 124 102.5 124C76.8188 124 56 103.181 56 77.5C56 51.8188 76.8188 31 102.5 31C128.181 31 149 51.8188 149 77.5Z"
      fill="var(--primary-10)"
    />
    <line
      x1="89.5947"
      y1="62.7964"
      x2="89.5947"
      y2="67.9934"
      stroke="var(--primary-40)"
      stroke-width="4.5"
      stroke-linecap="round"
    />
    <line
      x1="116.207"
      y1="62.7964"
      x2="116.207"
      y2="67.9934"
      stroke="var(--primary-40)"
      stroke-width="4.5"
      stroke-linecap="round"
    />
    <path
      d="M104.084 72.8787L100.655 76.3071L104.084 79.7355"
      stroke="var(--primary-40)"
      stroke-width="4.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M88.1812 95.8623C89.7187 93.7328 94.9743 88.1047 103.696 88.1047C112.418 88.1047 117.674 93.7328 119.211 95.8623"
      stroke="var(--primary-40)"
      stroke-width="4.5"
      stroke-linecap="round"
    />
    <circle cx="103" cy="139" r="21" fill="#F53C3C" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M96.4804 134.115C96.0899 133.725 96.0899 133.092 96.4804 132.701C96.8709 132.311 97.5041 132.311 97.8946 132.701L103.188 137.994L108.48 132.701C108.871 132.311 109.504 132.311 109.895 132.701C110.285 133.092 110.285 133.725 109.895 134.115L104.602 139.408L109.895 144.701C110.285 145.092 110.285 145.725 109.895 146.115C109.504 146.506 108.871 146.506 108.48 146.115L103.188 140.822L97.8946 146.115C97.5041 146.506 96.8709 146.506 96.4804 146.115C96.0899 145.725 96.0899 145.092 96.4804 144.701L101.773 139.408L96.4804 134.115Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {};
</script>

<template>
  <ViewContainer
    id="bankSelect_container"
    :paddingTop="appData.hasLogo ? 48 : 32"
  >
    <img
      @click="stopScan = true"
      src="@/assets/icon-close-32.svg"
      class="icon-close"
      :style="appData.hasLogo ? 'top:48px' : 'top:32px'"
      id="bankSelect_close_button"
    />
    <div class="card-result-title">{{ $t('계좌.계좌인증') }}</div>
    <img
      src="@/assets/back_32.svg"
      alt="back"
      class="icon-prev"
      :style="appData.hasLogo ? 'top:48px' : 'top:32px'"
      v-if="!loading"
      @click="$emit('cancel', { prev: true })"
    />

    <div class="bank-title-container">
      <h2>{{ $t('계좌.1원 송금을 받으실') }}</h2>
      <h2>{{ $t('계좌.은행 계좌를 선택하세요.') }}</h2>
    </div>

    <div class="account-input-field">
      <v-bottom-sheet scrollable v-model="sheet" inset close-delay="0">
        <template v-slot:activator="{ on, attrs }">
          <!-- @click="input1Validity = true" -->
          <v-btn
            id="bank-select--btn"
            class="bank-select"
            :ripple="false"
            v-bind="attrs"
            v-on="on"
            ref="bankSelectModal"
            :disabled="loading"
          >
            <v-img
              v-if="selectedItem !== -1"
              max-height="24px"
              max-width="24px"
              style="margin-right: 0.75rem"
              :transition="false"
              :src="
                require('@/assets/' +
                  items[selectedTab].items[selectedItem].img)
              "
            ></v-img>
            {{ bank }}
          </v-btn>
        </template>
        <v-sheet
          class="text-center bank-list-sheet"
          :height="sheetHeight"
          ref="bankSheet"
        >
          <div style="padding: 16px">
            <div class="bottom-sheet-handle"></div>
          </div>
          <v-card style="box-shadow: none">
            <v-tabs
              slider-color="var(--primary-100)"
              colors="#fff"
              v-model="tab"
              align-with-title
              style="box-shadow: none"
            >
              <v-tabs-slider
                style="
                  width: 100%;
                  margin-left: -16px;
                  margin-right: -16px;
                  width: calc(100% + 32px);
                "
                color="var(--primary-100)"
              ></v-tabs-slider>
              <v-tab
                :ripple="false"
                class="bank-tab"
                v-for="(item, idx) in items"
                :key="idx"
              >
                {{ item.title }}
              </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
              <v-tab-item v-for="(item, idx) in items" :key="idx">
                <v-card flat ref="bankCard">
                  <ul class="bank-list-container" ref="bankContainer">
                    <li
                      v-for="(bank, idx) in item.items"
                      :key="idx"
                      class="bank-list-item"
                      @click="onClickItem(bank, idx)"
                    >
                      <v-img
                        class="bank-image"
                        :transition="false"
                        :src="require('@/assets/' + bank.img)"
                      />
                      <v-list-item-title
                        v-text="$t(`banklist.${bank.name}`)"
                      ></v-list-item-title>
                    </li>
                  </ul>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </v-sheet>
      </v-bottom-sheet>
      <InputTextField
        v-model="account.account_number"
        :append="clearIconAccountNumber"
        type="text"
        pattern="\d*"
        @append="account.account_number = ''"
        @keydown="onKeyDown"
        :readonly="loading"
        :transform="rules.transformOnlyNumber"
        @focus="showClearIcon = true"
        @blur="showClearIcon = false"
        :placeholder="$t('placeholder.계좌번호 입력 (\'-\'제외)')"
        class="userinput-input"
        style="margin-top: 16px"
        id="bankSelect_account_input"
      />
      <div
        class="check-account-name-container"
        v-if="
          !appData.config.use_name_verification &&
          !isFirst &&
          account.account_number
        "
      >
        <h2>{{ $t('계좌.입력하신 계좌의 예금주명이') }}</h2>
        <h2 style="display: flex; align-items: center">
          <div class="modified-account">
            {{
              account.account_modified
                ? account.mod_account_holder
                : account.account_holder
            }}
          </div>
          {{ $t('계좌.가(이) 맞나요?') }}
        </h2>
        <div
          style="display: flex; justify-content: flex-start; flex-wrap: wrap"
        >
          <div class="account-radio-btn-container">
            <input
              type="radio"
              :value="true"
              v-model="bank_account_consistent"
              name="account_holder"
              id="account-confirm"
            />
            <label for="account-confirm" style="margin-right: 26px">{{
              $t('버튼.네')
            }}</label>
            <input
              type="radio"
              :value="false"
              v-model="bank_account_consistent"
              name="account_holder"
              id="account-update"
            />
            <label for="account-update">{{ $t('버튼.아니오') }}</label>
          </div>
        </div>
      </div>
      <div class="security-firms-info" v-if="tab">
        <p>
          {{
            $t(
              '계좌.*일부 증권사에서는 인증 문구를 확인하기 어려운 경우가 있습니다.'
            )
          }}
        </p>
        <p>
          {{
            $t('계좌.증권사마다 인증 문구를 확인할 수 있는 방법이 상이하므로,')
          }}
        </p>
        <p>
          {{
            $t(
              '계좌.인증 문구를 찾을 수 없을 경우 해당 금융사에 문의 부탁드립니다.'
            )
          }}
        </p>
      </div>

      <div class="button-container" ref="btnContainer">
        <v-btn
          class="next"
          dark
          style="box-shadow: none"
          :color="disabled ? 'var(--primary-20)' : 'var(--primary-100)'"
          :disabled="disabled"
          @click="startAccount"
          :class="{ disabled: disabled, loading }"
          id="bankSelect_submit_button"
        >
          <span v-if="!loading" style="color: var(--font-color)">{{
            $t('계좌.1원 송금')
          }}</span>
          <Loading v-else />
        </v-btn>
      </div>
    </div>

    <ExitDialog
      id="bankSelect_exitPopup"
      v-model="stopScan"
      @ok="onClickBack"
      :disabledProp="!stopScan"
    ></ExitDialog>
    <AccountHolderDialog
      v-model="account_holder_dialog"
      @ok="onChangeAccountHolder"
      @cancel="bank_account_consistent = true"
      :loading="checkDialogLoading"
      id="bankSelect_accountHolderPopup"
      :disabledProp="!account_holder_dialog"
    />
    <CheckAccountDialogVue
      v-model="check_account_dialog"
      :userName="account.account_holder"
      :accountHolder="account.mod_account_holder"
      :loading="checkDialogLoading"
      @ok="onConfirmAccoutCheck"
      @cancel="onCancelAccountCheck"
      id="bankSelect_checkAccountPopup"
      :disabledProp="!check_account_dialog"
    />
    <SystemErrorDialogVue
      v-model="system_error_dialog"
      :errorCode="errorCode"
      id="bankSelect_systemErrorPopup"
      :disabledProp="!system_error_dialog"
    />
    <DefaultErrorDialog
      v-model="default_error_dialog"
      @retry="isRetry = true"
      :errorCode="errorCode"
      :errorType="errorType"
      id="bankSelect_defaultErrorPopup"
      :disabledProp="!default_error_dialog"
    />
  </ViewContainer>
</template>
<script>
import BANK_CONSTANTS from './constants';
import GLOBAL_CONSTANTS from '@/constants.js';
import useb from '@/api/useb';
// import server from '@/api/server'
import AccountHolderDialog from './dialog/AccountHolderDialog';
import CheckAccountDialogVue from './dialog/CheckAccountDialog.vue';
import ExitDialog from './dialog/ExitDialog';
import SystemErrorDialogVue from './dialog/SystemErrorDialog.vue';

import InputTextField from '../auth/InputTextField.vue';
import rules from '../rules';
import Loading from './Loading.vue';
import DefaultErrorDialog from './dialog/DefaultErrorDialog.vue';
import ViewContainer from '../../../layout/auth/ViewContainer.vue';

export default {
  name: 'BankSelect',
  props: ['phase', 'phaseItems', 'selectBank', 'appData', 'industry'],
  components: {
    ExitDialog,
    InputTextField,
    Loading,
    AccountHolderDialog,
    CheckAccountDialogVue,
    SystemErrorDialogVue,
    DefaultErrorDialog,
    ViewContainer,
  },
  data() {
    return {
      rules,
      stopScan: false,
      iconClear: require('@/assets/delete_24.svg'),
      showClearIcon: false,

      dialog: {
        title: '시스템 오류',
        contents1:
          '시스템 오류로 계좌 확인에 실패하였습니다. 잠시 후 재시도 해주세요.',
        contents2: '잠시 후 재시도 해주세요.',
        btnText: '확인',
        open: false,
      },
      sheet: false,
      bank: this.$t('계좌.은행/증권사 선택'),
      bankList: BANK_CONSTANTS.BANK_NAME,

      selectedItem: -1,
      selectedTab: null,
      tab: null,
      items: [
        {
          title: this.$t('계좌.은행'),
          name: 'bank',
          items: BANK_CONSTANTS.BANK_NAME,
        },
        {
          title: this.$t('계좌.증권사'),
          name: 'brokerage',
          items: BANK_CONSTANTS.BROKERAGE,
        },
      ],

      account: {
        verified_account: false,
        finance_company: '',
        finance_code: '',
        account_number: '',
        account_holder: this.appData.userName,
        account_modified: false,
        mod_account_holder: '',
      },
      bank_account_consistent: undefined,
      account_holder_dialog: false,
      check_account_dialog: false,
      system_error_dialog: false,
      default_error_dialog: false,
      errorCode: '',
      errorType: '',
      mod_account_holder: '',
      loading: false,
      checkDialogLoading: false,
      disabled: true,

      sheetHeight: 500,
      isFirst: true, // 첫번재 시도에는 보여주지 않음
      isRetry: false, // 계좌 검증 실패 후 재시도
      isFirmbank: false,
    };
  },
  mounted() {
    let _this_ = this;

    _this_.sheet = _this_.selectBank;

    const FIRMBANK = [
      GLOBAL_CONSTANTS.INDUSTRY.VASP,
      GLOBAL_CONSTANTS.INDUSTRY.P2P,
    ];

    let bank_support = 'openbank';
    if (FIRMBANK.includes(_this_.industry)) {
      bank_support = 'firmbank';
      _this_.isFirmbank = true;
    }
    _this_.$log.debug('BankSelect.vue #mounted', {
      appData: _this_.appData,
      industry: _this_.industry,
      bank_support,
      isFirmbank: _this_.isFirmbank,
    });

    // 입력 받은 순서(priority를 map에 저장)
    let preorder_bank_map = new Map();
    let preorder_brokerage_map = new Map();
    _this_?.appData?.priority_finance_code_list?.forEach((value) => {
      preorder_bank_map.set(value, null);
      preorder_brokerage_map.set(value, null);
    });

    _this_.items = _this_.items.map((type) => {
      return {
        ...type,
        items: type.items.filter((item) => {
          if (
            _this_?.appData?.exclude_finance_code_list.includes(item.bank_code)
          ) {
            return false;
          } else if (_this_?.appData?.priority_finance_code_list.includes(item.bank_code)) {
            // '은행', '증권사' 각 맵에 priority 대상인 경우 맵에 업데이트
            if (item.bank_support === bank_support || item.bank_support === 'all') {
              if (type.name === 'bank') {
                preorder_bank_map.set(item.bank_code, item);
              } else {
                preorder_brokerage_map.set(item.bank_code, item);
              }
            }
            return false;
          }
          return (
            item.bank_support === bank_support || item.bank_support === 'all'
          );
        }),
      };
    });

    // map.values()로 순서를 보장하고, 업데이트 되지 않은 항목(value === null)은 제외시킴
    let preorder_bank_items = [...preorder_bank_map.values()].filter(value => value !== null);
    let preorder_brokerage_items = [...preorder_brokerage_map.values()].filter(value => value !== null);

    // 0 : 은행, 1 : 증권사
    // 각 priority 리스트를 각 '은행', '증권사' 리스트의 최상위에 올려서 업데이트
    _this_.items[0].items = [...preorder_bank_items, ..._this_.items[0].items];
    _this_.items[1].items = [...preorder_brokerage_items, ..._this_.items[1].items];
  },
  computed: {
    clearIconAccountNumber() {
      return this.showClearIcon && this.account.account_number
        ? this.iconClear
        : '';
    },
  },
  watch: {
    account: {
      deep: true,
      handler(val) {
        if (
          val.account_number.length >= 7 &&
          val.finance_code &&
          (this.isFirst ||
            this.bank_account_consistent ||
            this.account.mod_account_holder ||
            this.isRetry)
        ) {
          this.disabled = false;
        } else {
          this.disabled = true;
        }
      },
    },
    bank_account_consistent: function (newBool) {
      if (newBool === false) {
        this.account_holder_dialog = true;
      }
      if (
        this.account.account_number &&
        this.account.finance_code &&
        (newBool || this.account.mod_account_holder)
      ) {
        this.disabled = false;
      }
    },
  },

  methods: {
    onKeyDown(e) {
      const maxLength = 16;
      const { account_number } = this.account;
      const new_account_number = account_number.slice(0, maxLength);
      this.$set(this.account, 'account_number', new_account_number);

      if (e.key === 'Enter') {
        this.startAccount();
      }
    },
    onClickItem(item, idx) {
      this.selectedTab = this.tab;
      this.bank = this.$t(`banklist.${item.name}`);
      this.sheet = false;
      this.selectedItem = idx;
      // this.input1Validity = false;
      this.$set(
        this.account,
        'finance_code',
        this.items[this.tab].items[idx].bank_code
      );
      this.$set(
        this.account,
        'finance_company',
        this.items[this.tab].items[idx].fullName
      );
    },
    onClickBack() {
      this.$emit('cancel');
    },
    onClickHolderChange(event) {
      this.$emit('next', event);
    },
    onCloseModal() {
      this.$set(this.dialog, 'open', false);
    },
    async checkAccount() {
      try {
        // eslint-disable-next-line no-unreachable
        const data = await useb.checkAccount(
          {
            bank_code: this.account.finance_code,
            account_num: this.account.account_number,
            account_holder_info: this.appData.birthDate
              .replaceAll('-', '')
              .substr(2, 8),
          },
          this.isFirmbank
        );

        return data;
      } catch (error) {
        this.loading = false;
        const { errorCode } = error;
        this.$log.debug(errorCode);
        switch (errorCode) {
          case 'RN004':
            this.default_error_dialog = true;
            this.errorCode = errorCode;
            this.errorType = 'verifyError';

            if (
              /^01(?:0|1|6|7|8|9)\d{0,8}$/.test(this.account.account_number)
            ) {
              this.errorCode = 'CF016';
              this.errorType = 'accountNumberError';
            }
            break;
          case 'RN013':
            this.default_error_dialog = true;
            this.errorCode = errorCode;
            this.errorType = 'maintenanceError';
            break;
          default:
            this.errorCode = errorCode;
            this.system_error_dialog = true;
            break;
        }
      }
    },
    async onConfirmAccoutCheck() {
      this.checkDialogLoading = true;
      await this.send(true);
      this.check_account_dialog = false;
      this.checkDialogLoading = false;
    },
    onCancelAccountCheck() {
      this.disabled = true;
    },
    async send(isModified) {
      try {
        // eslint-disable-next-line no-unreachable
        this.$log.debug('isModified', isModified);

        const sendData = {
          bank_code: this.account.finance_code,
          account_num: this.account.account_number,
          account_holder_name: isModified
            ? this.account.mod_account_holder
            : this.account.account_holder,
          code_type: this.appData.account_subcode,
        };
        this.$log.debug({ sendData });
        // 하이픈 펌뱅킹 1원송금은 account_holder_name가 필수가 아님.
        // if (this.isFirmbank) sendData.account_holder_name = '테스트';
        const res = await useb.sendBank(sendData, this.isFirmbank);
        const useb_token = this.isFirmbank ? res.tid : res.transaction_id;

        this.loading = false;
        this.disabled = false;
        this.$set(this.account, 'transaction_id_useb', useb_token);
        this.$set(this.account, 'verified_account', true);
        this.$set(this.account, 'account_modified', isModified);
        this.$log.debug({ account: this.account });
        this.$emit('next', this.account);
      } catch (error) {
        this.isFirst = false;
        this.loading = false;
        this.disabled = true;
        const { errorCode } = error;
        this.$log.debug(3);

        if (['S013', 'FS013'].includes(errorCode)) {
          this.default_error_dialog = true;
          this.errorCode = errorCode;
          this.errorType = 'maintenanceError';
        } else if (['S011', 'S012', 'FS003', 'FS004'].includes(errorCode)) {
          this.default_error_dialog = true;
          this.errorCode = errorCode;
          this.errorType = 'verifyError';
        } else {
          this.default_error_dialog = true;
          this.errorCode = errorCode;
          this.errorType = 'systemError';
        }
      }
    },
    async onChangeAccountHolder(newAccountHolder) {
      this.checkDialogLoading = true;
      this.$set(this.account, 'account_modified', true);
      this.$set(this.account, 'mod_account_holder', newAccountHolder);
      await this.send(true);
      this.bank_account_consistent = true;
      this.checkDialogLoading = false;
      this.account_holder_dialog = false;
    },
    async startAccount() {
      if (this.disabled) {
        return;
      }
      this.loading = true;
      this.disabled = true;
      try {
        // 계좌 실명 인증 사용 하는 경우
        if (this.appData.config.use_name_verification) {
          const accountHolderInfo = await this.checkAccount();
          this.$log.debug('checkAccount', accountHolderInfo);
          // 펌뱅킹은 계좌 실명인증 결과가 account_name으로 옴
          if (
            accountHolderInfo.account_holder_name === this.appData.userName ||
            accountHolderInfo.account_name === this.appData.userName
          ) {
            // account holder is equalt to userName
            this.$log.debug('계좌 정보 일치');
            await this.send(false);
          } else {
            // account holder is not equal
            this.loading = false;
            this.disabled = false;
            this.$set(
              this.account,
              'mod_account_holder',
              accountHolderInfo.account_holder_name
            );
            this.check_account_dialog = true;
            this.$log.debug('게좌 정보 불일치');
          }
        } else {
          // 계좌 실명 인증 사용 안 함
          this.$log.debug('firmbank start');
          if (this.account.account_modified) {
            this.send(true);
          } else {
            this.send(false);
          }
        }
        return;
      } catch (err) {
        this.$log.debug(err);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@media (min-width: 800px) {
  .container {
    width: 800px !important;
    margin: auto !important;
  }
}

@mixin h2 {
  h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 2rem;

    color: var(--surface-high);
  }
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
  opacity: 1;
}

.icon-prev {
  position: absolute;
  width: 32px;
  left: 32px;
  top: 32px;
  cursor: pointer;
}

.bank-title-container {
  text-align: center;
  margin-bottom: 40px;
  margin-top: 56px;

  @include h2;
}

.account-input-field {
  display: flex;
  flex-direction: column;
  /* flex: 1; */

  .check-account-name-container {
    margin-top: 32px;

    @include h2;
    & > h2 {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 10px;
    }
  }
}

#bank-select--btn.bank-select {
  width: 100%;
  height: 64px;

  display: flex;
  justify-content: flex-start;
  box-shadow: none;
  background: var(--surface-100);
  font-size: 1.25rem;
  line-height: 2rem;
  color: var(--surface-high);
  font-weight: 400;
}

#bank-select--btn.bank-select.v-btn--disabled {
  background-color: var(--gray-100) !important;
  // border: 1px solid var(--surface-medium);
  // background-color: var(--surface-100) !important;
  border: 1px solid var(--surface-disabled);
  color: var(--surface-medium) !important;

  .v-btn__content {
    position: absolute;
    color: var(--surface-medium);
    color: #868686;
  }
}

.bank-select.focus {
  border-color: var(--primary-80);
}
.theme--light.bank-select.v-btn:focus::before {
  opacity: 0;
}
.bank-select:focus {
}

.v-btn__content {
  font-size: 1.25rem;
  letter-spacing: initial;
  font-style: normal;
  font-weight: normal;
  line-height: 2rem;
  color: var(--surface-high);
}

.bank-select,
bank-account-input {
  background: var(--surface-100);
  background-color: var(--surface-100);
  color: var(--surface-high);
  height: 64px;

  /* Primary/80% */

  border: 1px solid var(--gray-300);
  /* 2 dp, Primary */

  box-shadow: 0px 0px 8px rgba(95, 101, 255, 0.1),
    0px 2px 8px 4px rgba(95, 101, 255, 0.05);
  border-radius: 8px;
}
.bank-account-input.focus {
  .v-input__slot.focus {
    border: 1px solid var(--primary-100) !important;
  }
}
.v-window.v-item-group.v-tabs-items {
  padding-top: 20px;
  height: 420px;
  // height: 800px;
  overflow-y: scroll;
  overflow: scroll-y;
  -webkit-overflow-scrolling: touch;
}
.v-text-field--outlined fieldset {
  border: 1px solid var(--gray-300) !important;
}
.v-text-field--outlined.focus fieldset {
  border: 1px solid var(--primary-80) !important;
}
.v-text-field--outlined.v-input--is-focused fieldset {
  border: 1px solid var(--primary-80) !important;
}
.v-input__slot {
  margin-top: 1rem;
  /* border: 1px solid var(--gray-300); */
  border-radius: 8px;
  box-shadow: none !important;
}
.bank-list-sheet {
  /* padding-left: 1rem;
  padding-right: 1rem; */

  width: 100%;
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
  transition: height 1000ms;

  transition-delay: 0ms;
}

.v-tabs .bank-tab {
  width: 50%;
  letter-spacing: 0;
  font-size: 1.25rem;
  margin-left: 0 !important;
  max-width: none;
  border-bottom: 2px solid var(--gray-100);
  color: var(--surface-medium) !important;
  font-weight: 400;
  &::before {
    display: none;
  }
  &:focus {
    background: #fff;
  }
  &:hover {
    background: #fff;
  }
}

.v-tab--active {
  color: var(--primary-100) !important;
}

.bank-list-container {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0px !important;
}
.bank-list-item {
  display: flex;
  flex-direction: column;
  height: 104px;
  align-items: center;
  width: 25%;
  padding: 15px 34px;

  .bank-image {
    max-height: 36px;
    max-width: 36px;
    width: 36px;
    height: 36px;
    margin-bottom: 8px;
  }

  .v-list-item__title {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    text-align: center;

    color: var(--surface-high);
  }
}
.bottom-sheet-handle {
  height: 4px;
  width: 60px;
  border-radius: 2px;
  background: #c4c4c4;
  margin: 0 auto;
}

.account-radio-btn-container {
  display: flex;
  align-items: center;
  input {
    appearance: none;
    border: 1.5px solid var(--surface-high);
    border-radius: 50%;
    box-sizing: border-box;
    width: 1.25rem;
    height: 1.25rem;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:checked {
      appearance: none;
    }
    &:checked::before {
      content: '';
      display: block;
      appearance: none;
      width: 0.75rem;
      height: 0.75rem;

      background-color: var(--primary-100);
      border-radius: 50%;
      box-sizing: border-box;
    }
  }
  label {
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    display: inline-block;
    line-height: 1.5rem;
    color: var(--surface-high);
    &:first-child {
      margin-right: 26px;
    }
  }
}

.modified-account {
  height: 24px;
  background: var(--gray-100);
  border-radius: 4px;
  padding: 0px 10px;
  width: fit-content;
  font-size: 0.875rem;
  font-weight: 500;
  margin-right: 4px;
}

/* Modal Css */
.modal-title {
  font-style: normal;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 2rem;
  color: var(--surface-high);
}
.v-card .v-card__text .modal-contents {
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  margin-bottom: 2rem;
  line-height: 26px;
  color: var(--surface-high);
}
// .modal-btn {
//   padding: 15px 16px;
//   height: 60px !important;
//   color: #fff !important;
//   background: var(--primary-100);
//   font-size: 1rem;
//   border-radius: 8px;
//   span {
//     font-size: 1rem;
//   }
// }
.v-btn__loader {
  color: #fff;
  background: var(--primary-100);
  border-radius: 8px;
}
.v-input__slot {
  height: 64px;
}
div .theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
  background-color: var(--primary-20) !important;
  /* background-color: rgba(215, 216, 255, 1) !important; */
  color: var(--surface-100) !important;
}

.security-firms-info {
  margin-top: 16px;
  padding: 8px 16px;
  background-color: var(--gray-100);
  color: var(--surface-medium);
  font-size: 14px;
  border-radius: 8px;

  p {
    line-height: 24px;
  }
}

.button-container {
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 16px;
  /* height: 116px; */
  cursor: pointer;
  background-color: white;
  button {
    height: 60px !important;
    font-size: 1rem;
    border-radius: 8px;
    color: var(--font-color);
    flex: 1;
  }
  button.disabled {
    background: var(--primary-20);
  }
  .theme--dark.v-btn.v-btn--disabled.v-btn--has-bg.loading {
    background-color: var(--primary-100) !important;
  }
}
.button-container.modal-btn {
  display: flex;
  margin-bottom: 0px;
  padding: 0 0px;

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    font-weight: 500;
    font-size: 1rem;
    height: 60px;
    cursor: pointer;
    user-select: none;

    &.disabled {
      // background: var(--primary-20) !important;
    }

    &.cancel {
      flex-grow: 0.65;
      background: var(--gray-100);
      color: var(--surface-medium);
      margin-right: 10px;
    }

    &.ok {
      flex-grow: 1;
      color: var(--font-color);
      background: var(--primary-100);
    }
  }
}
</style>

<template>
  <svg
    width="103"
    height="120"
    viewBox="0 0 103 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M87.5186 76.0015C87.5186 100.053 68.0214 119.55 43.9703 119.55C19.9191 119.55 0.421875 100.053 0.421875 76.0015C0.421875 51.9504 19.9191 32.4531 43.9703 32.4531C68.0214 32.4531 87.5186 51.9504 87.5186 76.0015Z"
      fill="var(--primary-10)"
    />
    <line
      x1="30.3281"
      y1="61.6367"
      x2="30.3281"
      y2="66.7276"
      stroke="var(--primary-80)"
      stroke-width="4"
      stroke-linecap="round"
    />
    <line
      x1="55.2656"
      y1="61.6367"
      x2="55.2656"
      y2="66.7276"
      stroke="var(--primary-80)"
      stroke-width="4"
      stroke-linecap="round"
    />
    <path
      d="M44.011 71.1999L40.7969 74.4141L44.011 77.6282"
      stroke="var(--primary-80)"
      stroke-width="4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M29.1094 92.7461C30.5508 90.7497 35.4779 85.4734 43.6548 85.4734C51.8317 85.4734 56.7588 90.7497 58.2003 92.7461"
      stroke="var(--primary-80)"
      stroke-width="4"
      stroke-linecap="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M102.996 15.9627C102.996 24.7786 95.8489 31.9253 87.033 31.9253C78.217 31.9253 71.0703 24.7786 71.0703 15.9627C71.0703 7.14672 78.217 0 87.033 0C95.8489 0 102.996 7.14672 102.996 15.9627ZM89.3182 9.12174C89.3182 7.86241 88.2973 6.84152 87.0379 6.84152C85.7786 6.84152 84.7577 7.86241 84.7577 9.12174V17.1034C84.7577 18.3627 85.7786 19.3836 87.0379 19.3836C88.2973 19.3836 89.3182 18.3627 89.3182 17.1034V9.12174ZM87.0354 26.2241C88.2948 26.2241 89.3157 25.2032 89.3157 23.9438C89.3157 22.6843 88.2948 21.6634 87.0354 21.6634C85.7759 21.6634 84.755 22.6843 84.755 23.9438C84.755 25.2032 85.7759 26.2241 87.0354 26.2241Z"
      fill="#F53C3C"
    />
    <path
      d="M76.0294 24.4016L83.9867 29.8663L75.2755 34.0252L76.0294 24.4016Z"
      fill="#F53C3C"
    />
  </svg>
</template>

<script>
export default {};
</script>

<template>
  <v-dialog v-model="dialog" max-width="420px" persistent scrollable>
    <v-card>
      <v-card-text :id="id" :class="{ disabled: disabledProp }">
        <div class="confirm-title">{{ $t('신분증.사진 확인') }}</div>
        <img :src="image" class="crop-image" />
        <div class="confirm-content">
          {{ $t('신분증.신분증 사진이 선명한지 확인하세요.') }}
        </div>
        <div
          class="confirm-tip"
          v-html="
            $t(
              '신분증.빛 반사, 흔들림, 영역 잘림 등으로 정확한 신분증 인식이 어려울 수 있습니다.'
            )
          "
        ></div>
        <div class="button-container">
          <div
            :id="'cardScanConfirmDialog_button_retry'"
            @click="retry"
            class="button retry"
          >
            {{ $t('버튼.재시도') }}
          </div>
          <div
            :id="'cardScanConfirmDialog_button_ok'"
            @click="ok"
            class="button ok"
          >
            {{ $t('버튼.확인') }}
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    upload: {
      type: Boolean,
      default: false,
    },
    image: {
      type: String,
    },
    id: {
      type: String,
    },
    disabledProp: {
      type: Boolean,
    },
  },
  data() {
    return {
      dialog: this.value,
    };
  },
  watch: {
    value(val) {
      this.dialog = val;
    },
  },
  methods: {
    close() {
      this.dialog = false;
      this.$emit('input', false);
    },
    retry() {
      this.close();
      this.$emit('retry');
    },
    ok() {
      this.close();
      this.$emit('ok');
    },
  },
};
</script>

<style lang="scss" scoped>
.v-card__text {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;

  .confirm-title {
    color: var(--surface-high);
    font-weight: 500;
    margin: 40px auto 25px;
    font-size: 20px;
    line-height: 32px;
  }

  .crop-image {
    width: 90%;
    align-self: center;
    border-radius: 8px;
  }

  .confirm-content {
    font-size: 16px;
    line-height: 26px;
    margin: 18px auto 8px;
    color: var(--surface-high);
  }

  .confirm-tip {
    font-size: 12px;
    line-height: 18px;
    color: var(--surface-medium);
    margin: 8px auto 40px;
    text-align: center;
  }
}
.button-container {
  display: flex;
  padding: 0 30px;
  margin-bottom: 32px;

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    font-weight: 500;
    font-size: 1rem;
    height: 60px;
    cursor: pointer;
    user-select: none;

    &.retry {
      flex-grow: 0.65;
      background: var(--gray-100);
      color: var(--surface-medium);
      margin-right: 10px;
    }

    &.ok {
      flex-grow: 1;
      color: var(--font-color);
      background: var(--primary-100);
    }
  }
}
</style>

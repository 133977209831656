<template>
  <div class="loading-root">
    <img
      src="@/assets/Loading_40px.svg"
      width="35px"
      style="margin-bottom: 24px"
    />
    <div
      v-for="(message, index) in messages"
      :key="`${index}-msg`"
      class="text-info"
    >
      {{ message }}
    </div>

    <div
      v-for="(desc, index) in descriptions"
      :key="`${index}-desc`"
      class="text-info desc"
    >
      {{ desc }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    messages: {
      type: Array,
      default: () => [],
    },
    descriptions: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
.loading-root {
  z-index: 203;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #353648cc;

  .text-info {
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    color: var(--surface-100);
    text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2), 0px 4px 8px rgba(0, 0, 0, 0.2);
  }
  .desc {
    font-size: 16px;
    font-weight: 100;
    color: var(--gray-200);
  }
}
</style>

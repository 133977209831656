var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{staticStyle:{"width":"calc(100% - 96px)"},attrs:{"width":"calc(100% - 96px)","max-width":"384px","persistent":""},on:{"click:outside":() => {},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return (() => {}).apply(null, arguments)}},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-text',{class:{ disabled: _vm.disabledProp },staticStyle:{"display":"flex","flex-direction":"column","padding":"0px 30px","padding-top":"2rem","margin":"0","text-align":"center"},attrs:{"id":_vm.id}},[_c('img',{staticClass:"icon-close",staticStyle:{"width":"60px","height":"60px","margin":"0px auto 18px","display":"none"},attrs:{"src":require("@/assets/warning.svg")},on:{"click":function($event){_vm.stopScan = true}}}),(_vm.errorType === 'maintenanceError')?_c('div',{staticClass:"card-title"},[_c('div',{staticClass:"card-title"},[_vm._v(_vm._s(_vm.$t('계좌.송금 실패')))]),_c('div',{staticClass:"card-body",domProps:{"innerHTML":_vm._s(
            _vm.$t(
              '계좌.은행 점검 시간으로 인해 <br />송금이 불가하여 <br />고객 신원 확인을 종료합니다. <br />서비스 가능 시간 확인 후 <br />재시도 해주세요.'
            )
          )}}),_vm._v(" "+_vm._s(_vm.errorCode ? `${_vm.$t('에러.에러코드')}: ${_vm.errorCode}` : '')+" "),_c('div',{staticClass:"button-container",staticStyle:{"margin-bottom":"32px"}},[_c('div',{staticClass:"button ok",on:{"click":_vm.cancel}},[_vm._v(_vm._s(_vm.$t('버튼.확인')))])])]):(_vm.errorType === 'verifyError')?_c('div',[_c('div',{staticClass:"card-title"},[_vm._v(_vm._s(_vm.$t('계좌.계좌 검증 실패')))]),_c('div',{staticClass:"card-body",staticStyle:{"word-break":"keep-all"}},[_vm._v(" "+_vm._s(_vm.$t('계좌.입력하신 계좌가 해당 은행에 존재하지 않거나'))+" "+_vm._s(_vm.$t('계좌.예금주명이 일치하지 않습니다.'))+" "),_c('div',{staticClass:"account-info",staticStyle:{"word-break":"normal"}},[_vm._v(" "+_vm._s(_vm.$t( '계좌.* 미니계좌, 가상계좌 등 본인명의 실계좌가 아닐경우 1원 인증이 어렵습니다.' ))+" ")])]),_vm._v(" "+_vm._s(_vm.errorCode ? `${_vm.$t('에러.에러코드')}: ${_vm.errorCode}` : '')+" "),_c('div',{staticClass:"button-container",staticStyle:{"margin-bottom":"32px"}},[_c('div',{staticClass:"button ok",on:{"click":_vm.retry}},[_vm._v(_vm._s(_vm.$t('버튼.재시도')))])])]):(_vm.errorType === 'accountNumberError')?_c('div',[_c('div',{staticClass:"card-title"},[_vm._v(_vm._s(_vm.$t('계좌.계좌 검증 실패')))]),_c('div',{staticClass:"card-body"},[_vm._v(" "+_vm._s(_vm.$t( '계좌.평생계좌번호일 경우 예금주 정보를 조회할 수 없습니다. 원계좌번호로 다시 시도해주세요.' ))+" "),_c('div',{staticClass:"account-info",domProps:{"innerHTML":_vm._s(
              _vm.$t(
                '계좌.* 평생계좌번호란? <br />휴대전화번호 등 고객이 임의 생성한 계좌번호'
              )
            )}})]),_vm._v(" "+_vm._s(_vm.errorCode ? `${_vm.$t('에러.에러코드')}: ${_vm.errorCode}` : '')+" "),_c('div',{staticClass:"button-container",staticStyle:{"margin-bottom":"32px"}},[_c('div',{staticClass:"button ok",on:{"click":_vm.retry}},[_vm._v(_vm._s(_vm.$t('버튼.재시도')))])])]):_c('div',[_c('div',{staticClass:"card-title"},[_vm._v(_vm._s(_vm.$t('계좌.시스템 오류')))]),_c('div',{staticClass:"card-body",domProps:{"innerHTML":_vm._s(
            _vm.$t(
              '계좌.시스템 오류로 1원 송금에 실패하였습니다.<br />잠시 후 재시도 해주세요.'
            )
          )}}),_vm._v(" "+_vm._s(_vm.errorCode ? `${_vm.$t('에러.에러코드')}: ${_vm.errorCode}` : '')+" "),_c('div',{staticClass:"button-container",staticStyle:{"margin-bottom":"32px"}},[_c('div',{staticClass:"button ok",on:{"click":_vm.cancel}},[_vm._v(_vm._s(_vm.$t('버튼.확인')))])])])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
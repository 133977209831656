<template>
  <ViewContainer id="faceGuide_container">
    <div class="container-phase-guide">
      <Step :phase="phase" :items="phaseItems"></Step>
      <div class="guide-subject">
        {{ $t('온보딩.얼굴 인증을 시작합니다.') }}
      </div>
      <Icon_GuideVue class="icon-guide-img" />
      <div class="tip-container">
        <div class="text-title-tip">{{ $t('온보딩.올바른 촬영 TIP') }}</div>
        <div class="tip-list">
          <img src="@/assets/icon-check.svg" />
          <div>
            {{ $t('온보딩.영역 안에 얼굴을 맞추고 정면을 바라봐 주세요.') }}
          </div>
        </div>
        <div class="tip-list">
          <img src="@/assets/icon-check.svg" />
          <div>
            {{
              $t(
                '온보딩.너무 밝거나 어둡지 않은 공간에서 촬영을 진행해 주세요.'
              )
            }}
          </div>
        </div>
        <div class="tip-list">
          <img src="@/assets/icon-check.svg" />
          <div>
            {{
              $t(
                '온보딩.마스크, 선글라스와 같이 얼굴을 가리는 방해물은 제거 후 촬영해 주세요.'
              )
            }}
          </div>
        </div>
      </div>
      <div class="button-container">
        <div
          @click="onClose"
          id="faceGuide_submit_button"
          class="button cancel"
        >
          {{ $t('버튼.이전') }}
        </div>
        <div
          @click="$emit('next')"
          id="faceGuide_cancel_button"
          class="button ok"
        >
          {{ $t('버튼.얼굴 촬영') }}
        </div>
      </div>
    </div>
    <ExitDialog
      v-model="exitDialog"
      @cancel="exitDialog = false"
      @ok="$emit('cancel')"
      :id="'faceGuide_exitPopup'"
      :disabledProp="!exitDialog"
    />
    <ExitDisabledDialog
      v-model="exitDisabledDialog"
      @cancel="exitDisabledDialog = false"
      @ok="$emit('cancel')"
      :id="'additionalGuide_exitDisabledPopup'"
      :disabledProp="!exitDisabledDialog"
    />
  </ViewContainer>
</template>

<script>
import Step from './Step';
import ViewContainer from '../../../layout/auth/ViewContainer.vue';
import ExitDialog from '../dialog/ExitDialog.vue';
import ExitDisabledDialog from '../dialog/ExitDisabledDialog.vue';
import Icon_GuideVue from './DynamicCt_imgs/Icon_Guide.vue';
export default {
  components: {
    Step,
    ViewContainer,
    ExitDialog,
    ExitDisabledDialog,
    Icon_GuideVue,
  },
  props: {
    phase: Number,
    phaseItems: Array,
    isMultipleModule: Boolean,
  },
  data() {
    return {
      exitDialog: false,
      exitDisabledDialog: false,
    };
  },
  methods: {
    onClose() {
      if (this.isMultipleModule) {
        this.exitDisabledDialog = true;
      } else {
        this.exitDialog = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.root-container {
  position: relative;
  height: 100%;
  overflow: hidden;
  color: var(--surface-high);
  overflow: auto;
}

.container-phase-guide {
  display: flex;
  flex-direction: column;
  height: 100%;

  .guide-subject {
    font-weight: 500;
    font-size: 20px;
    text-align: center;
  }

  .icon-guide-img {
    width: 136px;
    margin: 32px auto 52px;
  }

  .tip-container {
    align-self: center;
    .text-title-tip {
      text-align: left;

      margin-bottom: 24px;
      font-weight: 500;
      font-size: 20px;
    }

    .tip-list {
      display: flex;
      align-items: baseline;
      text-align: left;

      margin-bottom: 16px;
      font-size: 16px;

      & > img {
        height: 10px;
        margin-right: 16px;
      }
    }
  }
}

.spacer {
  flex-grow: 1;
}

.button-container {
  width: 100%;
  bottom: 0;
  display: flex;

  margin-top: 56px;
  background-color: white;

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    font-weight: 500;
    font-size: 1rem;
    height: 60px;
    cursor: pointer;
    user-select: none;

    &.cancel {
      flex-grow: 0.65;
      background: var(--gray-100);
      color: var(--surface-medium);
      margin-right: 10px;
    }

    &.ok {
      flex-grow: 1;
      color: var(--font-color);
      background: var(--primary-100);
    }
  }
}
</style>

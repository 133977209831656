<template>
  <svg
    v-if="checked"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="4" y="4" width="16" height="16" rx="2" fill="var(--primary-100)" />
    <path
      d="M8.19043 12.1906L11.0476 15.0478L15.8095 9.3335"
      stroke="var(--font-color)"
      stroke-width="1.14286"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
  <svg
    v-else
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="4.6"
      y="4.6"
      width="14.8"
      height="14.8"
      rx="1.4"
      fill="var(--gray-100)"
      stroke="var(--gray-300)"
      stroke-width="1.2"
    />
  </svg>
</template>

<script>
export default {
  props: {
    checked: { type: Boolean, default: false },
  },
};
</script>

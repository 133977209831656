/*global faceapi*/
import lenaPng from './lena';

let isInit = false;
let ts = 0;
let forwardTimes = [];
let status = '';
let worker = null;
let workerCallback = null;
let preloadingSuccess = false;
// const isWorker = !!window.Worker; // worker 사용이 가능할 경우
const isWorker = false; // worker 사용이 가능할 경우
const defaultDetectorOptions = new faceapi.TinyFaceDetectorOptions({
  inputSize: 320, // 128, 160, 224, 320, 416, 512, 608
  scoreThreshold: 0.5,
});

export async function initFaceApi() {
  if (!isInit) {
    isInit = true;
    console.info('face api loading...');
    if (isWorker) {
      worker = new Worker('/face/face-api-impl-worker.js');
      worker.onmessage = e => {
        workerCallback && workerCallback(e.data);
      };
      worker.postMessage('init');
    }
    const loaded = await isFaceDetectionModelLoaded();
    if (!loaded) {
      await faceapi.nets.tinyFaceDetector.load('/face');
    }
    console.info('face api - FaceDetector loaded');
    await faceapi.loadFaceLandmarkModel('/face');
    console.info('face api - FaceLandmarkModel loaded');
    await preloading();
  }
}

async function preloading(retry = 100) {
  console.info('face api - preloading');
  let faceImage = new Image();
  faceImage.src = lenaPng;

  const iterable = new Array(retry).fill().map((_, index) => index + 1);
  for await (let count of iterable) {
    const result = await detectSingleFaceForCard(faceImage);
    if (result) {
      break;
    } else {
      console.info('face api - preloading retry', count);
      await new Promise(resolve => setTimeout(resolve, 100));
    }
  }
  console.info('face api - preloaded');
  preloadingSuccess = true;
}

export function destoryFaceApi() {
  if (isWorker && worker) {
    workerCallback = null;
    worker.terminate();
    worker = null;
    preloadingSuccess = false;
  }
}

export function isFaceDetectionModelLoadedSync() {
  return preloadingSuccess && !!faceapi.nets.tinyFaceDetector.params;
}

async function isFaceDetectionModelLoaded() {
  return new Promise(resolve => {
    const check = () => {
      setTimeout(() => {
        if (faceapi) {
          resolve(!!faceapi.nets.tinyFaceDetector.params);
        } else {
          check();
        }
      }, 1000);
    };
    check();
  });
}

export async function detectSingleFaceForCard(image, timestats = false) {
  let options = new faceapi.TinyFaceDetectorOptions({
    inputSize: 320, // 128, 160, 224, 320, 416, 512, 608
    scoreThreshold: 0.3, // 신분증 감안하여 스코어를 조금 낮춤
  });
  if (timestats) {
    ts = Date.now();
  }
  const result = await faceapi
    .detectSingleFace(image, options)
    .withFaceLandmarks();
  if (timestats) {
    updateTimeStats(ts);
  }
  return result;
}

export async function detectSingleFace(imageData, timestats = false) {
  let result;
  if (timestats) {
    ts = Date.now();
  }
  if (isWorker && worker) {
    result = await new Promise(resolve => {
      workerCallback = data => resolve(data);
      worker.postMessage(['detectSingleFace', imageData]);
    });
  } else {
    const canvas = await faceapi.createCanvasFromMedia(imageData);
    result = await faceapi
      .detectSingleFace(canvas, defaultDetectorOptions)
      .withFaceLandmarks();
  }
  if (timestats) {
    updateTimeStats(ts);
  }
  return result;
}

export function matchDimensionsAndResize(canvas, videoEl, result) {
  const dims = faceapi.matchDimensions(canvas, videoEl, true);
  const resizedResult = faceapi.resizeResults(result, dims);
  return resizedResult;
}

export function drawCanvas(canvas, result, withLandmarks = false) {
  // let box;
  // if ("box" in result.detection) {
  //   box = result.detection.box;
  // } else {
  //   box = result.detection._box;
  //   box = { x: box._x, y: box._y, w: box._width, h: box._height };
  // }
  const drawBox = new faceapi.draw.DrawBox(result.detection.box, {
    boxColor: 'blue',
    lineWidth: 1,
  });
  drawBox.draw(canvas);
  if (withLandmarks) {
    faceapi.draw.drawFaceLandmarks(canvas, result);
  }
}

export function euclideanDistance(x1, y1, x2, y2) {
  return faceapi.euclideanDistance([x1, y1], [x2, y2]);
}

function updateTimeStats() {
  const time = Date.now() - ts;
  forwardTimes = [time].concat(forwardTimes).slice(0, 30);
  const avgTimeInMs =
    forwardTimes.reduce((total, t) => total + t) / forwardTimes.length;
  status = `${Math.round(avgTimeInMs)} ms ${faceapi.utils.round(
    1000 / avgTimeInMs
  )} fps`;
}

export function getTimeStats() {
  return status;
}

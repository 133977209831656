<template>
  <div>
    <Loading
      v-if="PHASE_LOADING"
      :success="loadingSuccess"
      :messages="loadingMessages"
    />
    <div v-else :class="['root-container', { logo: appData.hasLogo }]">
      <img
        @click="stopScan = true"
        src="@/assets/icon-close-32.svg"
        class="icon-close"
      />
      <img
        src="@/assets/back_32.svg"
        alt="back"
        width="30"
        class="icon-prev"
        @click="$emit('cancel', { prev: true })"
      />
      <div class="additional-input-title">추가 인증</div>
      <div
        class="additional-stepper"
        v-if="steps.edd.value && steps.survey.value"
      >
        <ul>
          <li v-for="(page, index) in paging.last" :key="`${page}_${index}`">
            <div
              :class="{
                current: paging.current === page,
                prev: page < paging.current,
              }"
            >
              <span :class="['bullet', { prev: page < paging.current }]" />
              <span class="index">{{ page }}</span>
            </div>
          </li>
        </ul>
      </div>
      <!-- EDD Field -->
      <CtAdditionalEdd
        v-show="steps.edd.show"
        :eddField="eddFields"
        :appData="appData"
        @update="onEddFieldUpdate"
      />
      <!-- 추가 설문 -->
      <CtAdditionalSurvey
        v-show="steps.survey.show"
        :pageOffset="steps.survey"
        :customField="customFields"
        :appData="appData"
        @update="onCustomFieldUpdate"
        @fileUpdate="onCustomFieldFileUpdate"
      />
      <div class="button-container">
        <div
          @click="onPrev"
          :class="['button', 'prev']"
          :id="'cardResult_button_prev'"
        >
          이전
        </div>
        <div
          @click="onNext"
          :class="['button', 'next', { disabled }]"
          :id="'cardResult_button_next'"
        >
          다음
        </div>
      </div>
    </div>

    <SystemErrorDialog
      v-model="systemError"
      @ok="$emit('cancel')"
      :id="'additionalInput_sytemErrorPopup'"
    />
    <ExitDialog
      v-model="stopScan"
      @ok="$emit('cancel')"
      id="cardNotMatched_exitPopup"
      :disabledProp="!stopScan"
    />
  </div>
</template>

<script>
import rules from '../rules';
import server from '@/api/server';
import util from '@/util';
import SystemErrorDialog from '../dialog/SystemErrorDialog.vue';
import Loading from './Loading';
import CtAdditionalEdd from './CtAdditionalEdd.vue';
import CtAdditionalSurvey from './CtAdditionalSurvey.vue';
import ExitDialog from '../dialog/ExitDialog.vue';

export default {
  components: {
    SystemErrorDialog,
    Loading,
    CtAdditionalEdd,
    CtAdditionalSurvey,
    ExitDialog,
  },
  props: {
    appData: Object,
  },
  data: function () {
    return {
      rules,
      steps: {
        edd: { value: false, show: false, current: 0, last: 0 },
        survey: { value: false, show: false, current: 0, last: 0 },
      },
      SURVEY_LIMIT: 99,
      eddFields: [], // edd field 항목
      eddFieldItems: [], // edd field 결과
      customFields: [], // survey field 항목
      customItems: {}, // survey field 결과
      customFieldItems: [], // survey field 결과 (list)
      localFileObject: {},
      fileUploadManager: {},
      disabled: true,
      error: false,
      systemError: false,
      PHASE_LOADING: false,
      loadingMessages: ['추가 인증 정보를 전송 중입니다.'],
      loadingSuccess: false,
      stopScan: false,
    };
  },
  computed: {
    paging() {
      const { edd, survey } = this.steps;
      const last = edd.last + survey.last;
      const current = (function () {
        if (edd.value) {
          if (edd.show) return 1;
          return survey.current + 1;
        }
        return survey.current;
      })();
      return { current, last };
    },
  },
  created() {
    this.$log.debug('AdditionalInput #created');
    this.initialize();
  },
  mounted() {
    const { customFields, custom, customItems } = this; // prettier-ignore
    this.$log.debug('AdditionalInput #mouted', { customFields, custom, customItems }); // prettier-ignore
    this.validateForm();
  },
  updated() {
    const { steps } = this;
    this.$log.debug('AdditionalInput #updated', { ...steps });
  },
  methods: {
    initialize() {
      this.$log.debug('AdditionalInput #initialize', { ...this.appData });
      const { customField: respFields = [], eddField = [] } = this.appData;

      const activeCustomFields = respFields.filter((f) => f.active);
      const activeEddFields = eddField.flat().filter((f) => f.active);
      this.$log.debug('AdditionalInput #initialize', { activeEddFields, activeCustomFields }); // prettier-ignore

      this.initializeSteps(activeEddFields?.length, activeCustomFields?.length);

      this.$set(this, 'eddFields', activeEddFields);

      const orderedCustomFields = [...activeCustomFields].sort((a, b) => a.order - b.order); // prettier-ignore
      this.$set(this, 'customFields', orderedCustomFields);

      const { eddFields, customFields } = this;
      this.initializeResultObj(eddFields, customFields);

      this.$log.debug('AdditionalInput #initialize', { eddFieldItems: this.eddFieldItems, customItems: this.customItems }); // prettier-ignore
    },
    initializeResultObj(eddFields, surveyFields) {
      const eddFieldItems = eddFields.map((field) => {
        const { category, name, id, type } = field;
        return { category, name, id, score: 0, result: null, type };
      });
      this.eddFieldItems = eddFieldItems;

      const surveyFieldItems = surveyFields.reduce((acc, cur) => {
        const { name, id, type } = cur;
        return {
          ...acc,
          [id]: { id, name, value: '', attachment_id: null, type },
        };
      }, {});

      this.customItems = surveyFieldItems;
    },
    initializeSteps(eddLength = 0, surveyLength = 0) {
      const { use_edd, use_custom_field } = this.appData.config;
      if (use_edd && eddLength > 0) {
        this.steps.edd = { value: true, show: true, current: 1, last: 1 };
      }
      if (use_custom_field && surveyLength > 0) {
        if (!this.steps.edd.value) {
          this.steps.survey.show = true;
        }
        this.steps.survey.value = true;
        this.steps.survey.current = 1;
        // current, last 계산
        this.steps.survey.last = Math.ceil(surveyLength / this.SURVEY_LIMIT);
      }

      this.$log.debug('initializeSteps', { steps: this.steps });
    },
    validateForm() {
      const cnt = 5;
      const { current } = this.steps.survey;
      const start = (current - 1) * 5;
      const end = start + cnt;

      const customFieldValid = this.steps.survey.show
        ? this.customFields.slice(start, end).every((item) => {
            const field = this.customItems[item.id];
            if (item.required) {
              return (
                (item.type === 'file' && field.attachment_id) || field.value
              );
            }
            return true;
          })
        : true;
      const eddFieldValid = this.steps.edd.show
        ? this.eddFieldItems.every((el) => el.result)
        : true;
      this.disabled = !customFieldValid || !eddFieldValid;
    },
    onEddFieldUpdate(result) {
      this.eddFieldItems = result;
      this.$log.debug('AdditionalInput.vue #onEddFieldUpdate', result); // prettier-ignore
      this.validateForm();
    },
    onCustomFieldUpdate(result) {
      const { id, result: res } = result;
      this.customItems[id] = { ...this.customItems[id], value: res.value ?? "", attachment_id: res.attachment_id ?? null }; // prettier-ignore
      this.$log.debug('AdditionalInput.vue #onCustomFieldUpdate', result, this.customItems ); // prettier-ignore
      this.validateForm();
    },
    onCustomFieldFileUpdate({ fieldName, file_name, value }) {
      this.localFileObject[fieldName] = { file_name, value };
      this.$log.debug('AdditionalInput.vue #onCustomFieldFileUpdate', { fieldName, file_name, value, localFileObject: this.localFileObject }); // prettier-ignore
    },
    onClickRetry() {},
    onPrev() {
      const { edd, survey } = this.steps;
      this.$log.debug('AdditionalInput.vue #onPrev', { edd, survey });
      util.scrollTop();
      if (edd.show) {
        // edd 화면인 경우
        this.$emit('cancel', { prev: true });
        return;
      }
      // survey 화면인 경우
      if (survey.current > 1) {
        // survey page offset이 처음이 아닌 경우
        this.steps.survey.current -= 1;
        this.validateForm();
        return;
      }
      // survey page offset이 처음인 경우
      if (!edd.value) {
        this.$emit('cancel', { prev: true });
        return;
      }
      this.steps.edd.show = true;
      this.steps.survey.show = false;
      this.steps.survey.current = 1;
      this.validateForm();
    },
    onNext() {
      const { edd, survey } = this.steps;
      this.$log.debug('AdditionalInput.vue #onNext', { edd, survey });
      if (this.disabled) return;
      util.scrollTop();
      if (edd.value && edd.show) {
        // edd활성화, edd 화면인 경우
        if (!survey.value) {
          // survey 비활성화면 onSubmit
          this.onSubmit();
          return;
        }
        // survey 활성화면 survey 화면 표시
        this.steps.edd.show = false;
        this.steps.survey.show = true;
        this.validateForm();
        return;
      }
      // survey 화면
      if (survey.current === survey.last) {
        this.onSubmit();
        return;
      }
      this.steps.survey.current += 1;
      this.validateForm();
    },
    createPayload() {
      const { customItems, eddFieldItems, steps, appData } = this;
      const { edd, survey } = steps;
      const { allow_ra, use_ra, edd_countries_mode } = appData.config;
      // EDD & RA request payload
      const snapshot =
        allow_ra && use_ra
          ? {
              ra_threshold: appData.raThreshold,
              edd_categories: appData.eddCategory,
            }
          : null;
      const edd_field_result = edd.value
        ? eddFieldItems.map((field) => {
            const { category, result, type } = field;
            const { key, nation_info, country_id } = result;

            const countries = edd_countries_mode === 1 ? appData.countries : appData.eddCountries; 
            const country = countries.find((c) => c.id === country_id);
            const score = country_id ? (country?.score ?? 0) : field.score;

            const value = nation_info ? JSON.stringify([result.value, ...nation_info]) : result.value;

            return { category, score, key, value , type};
          })
        : null; // prettier-ignore

      // Survey request payload
      const survey_field_result = survey.value
        ? Object.values(customItems).map((item) => {
            const { name: key, value, attachment_id, type } = item;
            return { key, value, attachment_id, type };
          })
        : null;

      return { snapshot, edd_field_result, survey_field_result };
    },
    async onSubmit() {
      this.$log.debug('onSubmit');

      if (this.disabled) return;
      this.PHASE_LOADING = true;
      const { transaction_id } = this.appData;

      const { snapshot, edd_field_result, survey_field_result } = this.createPayload(); // prettier-ignore

      const eddReq = { transaction_id, snapshot, field_result: edd_field_result }; // prettier-ignore
      const surveyReq = { transaction_id, field_result: survey_field_result };

      this.$log.debug({ transaction_id, eddReq, surveyReq });

      try {
        this.loadingSuccess = true;
        this.loadingMessages = ['추가 인증이 완료되었습니다.'];

        const eddRes = eddReq.field_result && await server.startApplicationEdd(eddReq); // prettier-ignore
        const surveyRes = surveyReq.field_result && await server.startApplicationCustom(surveyReq); // prettier-ignore

        const review_result = eddRes?.review_result || surveyRes?.review_result;

        this.$log.debug('onSubmit response...', { eddRes, surveyRes, review_result }); // prettier-ignore

        await new Promise((resolve) => setTimeout(() => resolve(), 500));

        const result = { ...eddRes, ...surveyRes };
        this.$log.debug('onSubmit result', { result });

        this.PHASE_LOADING = false;

        if (surveyRes) {
          const { custom_result } = result.review_result;
          const attachment = custom_result.reduce((acc, cur) => {
            if (cur.type === 'file' && cur.attachment) {
              const { key: field_name, attachment } = cur;

              return {
                ...acc,
                [attachment.id]: {
                  file_name: attachment.name,
                  value: this.localFileObject[field_name].value,
                },
              };
            }
            return acc;
          }, {});
          if (Object.keys(attachment).length) {
            result.attachment = attachment;
          }
        }

        this.$log.debug('result', result);
        this.$emit('next', { response: result });
      } catch (error) {
        this.$log.debug(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.root-container {
  position: relative;
  display: flex;
  flex-direction: column;
  color: var(--surface-high);
  padding: 32px 30px 40px;

  &.logo {
    padding-top: 48px;
    .icon-prev,
    .icon-close {
      top: 48px;
    }
  }
}
.icon-prev {
  position: absolute;
  width: 32px;
  left: 32px;
  top: 32px;
  cursor: pointer;
}
.icon-close {
  position: absolute;
  width: 32px;
  right: 32px;
  top: 32px;
  cursor: pointer;
}

.additional-stepper {
  display: flex;
  justify-content: center;
  align-items: center;
  ul,
  li {
    list-style: none;
  }
  ul {
    display: flex;
    gap: 48px;

    li {
      font-size: 14px;
      .bullet {
        width: 10px;
        height: 10px;
        display: inline-block;
        border-radius: 50%;
        border: 2px solid var(--gray-300);

        &.prev {
          border-color: var(--primary-100);
          background-color: var(--primary-100);
        }
      }
      .index {
        color: var(--surface-high);
      }

      &:not(:first-of-type) {
        div::before {
          content: '';
          position: absolute;
          top: 4px;
          left: -48px;
          height: 1px;
          width: 48px;
          border: 1px dashed var(--gray-300);
        }
      }

      div {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;
        position: relative;

        &.current {
          .bullet {
            border-color: var(--primary-100);
          }
          .index {
            color: var(--primary-100);
            font-weight: 500;
          }
        }

        &.prev::before {
          border: 1px solid var(--primary-100);
        }
        &.current::before {
          border: 1px dashed var(--primary-100);
        }
      }
    }
  }
}

.additional-input-fields-container {
  & > div {
    margin-bottom: 24px;
  }
  article {
    margin-bottom: 24px;
    .optional__field__button {
      width: 100%;
      background-color: var(--surface-100);
      box-shadow: none;
      border: 1px solid var(--gray-300);
      border-radius: 8px;
      padding: 16px 24px;
      height: auto;
      justify-content: start;
      color: var(--surface-medium);
      font-size: 20px;
      font-weight: 400;
      &.selected {
        color: var(--surface-high);
      }
      &:hover:before,
      &:focus:before {
        opacity: 0;
      }
    }
    .optional__field__owner {
      display: flex;
      label {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        font-weight: 400;
        flex: 1;
        height: 64px;
        color: var(--surface-medium);
        border: 1px solid var(--gray-300);
        cursor: pointer;
        & input {
          display: none;
        }
        &:first-child {
          border-radius: 8px 0 0 8px;
        }
        &:last-child {
          border-radius: 0 8px 8px 0;
        }
        &.selected {
          color: var(--surface-high);
          border: none;
          border: 2px solid var(--primary-80);
        }
      }
    }
  }
}

.additional-input-title {
  font-size: 20px;
  line-height: 32px;
  font-weight: 500;

  text-align: center;
  margin-bottom: 32px;
}

.additional-input-info1 {
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  margin-bottom: 8px;
  text-align: center;
}

.additional-input-label {
  font-size: 16px;
  color: var(--surface-medium);
  margin-bottom: 8px;
}

#app
  > div.root-container-auth
  > div.root-container
  > div.flex-row-layout
  > div.input-textfield-root.correctJumin
  > div.input-textfield-container.error {
  border-color: var(--gray-300);
}

.button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 56px;

  cursor: pointer;
  background-color: white;

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    font-weight: 500;
    font-size: 1rem;
    height: 60px;
  }

  .retry {
    flex-grow: 3.5;

    margin-right: 10px;
    background-color: var(--gray-100);
    color: var(--surface-medium);
  }

  .prev {
    flex-grow: 4;
    background: var(--gray-100);
    color: var(--surface-medium);
    margin-right: 10px;
  }

  .next {
    flex-grow: 6;

    background-color: var(--primary-100);
    color: var(--font-color);
  }

  .next-lock {
    flex-grow: 1;
    background-color: var(--primary-100);
    color: var(--font-color);
  }

  .disabled {
    background-color: var(--primary-20);
  }
}
</style>
